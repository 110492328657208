import { Component, HostListener } from '@angular/core';
import { environment } from '@environments/environment';
import { NbIconLibraries } from '@nebular/theme';
import { AuthService } from './core/services/auth/auth.service';
import { CryptoService } from './core/services/crypto/crypto.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  exemptedPages = [
    '/public/flight-plan-details',
    '/page-not-found',
    '/login',
    '/forbidden',
    '/internal-server-error',
    '/activate-user',
    '/reset-password',
    '/forgot-password',
    '/register',
    '/terms-and-conditions',
  ];

  constructor(
    private iconLibraries: NbIconLibraries,
    private cryptoService: CryptoService,
    private authService: AuthService
  ) {
    this.iconLibraries.registerFontPack('material-icons', {
      packClass: 'material-icons',
      iconClassPrefix: 'material-icons',
    });
    this.iconLibraries.setDefaultPack('material-icons');

    if (
      this.cryptoService.getRawToken() &&
      this.authService.isTokenExpired() &&
      !this.exemptedPages.some((url) => window.location.href.includes(url)) &&
      window.location.href !== environment.base_url
    ) {
      this.authService.generateNewAccessToken();
    }

    if (this.authService.getUser() && !this.authService.isTokenExpired()) {
      if (environment.watch_idle) {
        this.authService.startIdleObserver();
      }
    }
  }

  @HostListener('document:keyup')
  @HostListener('document:click')
  @HostListener('document:wheel')
  resetTimer(): void {
    if (
      this.cryptoService.getRawToken() &&
      this.authService.isTokenExpired() &&
      !this.exemptedPages.some((url) => window.location.href.includes(url)) &&
      window.location.href !== environment.base_url
    ) {
      this.authService.generateNewAccessToken();
    }

    if (
      this.authService.getUser() &&
      !this.authService.isTokenExpired() &&
      environment.watch_idle
    ) {
      this.authService.restartIdleObserver();
    }
  }
}
