import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AddFlightService } from '@app/core/services/add-flight.service.ts.service';
import { PaymentService } from '@app/core/services/payment.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { NotiflixService } from '@app/shared/services/notiflix.service';

@Component({
  selector: 'app-payment-portal',
  templateUrl: './payment-portal.component.html',
  styleUrls: ['./payment-portal.component.scss'],
})
export class PaymentPortalComponent implements OnInit {
  @Output() redirect = new EventEmitter();
  @Input() totalAmount: string;
  @Input() flightPlanDetails: any;
  @Input() paymentBaseUrl: string;
  @Input() paymentHostedPage: string;
  token: string;
  subscriptions = new Subscription();
  receiveMessage: any;
  iframeLink: string;
  constructor(
    public paymentService: PaymentService,
    private router: Router,
    private addFlightService: AddFlightService,
    private notiflixService: NotiflixService
  ) {}

  ngOnInit(): void {
    this.getHppCode();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(): void {}

  getHppCode(): void {
    this.paymentService
      .getHppCode({ totalPayment: parseFloat(this.totalAmount) })
      .subscribe(
        (res) => {
          this.token = res.securityToken;
          this.iframeLink = `${this.paymentBaseUrl}${this.paymentHostedPage}/${res.securityToken}`;
          this.notiflixService.closeLoader();
        },
        (error) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchConfirm(
            'An Error Occurred',
            error.error.clientError.message,
            this,
            'Try Again',
            'Cancel'
          );
        }
      );
  }

  onPositive(): void {
    this.getHppCode();
  }

  onNegative(): void {
    this.router.navigate(['/flight-list'], {
      queryParams: { page: null },
      queryParamsHandling: 'merge',
    });
  }

  onOk(): void {
    this.router.navigate(['/flight-list'], {
      queryParams: { page: null },
      queryParamsHandling: 'merge',
    });
  }

  @HostListener('window:message', ['$event'])
  // tslint:disable-next-line: typedef
  onMessage(event) {
    switch (event.data.data) {
      case 'SuccessPayment':
        this.notiflixService.loadPulse('Processing...');
        this.flightPlanDetails.singleUseToken = this.token;
        this.addFlightService
          .createAddFlight(JSON.stringify(this.flightPlanDetails))
          .subscribe(
            (res) => {
              this.notiflixService.closeLoader();
              this.notiflixService.launchReport(
                'success',
                'Success',
                `${res.body.message}
               Reference Number: ${event.data.refNo}`,
                '',
                this
              );
            },
            (error) => {
              this.notiflixService.closeLoader();
              this.notiflixService.launchReport(
                'error',
                'Error',
                `${error.error.clientError}`,
                '',
                this
              );
            }
          );
        break;
      case 'CancelTransaction':
        this.router.navigate(['/flight-list'], {
          queryParams: { page: null },
          queryParamsHandling: 'merge',
        });
        break;
      case 'RetryPayment':
        // generate new security token\
        this.notiflixService.loadPulse('Processing...');
        this.getHppCode();
    }
  }
}
