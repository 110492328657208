import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { AuthService } from '@app/core/services/auth/auth.service';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  const = CONSTANTS;

  /** Variables for reset token and error trace ID */
  resetToken = '';
  errorTraceId = '';
  isLoading = true;

  countDown: Subscription;
  counter = 15;
  tick = 1000;

  returnUrl: string;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.userRoleLogin();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        const requestBody = {
          resetToken: params.token,
        };
        this.authService
          .verifyResetLink(requestBody)
          .toPromise()
          .then(
            () => {
              this.resetToken = params.token;
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
              this.redirectToForgotPassword();
            }
          );
      } else {
        this.isLoading = false;
        this.redirectToForgotPassword();
      }
    });
  }

  ngOnInit(): void {}

  onSubmitEvent(params): void {
    const newCredentials = {
      newPassword: params.newPassword,
      confirmPassword: params.confirmPassword,
      resetToken: this.resetToken,
    };
    this.authService.resetPassword(newCredentials);
  }

  redirectToForgotPassword(): void {
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter) {
        --this.counter;
      } else {
        this.router.navigate(['/forgot-password'], {
          queryParams: { token: null },
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  userRoleLogin(): void {
    if (this.authService.getUser()) {
      this.router.navigate(['/home'], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
      });
    }
  }
}
