import { Component, OnInit } from '@angular/core';
import { TableContents } from '@app/core/models/table-contents.model';
import { getRegisteredUsers } from '@app/core/store/flight-plan/flight-plan.action';
import { selectRegisteredUsers } from '@app/core/store/flight-plan/flight-plan.selector';
import { select, Store } from '@ngrx/store';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import { ngxCsv } from 'ngx-csv';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewUserDetailsComponent } from './view-user-details/view-user-details/view-user-details.component';

@Component({
  selector: 'app-registered-users',
  templateUrl: './registered-users.component.html',
  styleUrls: ['./registered-users.component.scss'],
})
export class RegisteredUsersComponent implements OnInit {
  tableContents: TableContents;
  public data: any;
  registeredUsers: any;
  loadingIndicator$ = false;
  advancedSearchKeys: any;

  constructor(
    private store: Store<FlightPlanState>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.registeredUsers = [];
    this.getRegisterUser();
    this.initTableContents();
  }

  initTableContents(): void {
    this.tableContents = {
      tableName: 'registered-users-table',
      noGenericSearch: true,
      isRowClickable: false,
      columns: [
        {
          prop: 'id',
          label: 'Ref',
          type: 'id',
        },
        {
          prop: 'carrierName',
          label: 'Carrier',
          type: 'passenger',
          minWidth: '200px',
          maxWidth: '200px',
        },
        {
          prop: 'name',
          label: 'ICAO 3 letters code',
          type: 'passenger',
          minWidth: '200px',
          maxWidth: '200px',
        },
        {
          prop: 'aircraftIdentifications',
          label: 'Aircraft Identification Number',
          type: 'passenger',
          minWidth: '200px',
          maxWidth: '200px',
        },
        {
          prop: 'actions',
          label: '',
          type: 'action',
        },
      ],
    };
  }

  getRegisterUser(): void {
    this.store.dispatch(getRegisteredUsers());
    this.store.pipe(select(selectRegisteredUsers)).subscribe((data) => {
      if (data) {
        this.registeredUsers = data ? data : [];
      }
    });
  }

  onViewUserDetails(event): void {
    const modalConfig = {
      disableClose: true,
      autoFocus: false,
      width: '1000px',
      maxHeight: '750px',
    };
    const dialogRef: MatDialogRef<ViewUserDetailsComponent> = this.dialog.open(
      ViewUserDetailsComponent,
      modalConfig
    );
    dialogRef.componentInstance.registeredUserDetails = event;
  }

  fileDownload(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Registered Users',
      useBom: true,
      noDownload: false,
      removeEmptyValues: true,
      headers: [
        'Ref',
        'Carrier',
        'ICAO 3 letters code',
        'Email',
        'Street',
        'City',
        'State',
        'Zipcode',
        'Country',
        'Aircraft Identification Number',
      ],
    };
    // tslint:disable-next-line: no-unused-expression
    new ngxCsv(this.registeredUsers, 'Registered Users', options);
  }
}
