<ng-container *ngIf="isLoading; else contents">
  <app-loader></app-loader>
</ng-container>
<ng-template #contents>
  <nb-layout>
    <nb-layout-header fixed>
      <div class="logo-wrapper">
        <!-- <img
          animateOnScroll
          animationName="animate__animated animate__fadeInRight"
          class="bahamas-logo"
          src="../../../../assets/images/Bahamas Logo.png"
          alt=""
          (click)="caabClick()"
        /> -->
        <img
          animateOnScroll
          animationName="animate__animated animate__fadeInRight"
          class="bansa-logo"
          src="../../../../assets/images/BANSA logo.png"
          alt=""
          (click)="logoClick()"
        />
      </div>
    </nb-layout-header>
    <nb-layout-column>
      <div class="terms-container">
        <h1>Term and Conditions</h1>
        <h5>1. General Terms and Conditions</h5>
        <p>
          The general Terms and Conditions detailed below govern the contractual
          relationship between the "User" (hereafter also referred to as "You"
          or the "Customer") and "The Bahamas Air Navigation Services Authority"
          (hereafter also referred to as "Authority" or the "Website") for the
          use of the electronic Flight Plan submission web portal
          <a target="_blank" href="www.flightplanbahamas.com"
            >www.flightplanbahamas.com</a
          >
          (hereafter also referred to as "the portal"). Both parties accept
          these Terms and Conditions unreservedly. These General Terms and
          Conditions are the only terms and conditions that are applicable to
          the use of the portal and replace all other conditions. We maintain
          that, by using the portal, you have read and do unreservedly accept
          our general Terms and Conditions. These Terms and Conditions are
          important to you and the Authority as they are used to protect your
          rights as a valued customer and our rights as a business.
        </p>
        <p>
          The portal provides digital access to air operators and pilots flying
          in The Bahamas airspace, for their registration and submission of
          flight plans, as well as for the payment of their due Air Traffic
          Control (ATC) charges, in accordance with their applicable charges, as
          published in the Aeronautical Information Publication of The Bahamas
          and/or any supplement thereof.
        </p>

        <h6>1.1 Use of the portal</h6>
        <p>
          All aircraft travelling in the sovereign airspace of The Bahamas shall
          be registered through the portal ensuring a clear identification of
          the aircraft operator and their fleet of operated aircraft through
          aircraft registration numbers, for charges billing and collection
          purposes.
        </p>
        <p>
          The personal data collected and processed through the FPL submission
          portal are kept, used and, if applicable, disclosed in accordance with
          the legislation currently in force in The Bahamas, i.e. the
          <a
            target="_blank"
            href="http://laws.bahamas.gov.bs/cms/images/LEGISLATION/PRINCIPAL/2003/2003-0003/DataProtectionPrivacyofPersonalInformationAct_1.pdf"
            >Data Protection Law 25/2007</a
          >
        </p>
        <p>
          Any user proving personal data could exercise his/her rights of
          access, rectification or deletion from the portal databases by writing
          to
          <a href="mailto: {{ env.support_email }}">{{ env.support_email }}</a>
          with an explanation of the change.
        </p>

        <div id="payment">
          <h6>1.2 Flight plan submission and validation</h6>
          <p>
            Every flight departing or arriving in an aerodrome in the Bahamas
            shall submit its flight plan to the appointed national Authorities.
            The portal allows the electronic submission of the flight plan and
            automatically calculates its applicable charges.
          </p>

          <p>
            The veracity and correctness of the information contained in the
            Flight Plan submitted through the portal is under the sole
            responsibility of the user submitting the flight plan.
          </p>
        </div>
        <div>
          <h6>1.3 Secure payment</h6>
          <p>
            The portal payment options use advanced SSL encryption to keep your
            transaction secure and will not charge any additional cost for its
            use to the user.
          </p>

          <p>
            The Authority does everything in its power to respect the delivery
            times that may be indicated on the website. We cannot, however, be
            held responsible for the consequences of a late delivery caused by a
            third-party contracted, or by you, or because of some unforeseen
            event or an Act of God. In the event that any specific payment
            process is not correctly processed, an investigation will be
            conducted and may take several days upon receipt of your claim.
            During this period of investigation, no reimbursement or re-delivery
            will take place.
          </p>

          <p>
            For any issue with the payment gateway, the user could contact First
            Atlantic Commerce (FAC) at:
            <a href="mailto:support@fac.bm">support@fac.bm</a>
          </p>
        </div>

        <h6>1.4 Cancellation policy</h6>
        <p>
          Any refund of ATC charges paid through the portal will be considered
          only in case of flight cancellations not imputable to the pilot or the
          aircraft operator. These cases include ATC unavailability of service
          or airport closure due to severe adverse weather, preventing the
          flight to operate according to its flight plan.
        </p>

        <p>
          To request a refund, an email shall be sent to
          <a href="mailto:{{ env.support_email }}">{{ env.support_email }}</a
          >, with subject "Bahamas ATC charge refund request – ANM – date+time"
          where ANM stands for the applicable Aircraft registration number and
          date+time stands for the date and time of flight operation (i.e.
          either the take-off from or the arrival to) the airport within The
          Bahamas, as approved in the flight plan. The Refund Form reported in
          Annex I below shall be duly filled in and attached to the email,
          together with the flight plan, both in pdf format.
        </p>
        <p>
          The reasons for any flight cancellation and the consequent refund of
          ATC charges will be reviewed in detail by the Authority in each case
          and a response will be communicated to each case within 90 days from
          the original request.
        </p>

        <p>
          Any refund approved by the Authority will be provided in the form of
          credits, to be spendable through the IATA SIS platform for ATC charges
          applicable to flights in The Bahamas operated by the same refunded
          operator.
        </p>

        <p>The refund process is summarized in Figure 1 below.</p>

        <figure>
          <img
            src="../../../assets/images/cancel-refund-process.png"
            alt="Cancellation/refund process"
            style="width: 100%"
          />
          <figcaption>Figure 1: Cancellation/refund process</figcaption>
        </figure>

        <div id="data-privacy">
          <h6>1.5 Data privacy policy</h6>
          <p>
            The details you give us are essential for the processing flight
            plans and delivery ATC services, therefore failure to provide
            correct details can result in the lack of approval of the flight
            plan. By using the portal, you agree to provide us with sincere and
            true information as it concerns you or your company. Communicating
            false information is contrary to the present General Terms and
            Conditions.
          </p>
          <p>
            The personal data collected and processed through the portal is
            managed in accordance with the legislation currently in force in The
            Bahamas, i.e. the Data Protection Law 25/2007. Please be assured
            that the Authority is committed to honor your privacy and treat data
            with the sole objective of efficiently providing services, better
            understanding your needs and respond to your queries in a timely
            fashion.
          </p>
          <p>
            With the act of registering in the FPL submission portal, the user
            declares that he has read this information and accepts this policy.
            Should the user refuse to provide any required data, he/she will not
            be authorized to access the electronic FPL submission service.
          </p>
          <p>
            Access to this website may also imply the use of cookies. Cookies
            are small amounts of information that are stored in the user's
            browser in order for the server to remember certain information that
            only the server that created the cookie may subsequently read. In
            general, cookies have a limited duration. Cookies do not allow users
            to be contacted through their telephone number, e-mail address or by
            any other means of contact. Cookies are not able to extract
            information from the user's hard drive or steal personal
            information. Those users who do not wish to store cookies or who
            want to be notified when they are added may configure their browsers
            accordingly.
          </p>
        </div>
        <br />

        <h5>
          <a href="assets/files/Annex I.pdf" class="annex"
            >Annex I - Refund Form</a
          >
        </h5>
      </div>
    </nb-layout-column>
    <nb-layout-footer>
      <app-footer></app-footer>
    </nb-layout-footer>
  </nb-layout>
</ng-template>
