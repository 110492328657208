import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth/auth.service';
import { SystemParameterService } from '@app/core/services/system-parameter.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { AddSystemParametersComponent } from '../add-system-parameters/add-system-parameters/add-system-parameters.component';

@Component({
  selector: 'app-edit-system-parameters',
  templateUrl: './edit-system-parameters.component.html',
  styleUrls: ['./edit-system-parameters.component.scss'],
})
export class EditSystemParametersComponent implements OnInit, OnDestroy {
  editSystemParametersForm: FormGroup;
  systemParameters: any;
  filteredSystemParams: any;
  typeList: any;
  currentTransaction: string;
  checker: any;
  data: any;
  systemParams: any;
  initialParams: any;

  constructor(
    private dialogRef: MatDialogRef<AddSystemParametersComponent>,
    private systemParametersService: SystemParameterService,
    private formBuilder: FormBuilder,
    private notiflixService: NotiflixService,
    private authService: AuthService
  ) {}

  @HostListener('window:beforeunload', ['$event']) unloadHandler(
    event: Event
  ): void {
    const formLength = [
      JSON.stringify(this.editSystemParametersForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentFormLength !== formLength) {
        // tslint:disable-next-line: deprecation
        event.returnValue = false;
      }
      // tslint:disable-next-line: deprecation
      // event.returnValue = false; // stay on same page
    }
  }

  ngOnInit(): void {
    this.getTypes();
    this.initFormControls();
    this.initialParams = this.editSystemParametersForm.value;
  }

  getTypes(): void {
    this.systemParametersService.getTypeList().subscribe((data) => {
      this.typeList = data;
    });
  }

  initFormControls(): FormGroup {
    return (this.editSystemParametersForm = this.formBuilder.group({
      typeId: [
        this.systemParams ? this.systemParams.systemParameterType.id : '',
        Validators.required,
      ],
      typeMotor: [
        this.systemParams ? this.systemParams.typeMotor : '',
        Validators.required,
      ],
      numberMotors: [
        this.systemParams ? this.systemParams.numberMotors : '',
        Validators.required,
      ],
      mtow: [
        this.systemParams ? this.systemParams.mtow : '',
        Validators.required,
      ],
      mtowClass: [
        this.systemParams ? this.systemParams.mtowClass : '',
        Validators.required,
      ],
      displayWarning: [
        this.systemParams ? this.systemParams.displayWarning : '',
        Validators.required,
      ],
      code: [
        this.systemParams ? this.systemParams.code : '',
        Validators.required,
      ],
      value: [
        this.systemParams ? this.systemParams.value : '',
        Validators.required,
      ],
      description: [
        this.systemParams ? this.systemParams.description : '',
        Validators.required,
      ],
    }));
  }

  close(): void {
    if (
      JSON.stringify(this.initialParams) !==
      JSON.stringify(this.editSystemParametersForm.value)
    ) {
      this.currentTransaction = 'BACK';
      this.notiflixService.launchConfirm(
        'Are you sure you want to exit?',
        'Changes you made will not be saved.',
        this,
        'EXIT',
        'NO'
      );
    } else {
      this.currentTransaction = 'BACK';
      this.onPositive();
    }
  }

  onUpdate(): void {
    this.currentTransaction = 'UPDATE';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'System Parameters will be saved.',
      this,
      'UPDATE',
      'CANCEL'
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'UPDATE':
        this.onUpdateSystemParameters();
        const saveBtn = document.getElementById('updateBtn') as HTMLElement;
        saveBtn.click();
        this.dialogRef.close();
        break;
      case 'BACK':
        this.dialogRef.close();
        break;
      default:
        throw new Error(`${this.currentTransaction} is invalid`);
    }
  }

  onChange(value): void {
    if (value !== 3) {
      this.editSystemParametersForm.get('typeMotor').clearValidators();
      this.editSystemParametersForm.get('typeMotor').updateValueAndValidity();
      this.editSystemParametersForm.get('numberMotors').clearValidators();
      this.editSystemParametersForm
        .get('numberMotors')
        .updateValueAndValidity();
      this.editSystemParametersForm.get('mtow').clearValidators();
      this.editSystemParametersForm.get('mtow').updateValueAndValidity();
      this.editSystemParametersForm.get('mtowClass').clearValidators();
      this.editSystemParametersForm.get('mtowClass').updateValueAndValidity();
      this.editSystemParametersForm.get('displayWarning').clearValidators();
      this.editSystemParametersForm
        .get('displayWarning')
        .updateValueAndValidity();
    } else {
      this.editSystemParametersForm
        .get('typeMotor')
        .setValidators([Validators.required]);
      this.editSystemParametersForm.get('typeMotor').updateValueAndValidity();
      this.editSystemParametersForm
        .get('numberMotors')
        .setValidators([Validators.required]);
      this.editSystemParametersForm
        .get('numberMotors')
        .updateValueAndValidity();
      this.editSystemParametersForm
        .get('mtow')
        .setValidators([Validators.required]);
      this.editSystemParametersForm.get('mtow').updateValueAndValidity();
      this.editSystemParametersForm
        .get('mtowClass')
        .setValidators([Validators.required]);
      this.editSystemParametersForm.get('mtowClass').updateValueAndValidity();
      this.editSystemParametersForm
        .get('displayWarning')
        .setValidators([Validators.required]);
      this.editSystemParametersForm
        .get('displayWarning')
        .updateValueAndValidity();
    }
  }

  onNegative(): void {}

  onUpdateSystemParameters(): void {
    this.systemParametersService.editSystemParameters(
      this.systemParams.id,
      this.editSystemParametersForm.value
    );
  }

  ngOnDestroy(): void {}
}
