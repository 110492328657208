import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TableContents } from '@app/core/models/table-contents.model';
import { getFlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.action';
import { FlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.model';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import { selectFlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.selector';
import { select, Store } from '@ngrx/store';
import { CONSTANTS } from '@app/core/constants/constants.const';
import * as moment from 'moment';
import { environment } from '@environments/environment';
import { PAYMENT_STATUS } from '@app/core/constants/payment-status';

@Component({
  selector: 'app-view-flight-plan',
  templateUrl: './view-flight-plan.component.html',
  styleUrls: ['./view-flight-plan.component.scss'],
})
export class ViewFlightPlanComponent implements OnInit {
  public data: any;
  flightPlanDetails: FlightPlanDetails;
  tableContents: TableContents;
  loadingIndicator$ = false;
  advancedSearchKeys: any;
  qrCode: string;
  paymentStatus = PAYMENT_STATUS;
  constructor(
    private dialogRef: MatDialogRef<ViewFlightPlanComponent>,
    private store: Store<FlightPlanState>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getFlightPlanDetails({ id: this.data }));
    this.store
      .pipe(select(selectFlightPlanDetails))
      .subscribe((res: FlightPlanDetails) => {
        this.flightPlanDetails = res;
        this.qrCode = `${environment.base_url}public/flight-plan-details?qrIdentifier=${this.flightPlanDetails.qrIdentifier}`;
        this.initTableContents();
      });
  }

  initTableContents(): void {
    this.tableContents = {
      tableName: 'view-passengers',
      noGenericSearch: true,
      isRowClickable: false,
      columns: [
        {
          prop: 'name',
          label: 'Name',
          type: 'passenger',
        },
        {
          prop: 'address',
          label: 'Address',
          type: 'passenger',
          maxWidth: '250px',
        },
        {
          prop: 'phone',
          label: 'Phone',
          type: 'passenger',
        },
        {
          prop: 'emergencyContact',
          label: 'Passenger emergency contact',
          type: 'passenger',
        },
      ],
    };
  }

  formatTime(time: string): string {
    return moment(time, 'HH:mm').format('HH:mm');
  }

  close(): void {
    this.dialogRef.close();
  }
}
