import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  SimpleChanges,
  OnChanges,
  AfterContentInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewFlightPlanComponent } from '@app/pages/view-pages/flight-plan/view-flight-plan/view-flight-plan.component';
import { AircraftIdentificationComponent } from '@app/pages/user/my-profile/aircraft-identification/aircraft-identification.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mat-data-table',
  templateUrl: './mat-data-table.component.html',
  styleUrls: ['./mat-data-table.component.scss'],
})
export class MatDataTableComponent
  implements OnInit, OnChanges, AfterContentInit
{
  @Output() isCopyFlight: EventEmitter<any> = new EventEmitter();
  @Output() isSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() isViewDetails: EventEmitter<any> = new EventEmitter();
  @Output() isViewUserDetails: EventEmitter<any> = new EventEmitter();
  @Output() isSendVfrMail: EventEmitter<any> = new EventEmitter();
  @Output() isEdit: EventEmitter<any> = new EventEmitter();
  @Output() isDelete: EventEmitter<any> = new EventEmitter();
  @ViewChild('matTable', { static: false }) table: MatTable<any>;
  @ViewChild('tableRow', { static: false }) row: ElementRef;
  displayedData: MatTableDataSource<object>;
  displayedColumns: Array<any>;
  displayNoRecords = false;
  hasNoRecord: boolean;
  @Input() tableContents;
  @Input() component;
  @Input() dialogRef;
  @Input() dataSource: Array<any>;
  @Input() loadingIndicator: Observable<boolean>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() advancedSearchKeys: any;
  @Input() expenseTypeList: any;
  @Input() taxExemptionList: any;
  @Input() filingType: string;
  @Input() copyFlight: boolean;
  @Input() viewFlightDetails: boolean;
  @Input() sendVfrMail: boolean;
  @Input() editButton: boolean;
  @Input() deleteButton: boolean;
  @Input() viewUserDetails: boolean;
  filteredData = [];
  tableComponent: this;
  updateReceiptForm = null;
  minReceiptDate = null;
  maxReceiptDate = null;
  PATTERN = {
    NUMERIC: 'Numeric',
    AMOUNT: 'Amount',
  };
  constructor(
    private dialogService: NbDialogService, // private xlsxService: XlsxService
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setTableContents();
    // if (
    //   this.displayedData.filteredData.length === 0 &&
    //   this.displayedData.data.length !== 0
    // ) {
    //   this.displayNoRecords = true;
    // } else {
    //   this.displayNoRecords = false;
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayedData = new MatTableDataSource(this.dataSource);
    if (this.displayedData) {
      if (this.displayedData.data.length === 0) {
        this.displayNoRecords = true;
      } else {
        this.displayNoRecords = false;
      }
      this.setTableContents();
    }

    if (changes.advancedSearchKeys) {
      this.applyAdvancedFilter();
    }
  }

  ngAfterContentInit(): void {
    this.setTableContents();
  }

  setTableContents(): void {
    this.displayedColumns = this.tableContents.columns;
    this.displayedData = new MatTableDataSource(this.dataSource);
    this.displayedData.paginator = this.paginator;
    this.displayedData.sortingDataAccessor = (data: any, sortHeaderId) =>
      this.tableSortingDataAccessor(data, sortHeaderId);
    this.displayedData.sort = this.sort;
    this.setSortable();
    this.displayedData.filterPredicate = (data, filter: string): boolean => {
      const checkers = [];
      for (const e of this.tableContents.columns) {
        if (e.prop !== 'actions') {
          if (data[e.prop]) {
            const isTrue = data[e.prop]
              .toString()
              .toLowerCase()
              .trim()
              .includes(filter)
              ? true
              : false;
            checkers.push(isTrue);
          }
        }
      }
      return checkers.includes(true);
    };

    this.applyAdvancedFilter();
    if (this.displayedData.data !== null) {
      if (this.displayedData.data.length <= 0) {
        this.hasNoRecord = true;
      } else {
        this.hasNoRecord = false;
      }
    }

    // For refreshing data table
    if (this.table) {
      this.table.renderRows();
    }
  }

  tableSortingDataAccessor(data: any, sortHeaderId): any {
    switch (sortHeaderId) {
      case 'taxExFilingEndDate': {
        return new Date(data.taxExemptFilingEdate);
      }
      case 'taxExFilingStartDate': {
        return new Date(data.taxExemptFilingSdate);
      }
      case 'createdDate': {
        return new Date(data.createdDate);
      }
      case 'dateHired': {
        return new Date(data.dateHired);
      }

      default: {
        return data[sortHeaderId].toString().toLowerCase();
      }
    }
  }

  applyFilter(searchKey: string): void {
    // Filter the records displayed on the table
    this.displayedData.filter = searchKey.trim().toLowerCase();
    if (
      this.displayedData.filteredData.length === 0 &&
      this.displayedData.data.length !== 0
    ) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }

    // Set the results page to first page when searching
    if (this.displayedData.paginator) {
      this.displayedData.paginator.firstPage();
    }
  }

  applyAdvancedFilter(): void {
    if (this.dataSource) {
      if (this.advancedSearchKeys) {
        this.filteredData = this.dataSource.filter((item) => {
          for (const key of Object.keys(this.advancedSearchKeys)) {
            // item[key] = item[key] ? item[key] : '';
            if (
              item[key]
                .toString()
                .toLowerCase()
                .indexOf(
                  this.advancedSearchKeys[key].toString().toLowerCase()
                ) === -1
            ) {
              return false;
            }
          }
          return true;
        });
        this.displayedData = new MatTableDataSource(this.filteredData);
        this.displayedData.sort = this.sort;
        this.setSortable();
        this.displayedData.paginator = this.paginator;
        this.table.renderRows();
        if (this.displayedData.data.length === 0) {
          this.displayNoRecords = true;
        } else {
          this.displayNoRecords = false;
        }
      }
    }
  }

  // Uncomment if there is a functionality for exporting the table to excel file
  // handleExportBtn() {
  //   this.xlsxService.exportAsXLSX(this.displayedData.filteredData);
  // }

  getColumnHeaders(): Array<string> {
    return this.tableContents.columns.map((column) => {
      return column.prop;
    });
  }

  // onDelete(record: any): void {
  //   this.component.onDelete(record);
  // }

  removeAccess(record: any): void {
    this.component.onRemoveAccess(record);
  }

  setSortable(): void {
    if (this.tableContents.sort) {
      this.sort.active = this.tableContents.sort.active;
      this.sort.direction = this.tableContents.sort.direction;
      this.sort.sortChange.emit(this.tableContents.sort);
    }
  }

  onRowClick(row: any): void {
    if (this.component.onRowClick) {
      this.component.onRowClick(row);
    }
  }

  onViewDetails(data: any): void {
    this.isViewDetails.emit(data);
  }

  onCopyFlight(data: any): void {
    this.isCopyFlight.emit(data);
  }

  onSendVfrMail(data: any): void {
    this.isSendVfrMail.emit(data);
  }

  onDelete(data: any): void {
    this.isDelete.emit(data);
  }

  onEdit(data: any): void {
    this.isEdit.emit(data);
  }

  onViewUserDetails(data: any): void {
    this.isViewUserDetails.emit(data);
  }
}
