<nb-layout *ngIf="!isLoggingout" class="page-container-one">
  <nb-layout-header subheader fixed>
    <app-header
      class="padding-sides"
      [component]="this"
      [menuItems]="menuItems"
    ></app-header>
  </nb-layout-header>

  <nb-sidebar tag="left" class="sidebar-left" [responsive]="true">
    <app-sidebar></app-sidebar>
  </nb-sidebar>

  <nb-layout-column class="page-container">
    <section>
      <router-outlet></router-outlet>
    </section>
  </nb-layout-column>

  <nb-layout-footer>
    <app-footer class="padding-sides"></app-footer>
  </nb-layout-footer>
</nb-layout>
<ng-container *ngIf="isLoggingout">
  <app-loader [message]="logoutMsg"></app-loader>
</ng-container>
