export const CONFIRM_MESSAGES = {
  SWAL_SAMPLE: {
    title: 'Just making sure',
    body: 'You wanted to see this sample?',
  },
  SWAL_LOGOUT: {
    title: 'Just making sure',
    body: 'Are you sure you want to log out? ',
  },
  SWAL_ADD_EMPLOYEE: {
    title: 'Just making sure',
    body: 'Are you sure you want to add new employee? ',
  },
  SWAL_DELETE_APP: {
    title: 'Just making sure',
    body: 'Are you sure you want to delete',
  },
  SWAL_REMOVE_USER_ACCESS: {
    title: 'Just making sure',
  },
  SWAL_BATCH_ACTIVATE: {
    title: 'Just making sure',
    body: 'Are you sure you want to activate users?',
  },
  SWAL_EDIT_EMPLOYMENT_INFO: {
    title: 'Just making sure',
    body: 'Are you sure you want to edit employment information? ',
  },
  SWAL_RESEND_ACTIVATION_EMAIL: {
    title: 'Just making sure',
    body: 'Resend activation email to the selected employee/s? ',
  },
  SAVE_PERSONAL_INFO_EDIT: {
    title: 'Just making sure',
    body: `You are about to save the employee's personal information.`,
  },
  ASSIGN_ACCESS: {
    title: 'Just making sure',
    assign: 'Assign',
    accessToEmployees: 'access to the selected employee/s?',
  },
  REMOVE_ACCESS: {
    title: 'Just making sure',
    remove: 'Remove',
    accessToEmployees: 'access to the selected employee/s?',
  },
  UNSAVED_DATA: {
    title: 'Just making sure',
    body: 'You are about to exit. Do you want to discard all details?',
  },
  DISCARD_CHANGES: {
    title: 'Discard changes?',
    body: 'You have unsaved changes. Do you want to exit?',
  },
  SAVE_TAG_AS_SEPARATED: {
    title: 'Just making sure',
    body: 'You are about to tag this employee as separated.',
  },
  REHIRE_EMPLOYEE: {
    title: 'Just making sure',
    body: `Upon successful re-hiring process, the employee's user account will be reactivated and an email notification will be sent to the employee.`,
  },
  DEACTIVATE_USERS: {
    title: 'Just making sure',
    body: 'Are you sure you want to deactivate users?',
  },
  REACTIVATE_USERS: {
    title: 'Just making sure',
    body: 'Are you sure you want to reactivate users?',
  },
  EDIT_PERSONAL_INFO_AS_USER: {
    title: 'Just making sure',
    body: 'Are you sure you want to edit your personal information? ',
  },
  BATCH_ADD_EMPLOYEE: {
    title: 'Just making sure',
    body: 'Add and create account for these employees?'
  }
};
