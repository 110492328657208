import { createReducer, on } from '@ngrx/store';
import {
  AircraftIdentifications,
  CurrentUser,
  RegisteredUser,
  RegisteredUsers,
} from './registered-user.model';
import {
  getRegisteredUser,
  getRegisteredUserSuccess,
  getRegisteredUserFailure,
  getAircraftIdNumber,
  getAircraftIdNumberSuccess,
  getAircraftIdNumberFailure,
} from './registered-user.action';

export const registeredUserFeatureKey = 'registeredUser';

export interface RegisteredUserState {
  currentUser: CurrentUser[];
  editAircraft: RegisteredUser;
  error?: any;
}

export const initialState: RegisteredUserState = {
  currentUser: undefined,
  editAircraft: undefined,
};

export const reducer = createReducer(
  initialState,

  on(getRegisteredUser, (state) => ({ ...state })),

  on(getRegisteredUserSuccess, (state, action) => ({
    ...state,
    currentUser: action.response,
  })),

  on(getRegisteredUserFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),

  on(getAircraftIdNumber, (state) => ({ ...state })),
  on(getAircraftIdNumberSuccess, (state, action) => ({
    ...state,
    editAircraft: action.response,
  })),
  on(getAircraftIdNumberFailure, (state, action) => ({
    ...state,
    error: action.error,
  }))
);
