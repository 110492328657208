import { createAction, props } from '@ngrx/store';

export const getCurrentUserInfo = createAction(
  '[Pages Component] Get Current User Info'
);

export const getCurrentUserInfoSuccess = createAction(
  '[API Current User Info] Get Current User Info Success',
  props<{ response: any }>()
);

export const getCurrentUserInfoFailure = createAction(
  '[API Current User Info] Get Current User Info Failure',
  props<{ error: any }>()
);
