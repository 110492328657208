<div class="container downloadPDF">
  <div class="row">
    <div class="col-12">
      <button
        nbButton
        status="info"
        size="small"
        fullWidth
        (click)="downloadPDF()"
      >
        Download PDF
      </button>
    </div>
  </div>
</div>

<div class="pdf-holder">
  <div class="pdf-container" id="pdf-container">
    <div class="header-container">
      <div class="row">
        <div class="col-12">
          <div class="logo-container">
            <img
              class="logo"
              src="../../../../assets/images/Bahamas Logo.png"
              alt=""
            />
          </div>
          <div class="qr-container">
            <img
              class="logo"
              src="../../../../assets/images/BANSA logo.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="body-container">
      <div class="header-title">
        <h3>Flight Plan</h3>
      </div>
      <br />
      <div class="flight-plan-details">
        <div class="header-wrapper">
          <div class="row">
            <div class="col-5">
              <div class="pilot-profile-wrapper">
                <table>
                  <tr>
                    <td rowspan="2">
                      <div class="pilot-icon">
                        <img
                          src="../../../../../assets/images/pilot-logo.png"
                          alt="Pilot icon"
                        />
                      </div>
                    </td>
                    <td>
                      <h5 class="pilot-name">
                        {{ flightPlanDetails.pilotInformation?.name }}
                      </h5>
                      <span class="pilot-base">
                        {{
                          flightPlanDetails.pilotInformation?.aircraftHomeBase
                        }}
                      </span>
                      <span class="pilot-number">
                        {{ flightPlanDetails.pilotInformation?.phone }}
                      </span>
                      <p>{{ flightPlanDetails.pilotInformation?.email }}</p>
                      <p>{{ flightPlanDetails.pilotInformation?.address }}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-5">
              <div class="filing-wrapper left-border">
                <div class="row">
                  <div class="col-6">
                    <div class="filing-details-wrapper">
                      <div class="label-wrapper">
                        <span class="label">Filing Date</span>
                      </div>
                      <div class="value-wrapper">
                        <p class="value">
                          {{
                            flightPlanDetails.filingDate | date: 'dd/MM/yyyy'
                          }}
                        </p>
                      </div>
                      <div class="label-wrapper">
                        <span class="label">Filing Time</span>
                      </div>
                      <div class="value-wrapper">
                        <p class="value">
                          {{ formatTime(flightPlanDetails.filingTime) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <qrcode
                      [qrdata]="qrCode"
                      [width]="150"
                      [errorCorrectionLevel]="'M'"
                      [elementType]="'svg'"
                    ></qrcode>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body-wrapper">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <div class="body-title">
                <h3>Flight Details</h3>
              </div>
              <table class="details">
                <tr>
                  <td class="item-label td-wrapper">Flight Date</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.flightDate | date: 'dd/MM/yyyy' }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Departure Time (local time)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ formatTime(flightPlanDetails.departureTime) }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Offblock Time (local time)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ formatTime(flightPlanDetails.offBlockTime) }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Type of Operation</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.typeOperation }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Type of Flight</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.typeFlight }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Flight Rules</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.flightRules }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Aircraft Identification</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.aircraftIdentification }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Type of aircraft (ICAO)</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.aircraftType }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Number of Motors</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.numberMotors }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Type of Motors</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.typeMotor }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">MTOW (kg)</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.mtow }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <div class="body-title">
                <h3>Departure Details</h3>
              </div>
              <table class="details">
                <tr>
                  <td class="item-label td-wrapper">
                    Departure Aerodrome (ICAO)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.departureAerodrome | uppercase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Destination Aerodrome (ICAO)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.destinationAerodrome | uppercase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Other Information</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.otherInformation }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Estimated Time Enroute Hours
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.estimatedTimeEnrouteHours }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Estimated Time Enroute Minutes
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.estimatedTimeEnrouteMinutes }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Fuel Endurance Hours</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.fuelEnduranceHours }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Fuel Endurance Minutes</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.fuelEnduranceMinutes }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Route</td>
                  <td class="item-value td-wrapper wrap-text">
                    {{ flightPlanDetails.flightRoute | uppercase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Cruising speed (TAS-Knots)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.cruisingSpeed }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Cruising level</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.cruisingAltitude | uppercase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Alternate Aerodrome (ICAO)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.alternateAerodrome | uppercase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    2nd Alternate Aerodrome (ICAO)
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.secondAlternateAerodrome || '-' | uppercase }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="seperator"></div>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <div class="body-title">
                <h3>Emergency Details</h3>
              </div>
              <table class="details">
                <tr>
                  <td class="item-label td-wrapper">Emergency Radio</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.emergencyRadio }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Number of life jackets and color
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.numberLifeJacketsColor | titlecase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Number of dinghies</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.dinghiesNumber | titlecase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Capacity of dinghies</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.dinghiesCapacity | titlecase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Color of dinghies</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.dinghiesColor | titlecase }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">
                    Aircraft color and markings
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.aircraftColor | titlecase }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <br />
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <div class="body-title">
                <h3>Passenger Details</h3>
              </div>
              <table class="details">
                <tr>
                  <td class="item-label td-wrapper">
                    Total number of person on board
                  </td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.totalNumberPersonsOnBoard }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Method of closure</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.methodClosure }}
                  </td>
                </tr>
                <tr>
                  <td class="item-label td-wrapper">Equipment</td>
                  <td class="item-value td-wrapper">
                    {{ flightPlanDetails.otherEquipmentRemarks }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <div class="body-title">
                <h3>Payment Details</h3>
              </div>
              <table class="payment-table">
                <tr>
                  <td colspan="2" class="item-label">Reference Number</td>
                  <td class="item-value center">
                    {{
                      flightPlanDetails?.paymentResponse?.refNo || '-'
                    }}
                  </td>
                  <td colspan="2"></td>
                  <td colspan="2" class="item-label pay-status">Payment Status</td>
                  <td class="item-value center">
                    {{
                      flightPlanDetails?.paymentInformation?.paymentStatus !== null && !flightPlanDetails?.paymentInformation?.paymentStatus ? paymentStatus.PAYMENT_WITH_IATA : paymentStatus.PAYMENT_COMPLETED
                    }}
                  </td>
                </tr>
                <tr><td class="spacer"></td></tr>
                <tr>
                  <td colspan="2" class="item-label">ATC charge</td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails?.paymentInformation?.atcCharge || 0
                        | number: '1.2-2'
                    }}
                  </td>
                  <td colspan="2" class="item-label">
                    Tax amount (VAT, BSD)
                  </td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails?.paymentInformation?.taxAtc || 0
                        | number: '1.2-2'
                    }}
                  </td>
                  <td colspan="2" class="item-label">
                    Subtotal amount (BSD)
                  </td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails?.paymentInformation?.subtotalAtc || 0
                        | number: '1.2-2'
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="item-label">
                    Passenger charge
                  </td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails?.paymentInformation?.passengerCharge ||
                        0 | number: '1.2-2'
                    }}
                  </td>
                  <td colspan="2" class="item-label">
                    Tax amount (VAT, BSD)
                  </td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails?.paymentInformation?.taxPassenger || 0
                        | number: '1.2-2'
                    }}
                  </td>
                  <td colspan="2" class="item-label">
                    Subtotal amount (BSD)
                  </td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails.paymentInformation?.subtotalPassenger ||
                        0 | number: '1.2-2'
                    }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colspan="2" class="item-label">Total Payment</td>
                  <td class="item-value payment">
                    {{
                      flightPlanDetails?.paymentInformation?.totalPayment || 0
                        | number: '1.2-2'
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
