import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS, BUTTONS } from '@app/core/constants/constants.const';
import { AuthService } from '@app/core/services/auth/auth.service';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { RegisterUserService } from '@app/core/services/register-user.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  subscriptions$ = new Subscription();

  appVersion = environment.version;
  constants = CONSTANTS;
  buttons = BUTTONS;

  isLoading = false;
  /** Checks if the form has an error */
  hasError: boolean;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private router: Router,
    private route: ActivatedRoute,
    private notiflixService: NotiflixService,
    private userService: RegisterUserService
  ) {
    this.userRoleLogin();
  }

  ngOnInit(): void {
    this.initFormControls();
  }

  initFormControls(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          this.userService.validateEmail,
          Validators.maxLength(50),
        ],
      ],
    });
  }

  onSubmit(): void {
    this.notiflixService.loadPulse('Processing...');
    if (this.forgotPasswordForm.valid) {
      this.subscriptions$.add(
        this.authService.forgotPassword(this.forgotPasswordForm.value)
      );
      this.userRoleLogin();
      this.hasError = false;
    } else {
      this.notiflixService.closeLoader();
      this.hasError = true;
    }
  }

  /**
   * Redirect to Bahamas app page if already logged in
   */
  userRoleLogin(): void {
    if (this.authService.getUser()) {
      // const currentUser = JSON.parse(this.cryptoService.getStorageItem('currentUser'));
      const currentUser = this.cryptoService.getStorageItem('currentUser');
      this.router.navigate(['/home'], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
      });
    }
    this.returnUrl =
      (this.route.snapshot.queryParams.returnUrl === '/'
        ? '/home'
        : this.route.snapshot.queryParams.returnUrl) || '/home';
  }
}
