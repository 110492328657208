import { Injectable } from '@angular/core';
import * as Notiflix from 'notiflix';
import { NOTIFLIX_CONFIG } from '../../core/constants/notiflix-config.const';

@Injectable({
  providedIn: 'root',
})
export class NotiflixService {
  [x: string]: any;
  constructor() {
    this.initNotiflixConfigs();
  }

  initNotiflixConfigs(): void {
    this.initNotiflixReport();
    this.initNotiflixNotify();
    this.initNotiflixConfirm();
    this.initNotiflixBlock();
    this.initNotiflixLoading();
  }

  private initNotiflixNotify(): void {
    Notiflix.Notify.Init({
      fontFamily: NOTIFLIX_CONFIG.FONT_FAMILY,
      position: NOTIFLIX_CONFIG.NOTIFY_POSITION,
      titleFontSize: NOTIFLIX_CONFIG.TITLE_FONT_SIZE,
      success: {
        background: NOTIFLIX_CONFIG.COLORS.SUCCESS_NOTIFY,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.SUCCESS,
      },
      failure: {
        background: NOTIFLIX_CONFIG.COLORS.ERROR,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.ERROR,
      },
      warning: {
        background: NOTIFLIX_CONFIG.COLORS.WARNING,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.WARNING,
      },
      info: {
        background: NOTIFLIX_CONFIG.COLORS.INFO_NOTIFY,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.INFO,
      },
    });
  }

  private initNotiflixReport(): void {
    Notiflix.Report.Init({
      fontFamily: NOTIFLIX_CONFIG.FONT_FAMILY,
      cssAnimationDuration: NOTIFLIX_CONFIG.ANIMATION_DURATION,
      cssAnimationStyle: NOTIFLIX_CONFIG.ANIMATION_STYLE,
      titleFontSize: NOTIFLIX_CONFIG.TITLE_FONT_SIZE,
      messageFontSize: NOTIFLIX_CONFIG.MESSAGE_FONT_SIZE,
      success: {
        svgColor: NOTIFLIX_CONFIG.COLORS.SUCCESS,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.SUCCESS,
        backOverlayColor: NOTIFLIX_CONFIG.COLORS.SUCCESS_OVERLAY,
      },
      failure: {
        svgColor: NOTIFLIX_CONFIG.COLORS.ERROR,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.INFO,
        backOverlayColor: NOTIFLIX_CONFIG.COLORS.ERROR_OVERLAY,
      },
      warning: {
        svgColor: NOTIFLIX_CONFIG.COLORS.WARNING,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.WARNING,
        backOverlayColor: NOTIFLIX_CONFIG.COLORS.WARNING_OVERLAY,
      },
      info: {
        svgColor: NOTIFLIX_CONFIG.COLORS.INFO,
        buttonBackground: NOTIFLIX_CONFIG.COLORS.INFO,
        backOverlayColor: NOTIFLIX_CONFIG.COLORS.INFO_OVERLAY,
      },
    });
  }

  private initNotiflixConfirm(): void {
    Notiflix.Confirm.Init({
      fontFamily: NOTIFLIX_CONFIG.FONT_FAMILY,
      titleColor: NOTIFLIX_CONFIG.COLORS.SUCCESS,
      titleFontSize: NOTIFLIX_CONFIG.TITLE_FONT_SIZE,
      cssAnimationStyle: NOTIFLIX_CONFIG.ANIMATION_STYLE,
      cssAnimationDuration: NOTIFLIX_CONFIG.ANIMATION_DURATION,
      okButtonColor: '#f8f8f8',
      okButtonBackground: NOTIFLIX_CONFIG.COLORS.PRIMARY,
      cancelButtonColor: '#f8f8f8',
      cancelButtonBackground: NOTIFLIX_CONFIG.COLORS.INFO,
      plainText: false,
      messageMaxLength: 500,
    });
  }

  private initNotiflixBlock(): void {
    Notiflix.Block.Init({
      svgColor: NOTIFLIX_CONFIG.COLORS.INFO,
      fontFamily: NOTIFLIX_CONFIG.FONT_FAMILY,
    });
  }

  private initNotiflixLoading(): void {
    Notiflix.Loading.Init({
      svgColor: NOTIFLIX_CONFIG.COLORS.WARNING,
      fontFamily: NOTIFLIX_CONFIG.FONT_FAMILY,
      messageMaxLength: '100',
    });
  }

  public launchConfirm(
    title: string,
    msg: string,
    confirmCallBack: OnConfirmCallback,
    positiveButton?: string,
    negativeButton?: string
  ): void {
    // const message = `<span class="">${msg}</span>`;
    Notiflix.Confirm.Show(
      title,
      msg,
      negativeButton ? negativeButton : 'CANCEL',
      positiveButton ? positiveButton : 'YES',
      () => {
        confirmCallBack.onNegative();
      },
      () => {
        confirmCallBack.onPositive();
      }
    );
  }

  public launchReport(
    status: string,
    title: string,
    msg: string,
    buttonText?: string,
    okCallBack?: OnOkCallback
  ): void {
    switch (status) {
      case 'success': {
        Notiflix.Report.Success(
          title,
          msg,
          buttonText ? buttonText : 'OK',
          () => {
            if (okCallBack) {
              okCallBack.onOk();
            }
          }
        );
        break;
      }
      case 'error': {
        Notiflix.Report.Failure(
          title,
          msg,
          buttonText ? buttonText : 'OK',
          () => {
            if (okCallBack) {
              okCallBack.onOk();
            }
          }
        );
        break;
      }
      case 'warning': {
        Notiflix.Report.Warning(
          title,
          msg,
          buttonText ? buttonText : 'OK',
          () => {
            if (okCallBack) {
              okCallBack.onOk();
            }
          }
        );
        break;
      }
      case 'info': {
        Notiflix.Report.Info(
          title,
          msg,
          buttonText ? buttonText : 'OK',
          () => {
            if (okCallBack) {
              okCallBack.onOk();
            }
          }
        );
        break;
      }
      default: {
        throw new Error(`${status} is not a valid status`);
      }
    }
  }

  public launchNotify(status, msg): void {
    switch (status) {
      case 'success': {
        Notiflix.Notify.Success(msg);
        break;
      }
      case 'error': {
        Notiflix.Notify.Failure(msg);
        break;
      }
      case 'warning': {
        Notiflix.Notify.Warning(msg);
        break;
      }
      case 'info': {
        Notiflix.Notify.Info(msg);
        break;
      }
      default: {
        throw new Error(`${status} is not a valid status`);
      }
    }
  }

  public load(): void {
    Notiflix.Loading.Hourglass('Give it a moment to load...');
  }

  public loadPulse(txt?: string): void {
    Notiflix.Loading.Pulse(txt ?? 'Give it a moment to load...');
  }

  public closeLoader(): void {
    Notiflix.Loading.Remove();
  }
}

export interface OnConfirmCallback {
  onPositive(): any;
  onNegative(): any;
}

export interface OnOkCallback {
  onOk(): any;
}
