<div class="file-manager">
  <div class="row">
    <div class="col-md-12">
      <nb-card class="card-wrapper">
        <nb-card-header>
          <div class="text-center">
            <button
              type="button"
              matSuffix
              [attr.aria-label]="'Hide password'"
              color="accent"
              mat-flat-button
              class="cancel-button h-60 form-buttons back-button"
              matTooltip="Go to List of Flight Plans"
              matTooltipClass="tooltipClass"
              matTooltipPosition="below"
              id="backBtn"
              (click)="onBack()"
            >
              <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <h5 class="text-haze-blue">Aeronautical Information</h5>
          </div>
        </nb-card-header>
        <nb-card-body>
          <div class="row">
            <div class="col-md-3 file-tree">
              <app-file-tree
                [currentActive]="currentActive"
                [reloadDir]="reloadDir"
                (directory)="goToDirectory($event)"
              >
              </app-file-tree>
            </div>
            <div class="col-md-9">
              <app-file-explorer
                [fileElements]="fileElements | async"
                [path]="currentPath"
                [root]="currentRoot"
                [canNavigateUp]="canNavigateUp"
                [isAdmin]="isAdmin"
                [maxLength]="maxLength"
                (folderAdded)="addFolder($event)"
                (elementRemoved)="removeElement($event)"
                (navigatedDown)="navigateToFolder($event)"
                (navigatedUp)="navigateUp()"
                (elementRenamed)="renameElement($event)"
                (elementMoved)="moveElement($event)"
                (fileRemoved)="removeFile($event)"
                (fileDownload)="downloadFile($event)"
                (fileUpload)="uploadFile($event)"
                (fileView)="viewFile($event)"
              >
              </app-file-explorer>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
