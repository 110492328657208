import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/constants/api.const';
import { HttpService } from '@app/core/services/http/http.service';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import {
  getRegisteredUser,
  getRegisteredUserSuccess,
  getRegisteredUserFailure,
  getAircraftIdNumber,
  getAircraftIdNumberSuccess,
  getAircraftIdNumberFailure,
} from './registered-user.action';
import {
  AircraftIdentifications,
  RegisteredUser,
} from './registered-user.model';

@Injectable()
export class RegisteredUserEffects {
  constructor(
    private actions$: Actions,
    private http: HttpService,
    private httpClient: HttpClient
  ) {}

  getRegisteredUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegisteredUser),
      mergeMap(() => {
        return this.http.get(`${environment.api}${API.registeredUser}`).pipe(
          map((res: RegisteredUser) =>
            getRegisteredUserSuccess({ response: res })
          ),
          catchError((err) =>
            of(getRegisteredUserFailure({ error: err.message }))
          )
        );
      })
    )
  );

  getAircraftId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAircraftIdNumber),
      mergeMap(() => {
        return this.http.get(`${environment.api}${API.registeredUser}`).pipe(
          map((res: AircraftIdentifications) =>
            getAircraftIdNumberSuccess({ response: res })
          ),
          catchError((err) =>
            of(getAircraftIdNumberFailure({ error: err.message }))
          )
        );
      })
    )
  );
}
