<div class="system-parameter-container">
  <div class="row">
    <div class="col-12">
      <nb-card>
        <nb-card-header>
          <div class="text-center">
            <h5 class="text-haze-blue">System Parameters</h5>
          </div>
          <br />
          <div class="row">
            <mat-form-field appearance="outline" class="width-1 col-4">
              <mat-label>Type</mat-label>
              <mat-select [(ngModel)]="type" (selectionChange)="onSelect()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let type of typeList" [value]="type.id">
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-4 col-4">
              <mat-label>Value</mat-label>
              <input
                matInput
                placeholder="Value"
                [(ngModel)]="value"
                (input)="onPress()"
              />
            </mat-form-field>
            <div class="col-4">
              <button
                type="button"
                matSuffix
                [attr.aria-label]="'Hide password'"
                color="accent"
                size="medium"
                mat-flat-button
                class="cancel-button h-60 form-buttons"
                matTooltip="Go to Fees payment record"
                matTooltipClass="tooltipClass"
                matTooltipPosition="below"
                id="backBtn"
                [routerLink]="['/flight-list']"
              >
                <mat-icon>keyboard_backspace</mat-icon>
              </button>
            </div>
          </div>
        </nb-card-header>
        <nb-card-body>
          <div class="settings-button">
            <button
              nbButton
              status="info"
              size="small"
              matTooltip="Add system parameters"
              matTooltipClass="tooltipClass"
              matTooltipPosition="right"
              (click)="onAddSystemParameters()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <app-mat-data-table
            [tableContents]="tableContents"
            [dataSource]="filteredSystemParams"
            [loadingIndicator]="loadingIndicator$"
            [component]="this"
            [advancedSearchKeys]="advancedSearchKeys"
            [copyFlight]="false"
            [viewUserDetails]="false"
            [editButton]="true"
            [deleteButton]="true"
            [viewFlightDetails]="false"
            (isDelete)="onDelete($event)"
            (isViewDetails)="onViewDetails($event)"
            (isEdit)="onEditSystemParameters($event)"
          ></app-mat-data-table>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
