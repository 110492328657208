<div class="view-user-details-container">
  <div fxLayout="row" fxLayoutAlign="space-between" class="header-title">
    <h5 class="center">User Details</h5>
    <button mat-icon-button color="primary" (click)="close()">
      <i class="material-icons"> close </i>
    </button>
  </div>
  <mat-dialog-content class="view-user-details-modal">
    <div class="tab-body">
      <div class="body-container">
        <div class="row">
          <div class="col-md-12 no-gutters">
            <div class="details-card-body">
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">Carrier</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.carrierName || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">ICAO 3 letters code</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">Email</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.email || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">Street</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.street || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">City</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.city || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">State</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.state || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">Country</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.country || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">Zip Code</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{ registeredUserDetails.zipCode || 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="item-label">Aircraft Identifications</div>
                  </div>
                  <div class="col-md-9">
                    <div class="item-value">
                      {{
                        registeredUserDetails.aircraftIdentifications || 'N/A'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
