import { Injectable } from '@angular/core';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { HttpService } from '../services/http/http.service';
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AddFlightService {
  API_ROUTES = API_ENPOINTS;
  BACKEND_ENDPOINT = environment;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private notiflix: NotiflixService,
    private router: Router
  ) {}

  createAddFlight(formData): Observable<any> {
    return this.http
      .post(`${environment.api}${API_ENPOINTS.flightPlan}`, formData, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: true,
      });
  }

  onOk(): void {
    this.router.navigate(['/flight-list'], {
      queryParams: { page: null },
      queryParamsHandling: 'merge',
    });
  }

  // tslint:disable-next-line: typedef
  getAddFlight() {
    return this.httpService.get(`${this.API_ROUTES.flightPlan}`);
  }
  getAddFlightById(id): Observable<any> {
    return this.httpService.get(`${this.API_ROUTES.flightPlan}/${id}`);
  }

  createAddFlightProperties(): Observable<any> {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${API_ENPOINTS.flightProperties}`
    );
  }
  // tslint:disable-next-line: typedef
  getAddFlightProperties() {
    return this.httpService.get(`${this.API_ROUTES.flightProperties}`);
  }
  getAddFlightPropertiesById(id): Observable<any> {
    return this.httpService.get(`${this.API_ROUTES.flightProperties}/${id}`);
  }
}
