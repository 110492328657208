export class AddFlightPlan {
  filingTime: string;
  filingDate: string;
  flightDate: string;
  departureTime: string;
  offBlockTime: string;
  typeOperation: string;
  typeFlight: string;
  flightRules: string;
  aircraftIdentification: string;
  aircraftType: string;
  numberMotors: number;
  typeMotor: string;
  mtow: number;
  departureAerodrome: string;
  destinationAerodrome: string;
  estimatedTimeEnrouteHours: number;
  estimatedTimeEnrouteMinutes: number;
  fuelEnduranceHours: number;
  fuelEnduranceMinutes: number;
  otherInformation: string;
  alternateAerodrome: string;
  secondAlternateAerodrome: string;
  flightRoute: string;
  cruisingSpeed: number;
  cruisingAltitude: string;
  emergencyRadio: string;
  numberLifeJacketsColor: string;
  dinghiesNumber: string;
  dinghiesCapacity: string;
  dinghiesColor: string;
  aircraftColor: string;
  totalNumberPersonsOnBoard: number;
  methodClosure: string;
  otherEquipmentRemarks: string;
  pilotInformation: PilotInformation;
  passengers: Array<Passengers>;
  forValidation: boolean;
  payLater?: boolean;
  singleUseToken?: string;
}
export interface PilotInformation {
  name: string;
  phone: string;
  email: string;
  address: string;
  aircraftHomeBase: string;
}
export interface Passengers {
  name: string;
  emergencyContact: string;
  address: string;
  phone: string;
}
