<div class="tree-container">
  <div class="tree-wrapper scrollable">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree tree">
      <mat-tree-node *matTreeNodeDef="let node">
        <div 
          *ngIf="node.name !== 'NOTAM'"
          class="mat-tree-node folder-dir"
          matTooltip="{{node.name}}"
          matTooltipClass="tooltipClass"
          matTooltipPosition="below"
          >
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name"
                  class="hidden-button">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <button
            mat-flat-button
            [attr.aria-label]="'Toggle ' + node.name"
            fullWidth
            #buttonIcon
            class="no-child tree-button"
            [ngClass]="{active: selected === node.id}"
            (click)="goToDirectory(node.path, node.id)"
            >
            <mat-icon class="mat-icon-rtl-mirror" color="accent">
              folder
            </mat-icon>
            {{node.name}}
          </button>
        </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div 
            *ngIf="node.name !== 'NOTAM'"
            class="mat-tree-node folder-dir"
            matTooltip="{{node.name}}"
            matTooltipClass="tooltipClass"
            matTooltipPosition="below"
          >
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <button
            mat-flat-button
            [attr.aria-label]="'Toggle ' + node.name"
            fullWidth
            #buttonIcon
            class="tree-button"
            [ngClass]="{active: selected === node.id}"
            (click)="goToDirectory(node.path, node.id)"
            >
              <mat-icon class="mat-icon-rtl-mirror" color="accent">
                folder
              </mat-icon>
              {{node.name}}
            </button>
          </div>
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>