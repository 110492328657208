<div class="row">
  <div class="col">
    <div class="footer-text-wrapper">
      <p class="footer-text arial-regular">
        <a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a>
        <br />
        Copyright &copy; {{ copyrightYear }} Bahamas
        <br />
        All rights reserved. <br />
        Version {{ appVersion }}
      </p>
    </div>
  </div>
</div>
