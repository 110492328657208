import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http/http.service';
import { environment } from 'environments/environment';
import { API_ENPOINTS } from '../constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class PublicUrlService {
  constructor(private httpService: HttpService) {}

  getFlightPlanByQRIdentifier(qrIdentifier: string): Observable<any> {
    return this.httpService.get(
      `${environment.api}${API_ENPOINTS.flightPlanByQRIdentifier}/${qrIdentifier}`
    );
  }
}
