import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.model';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import { selectFlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.selector';
import { select, Store } from '@ngrx/store';
import html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { PAYMENT_STATUS } from '@app/core/constants/payment-status';
import { PdfService } from '@app/core/services/pdf.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { ERROR_MESSAGES } from '@app/core/constants/error-messages';

@Component({
  selector: 'app-flight-plan-pdf',
  templateUrl: './flight-plan-pdf.component.html',
  styleUrls: ['./flight-plan-pdf.component.scss'],
})
export class FlightPlanPdfComponent implements OnInit {
  @Output() pdfDownload: EventEmitter<any> = new EventEmitter();
  @Input() flightDetails;
  flightPlanDetails: FlightPlanDetails;
  qrCode: string;
  paymentStatus = PAYMENT_STATUS;

  constructor(
    private store: Store<FlightPlanState>,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectFlightPlanDetails))
      .subscribe((res: FlightPlanDetails) => {
        this.flightPlanDetails = res;
        this.qrCode = `${environment.base_url}public/flight-plan-details?qrIdentifier=${this.flightPlanDetails.qrIdentifier}`;
      });
  }

  formatTime(time: string): string {
    return moment(time, 'HH:mm').format('HH:mm');
  }

  downloadPDF(): void {
    this.pdfService.generateFlightPlanPdf(this.flightDetails);
  }
}
