<div class="form-card">
  <h2 class="page-title text-amazon-blue">Change password</h2>
  <p class="neo-sans-medium text-amazon-blue">
    {{ const.PASSWORD_REQUIREMENTS }}
  </p>
  <p class="req-1 text-amazon-blue">
    - {{ const.PASSWORD_CONTAIN_ATLEAST }}
    <span class="text-haze-blue neo-sans-medium">{{
      const.PASSWORD_MIN_LENGTH
    }}</span>
    {{ const.CHARACTERS }}
    <span class="text-haze-blue neo-sans-medium">{{
      const.PASSWORD_MAX_LENGTH
    }}</span>
    <br />

    - {{ const.CHARACTERS_CONTAIN_ATLEAST_THREE }} <br />
    <span class="req-2">
      • {{ const.CAPITAL_LETTERS }}
      <span class="text-haze-blue">
        {{ const.CAPITAL_LETTERS_EX }}
      </span>
    </span>
    <br />
    <span class="req-2">
      • {{ const.LOWERCASE_LETTERS }}
      <span class="text-haze-blue"> {{ const.LOWERCASE_LETTERS_EX }}</span>
      <br />
    </span>
    <span class="req-2">
      • {{ const.NUMBERS }}
      <span class="text-haze-blue"> {{ const.NUMBERS_EX }}</span>
      <br />
    </span>
    <span class="req-2">
      • {{ const.SPECIAL_CHARACTERS }}
      <span class="text-haze-blue"> {{ const.SPECIAL_CHARACTERS_EX }}</span>
      <br />
    </span>
    <span class="req-1">- {{ const.SYSTEM_REMEMBERS }}</span>
  </p>
  <app-new-password-form
    (newPassword)="onSubmitEvent($event)"
  ></app-new-password-form>
  <br />
  <p class="center">
    Happy with your current password?<br />
    <a [routerLink]="['/home']" class="link">Let's head back</a>
  </p>
</div>
