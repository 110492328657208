import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckpointComponent } from './pages/auth/checkpoint/checkpoint.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { LogoutComponent } from './pages/auth/logout/logout/logout.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ForbiddenComponent } from './pages/error-pages/forbidden/forbidden.component';
import { InternalServerErrorComponent } from './pages/error-pages/internal-server-error/internal-server-error.component';
import { NotFoundComponent } from './pages/error-pages/not-found/not-found.component';
import { ActivateUserComponent } from './pages/other-pages/activate-user/activate-user.component';
import { FlightPlanDetailsComponent } from './pages/other-pages/flight-plan-details/flight-plan-details.component';
import { ForgotPasswordComponent } from './pages/other-pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/other-pages/reset-password/reset-password.component';
import { PaymentResponseComponent } from './pages/payment/payment-response/payment-response.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { AddFlightPlanComponent } from './pages/user/add-flight-plan/add-flight-plan.component';
import { LoaderComponent } from './shared/loader/loader.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'internal-server-error',
    component: InternalServerErrorComponent,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'activate-user',
    component: ActivateUserComponent,
  },
  {
    path: 'checkpoint',
    component: CheckpointComponent,
  },
  {
    path: 'loaderist',
    component: LoaderComponent,
  },
  {
    path: 'public/flight-plan-details',
    component: FlightPlanDetailsComponent,
  },
  {
    path: 'payment-response',
    component: PaymentResponseComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
