<div class="page-wrapper">
  <br class="place-holder-break" />
  <br /><br />
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="img-wrapper">
          <img
            id="page-img"
            src="../../../../assets/images/svgs/chico_sillon_ordenador.svg"
            alt="mano-lupa"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-8">
        <div class="body-wrapper">
          <br /><br /><br /><br class="place-holder-break" />
          <p class="text-haze-blue page-msg">
            <span class="page-title noe-display-regular text-amazon-blue"
              >Something's not right, is it?</span
            >
            <br />
            We encountered some bumps while processing your request.
            {{
              status ? 'Please try again later or contact our support team' : ''
            }}
            <a href="mailto: {{ supportEmail }}" *ngIf="status">{{
              supportEmail
            }}</a>
            <span *ngIf="errorTraceId !== ''" class="err-trace-id"
              ><br /><br />
              Let us help you. Just send the error trace id to
              <span class="text-amazon-blue">{{ supportEmail }}</span
              >. <br />Error trace id:
              <span class="text-amazon-blue">{{ errorTraceId }}</span>
            </span>
          </p>
          <a class="link" [href]="['/']">Back to home</a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="align-center">
      <div class="bahamas-logo">
        <!-- <img src="../../../../assets/images/bahamas.png" alt="" /> -->
        <img src="../../../../assets/images/BANSA logo.png" alt="" />
      </div>
      <p class="text-haze-blue subtitle">
        {{ CONSTANTS.BAHAMAS_LOADING }}
      </p>
    </div>
    <div class="footer-texts">
      <p>
        Copyright &copy; {{ copyrightYear }} Bahamas.
        <br />
        All rights reserved. Version {{ appVersion }}
      </p>
    </div>
  </div>
</div>
