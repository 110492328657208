import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TableContents } from '@app/core/models/table-contents.model';
import { FlightPlanService } from '@app/core/services/flight-plan.service';
import { getFlightPlan } from '@app/core/store/flight-plan/flight-plan.action';
import {
  FlightPlan,
  FlightPlanDetails,
} from '@app/core/store/flight-plan/flight-plan.model';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import { selectFlightPlanResponse } from '@app/core/store/flight-plan/flight-plan.selector';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { select, Store } from '@ngrx/store';
import { ViewFlightPlanComponent } from './view-flight-plan/view-flight-plan.component';

@Component({
  selector: 'app-flight-plan',
  templateUrl: './flight-plan.component.html',
  styleUrls: ['./flight-plan.component.scss'],
})
export class FlightPlanComponent implements OnInit {
  tableContents: TableContents;
  flightPlanList: FlightPlan[];
  flightPlanDetails: FlightPlanDetails;
  loadingIndicator$ = false;
  advancedSearchKeys: any;
  constructor(
    private store: Store<FlightPlanState>,
    public dialog: MatDialog,
    private router: Router,
    private flightPlanService: FlightPlanService,
    private notiflixService: NotiflixService
  ) {}

  ngOnInit(): void {
    this.flightPlanList = [];
    this.getFlightPlans();
    this.initTableContents();
  }

  initTableContents(): void {
    this.tableContents = {
      tableName: 'flight-plan-table',
      noGenericSearch: true,
      isRowClickable: false,
      columns: [
        {
          prop: 'filingDate',
          label: 'Filing Date',
          type: 'date',
          minWidth: '100px',
          maxWidth: '100px',
        },
        {
          prop: 'filingTime',
          label: 'Filing Time (EST)',
          type: 'time',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'flightDate',
          label: 'Flight Date',
          type: 'date',
          minWidth: '100px',
          maxWidth: '100px',
        },
        {
          prop: 'typeOperation',
          label: 'Type of Operation',
          type: 'string',
          minWidth: '120px',
          maxWidth: '120px',
        },
        {
          prop: 'typeFlight',
          label: 'Type of Flight',
          type: 'string',
          minWidth: '120px',
          maxWidth: '120px',
        },
        {
          prop: 'aircraftIdentification',
          label: 'Aircraft Identification',
          type: 'code',
          alignment: 'center',
          minWidth: '80px',
          maxWidth: '80px',
        },
        {
          prop: 'aircraftType',
          label: 'Type of aircraft (ICAO)',
          type: 'code',
          alignment: 'center',
          minWidth: '80px',
          maxWidth: '80px',
        },
        {
          prop: 'numberMotors',
          label: 'Number of Motors',
          type: 'number',
          alignment: 'center',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'typeMotor',
          label: 'Type of Motor',
          type: 'string',
          alignment: 'left',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'mtow',
          label: 'MTOW (kg)',
          type: 'number',
          alignment: 'center',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'departureAerodrome',
          label: 'Departure Aerodrome (ICAO)',
          type: 'code',
          alignment: 'center',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'destinationAerodrome',
          label: 'Destination Aerodrome (ICAO)',
          type: 'code',
          alignment: 'center',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'pilotName',
          label: 'Pilot-in-command',
          type: 'string',
          minWidth: '100px',
          maxWidth: '100px',
        },
        {
          prop: 'paymentStatus',
          label: 'Payment Status',
          type: 'stringLeftAlign',
          minWidth: '150px',
          maxWidth: '150px',
        },
        {
          prop: 'actions',
          label: '',
          type: 'action',
          alignment: 'center',
          defWidth: '15px',
        },
      ],
    };
  }

  getFlightPlans(): void {
    this.store.dispatch(getFlightPlan());
    this.store.pipe(select(selectFlightPlanResponse)).subscribe((data) => {
      if (data) {
        this.flightPlanList = data ? data : [];
      }
    });
  }

  onViewDetails(event): void {
    const modalConfig = {
      disableClose: true,
      autoFocus: false,
      width: '1000px',
      maxHeight: '750px',
    };

    const dialogRef: MatDialogRef<ViewFlightPlanComponent> = this.dialog.open(
      ViewFlightPlanComponent,
      modalConfig
    );
    dialogRef.componentInstance.data = event.id;
  }

  onCopyFlight(event): void {
    this.router.navigate(['/copy-flight/' + event.id]);
  }

  onSendVfrMail(event): void {
    this.notiflixService.loadPulse('Closing the Flight Plan...');
    this.flightPlanService.sendVfrEmail(event.id).subscribe(
      (res) => {
        this.notiflixService.closeLoader();
        this.notiflixService.launchReport(
          'success',
          'Success',
          'The flight plan has been successfully closed.',
          '',
          this
        );
      },
      (err) => {
        this.notiflixService.closeLoader();
        this.notiflixService.launchReport(
          'error',
          'Error',
          err?.error?.clientError,
          '',
          this
        );
      }
    );
  }

  onOk(): void {
    this.getFlightPlans();
  }
}
