import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileElement } from '@app/core/models/file-element';
import { FileService } from '@app/core/services/file.service';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss'],
})
export class NewFolderDialogComponent implements OnInit {
  folderName: string;
  hasSpecialCharacters: boolean;
  folderNameFormControl: any;
  isExist: boolean;
  parentElement: FileElement;
  maxLength: string;
  isValid: boolean;

  constructor(
    public fileService: FileService,
    private dialogRef: MatDialogRef<NewFolderDialogComponent>
  ) {}

  ngOnInit(): void {
    this.folderName = '';
    this.hasSpecialCharacters = false;
    this.isValid = false;
    this.folderNameFormControl = new FormControl(this.folderName);
  }

  checkName(): void {
    this.hasSpecialCharacters = this.fileService.checkNameFormat(
      this.folderName
    );

    this.isExist = this.fileService.checkExisting(
      this.folderName,
      this.parentElement?.id
    );

    if (this.hasSpecialCharacters || this.isExist) {
      this.folderNameFormControl.setErrors({ error: true });
    } else {
      this.folderNameFormControl.setErrors(null);
      this.isValid = true;
      this.dialogRef.close();
    }
  }
}
