import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddAircraftIdService } from '@app/core/services/add-aircraft-id.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { RegisteredUserState } from '@app/core/store/registered-user-by-id/registered-user.reducer';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { RegisteredUser } from '@app/core/store/registered-user-by-id/registered-user.model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AircraftIdentificationsService } from '@app/core/services/aircraft-identifications.service';

@Component({
  selector: 'app-edit-aircraft-id',
  templateUrl: './edit-aircraft-id.component.html',
  styleUrls: ['./edit-aircraft-id.component.scss'],
})
export class EditAircraftIdComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  editAircraftIdForm: FormGroup;
  currentTransaction: string;
  checker: any;
  id: number;
  aircraftIdentificationNumber: RegisteredUser;
  aircraftIdSubscription$: Subscription;
  aircraftIds: Array<any>;
  data: any;

  constructor(
    private dialogRef: MatDialogRef<EditAircraftIdComponent>,
    private formBuilder: FormBuilder,
    private notiflixService: NotiflixService,
    private addAircraftIdNumber: AddAircraftIdService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.id = data.id;
    });
  }

  ngOnInit(): void {
    this.initFormControls();
  }

  initFormControls(): FormGroup {
    return (this.editAircraftIdForm = this.formBuilder.group({
      aircraftIdNumber: [
        this.data ? this.data.aircraftIdNumber : '',
        Validators.required,
      ],
    }));
  }

  isNoChanges(): boolean {
    return (
      JSON.stringify(this.data.aircraftIdNumber) ===
      JSON.stringify(this.editAircraftIdForm.get('aircraftIdNumber').value)
    );
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  close(): void {
    if (this.isNoChanges()) {
      this.dialogRef.close();
    } else {
      this.currentTransaction = 'BACK';
      this.notiflixService.launchConfirm(
        'Are you sure you want to exit?',
        'Changes you made will not be saved.',
        this,
        'EXIT',
        'NO'
      );
    }
  }

  onUpdate(): void {
    this.currentTransaction = 'UPDATE';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'Aircraft Identification Number will be updated.',
      this,
      'UPDATE',
      'CANCEL'
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'UPDATE':
        this.onUpdateAircraftId();
        const updateBtn = document.getElementById('updateBtn') as HTMLElement;
        updateBtn.click();
        this.dialogRef.close();
        break;
      case 'BACK':
        this.dialogRef.close();
        break;
      default:
        throw new Error(`${this.currentTransaction} is invalid`);
    }
  }

  onNegative(): void {}

  onUpdateAircraftId(): void {
    this.addAircraftIdNumber.editAircraftId(
      this.data.id,
      this.editAircraftIdForm.value
    );
  }

  ngOnDestroy(): void {}
}
