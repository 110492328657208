import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/constants/api.const';
import { UserInfo } from '@app/core/models/user-info.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  constructor(private http: HttpClient) {}

  validateSSOSession(): Observable<UserInfo> {
    return this.http.post<UserInfo>(
      `${environment.api}${API.validateSSO}`,
      null,
      { withCredentials: true }
    );
  }

  refreshToken(refreshToken: string): Observable<any> {
    const headers = new HttpHeaders().set('X-HRP-REFRESH-TOKEN', refreshToken);
    return this.http.post<UserInfo>(
      `${environment.api}${API.generateNewAccessToken}`,
      null,
      { headers }
    );
  }
}
