<mat-toolbar>
  <mat-icon
    *ngIf="canNavigateUp && path !== 'Integrated Aeronautical Information Package - IAIP/' "
    class="pointer"
    (click)="navigateUp()"
    matTooltip="Back to {{ backTo(path) || 'root' }}"
    matTooltipPosition="below"
  >
    arrow_back
  </mat-icon>
  <span style="margin-left: 8px" class="path">
    {{ formatPath(path) || '' }}
  </span>
  <span class="spacer"></span>
  <!-- <mat-icon
    *ngIf="isAdmin"
    color="accent"
    class="pointer new-folder"
    (click)="openNewFolderDialog()"
    matTooltip="Create Folder"
    matTooltipPosition="below"
  >
    create_new_folder
  </mat-icon>
  &nbsp;
  <mat-icon
    *ngIf="isAdmin"
    color="accent"
    class="pointer new-folder"
    matTooltip="Upload Files"
    matTooltipPosition="below"
    (click)="openUploadDialog()"
  >
    note_add
  </mat-icon> -->
  <div>
    <button
      *ngIf="isAdmin"
      nbButton
      status="info"
      size="small"
      color="accent"
      class="pointer"
      (click)="openNewFolderDialog()"
      matTooltip="Create Folder"
      matTooltipPosition="below"
    >
      Create Folder
    </button>
    &nbsp;
    <button
      *ngIf="isAdmin"
      nbButton
      status="info"
      size="small"
      color="accent"
      class="pointer"
      matTooltip="Upload Files"
      matTooltipPosition="below"
      (click)="openUploadDialog()"
      type="button"
    >
      Add +
    </button>
  </div>
</mat-toolbar>

<div
  class="explorer-content"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between stretch"
>
  <div class="content content-wrapper scrollable">
    <!-- convert to list start -->
    <table
      mat-table
      [dataSource]="fileElements"
      class="mat-elevation-z8 demo-table"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="name-col"
          (click)="navigate(element)"
        >
          <div
            fxLayout="row"
            (contextmenu)="openMenu($event, menuTrigger, element.isFolder)"
            matTooltip="{{ element.name }}"
            matTooltipPosition="below"
            (click)="viewFile(element)"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            <ng-container *ngIf="element.isFolder; else files">
              <mat-icon color="accent" class="file-or-folder-icon pointer">
                folder
              </mat-icon>
            </ng-container>
            <ng-template #files>
              <mat-icon
                class="file-or-folder-icon pointer"
                [ngStyle]="{
                  color: getColor(element.type)
                }"
              >
                {{ getIcon(element.type) }}
              </mat-icon>
            </ng-template>
            <span>
              {{ element.name }}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- More Menu -->
      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div
            (click)="openMenu($event, menuTrigger, element.isFolder)"
            *ngIf="isAdmin"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            <ng-container>
              <mat-icon class="file-or-folder-icon pointer">
                more_horiz
              </mat-icon>
            </ng-container>
          </div>
          <div
            (click)="openMenu($event, menuTrigger, element.isFolder)"
            *ngIf="!isAdmin && !element.isFolder"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            <ng-container>
              <mat-icon class="file-or-folder-icon pointer">
                more_horiz
              </mat-icon>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <!-- Date Modified Column -->
      <ng-container matColumnDef="dateModified">
        <th mat-header-cell *matHeaderCellDef>Date Modified</th>
        <td mat-cell *matCellDef="let element" (click)="navigate(element)">
          <div
            fxLayout="row"
            (contextmenu)="openMenu($event, menuTrigger, element.isFolder)"
            matTooltip="{{ element.name }}"
            matTooltipPosition="below"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            {{ element.dateModified | date: 'MMM d, y, h:mm a' }}
          </div>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element" (click)="navigate(element)">
          <div
            fxLayout="row"
            (contextmenu)="openMenu($event, menuTrigger, element.isFolder)"
            matTooltip="{{ element.name }}"
            matTooltipPosition="below"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            {{ element.type !== '' ? getFileType(element.type) : 'Folder' }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <p
      class="align-center empty-folder"
      *ngIf="fileElements?.length <= 0 || fileElements === []"
    >
      This folder is empty.
    </p>
    <!-- convert to list end -->
  </div>
</div>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="openMoveDialog(element)" *ngIf="isAdmin">
      <mat-icon>open_with</mat-icon>
      <span>Move</span>
    </button>
    <button mat-menu-item *ngIf="!element.isFolder" (click)="viewFile(element)">
      <mat-icon>remove_red_eye</mat-icon>
      <span>View</span>
    </button>
    <button
      mat-menu-item
      *ngIf="!element.isFolder"
      (click)="downloadFile(element)"
    >
      <mat-icon>download</mat-icon>
      <span>Download</span>
    </button>
    <button
      mat-menu-item
      (click)="openRenameDialog(element)"
      *ngIf="isAdmin && element.isFolder"
    >
      <mat-icon>edit</mat-icon>
      <span>Rename</span>
    </button>
    <button mat-menu-item (click)="deleteElement(element)" *ngIf="isAdmin">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>
