import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  CONSTANTS = CONSTANTS;
  appVersion = environment.version;
  copyrightYear = environment.copyright_year;
  @Input() message: string;
  defaultMsg = `We are getting things ready for you`;

  constructor() {}

  ngOnInit(): void {}
}
