<div class="dashboard-container">
  <div class="row">
    <div class="col-md-12">
      <nb-card class="card py-2 px-md-5 py-md-4">
        <nb-card-body class="d-flex justify-content-between">
          <h2>
            Bahamas Air Navigation<br />
            Services Authority
          </h2>
          <img
            class="bansa-logo d-none d-md-flex"
            src="../../../../assets/images/BANSA logo.png"
            alt="Bansa logo"
          />
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-md-4">
      <nb-card class="card" [routerLink]="['/flight-list']">
        <nb-card-header class="pt-4">
          <div class="card__icon card__icon--green icon-home">
            <nb-icon icon="calendar-outline" pack="eva"></nb-icon>
          </div>
        </nb-card-header>
        <nb-card-body class="card--large">
          <h3>Flight planning</h3>
        </nb-card-body>
        <nb-card-footer>
          <a [routerLink]="['/flight-list']" class="link">
            See more
            <nb-icon
              icon="diagonal-arrow-right-up-outline"
              pack="eva"
            ></nb-icon>
          </a>
        </nb-card-footer>
      </nb-card>
    </div>
    <div class="col-md-4">
      <nb-card
        class="card"
        [routerLink]="['/aeronautical-information']"
      >
        <nb-card-header class="pt-4">
          <div class="card__icon card__icon--purple icon-home">
            <nb-icon icon="book-outline" pack="eva"></nb-icon>
          </div>
        </nb-card-header>
        <nb-card-body class="card--large">
          <h3>Aeronautical Information</h3>
        </nb-card-body>
        <nb-card-footer>
          <a
            [routerLink]="['/aeronautical-information']"
            class="link"
          >
            See more
            <nb-icon
              icon="diagonal-arrow-right-up-outline"
              pack="eva"
            ></nb-icon>
          </a>
        </nb-card-footer>
      </nb-card>
    </div>
    <div class="col-md-4">
      <nb-card
        class="card"
        [routerLink]="['/notam']"
      >
        <nb-card-header class="pt-4">
          <div class="card__icon card__icon--yellow icon-home">
            <nb-icon icon="file-text-outline" pack="eva"></nb-icon>
          </div>
        </nb-card-header>
        <nb-card-body class="card--large">
          <h3>NOTAM</h3>
        </nb-card-body>
        <nb-card-footer>
          <a
            [routerLink]="['/notam']"
            class="link"
          >
            See more
            <nb-icon
              icon="diagonal-arrow-right-up-outline"
              pack="eva"
            ></nb-icon>
          </a>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</div>
