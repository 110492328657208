import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FlightPlan,
  FeesPaymentBreakdown,
  DownloadFees,
  RegisteredUsers,
} from './flight-plan.model';
import { flightPlanFeatureKey, FlightPlanState } from './flight-plan.reducer';

export const selectFlightPlanFeature =
  createFeatureSelector<FlightPlanState>(flightPlanFeatureKey);

export const selectFlightPlanResponse = createSelector(
  selectFlightPlanFeature,
  (state: FlightPlanState) => {
    if (state.flightPlan) {
      return state.flightPlan.map((data) => {
        return new FlightPlan(data);
      });
    } else {
      return [];
    }
  }
);

export const selectFlightPlanDetails = createSelector(
  selectFlightPlanFeature,
  (state: FlightPlanState) => {
    if (state.flightPlanDetails) {
      return state.flightPlanDetails;
    } else {
      return [];
    }
  }
);

export const selectRegisteredUserDetails = createSelector(
  selectFlightPlanFeature,
  (state: FlightPlanState) => {
    if (state.registeredUserDetails) {
    } else {
      return [];
    }
  }
);

export const selectFeesBreakdownResponse = createSelector(
  selectFlightPlanFeature,
  (state: FlightPlanState) => {
    if (state.feesPaymentBreakdown) {
      return state.feesPaymentBreakdown.map((data) => {
        return new FeesPaymentBreakdown(data);
        // class model
      });
    } else {
      return [];
    }
  }
);

export const selectDownloadFees = createSelector(
  selectFlightPlanFeature,
  (state: FlightPlanState) => {
    if (state.downloadFees) {
      return state.downloadFees.map((data) => {
        return new DownloadFees(data);
        // class model
      });
    } else {
      return [];
    }
  }
);

export const selectRegisteredUsers = createSelector(
  selectFlightPlanFeature,
  (state: FlightPlanState) => {
    if (state.registeredUsers) {
      return state.registeredUsers.map((data) => {
        return new RegisteredUsers(data);
      });
    } else {
      return [];
    }
  }
);
