import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NOTIFLIX_STATUS } from '@app/core/constants/constants.const';
import { ERROR_MESSAGES } from '@app/core/constants/error-messages';
import { FileService } from '@app/core/services/file.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';

interface FolderNode {
  id: string;
  name: string;
  children?: FolderNode[];
  path: string;
}

@Component({
  selector: 'app-move-dialog',
  templateUrl: './move-dialog.component.html',
  styleUrls: ['./move-dialog.component.scss'],
})
export class MoveDialogComponent implements OnInit {
  treeControl = new NestedTreeControl<FolderNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<FolderNode>();
  element: any;
  currentPath: any;
  path = '';
  selected = '';
  isMoved = false;
  disableMove = false;

  constructor(
    public fileService: FileService,
    private dialogRef: MatDialogRef<MoveDialogComponent>,
    private notiflix: NotiflixService
  ) {}

  ngOnInit(): void {
    this.getDirectories();
    this.isMoved = false;
  }

  getDirectories(): void {
    this.fileService.getTreeDirectory().subscribe((data: FolderNode) => {
      this.dataSource.data = data.children;
    });
  }

  selectPath(path, id): void {
    if (this.selected === id) {
      this.selected = '';
      this.path = '';
    } else {
      this.selected = id;
      this.path = path;
    }

    if (!this.element?.isFolder) {
      if (`${path}/` === `/${this.currentPath}`) {
        this.disableMove = true;
      } else {
        this.disableMove = false;
      }
    } else {
      if (this.element?.id === this.selected) {
        this.disableMove = true;
      } else {
        this.disableMove = false;
      }
    }
  }

  moveElement(): void {
    if (this.element?.isFolder) {
      const payload = {
        path: this.element.parent !== 'root' ? `/${this.currentPath}` : '/',
        name: this.element.name,
        destinationPath: this.path !== '' ? `${this.path}/` : '/',
      };

      this.notiflix.loadPulse();
      this.fileService.moveFolder(payload).subscribe(
        (res) => {
          this.isMoved = true;
          this.notiflix.closeLoader();
          this.notiflix.launchReport(
            'success',
            'Folder Moved',
            `"${this.element.name}" folder has been successfully moved to ${
              payload.destinationPath === '/' ? 'root' : payload.destinationPath
            }.`
          );
          this.close();
        },
        (err) => {
          this.notiflix.closeLoader();
          this.notiflix.launchNotify(
            'error',
            err.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
          );
        }
      );
    } else {
      this.moveFile();
    }
  }

  moveFile(): void {
    const payload = {
      path: this.element.parent !== 'root' ? `/${this.currentPath}` : '/',
      name: this.element.name,
      destinationPath: this.path !== '' ? `${this.path}/` : '/',
    };
    this.notiflix.loadPulse();
    this.fileService.moveFile(payload).subscribe(
      (res) => {
        this.isMoved = true;
        this.notiflix.closeLoader();
        this.notiflix.launchReport(
          'success',
          'File Moved',
          `"${this.element.name}" has been successfully moved to ${
            payload.destinationPath === '/' ? 'root' : payload.destinationPath
          }.`
        );
        this.close();
      },
      (err) => {
        this.notiflix.launchConfirm(
          'Are you sure?',
          err?.error?.clientError,
          this,
          'OK',
          'CANCEL'
        );
      }
    );
  }

  retryMove(): void {
    const payload = {
      path: this.element.parent !== 'root' ? `/${this.currentPath}` : '/',
      name: this.element.name,
      destinationPath: this.path !== '' ? `${this.path}/` : '/',
      canOverwrite: true,
    };
    this.notiflix.loadPulse();
    this.fileService.moveFile(payload).subscribe(
      (res) => {
        this.isMoved = true;
        this.notiflix.closeLoader();
        this.notiflix.launchReport(
          'success',
          'File Moved',
          `"${this.element.name}" has been successfully moved to ${
            payload.destinationPath === '/' ? 'root' : payload.destinationPath
          }.`
        );

        this.close();
      },
      (err) => {
        this.notiflix.launchReport(
          NOTIFLIX_STATUS.ERROR,
          'An Error Occured',
          err?.error?.clientError,
          null,
          this
        );
      }
    );
  }

  onPositive(): void {
    this.retryMove();
  }

  onNegative(): void {
    this.notiflix.closeLoader();
  }

  onOk(): void {
    this.notiflix.closeLoader();
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  hasChild = (_: number, node: FolderNode) =>
    // tslint:disable-next-line: semicolon
    !!node.children && node.children.length > 0;
}
