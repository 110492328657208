<ng-container *ngIf="isLoading; else contents">
  <app-loader></app-loader>
</ng-container>
<ng-template #contents>
  <nb-layout>
    <nb-layout-column class="layout-container">
      <div class="page-container">
        <div class="content-wrapper">
          <div class="logo-wrapper">
            <!-- <img
              animateOnScroll
              animationName="animate__animated animate__fadeInRight"
              class="bahamas-logo"
              src="../../../../assets/images/Bahamas Logo.png"
              alt=""
            /> -->
            <img
              animateOnScroll
              animationName="animate__animated animate__fadeInRight"
              class="bansa-logo"
              src="../../../../assets/images/BANSA logo.png"
              alt=""
            />
            <h5
              animateOnScroll
              animationName="animate__animated animate__fadeInDown"
              class="subtitle text-haze-blue"
            >
              {{ constants.BAHAMAS_WELCOME }}
            </h5>
          </div>
          <div
            animateOnScroll
            animationName="animate__animated animate__fadeInUp"
            class="login-card"
          >
            <h5 class="align-center text-amazon-blue">
              {{ constants.SIGN_IN }}
            </h5>
            <br />
            <form [formGroup]="loginForm" (submit)="onSubmit()">
              <mat-form-field appearance="outline" class="full-width email">
                <mat-label>{{ constants.BAHAMAS_EMAIL }}</mat-label>
                <input
                  formControlName="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  autocomplete="off"
                  type="text"
                  matInput
                  (input)="inputEmail(email.value)"
                  #email
                />
                <mat-error>{{ constants.EMAIL_INVALID }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width password">
                <mat-label>{{ constants.PASSWORD }}</mat-label>
                <input
                  formControlName="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  autocomplete="off"
                  type="password"
                  (input)="inputPassword(password.value)"
                  #password
                  matInput
                />
              </mat-form-field>
              <div class="functions">
                <div class="row">
                  <div class="col-6">
                    <div class="remember-me">
                      <mat-checkbox
                        [checked]="localUser && localPass"
                        (change)="onRememberMeCheck($event)"
                        >{{ constants.REMEMBER_ME }}
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="forgot-password">
                      <a class="link" [routerLink]="['/forgot-password']">
                        {{ constants.CANT_ACCESS_ACCOUNT }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="hasError">
                <div class="error-message">
                  {{ constants.COMPLETE_FIELDS }}
                  <br />
                </div>
              </ng-container>
              <br />
              <div class="center">
                <div class="row">
                  <div class="col-6">
                    <button
                      class="h-60 form-buttons"
                      id="login-clear"
                      nbButton
                      status="primary"
                      type="reset"
                      (click)="clear()"
                    >
                      {{ buttons.CLEAR }}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      class="h-60 form-buttons"
                      type="submit"
                      nbButton
                      status="info"
                    >
                      {{ buttons.LOGIN }}
                    </button>
                  </div>
                </div>

                &nbsp;
              </div>
              <div class="register-user">
                <!-- <br />
                <a class="link center" [routerLink]="['/forgot-password']">
                  {{ constants.CANT_ACCESS_ACCOUNT }}
                </a>
                <br>
                <mat-divider></mat-divider> -->
                <!-- <br /> -->
                <span
                  >{{ constants.REGISTER_USER }}
                  <a class="link" [routerLink]="['/register']"
                    >Register here</a
                  ></span
                >
                <!-- <br /><br /> -->
                <!-- <mat-checkbox
                  [checked]="localUser && localPass"
                  (change)="onRememberMeCheck($event)"
                  >{{ constants.REMEMBER_ME }}
                </mat-checkbox> -->
              </div>
              <span class="subtitle test-mode" *ngIf="testMode">
                {{ testMode.value }}
              </span>
            </form>
          </div>
        </div>
      </div>
    </nb-layout-column>
    <nb-layout-footer class="footer-container">
      <app-footer></app-footer>
    </nb-layout-footer>
  </nb-layout>
</ng-template>
