import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  modalComponent = this;
  @Input()dialogTitle = '';
  @Input() modalName = '';
  @Input() isSubmit: boolean;
  @Input() submitBtnName: string;

  constructor(
    public dialogRef: NbDialogRef<ModalComponent>,
    ) { }

  ngOnInit(): void {
  }

}
