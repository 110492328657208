<mat-toolbar>
  <div class="col-12 px-0">
    <div *ngIf="path !== 'NOTAM/'" class="d-flex justify-content-end mt-4">
      <mat-icon
        class="pointer"
        (click)="navigateUp()"
        matTooltip="Back to {{ backTo(path) || 'root' }}"
        matTooltipPosition="below"
      >
        arrow_back
      </mat-icon>
    </div>
    <div
      class="
        d-flex
        justify-content-between
        align-center
        py-4
        flex-md-row flex-column
      "
    >
      <h1 class="titleNotam">
        {{ formatPath(path) || '' }}
      </h1>
      <div>
        <span class="spacer"></span>
        <button
          *ngIf="isAdmin"
          nbButton
          status="info"
          size="small"
          color="accent"
          class="pointer"
          (click)="openNewFolderDialog()"
          matTooltip="Create Folder"
          matTooltipPosition="below"
        >
          Create Folder
        </button>
        &nbsp;
        <button
          *ngIf="isAdmin"
          nbButton
          status="info"
          size="small"
          color="accent"
          class="pointer"
          matTooltip="Upload Files"
          matTooltipPosition="below"
          (click)="openUploadDialog()"
          type="button"
        >
          Add NOTAM +
        </button>
      </div>
    </div>
    
    <div class="row">
      <mat-form-field appearance="outline" class="width-4 col-4">
        <mat-label>Aerodrome</mat-label>
        <input
          matInput
          placeholder="Search by aerodrome"
          #aerodromeInput 
          (keyup)="onAerodromeInput($event.target.value)"
        />
      </mat-form-field>
      <div class="mat-form-field-wrapper-2 align-center d-flex">
        <button
            nbButton
            status="info"
            size="small"
            color="accent"
            class="pointer"
            matTooltip="Upload Files"
            matTooltipPosition="below"
            type="button"
            (click)="searchFormClick()"
        >
          <mat-icon class="pointer">
            search
          </mat-icon>
        </button>
      </div>
    </div>

  </div>
</mat-toolbar>

<div
  class="explorer-content"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div class="content content-wrapper scrollable">
    <!-- convert to list start -->
    <table mat-table [dataSource]="fileElements" class="">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>NOTAM Name</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="name-col"
          (click)="navigate(element)"
        >
          <div
            fxLayout="row"
            (contextmenu)="openMenu($event, menuTrigger, element.isFolder)"
            matTooltip="{{ element.name }}"
            matTooltipPosition="below"
            (click)="viewFile(element)"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            <ng-container *ngIf="element.isFolder; else files">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.4999 10C12.6162 10 11.8999 10.7163 11.8999 11.6V28.4C11.8999 29.2837 12.6162 30 13.4999 30H26.4999C27.3836 30 28.0999 29.2837 28.0999 28.4V15L23.0999 10H13.4999Z"
                  fill="#576A95"
                />
                <g filter="url(#filter0_dd_1175_982)">
                  <path
                    d="M21.375 22.4901V25.2061C21.375 25.2953 21.3573 25.3836 21.3231 25.466C21.2889 25.5484 21.2388 25.6232 21.1756 25.6862C21.048 25.8136 20.875 25.8851 20.6946 25.8851H19.3054C19.1251 25.8851 18.9521 25.8136 18.8244 25.6863C18.7612 25.6233 18.711 25.5484 18.6768 25.466C18.6426 25.3836 18.625 25.2952 18.625 25.2059V22.4901H21.375ZM20.6946 24.0461H19.3054V25.4098H20.6946V24.0461ZM21.3523 19.7741V21.1378H19.9859V19.7741H21.3523ZM20.0029 10.2V11.575H21.375V12.95H20.0027V14.2231H21.375V15.5981H20.0029V16.9562H21.375V18.3143H20.0029V19.6723H18.625V18.3142H19.9971V16.9561H18.625V15.5981H19.9971V14.2231H18.625V12.8481H19.9971V11.575H18.625V10.2H20.0029Z"
                    fill="white"
                  />
                </g>
                <path
                  d="M27.631 14.5313L23.5688 14.5312L28.1001 19.0625V15L27.631 14.5313Z"
                  fill="url(#paint0_linear_1175_982)"
                />
                <path
                  d="M24.7001 15L28.1001 15L23.1001 10L23.1001 13.4C23.1001 14.2837 23.8164 15 24.7001 15Z"
                  fill="#BBC3D4"
                />
                <defs>
                  <filter
                    id="filter0_dd_1175_982"
                    x="17.625"
                    y="9.19995"
                    width="11.75"
                    height="26.6851"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="4" dy="6" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1175_982"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect1_dropShadow_1175_982"
                      result="effect2_dropShadow_1175_982"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect2_dropShadow_1175_982"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_1175_982"
                    x1="24.5845"
                    y1="13.5156"
                    x2="29.1157"
                    y2="18.0469"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-opacity="0.2" />
                    <stop offset="1" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </ng-container>
            <ng-template #files>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.5707 10C12.6757 10 11.9502 10.7163 11.9502 11.6V28.4C11.9502 29.2837 12.6757 30 13.5707 30H26.7374C27.6324 30 28.3579 29.2837 28.3579 28.4V15L23.2938 10H13.5707Z"
                  fill="#FA4E4E"
                />
                <path
                  d="M23.4437 23.4693C22.7601 23.4192 22.102 23.1686 21.5704 22.7175C20.5326 22.943 19.5452 23.2687 18.5579 23.6698C17.7732 25.0482 17.0391 25.75 16.4062 25.75C16.2796 25.75 16.1278 25.725 16.0265 25.6497C15.748 25.5245 15.5962 25.2487 15.5962 24.9731C15.5962 24.7475 15.6468 24.121 18.0516 23.0933C18.6087 22.0908 19.0389 21.0633 19.3934 19.9857C19.0895 19.3842 18.4315 17.9055 18.887 17.1537C19.0389 16.878 19.3427 16.7276 19.6719 16.7527C19.925 16.7527 20.1781 16.878 20.33 17.0785C20.6592 17.5296 20.6338 18.482 20.2034 19.8855C20.6085 20.6363 21.1391 21.3141 21.7729 21.8905C22.3046 21.7901 22.8361 21.715 23.3677 21.715C24.5575 21.74 24.7347 22.2913 24.7094 22.6172C24.7094 23.4693 23.874 23.4693 23.4437 23.4693ZM16.3557 25.0231L16.4316 24.9981C16.786 24.8728 17.0644 24.6222 17.267 24.2963C16.8872 24.4467 16.5834 24.6973 16.3557 25.0232V25.0231ZM19.7224 17.5046H19.6465C19.6211 17.5046 19.5705 17.5046 19.5452 17.5296C19.4439 17.9556 19.5199 18.4067 19.6971 18.8077C19.849 18.3817 19.849 17.9306 19.7224 17.5046ZM19.8997 21.1386L19.8743 21.1886L19.849 21.1636C19.6211 21.7401 19.3679 22.3165 19.0895 22.8678L19.1402 22.8428V22.8928C19.7031 22.6913 20.278 22.5239 20.8616 22.3916L20.8363 22.3666H20.9122C20.5324 21.9906 20.1781 21.5646 19.8997 21.1386ZM23.3424 22.4668C23.1145 22.4668 22.912 22.4668 22.6842 22.5168C22.9374 22.6422 23.1906 22.6923 23.4437 22.7175C23.6209 22.7425 23.7981 22.7175 23.9499 22.6673C23.9499 22.5921 23.8487 22.4668 23.3424 22.4668Z"
                  fill="white"
                />
                <path
                  d="M27.8828 14.5313L23.7686 14.5312L28.3579 19.0625V15L27.8828 14.5313Z"
                  fill="url(#paint0_linear_1175_1072)"
                />
                <path
                  d="M24.9143 15L28.3579 15L23.2938 10L23.2938 13.4C23.2938 14.2837 24.0193 15 24.9143 15Z"
                  fill="#FDB8B8"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1175_1072"
                    x1="24.7972"
                    y1="13.5156"
                    x2="29.3281"
                    y2="18.1046"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-opacity="0.2" />
                    <stop offset="1" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </ng-template>
            <span>
              {{ element.name }}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Aerodrome Column -->
      <ng-container matColumnDef="aerodrome">
        <th mat-header-cell *matHeaderCellDef>Aerodrome</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.isFolder; else files"> -- </ng-container>
          <ng-template #files>
            {{
              element.optionals.aerodrome ? element.optionals.aerodrome : '--'
            }}
          </ng-template>
        </td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef>Upload Datetime (UTC)</th>
        <td mat-cell *matCellDef="let element" (click)="navigate(element)">
          <div
            fxLayout="row"
            (contextmenu)="openMenu($event, menuTrigger, element.isFolder)"
            matTooltip="{{ element.name }}"
            matTooltipPosition="below"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            {{ element.dateCreated | date: 'MMM d, y, h:mm a' }}
          </div>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element" (click)="navigate(element)">
          <div
            fxLayout="row"
            (contextmenu)="openMenu($event, menuTrigger, element.isFolder)"
            matTooltip="{{ element.name }}"
            matTooltipPosition="below"
          >
            <span
              [matMenuTriggerFor]="rootMenu"
              [matMenuTriggerData]="{ element: element }"
              #menuTrigger="matMenuTrigger"
            >
            </span>
            {{ element.type !== '' ? getFileType(element.type) : 'Folder' }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="text-center" fxLayout="row">
            <div
              (click)="openMenu($event, menuTrigger, element.isFolder)"
              *ngIf="isAdmin"
            >
              <span
                [matMenuTriggerFor]="rootMenu"
                [matMenuTriggerData]="{ element: element }"
                #menuTrigger="matMenuTrigger"
              >
              </span>
              <ng-container>
                <mat-icon class="file-or-folder-icon pointer">
                  more_horiz
                </mat-icon>
              </ng-container>
            </div>
            <div
              (click)="openMenu($event, menuTrigger, element.isFolder)"
              *ngIf="!isAdmin && !element.isFolder"
            >
              <span
                [matMenuTriggerFor]="rootMenu"
                [matMenuTriggerData]="{ element: element }"
                #menuTrigger="matMenuTrigger"
              >
              </span>
              <ng-container>
                <mat-icon class="file-or-folder-icon pointer">
                  more_horiz
                </mat-icon>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <tr class="position-sticky" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <p
      class="align-center empty-folder"
      *ngIf="fileElements?.length <= 0 || fileElements === []"
    >
      This folder is empty.
    </p>
    <!-- convert to list end -->
  </div>
</div>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="openMoveDialog(element)" *ngIf="isAdmin">
      <mat-icon>open_with</mat-icon>
      <span>Move</span>
    </button>
    <button
      mat-menu-item
      (click)="openEditDialog(element)"
      *ngIf="isAdmin && !element.isFolder"
    >
      <mat-icon>edit</mat-icon>
      <span>Edit</span>
    </button>
    <button mat-menu-item *ngIf="!element.isFolder" (click)="viewFile(element)">
      <mat-icon>remove_red_eye</mat-icon>
      <span>View</span>
    </button>
    <button
      mat-menu-item
      *ngIf="!element.isFolder"
      (click)="downloadFile(element)"
    >
      <mat-icon>download</mat-icon>
      <span>Download</span>
    </button>
    <button
      mat-menu-item
      (click)="openRenameDialog(element)"
      *ngIf="isAdmin && element.isFolder"
    >
      <mat-icon>edit</mat-icon>
      <span>Rename</span>
    </button>
    <button mat-menu-item (click)="deleteElement(element)" *ngIf="isAdmin">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>
