import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AddSystemParameters } from '@app/core/models/system-parameters.model';
import { AuthService } from '@app/core/services/auth/auth.service';
import { SystemParameterService } from '@app/core/services/system-parameter.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';

@Component({
  selector: 'app-add-system-parameters',
  templateUrl: './add-system-parameters.component.html',
  styleUrls: ['./add-system-parameters.component.scss'],
})
export class AddSystemParametersComponent implements OnInit {
  systemParametersForm: FormGroup;
  systemParameters: any;
  filteredSystemParams: any;
  typeList: any;
  currentTransaction: string;
  checker: any;
  selected = 'option2';

  constructor(
    private dialogRef: MatDialogRef<AddSystemParametersComponent>,
    private systemParametersService: SystemParameterService,
    private formBuilder: FormBuilder,
    private notiflixService: NotiflixService,
    private authService: AuthService
  ) {}

  @HostListener('window:beforeunload', ['$event']) unloadHandler(
    event: Event
  ): void {
    const formLength = [
      JSON.stringify(this.systemParametersForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentFormLength !== formLength) {
        // tslint:disable-next-line: deprecation
        event.returnValue = false;
      }
      // tslint:disable-next-line: deprecation
      // event.returnValue = false; // stay on same page
    }
  }

  ngOnInit(): void {
    this.getTypes();
    this.initFormmControls();
  }

  getTypes(): void {
    this.systemParametersService.getTypeList().subscribe((data) => {
      this.typeList = data;
    });
  }

  initFormmControls(): void {
    this.systemParametersForm = this.formBuilder.group({
      typeId: ['', Validators.required],
      code: ['', Validators.required],
      value: ['', Validators.required],
      description: ['', Validators.required],
      typeMotor: ['', Validators.required],
      numberMotors: ['', Validators.required],
      mtow: ['', Validators.required],
      mtowClass: ['', Validators.required],
      displayWarning: ['', Validators.required],
    });

    this.checker = [JSON.stringify(this.systemParametersForm.value).length];
  }

  close(): void {
    const formLength = [
      JSON.stringify(this.systemParametersForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );
    if (currentFormLength !== formLength) {
      this.currentTransaction = 'BACK';
      this.notiflixService.launchConfirm(
        'Are you sure you want to exit?',
        'Changes you made will not be saved.',
        this,
        'EXIT',
        'NO'
      );
    } else {
      this.currentTransaction = 'BACK';
      this.onPositive();
    }
  }

  onSave(): void {
    this.currentTransaction = 'SAVE';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'System Parameters will be saved.',
      this,
      'SAVE',
      'CANCEL'
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'SAVE':
        this.onSaveSystemParameters();
        const saveBtn = document.getElementById('saveBtn') as HTMLElement;
        saveBtn.click();
        this.dialogRef.close();
        break;
      case 'BACK':
        this.dialogRef.close();
        break;
      default:
        throw new Error(`${this.currentTransaction} is invalid`);
    }
  }

  onChange(value): void {
    if (value !== 3) {
      this.systemParametersForm.get('typeMotor').clearValidators();
      this.systemParametersForm.get('typeMotor').updateValueAndValidity();
      this.systemParametersForm.get('numberMotors').clearValidators();
      this.systemParametersForm.get('numberMotors').updateValueAndValidity();
      this.systemParametersForm.get('mtow').clearValidators();
      this.systemParametersForm.get('mtow').updateValueAndValidity();
      this.systemParametersForm.get('mtowClass').clearValidators();
      this.systemParametersForm.get('mtowClass').updateValueAndValidity();
      this.systemParametersForm.get('displayWarning').clearValidators();
      this.systemParametersForm.get('displayWarning').updateValueAndValidity();
    } else {
      this.systemParametersForm
        .get('typeMotor')
        .setValidators([Validators.required]);
      this.systemParametersForm.get('typeMotor').updateValueAndValidity();
      this.systemParametersForm
        .get('numberMotors')
        .setValidators([Validators.required]);
      this.systemParametersForm.get('numberMotors').updateValueAndValidity();
      this.systemParametersForm
        .get('mtow')
        .setValidators([Validators.required]);
      this.systemParametersForm.get('mtow').updateValueAndValidity();
      this.systemParametersForm
        .get('mtowClass')
        .setValidators([Validators.required]);
      this.systemParametersForm.get('mtowClass').updateValueAndValidity();
      this.systemParametersForm
        .get('displayWarning')
        .setValidators([Validators.required]);
      this.systemParametersForm.get('displayWarning').updateValueAndValidity();
    }
  }

  onNegative(): void {}

  onSaveSystemParameters(): void {
    const addSystemParams = new AddSystemParameters();

    addSystemParams.code = this.systemParametersForm.controls.code.value;
    addSystemParams.value = this.systemParametersForm.controls.value.value;
    addSystemParams.typeId = this.systemParametersForm.controls.typeId.value;
    addSystemParams.description =
      this.systemParametersForm.controls.description.value;
    addSystemParams.typeMotor =
      this.systemParametersForm.controls.typeMotor.value;
    addSystemParams.numberMotors =
      this.systemParametersForm.controls.numberMotors.value;
    addSystemParams.mtow = this.systemParametersForm.controls.mtow.value;
    addSystemParams.mtowClass =
      this.systemParametersForm.controls.mtowClass.value;
    addSystemParams.displayWarning =
      this.systemParametersForm.controls.displayWarning.value;

    this.systemParametersService.addSystemParameters(
      JSON.stringify(addSystemParams)
    );
  }
}
