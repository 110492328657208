import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { RegisterUserService } from '@app/core/services/register-user.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  signUpForm: FormGroup;
  appVersion = environment.version;
  constants = CONSTANTS;
  isLoading = false;
  passwordHide = true;
  confirmPasswordHide = true;
  passwordFormat: string;
  process: string;
  operatorName: string;
  carrier: string;
  spinnerLoading = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private registerUser: RegisterUserService,
    private notiflixService: NotiflixService
  ) {}

  ngOnInit(): void {
    this.spinnerLoading = true;
    this.initFormControls();
    this.passwordFormat = `
    - Password must contain at least 8 characters;
    - Max characters: 20
    - Must contain characters from the following:
      • Capital letters (A, B, C, D ...)
      • Lowercase letters (a, b, c, d ...)
      • Numbers (0, 1, 2, 3 ...)
      • Special characters ( ! @ # $ % & * _ + - = | ; > ? , . / )`;
    this.operatorName = `ICAO 3 letters code: 3 letters code assigned to the operator flights according to ICAO (e.g. American Airlines - AAL)`;
    this.carrier = `
    Carrier name: name of the operator providing the air transport service`;
  }

  initFormControls(): void {
    this.signUpForm = this.formBuilder.group({
      carrierName: ['', [Validators.required, Validators.maxLength(90)]],
      name: ['', [Validators.maxLength(90)]],
      street: ['', [Validators.required, Validators.maxLength(90)]],
      city: ['', [Validators.required, Validators.maxLength(90)]],
      state: ['', [Validators.required, Validators.maxLength(90)]],
      zipCode: ['', [Validators.required, Validators.maxLength(15)]],
      country: ['', [Validators.required, Validators.maxLength(90)]],
      email: ['', [Validators.required, this.registerUser.validateEmail]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*_+\-=|;>?,./])[A-Za-z\d!@#$%&*_+\-=|;>?,./]{8,20}$/
          ),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*_+\-=|;>?,./])[A-Za-z\d!@#$%&*_+\-=|;>?,./]{8,20}$/
          ),
        ],
      ],
      aircraftIdNo: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6)
        ]
      ],
    });
  }

  checkEmailFormat(email: string): void {}

  checkPassword(password: string): void {
    if (this.f.confirmPassword.value !== this.f.password.value) {
      this.f.confirmPassword.setErrors({ error: true });
    } else {
      this.f.confirmPassword.setErrors(null);
    }
  }

  hidePassword(passwordField: string): void {
    switch (passwordField) {
      case 'passwordHide':
        this.passwordHide = !this.passwordHide;
        if (!this.passwordHide) {
          setTimeout(() => {
            if (this.passwordHide === false) {
              this.passwordHide = true;
            }
          }, this.constants.PASSWORD_HIDE_TIMER);
        }
        break;
      case 'confirmPasswordHide':
        this.confirmPasswordHide = !this.confirmPasswordHide;
        if (!this.confirmPasswordHide) {
          setTimeout(() => {
            if (this.confirmPasswordHide === false) {
              this.confirmPasswordHide = true;
            }
          }, this.constants.PASSWORD_HIDE_TIMER);
        }
        break;
      default:
        break;
    }
  }

  // tslint:disable-next-line: typedef
  get f() {
    return this.signUpForm.controls;
  }

  onSubmit(): void {
    this.notiflixService.loadPulse('Processing...');
    this.registerUser.registerUser(this.signUpForm.value).subscribe(
      (data) => {
        if (data) {
          this.notiflixService.closeLoader();
          this.process = 'LOGIN';
          this.notiflixService.launchReport(
            'success',
            'Success!',
            `${data.message}`,
            null,
            this
          );
        }
      },
      (error) => {
        this.process = 'REGISTER';
        this.notiflixService.closeLoader();
        this.notiflixService.launchReport(
          'error',
          'Error!',
          `${error.error.clientError}`,
          null,
          this
        );
      }
    );
  }

  // tslint:disable-next-line: typedef
  onOk() {
    switch (this.process) {
      case 'LOGIN':
        this.router.navigate(['/login'], {
          queryParams: { token: null },
          queryParamsHandling: 'merge',
        });
        break;
      case 'REGISTER':
        break;
    }
  }
}
