<div class="screen-md">
  <div class="row">
    <div class="col header-menu-items" *ngFor="let item of menuItems">
      <div
        class="menu-item"
        matTooltip="{{ item.itemName }}"
        matTooltipClass="tooltipClass"
        matTooltipPosition="below"
        (click)="routeTo(item.link)"
      >
        <i
          class="material-icons md-36"
          [ngClass]="{ active: router.url.includes(item.link) }"
          >{{ item.icon }}</i
        >
      </div>
    </div>
  </div>
</div>
