import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private cryptoService: CryptoService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const currentUser = this.authService.currentUserValue;
    // return true;
    if (currentUser) {
      // If there is a current logged in user, proceed with accessing the page
      return true;
    }

    // Else navigate to checkpoint page
    this.router.navigate(['/checkpoint'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
