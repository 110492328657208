import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { CryptoService } from '../../services/crypto/crypto.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private cryptoService: CryptoService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const currentUser =  JSON.parse(this.cryptoService.getRawToken());
    const currentUser = this.cryptoService.getRawToken();
    if (currentUser) {
      req = req.clone({
        setHeaders: {
          Authorization: currentUser,
        },
      });
    }

    if (
      this.cryptoService.getStorageItem('refreshToken') &&
      this.authService.isTokenExpired()
    ) {
      const refreshtoken = this.cryptoService.getStorageItem('refreshToken');
      req = req.clone({
        setHeaders: {
          'x-hrp-refresh-token': refreshtoken,
        },
      });
    }

    return next.handle(req);
  }
}
