import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {
  NbSidebarModule,
  NbMenuModule,
  NbLayoutModule,
  NbIconModule,
  NbInputModule,
  NbCardModule,
  NbButtonModule,
  NbUserModule,
  NbContextMenuModule,
} from '@nebular/theme';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuItemsComponent } from './menu-items/menu-items.component';
import { AngularModule } from './modules/angular.module';
import { MaterialModule } from './modules/material.module';
import { DataTableComponent } from './data-table/data-table.component';
import { ModalComponent } from './modal/modal.component';
import { NewPasswordFormComponent } from './forms/new-password-form/new-password-form.component';
import { PaginatorComponent } from './paginator/paginator.component';

import { MatDataTableComponent } from './mat-data-table/mat-data-table.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    MenuItemsComponent,
    DataTableComponent,
    ModalComponent,
    NewPasswordFormComponent,
    PaginatorComponent,
    MatDataTableComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    AngularModule,
    MaterialModule,

    // Nebular
    NbSidebarModule,
    NbMenuModule,
    NbLayoutModule,
    NbIconModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbUserModule,
    NbContextMenuModule,

    // Material Design
    MatTooltipModule,
  ],
  exports: [
    AngularModule,
    MaterialModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    MenuItemsComponent,
    DataTableComponent,
    NewPasswordFormComponent,
    MatDataTableComponent,
    LoaderComponent,
  ],
})
export class SharedModule {}
