import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { HttpService } from '../services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  BACKEND_ENDPOINT = environment;
  API_ROUTES = API_ENPOINTS;

  constructor(private httpService: HttpService) { }

  getHppCode(totalPayment): Observable<any> {
    return this.httpService.post(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.hppCode}`, 
      totalPayment
    );
  }
  getPaymentResponseByToken(hppCode): Observable<any> {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.paymentResponse}${hppCode}`
    );
  }
}
