import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

const SECRET_KEY = '1ndr@_s3cur3D';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  public rawToken: any;

  constructor(private cookieService: CookieService) {}

  public setStorageItem(key: string, value: string): void {
    const encryptedValue = this.encrypt(value);
    sessionStorage.setItem(key, encryptedValue);
    localStorage.setItem(key, encryptedValue);
  }

  public setEncryptedLocalStorageItem(key: string, value: string): void {
    const encryptedValue = this.encrypt(value);
    localStorage.setItem(key, encryptedValue);
  }

  public getStorageItem(key: string): any {
    const localStorageItemValue = localStorage.getItem(key);
    if (localStorageItemValue !== null) {
      return this.decrypt(localStorageItemValue);
    } else {
      return localStorageItemValue;
    }
  }

  public getSessionStorageItem(key: string): any {
    const sessionStorageItemValue = sessionStorage.getItem(key);
    if (sessionStorageItemValue !== null) {
      return this.decrypt(sessionStorageItemValue);
    } else {
      return sessionStorageItemValue;
    }
  }

  public encrypt(data): string {
    const encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
    return encryptedData.toString();
  }

  private decrypt(data): any {
    let decryptedData = CryptoJS.AES.decrypt(data, SECRET_KEY);
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8);

    // For now, return fake decrypted data
    return decryptedData;
  }

  getRawToken(): any {
    const currentUser = this.getStorageItem('currentUser');
    if (currentUser !== null) {
      this.rawToken = currentUser;
    } else {
      this.resetRawToken();
    }

    return this.rawToken;
  }

  resetRawToken(): void {
    this.rawToken = null;
  }

  isAdmin(): boolean {
    const currentUser = JSON.parse(this.getStorageItem('userInfo'));

    return currentUser.profile.name === 'System Administrator';
  }
}
