<div class="d-flex flex-column h-100">
  <img
    animateOnScroll
    animationName="animate__animated animate__fadeInRight"
    class="bansa-logo d-none d-md-block"
    src="../../../../assets/images/BANSA logo.png"
    alt=""
  />

  <a href="#" class="sidebar-btn-menu d-none d-md-flex" (click)="toggle()">
    <nb-icon icon="arrowhead-left-outline" pack="eva"></nb-icon>
  </a>

  <nb-menu [items]="items" class="flex-grow-1 mt-2 mt-md-5 scrollable-y">
    <ng-container *ngFor="let item of items">
      <nb-menu-item [routerLink]="item.link">
        <nb-icon [icon]="item.icon.icon" [pack]="item.icon.pack"></nb-icon
        >{{ item.title }}
      </nb-menu-item>
    </ng-container>
  </nb-menu>

  <nb-menu [items]="itemsButton" class="mt-5"></nb-menu>
</div>
