export const SUCCESS_MESSAGES = {
  SWAL_SAMPLE: {
    title: 'Success',
    body: 'This is a sample success Sweetalert.',
  },
  TITLE: 'Success',
  PASSWORD_CHANGED: 'Successfully changed password',
  RESET_PASSWORD_REQUEST: `Your request has been processed. You'll receive a reset password email shortly.`,
  RESET_LINK: 'Reset link is valid! Please proceed in resetting your password',
  USER_ADDED: 'New employee has been added!',
  USERS_ADDED: 'New employees has been added!',
  ACTIVATED_ACCOUNT: 'Successfully activated account!',
  EDIT_EMPLOYMENT_INFO: 'Employment information has been updated!',
  RESEND_ACTIVATION_EMAIL: 'Activation email has been resent!',
  ASSIGN_ACCESS: 'access has been assigned!',
  REMOVE_ACCESS: 'access has been removed!',
  UPDATE_MOVEMENT: 'Employee movement details has been updated!',
  EDIT_EMPLOYMENT_TYPE: 'Employment information has been updated!',
  REHIRE_EMPLOYEE: 'Employee has been rehired!',
  DEACTIVATE_USERS: 'User/s has been successfully deactivated',
  REACTIVATE_USERS: 'User/s has been successfully reactivated',
};
