import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  Output,
  ChangeDetectorRef,
  AfterContentChecked,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { OperationsService } from '@app/core/services/operations.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { AddFlightService } from '@app/core/services/add-flight.service.ts.service';
import { FeesBreakdownService } from '@app/core/services/fees-breakdown.service';
import { AddFlightPlan } from '@app/core/models/add-flight-plan.model';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { PaginatorComponent } from 'app/shared/paginator/paginator.component';
import { AuthService } from '@app/core/services/auth/auth.service';
import { PaymentService } from '@app/core/services/payment.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FlightPlanService } from '@app/core/services/flight-plan.service';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { REGEX } from '@app/core/constants/regex.const';

@Component({
  selector: 'app-add-flight-plan',
  templateUrl: './add-flight-plan.component.html',
  styleUrls: ['./add-flight-plan.component.scss'],
})
export class AddFlightPlanComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild('paginator') paginator: PaginatorComponent;
  @ViewChild('iframe') iframe: ElementRef;
  @Output() pageEvent = new EventEmitter();
  atcCharge: number;
  operationSubscription$: Subscription;
  operationTypes: Array<any>;
  flightRules: Array<any>;
  flightTypes: Array<any>;
  aircraftTypes: Array<any>;
  motorTypes: Array<any>;
  airportCodeList: Array<any>;
  isLinear = true;
  isLoading = false;
  // isDisabled = true;
  canDeacForm: FormGroup;
  flightDetailsForm: FormGroup;
  departureDetailsForm: FormGroup;
  emergencyDetailsForm: FormGroup;
  pilotDetailsForm: FormGroup;
  passengerDetailsForm: FormGroup;
  feesBreakdownForm: FormGroup;
  filingDate: Date;
  filteredOperations: Observable<string[]>;
  filteredRules: Observable<string[]>;
  filteredFlightTypes: Observable<string[]>;
  filteredAircraftTypes: Observable<string[]>;
  filteredMotorTypes: Observable<string[]>;
  currentTransaction: string;
  items: FormArray;
  mindateToday = new Date(1960, 0, 1);
  propertiesSubscription$: Subscription;
  propertiesList: Array<any>;
  minDate = new Date(1960, 0, 1);
  checker: any;
  routeCount = '';
  constants = CONSTANTS;
  icaoErrorMsg: string;
  public requiredFieldMsg = 'This is a required field.';
  public icaoWarningMsg = 'This airport is not in Bahamas';
  public cruisingSpeedMsg = '';
  public minutesErrorMsg: string;
  minutesError = false;
  icaoWarning = false;
  deptAeroWarning = false;
  destAeroWarning = false;
  alterAeroWarning = false;
  secAlterAeroWarning = false;
  mtowDisplayWarning = false;
  totalAmount: string;
  formdata = new AddFlightPlan();
  // tslint:disable-next-line: no-inferrable-types
  forValidation: boolean = true;
  paymentBaseUrl: string = null;
  paymentHostedPage: string = null;
  // tslint:disable-next-line: no-inferrable-types
  enablePayment: boolean = false;
  enableAddRoute = false;
  enableAddPassenger = false;
  isFreeTax = false;
  cruisingSpeedRegEx = new RegExp(/^[0-9]+(\.[0-9]{1,3})?$/);
  timeZone: any;

  constructor(
    private notiflixService: NotiflixService,
    private formBuilder: FormBuilder,
    private operationsService: OperationsService,
    private feesBreakdownService: FeesBreakdownService,
    private addFlightService: AddFlightService,
    private router: Router,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private payment: PaymentService,
    private flightPlanService: FlightPlanService,
    private cryptoService: CryptoService
  ) {}

  @HostListener('window:beforeunload', ['$event']) unloadHandler(
    event: Event
  ): void {
    const formLength = [
      JSON.stringify(this.flightDetailsForm.value).length,
      JSON.stringify(this.departureDetailsForm.value).length,
      JSON.stringify(this.emergencyDetailsForm.value).length,
      JSON.stringify(this.pilotDetailsForm.value).length,
      JSON.stringify(this.passengerDetailsForm.value).length,
      JSON.stringify(this.feesBreakdownForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );

    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentFormLength !== formLength) {
        // tslint:disable-next-line: deprecation
        event.returnValue = false;
      }
      // tslint:disable-next-line: deprecation
      // event.returnValue = false; // stay on same page
    }
  }

  ngOnInit(): void {
    if (this.cryptoService.isAdmin()) {
      this.router.navigateByUrl('/forbidden');
    }

    this.getAllOperations();
    this.initFormControls();
    this.getTimeZone();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getTimeZone(): void {
    this.flightPlanService.getTimeZone().subscribe((timeZone) => {
      this.timeZone = this.flightPlanService.formatTimeZone(timeZone.value);

      this.flightDetailsForm
        .get('filingTime')
        .setValue(this.timeZone.filingTime);

      this.flightDetailsForm
        .get('filingDate')
        .setValue(this.timeZone.filingDate);

      this.flightDetailsForm
        .get('flightDate')
        .setValue(this.timeZone.currentDate);
    });
  }

  private filterAircraftType(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.aircraftTypes.filter((option) =>
      option.value.toLowerCase().includes(filterValue)
    );
  }

  // tslint:disable-next-line: typedef
  private filterAirportCode(value: string) {
    const filterValue = value.toLowerCase();
    return this.airportCodeList.filter((key) =>
      key.code.toLowerCase().includes(filterValue)
    );
  }

  getFilteredAircraftType(value: string): Observable<string[]> {
    return of(value).pipe(
      map((filterString) => this.filterAircraftType(filterString))
    );
  }

  displayAircraftTypeFn(aircraftType): string {
    return aircraftType && aircraftType.value ? aircraftType.value : '';
  }

  onAircraftTypeChange(event): void {
    this.filteredAircraftTypes = this.getFilteredAircraftType(
      this.flightDetailsForm.get('aircraftType').value
    );
    this.filteredAircraftTypes.subscribe((res) => {
      if (res.length === 0) {
        this.icaoErrorMsg = `Warning! Aircraft ICAO model code not recognized, please be
        sure it is correct`;
        this.icaoWarning = true;
      } else {
        this.icaoWarning = false;
      }
    });
  }

  onSelectAircraftType(option): void {
    this.mtowDisplayWarning = option.displayWarning;
    this.flightDetailsForm.get('aircraftType').setValue(option);
    this.flightDetailsForm.get('numberMotors').setValue(option.numberMotors);
    this.flightDetailsForm.get('typeMotors').setValue(option.typeMotor);
    this.flightDetailsForm.get('mtow').setValue(option.mtow);
  }

  getAllOperations(): void {
    this.operationSubscription$ = this.operationsService
      .getAllOperations()
      .subscribe((response: any) => {
        this.operationTypes = response.operationTypes;
        this.flightRules = response.flightRules;
        this.flightTypes = response.flightTypes;
        this.motorTypes = response.motorTypes;
        this.airportCodeList = response.airports;
        this.aircraftTypes = response.aircraftTypes;
        this.filteredAircraftTypes = this.getFilteredAircraftType(
          this.flightDetailsForm.get('aircraftType').value
        );
        response.payment.find((res) => {
          if (res.code === 'PAYMENT_BASE_URL') {
            this.paymentBaseUrl = res.value;
          }
          if (res.code === 'PAYMENT_HOSTED_PAGE') {
            this.paymentHostedPage = res.value;
          }
        });
      });
  }

  onSelect(event): void {}

  initFormControls(): void {
    this.flightDetailsForm = this.formBuilder.group({
      filingTime: ['', Validators.required],
      filingDate: ['', Validators.required],
      typeOperation: ['', Validators.required],
      typeFlight: ['', Validators.required],
      aircraftIdentification: ['', Validators.required],
      aircraftType: ['', Validators.required],
      flightRules: ['', Validators.required],
      numberMotors: ['', Validators.required],
      typeMotors: ['', Validators.required],
      mtow: ['', [Validators.required, Validators.pattern(REGEX.numberOnly)]],
      flightDate: ['', Validators.required],
    });

    this.departureDetailsForm = this.formBuilder.group({
      flightRoutes: new FormArray([
        new FormGroup({
          flightRoute: new FormControl(null, [
            Validators.required,
            Validators.pattern(/[a-zA-Z0-9.-_]/),
          ]),
        }),
      ]),
      departureHour: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      departureMinute: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      offBlockHour: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      offBlockMinute: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      departureAerodrome: ['', [Validators.required, Validators.minLength(4)]],
      destinationAerodrome: [
        '',
        [Validators.required, Validators.minLength(4)],
      ],
      estimatedTimeEnrouteHours: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      estimatedTimeEnrouteMinutes: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      fuelEnduranceHours: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      fuelEnduranceMinutes: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      cruisingSpeed: [
        '',
        [Validators.required, Validators.pattern(this.cruisingSpeedRegEx)],
      ],
      cruisingAltitude: ['', Validators.required],
      otherInformation: ['', Validators.required],
      alternateAerodrome: ['', [Validators.required, Validators.minLength(4)]],
      secondAlternateAerodrome: ['', [Validators.minLength(4)]],
    });

    this.emergencyDetailsForm = this.formBuilder.group({
      emergencyRadio: ['', Validators.required],
      numberLifeJacketsColor: ['', Validators.required],
      dinghiesNumber: ['', Validators.required],
      dinghiesCapacity: ['', Validators.required],
      dinghiesColor: ['', Validators.required],
      aircraftColor: ['', Validators.required],
    });
    this.pilotDetailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(REGEX.numberOnly)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /[a-zA-Z0-9.-_]{1,}@[a-zA-Z-]{1,}[.]{1}[a-zA-Z]{2,}/
          ),
          RxwebValidators.unique(),
        ],
      ],
      address: ['', Validators.required],
      aircraftHomeBase: ['', Validators.required],
    });
    this.passengerDetailsForm = this.formBuilder.group({
      passenger: new FormArray([]),
      totalNumberPersonsOnBoard: [
        0,
        [Validators.maxLength(50), Validators.pattern(REGEX.numberOnly)],
      ],
      numberOfUSPassenger: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(REGEX.numberOnly),
        ],
      ],
      numberOfNonUSPassenger: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(REGEX.numberOnly),
        ],
      ],
      methodClosure: ['', Validators.required],
      otherEquipmentRemarks: ['', Validators.required],
    });
    this.feesBreakdownForm = this.formBuilder.group({
      atcCharge: [''],
      taxAtc: [''],
      subtotalAtc: [''],
      passengerCharge: [''],
      taxPassenger: [''],
      subtotalPassenger: [''],
      totalPayment: [''],
    });

    this.checker = [
      JSON.stringify(this.flightDetailsForm.value).length,
      JSON.stringify(this.departureDetailsForm.value).length,
      JSON.stringify(this.emergencyDetailsForm.value).length,
      JSON.stringify(this.pilotDetailsForm.value).length,
      JSON.stringify(this.passengerDetailsForm.value).length,
      JSON.stringify(this.feesBreakdownForm.value).length,
    ];

    this.addPassenger();
    this.passengerDetailsForm.get('passenger').disable();
  }

  // tslint:disable-next-line: typedef
  inputTotal(length: number) {
    if (length < 0) {
      return;
    }
    length = Math.min(length, 100);
    if (length > this.passenger.length) {
      this.enableAddPassenger = true;
    } else if (length < this.passenger.length) {
      const size = this.passenger.length - length;
      for (let i = 0; i < size; i++) {
        this.enableAddPassenger = false;
        this.removePassenger();
      }
    }
    if (length === 0) {
      this.passenger.clear();
      this.addPassenger();
      this.passengerDetailsForm.get('passenger').disable();
    } else {
      this.passengerDetailsForm.get('passenger').enable();
    }
  }

  addPassenger(): void {
    if (
      this.passenger.length <
        this.passengerDetailsForm.get('totalNumberPersonsOnBoard').value ||
      this.passenger.length === 0
    ) {
      this.passenger.push(
        new FormGroup({
          name: new FormControl(),
          address: new FormControl(),
          phone: new FormControl('', [Validators.pattern('^[0-9]*$')]),
          emergencyContact: new FormControl(),
        })
      );
      if (
        this.passenger.length ===
        this.passengerDetailsForm.get('totalNumberPersonsOnBoard').value
      ) {
        this.enableAddPassenger = false;
      }
    } else {
      this.enableAddPassenger = false;
    }
  }

  removePassenger(): void {
    const index = this.passenger.length - 1;
    this.passenger.removeAt(index);
  }

  filterPassenger(passengerList): Array<any> {
    return passengerList.filter((value) => {
      if (
        value.name !== null ||
        value.address !== null ||
        value.phone !== '' ||
        value.emergencyContact !== null
      ) {
        return value;
      }
    });
  }

  get passenger(): FormArray {
    return this.passengerDetailsForm.get('passenger') as FormArray;
  }

  get flightRoutes(): FormArray {
    return this.departureDetailsForm.get('flightRoutes') as FormArray;
  }

  onPageEvent(): void {
    this.pageEvent.emit();
  }

  calculateFeesBreakdown(): void {
    const request = {
      departureAerodrome:
        this.departureDetailsForm.get('departureAerodrome').value,
      destinationAerodrome: this.departureDetailsForm.get(
        'destinationAerodrome'
      ).value,
      aircraftIdentification: this.flightDetailsForm.get(
        'aircraftIdentification'
      ).value,
      typeFlight: this.flightDetailsForm.get('typeFlight').value,
      typeMotor: this.flightDetailsForm.get('typeMotors').value,
      typeOperation: this.flightDetailsForm.get('typeOperation').value.label,
      numberMotors: +this.flightDetailsForm.get('numberMotors').value,
      // typeMotor: this.flightDetailsForm.get('typeMotors').value.label,
      mtow: +this.flightDetailsForm.get('mtow').value,
      totalNumberPersonsOnBoard: +this.passengerDetailsForm.get(
        'totalNumberPersonsOnBoard'
      ).value,
    };
    this.feesBreakdownService.calculateFeesBreakdown(request).subscribe(
      (data) => {
        if (data) {
          this.feesBreakdownForm.setValue({
            atcCharge: data.atcCharge.toFixed(2),
            taxAtc: data.taxAtc.toFixed(2),
            subtotalAtc: data.subtotalAtc.toFixed(2),
            passengerCharge: data.passengerCharge.toFixed(2),
            taxPassenger: data.taxPassenger.toFixed(2),
            subtotalPassenger: data.subtotalPassenger.toFixed(2),
            totalPayment: data.totalPayment.toFixed(2),
          });
          this.isFreeTax =
            this.feesBreakdownForm.get('totalPayment').value === '0.00'
              ? true
              : false;
          this.notiflixService.closeLoader();
        }
        this.totalAmount = data.totalPayment;
      },
      (error) => {
        this.notiflixService.closeLoader();
        this.notiflixService.launchReport(
          'error',
          'Error',
          `${error.error.clientError}`,
          '',
          this
        );
      }
    );
  }

  onSubmit(): void {
    this.currentTransaction = 'SUBMIT';
    this.notiflixService.launchConfirm(
      'Submit Flight Plan?',
      'The information introduced will be submitted to the Authority and will not be modifiable. Are you sure that you want to continue?',
      this,
      'SUBMIT FORM',
      'CANCEL'
    );
  }

  onProcess(): void {
    this.currentTransaction = 'PROCESS';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'You will be redirected to the payment page.',
      this,
      'YES',
      'NO'
    );
  }

  onBack(): void {
    const formLength = [
      JSON.stringify(this.flightDetailsForm.value).length,
      JSON.stringify(this.departureDetailsForm.value).length,
      JSON.stringify(this.emergencyDetailsForm.value).length,
      JSON.stringify(this.pilotDetailsForm.value).length,
      JSON.stringify(this.passengerDetailsForm.value).length,
      JSON.stringify(this.feesBreakdownForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );
    if (currentFormLength !== formLength) {
      this.currentTransaction = 'BACK';
      this.notiflixService.launchConfirm(
        'Are you sure you want to exit?',
        'Changes you made will not be saved.',
        this,
        'EXIT',
        'NO'
      );
    } else {
      this.currentTransaction = 'BACK';
      this.onPositive();
    }
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'SUBMIT':
        this.onProcessPayment(true);
        break;
      case 'PROCESS':
        this.onProcessPayment(false);
        const processPaymentBtn = document.getElementById(
          'processPaymentBtn'
        ) as HTMLElement;
        processPaymentBtn.click();
        this.enablePayment = true;
        break;
      case 'BACK':
        this.router.navigate(['/flight-list'], {
          queryParams: { page: null },
          queryParamsHandling: 'merge',
        });
        break;
      default:
        throw new Error(`${this.currentTransaction} is invalid`);
    }
  }

  onNegative(): void {}

  onClick(event): void {}

  reFormatTime(time: string): string {
    if (time.length > 1 && String(time).charAt(0) === '0') {
      return time;
    }

    return (+time < 10 ? '0' : '') + time;
  }

  // tslint:disable-next-line: typedef
  processPayload(isValidation: boolean, payLater: boolean) {
    let flightRoutes =
      this.departureDetailsForm.controls.flightRoutes.value.map(
        (x) => x.flightRoute
      );

    flightRoutes = flightRoutes.map((x) => x).join('-');
    this.passengerDetailsForm.get('passenger').enable();

    const flightPlanDetails = {
      filingTime: this.flightDetailsForm.controls.filingTime.value,
      filingDate: moment(
        this.flightDetailsForm.controls.filingDate.value,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD'),
      flightDate: moment(
        this.flightDetailsForm.controls.flightDate.value
      ).format('YYYY-MM-DD'),
      departureTime: `${this.reFormatTime(
        this.departureDetailsForm.controls.departureHour.value
      )}:${this.reFormatTime(
        this.departureDetailsForm.controls.departureMinute.value
      )}`,
      offBlockTime: `${this.reFormatTime(
        this.departureDetailsForm.controls.offBlockHour.value
      )}:${this.reFormatTime(
        this.departureDetailsForm.controls.offBlockMinute.value
      )}`,
      typeOperation: this.flightDetailsForm.controls.typeOperation.value,
      typeFlight: this.flightDetailsForm.controls.typeFlight.value,
      flightRules: this.flightDetailsForm.controls.flightRules.value,
      aircraftIdentification:
        this.flightDetailsForm.controls.aircraftIdentification.value,
      aircraftType:
        this.flightDetailsForm.controls.aircraftType.value.value ??
        this.flightDetailsForm.controls.aircraftType.value,
      numberMotors: this.flightDetailsForm.controls.numberMotors.value,
      typeMotor: this.flightDetailsForm.controls.typeMotors.value,
      mtow: this.flightDetailsForm.controls.mtow.value,
      departureAerodrome:
        this.departureDetailsForm.controls.departureAerodrome.value,
      destinationAerodrome:
        this.departureDetailsForm.controls.destinationAerodrome.value,
      estimatedTimeEnrouteHours:
        this.departureDetailsForm.controls.estimatedTimeEnrouteHours.value,
      estimatedTimeEnrouteMinutes:
        this.departureDetailsForm.controls.estimatedTimeEnrouteMinutes.value,
      fuelEnduranceHours:
        this.departureDetailsForm.controls.fuelEnduranceHours.value,
      fuelEnduranceMinutes:
        this.departureDetailsForm.controls.fuelEnduranceMinutes.value,
      otherInformation:
        this.departureDetailsForm.controls.otherInformation.value,
      alternateAerodrome:
        this.departureDetailsForm.controls.alternateAerodrome.value,
      secondAlternateAerodrome: this.departureDetailsForm.controls
        .secondAlternateAerodrome.value
        ? this.departureDetailsForm.controls.secondAlternateAerodrome.value.toUpperCase()
        : null,
      flightRoute: flightRoutes,
      // flightRoutes.length === 95 ? flightRoutes : flightRoutes.slice(0, -1),
      cruisingSpeed: this.departureDetailsForm.controls.cruisingSpeed.value,
      cruisingAltitude:
        this.departureDetailsForm.controls.cruisingAltitude.value,
      emergencyRadio: this.emergencyDetailsForm.controls.emergencyRadio.value,
      numberLifeJacketsColor:
        this.emergencyDetailsForm.controls.numberLifeJacketsColor.value,
      dinghiesNumber: this.emergencyDetailsForm.controls.dinghiesNumber.value,
      dinghiesCapacity:
        this.emergencyDetailsForm.controls.dinghiesCapacity.value,
      dinghiesColor: this.emergencyDetailsForm.controls.dinghiesColor.value,
      aircraftColor: this.emergencyDetailsForm.controls.aircraftColor.value,
      totalNumberPersonsOnBoard:
        this.passengerDetailsForm.controls.totalNumberPersonsOnBoard.value ?? 0,
      numberOfUSPassenger:
        this.passengerDetailsForm.controls.numberOfUSPassenger.value ?? 0,
      numberOfNonUSPassenger:
        this.passengerDetailsForm.controls.numberOfNonUSPassenger.value ?? 0,
      methodClosure: this.passengerDetailsForm.controls.methodClosure.value,
      otherEquipmentRemarks:
        this.passengerDetailsForm.controls.otherEquipmentRemarks.value,
      pilotInformation: this.pilotDetailsForm.value,
      passengers:
        this.filterPassenger(this.passengerDetailsForm.value.passenger) ?? null,
      forValidation: isValidation,
      payLater,
    };
    return flightPlanDetails;
  }

  // tslint:disable-next-line: typedef
  submitFlightPlan(paylater = false) {
    this.formdata = this.processPayload(false, paylater);
    this.notiflixService.loadPulse('Processing...');
    this.addFlightService
      .createAddFlight(JSON.stringify(this.formdata))
      .subscribe(
        (res) => {
          if (res) {
            this.notiflixService.closeLoader();
            this.notiflixService.launchReport(
              'success',
              'Success',
              `${res.body.message}`,
              '',
              this
            );
          } else {
            this.notiflixService.closeLoader();
            this.notiflixService.launchReport(
              'error',
              'Error',
              'An Error Occurred.',
              '',
              this
            );
          }
        },
        (error) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchReport(
            'error',
            'Error',
            `${error?.error?.message}`,
            '',
            this
          );
        }
      );
  }

  onOk(): void {
    switch (this.currentTransaction) {
      case 'VERIFICATION_ERROR':
        break;
      default:
        this.router.navigate(['/flight-list'], {
          queryParams: { page: null },
          queryParamsHandling: 'merge',
        });
        break;
    }
  }

  onProcessPayment(forValidation: boolean): void {
    this.notiflixService.loadPulse('Processing...');
    this.formdata = this.processPayload(forValidation, false);
    if (forValidation === true) {
      this.addFlightService
        .createAddFlight(JSON.stringify(this.formdata))
        .subscribe(
          (res) => {
            this.calculateFeesBreakdown();
            const feesBreakdownBtn = document.getElementById(
              'feesBreakdownBtn'
            ) as HTMLElement;
            feesBreakdownBtn.click();
          },
          (error) => {
            this.currentTransaction = 'VERIFICATION_ERROR';
            this.notiflixService.closeLoader();
            this.notiflixService.launchReport(
              'error',
              'Error',
              `${error?.error?.message}`,
              '',
              this
            );
          }
        );
    }
  }

  buildFormData(formData: any, data: any, parentKey?: any): void {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else if (data === null || data === undefined || data === '') {
      const value = null;
      formData.append(parentKey, value);
      if (!parentKey.includes('indraHistory')) {
        formData.delete(parentKey, value);
      }
    } else {
      const value = data === '' ? null : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data: any): FormData {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  inputMtow(value: any): void {
    if (value) {
      this.flightDetailsForm.get('mtow').updateValueAndValidity();
      this.mtowDisplayWarning = value === 10000 ? true : false;
    }
  }

  inputCruisingSpeed(value: number): void {
    if (value) {
      this.cruisingSpeedMsg = 'Error! only numbers are allowed';
    } else {
      this.cruisingSpeedMsg = 'This is a required field';
    }
  }

  inputHours(value: any, formControl: string): void {
    if (value > 23) {
      this.departureDetailsForm.get(formControl).setErrors({ invalid: true });
    }
  }

  inputMinutes(value: any, formControl: string): void {
    if (value > 59) {
      this.departureDetailsForm.get(formControl).setErrors({ invalid: true });
    }
  }

  inputDeptAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value);
    if (!filteredAirportCode.length && value.length === 4) {
      this.deptAeroWarning = true;
    } else {
      this.deptAeroWarning = false;
    }
  }

  inputDestAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value);
    if (!filteredAirportCode.length && value.length === 4) {
      this.destAeroWarning = true;
    } else {
      this.destAeroWarning = false;
    }
  }

  inputAlterAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value);
    if (!filteredAirportCode.length && value.length === 4) {
      this.alterAeroWarning = true;
    } else {
      this.alterAeroWarning = false;
    }
  }
  inputSecAltAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value);
    if (!filteredAirportCode.length && value.length === 4) {
      this.secAlterAeroWarning = true;
    } else {
      this.secAlterAeroWarning = false;
    }
  }

  addRoute(): void {
    this.addBlankField();
    this.enableAddRoute = false;
  }

  InputFlightRoute(value, index): void {
    const flightRoutesArr = this.departureDetailsForm.controls
      .flightRoutes as FormArray;
    if (value.length > 0 && flightRoutesArr.length < 16) {
      this.enableAddRoute = true;
    }
    // if length of input is equal to 0, delete field
    else if (value.length === 0) {
      this.removeFlightRoute(index);
    }
  }

  addBlankField(): void {
    const flightRoutesArr = this.departureDetailsForm.controls
      .flightRoutes as FormArray; // get array of flight route fields
    const fRFieldLength = flightRoutesArr.length; // get number of flight route fields
    // get concatenated flight route values
    let itemJoin = flightRoutesArr.value.map((x) => x.flightRoute);
    itemJoin = itemJoin.map((x) => x).join('');
    const fRStringLength = fRFieldLength * 5;
    if (fRFieldLength < 16) {
      this.addFlightRoute();
    } else {
      this.enableAddRoute = false;
    }
    // this is for automatic adding of field
    // if (itemJoin.length === fRStringLength && fRFieldLength < 16) {
    //   this.addFlightRoute();
    // }
  }

  addFlightRoute(index?: number): void {
    if (index === 0) {
      this.flightRoutes.push(
        new FormGroup({
          flightRoute: new FormControl('', [
            Validators.required,
            Validators.pattern(/[a-zA-Z0-9.-_]/),
          ]),
        })
      );
    } else {
      this.flightRoutes.push(
        new FormGroup({
          flightRoute: new FormControl(
            '',
            Validators.pattern(/[a-zA-Z0-9.-_]/)
          ),
        })
      );
    }
  }

  removeFlightRoute(index): void {
    const flightRoutesArr = this.departureDetailsForm.controls
      .flightRoutes as FormArray; // get array of flight route fields
    const fRFieldLength = flightRoutesArr.length; // get number of flight route fields
    // get concatenated flight route values
    if (index < fRFieldLength - 1 || fRFieldLength > 1) {
      this.enableAddRoute = true;
      // delete the field only if the deleted text is not from the last field
      this.flightRoutes.removeAt(index);
      // this.addBlankField();
      // add validators on first element
      flightRoutesArr
        .at(0)
        .get('flightRoute')
        .setValidators([
          Validators.required,
          Validators.pattern(/[a-zA-Z0-9.-_]/),
        ]);
      // refresh validators
      flightRoutesArr.at(0).get('flightRoute').updateValueAndValidity();
    } else {
      this.enableAddRoute = false;
    }
  }
  ngOnDestroy(): void {}
}
