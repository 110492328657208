import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  currentUserInfoFeatureKey,
  CurrentUserInfoState,
} from './current-user-info.reducer';

export const selectCurrentUserInfoFeature = createFeatureSelector<CurrentUserInfoState>(
  currentUserInfoFeatureKey
);

// DATA FROM (GET CURRENT USER INFORMATION) API

export const selectCurrentUserInfoResponseData = createSelector(
  selectCurrentUserInfoFeature,
  (state: CurrentUserInfoState) => {
    return state.currentUserInfo;
  }
);
