import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/constants/api.const';
import { HttpService } from '@app/core/services/http/http.service';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import {
  getCurrentUserInfo,
  getCurrentUserInfoSuccess,
  getCurrentUserInfoFailure,
} from './current-user-info.actions';
import { CurrentUserInfo } from './current-user-info.model';

@Injectable()
export class CurrentUserInfoEffects {
  constructor(private actions$: Actions, private http: HttpService) {}

  getCurrentUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUserInfo),
      mergeMap(() => {
        return this.http.get(`${environment.api}${API.currentUserInfo}`).pipe(
          map((res: CurrentUserInfo) =>
            getCurrentUserInfoSuccess({ response: res })
          ),
          catchError((err) =>
            of(getCurrentUserInfoFailure({ error: err.message }))
          )
        );
      })
    )
  );
}
