import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent implements OnInit {
  CONSTANTS = CONSTANTS;
  appVersion = environment.version;
  copyrightYear = environment.copyright_year;
  supportEmail = environment.support_email;
  errorTraceId = '';
  status = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.errorTraceId = this.route.snapshot.queryParams.errorTraceId ?? '';
    this.status = this.route.snapshot.queryParams.status ?? null;
  }
}
