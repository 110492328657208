import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CryptoService } from '../crypto/crypto.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpService } from '@core/services/http/http.service';
import { ERROR_MESSAGES } from '@core/constants/error-messages';
import { SUCCESS_MESSAGES } from '@core/constants/success-messages';
import { UserIdleService } from 'angular-user-idle';
import * as SecureLS from 'secure-ls';
import { API } from '@core/constants/api.const';
import { AuthEntity } from '@core/models/auth.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CONFIRM_MESSAGES } from '@app/core/constants/confirm-messages';
import * as createHost from 'cross-domain-storage/host';
import { BAHAMAS } from '@app/core/constants/app-list.const';
import { DataService } from '@app/shared/services/data.service';
import { getCurrentUserInfo } from '@app/core/store/current-user-info/current-user-info.actions';
import { CurrentUserInfoState } from '@app/core/store/current-user-info/current-user-info.reducer';
import { selectCurrentUserInfoResponseData } from '@app/core/store/current-user-info/current-user-info.selectors';
import { Store, select } from '@ngrx/store';
import { UserInfo } from '@app/core/models/user-info.model';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { NOTIFLIX_STATUS } from '@app/core/constants/constants.const';
import { SsoService } from '../sso/sso.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static CREDENTIAL_KEY = 'access_token';
  public static SECRET_KEY = '1ndr@_s3cur3D';

  private onTimerSubscriber: Subscription;
  private onTimeOutSubscriber: Subscription;

  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  private timer: number;

  private storageHost: any;

  private secureLS = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: AuthService.SECRET_KEY,
  });
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private currentTransaction: string;
  private logoutComponent: any;
  private failedEmail: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private cryptoService: CryptoService,
    private userIdle: UserIdleService,
    private router: Router,
    private dataService: DataService,
    private store: Store<CurrentUserInfoState>,
    private notiflixService: NotiflixService,
    private ssoService: SsoService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(loginDetails, component?: any): Subscription {
    const requestBody = {
      email: loginDetails.email,
      password: loginDetails.password,
    };
    return this.http
      .post(`${environment.api}${API.login}`, requestBody, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: true,
      })
      .subscribe(
        (response: any) => {
          const parsedToken = JSON.parse(
            this.decryptPayload(response.body.accessToken)
          );

          const fakeUser: AuthEntity = {
            email: loginDetails.email,
            password: loginDetails.password,
          };

          this.cryptoService.setStorageItem(
            'currentUser',
            response.body.accessToken
          );
          this.cryptoService.setStorageItem(
            'refreshToken',
            response.body.refreshToken
          );
          this.currentUserSubject.next(JSON.stringify(parsedToken));
          this.setCredentials(fakeUser);
          this.store.dispatch(getCurrentUserInfo());

          this.store
            .pipe(select(selectCurrentUserInfoResponseData))
            .subscribe((data) => {
              if (data) {
                this.dataService.setUserInfo(data);
                this.cryptoService.setStorageItem(
                  'userInfo',
                  JSON.stringify(data)
                );
                this.router.navigate(['/home'], {
                  queryParams: { page: null },
                  queryParamsHandling: 'merge',
                });
              }
            });
        },
        (error) => {
          component.isLoading = false;
          if (
            error.error.clientError ===
            'Your account is not yet activated. Please check your email to activate.'
          ) {
            this.currentTransaction = 'onFailActivation';
            this.failedEmail = loginDetails.email;
            this.notiflixService.launchConfirm(
              ERROR_MESSAGES.ERROR_ENCOUNTERED,
              error.error.clientError,
              this,
              'OK',
              'Resend Email'
            );
          } else {
            this.notiflixService.launchReport(
              NOTIFLIX_STATUS.ERROR,
              ERROR_MESSAGES.ERROR_ENCOUNTERED,
              error.error.clientError
            );
          }
        }
      );
  }

  onPositive(): void {
    if (this.currentTransaction === 'onLogout') {
      this.logoutComponent.isLoggingout = true;
      this.removeCredentials();
    }
  }

  onNegative(): void {
    switch (this.currentTransaction) {
      case 'onLogout':
        this.logoutComponent.isLoggingout = false;
        break;
      case 'onFailActivation':
        this.notiflixService.loadPulse('Processing...');
        this.activateAccount({ email: this.failedEmail })
          // this.activation.resendActivationEmail({'email': this.failedEmail})
          .subscribe(
            (res: any) => {
              this.notiflixService.closeLoader();
              this.notiflixService.launchReport(
                'success',
                'Success',
                res.message
              );
            },
            (error) => {
              this.notiflixService.closeLoader();
              this.notiflixService.launchReport(
                'error',
                'Error',
                error.error.clientError,
                '',
                this
              );
            }
          );
        break;
      default:
        break;
    }
    // if (this.currentTransaction === 'onLogout') {
    //   this.logoutComponent.isLoggingout = false;
    // }
  }

  onLogout(component?: any): void {
    this.currentTransaction = 'onLogout';
    this.logoutComponent = component;
    this.notiflixService.launchConfirm(
      CONFIRM_MESSAGES.SWAL_LOGOUT.title,
      CONFIRM_MESSAGES.SWAL_LOGOUT.body,
      this
    );
  }

  public decryptPayload(payload: string): string {
    return window.atob(payload.split(' ')[1].split('.')[1]);
  }

  public setCredentials(credentials?: AuthEntity, remember?: boolean): void {
    if (credentials) {
      if (environment.watch_idle) {
        this.startIdleObserver();
      }
      this.secureLS.set(
        AuthService.CREDENTIAL_KEY,
        JSON.stringify(credentials)
      );
    } else {
      this.secureLS.remove(AuthService.CREDENTIAL_KEY);
    }
  }

  startIdleObserver(): void {
    this.userIdle.startWatching();
    this.onTimerSubscriber = this.userIdle.onTimerStart().subscribe((count) => {
      this.timer = count;
    });
    this.onTimeOutSubscriber = this.userIdle.onTimeout().subscribe(() => {
      this.idleTimeOut();
    });
  }

  idleTimeOut(): void {
    this.ssoService.validateSSOSession().subscribe(
      (data: UserInfo) => {
        if (data) {
          this.currentUserSubject.next(data);
          this.cryptoService.setStorageItem('refreshToken', data.refreshToken);

          this.ssoService.refreshToken(data.refreshToken).subscribe(
            (response) => {
              if (![null, undefined].includes(response.accessToken)) {
                this.cryptoService.setStorageItem(
                  'currentUser',
                  response.accessToken
                );

                this.restartIdleObserver();
              }
            },
            (error) => {
              this.currentTransaction = 'logout';
              this.notiflixService.launchReport(
                NOTIFLIX_STATUS.ERROR,
                'Oops!',
                `Session is invalid. We're taking you to the login page now.`,
                null,
                this
              );
            }
          );
        }
      },
      (error) => {
        this.removeCredentials();
      }
    );
  }

  removeCredentials(): void {
    if (environment.watch_idle) {
      this.stopIdleObserver();
    }

    const token = this.cryptoService.getRawToken();
    this.http
      .post(`${environment.api}${API.logout}`, token, {
        withCredentials: true,
      })
      .toPromise()
      .then(
        () => {
          this.setCredentials();
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem('refreshToken');
          sessionStorage.removeItem('hrPortalToken');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('hrPortalToken');
          this.currentUserSubject.next(null);
          window.location.href = 'login';
        },
        (err) => {
          window.location.href = 'login';
        }
      );
  }

  stopIdleObserver(): void {
    this.userIdle.stopWatching();
    if (this.onTimerSubscriber && this.onTimeOutSubscriber) {
      this.onTimerSubscriber.unsubscribe();
      this.onTimeOutSubscriber.unsubscribe();
    }
  }

  getUser(): AuthEntity | null {
    const session = this.secureLS.get(
      JSON.stringify(AuthService.CREDENTIAL_KEY)
    );

    if (session) {
      return JSON.parse(this.secureLS.get(AuthService.CREDENTIAL_KEY));
    }
  }

  public isTokenExpired(): AuthEntity | any {
    const token = this.cryptoService.getRawToken();
    return token ? this.jwtHelper.isTokenExpired(token) : true;
  }

  restartIdleObserver(): void {
    if (this.timer >= environment.idle) {
      this.userIdle.resetTimer();
    }
  }

  public setStorageHost(): void {
    this.storageHost = createHost(BAHAMAS);
  }

  changePassword(newPassword): void {
    this.notiflixService.loadPulse('Processing...');
    this.httpService
      .post(`${environment.api}${API.changePass}`, newPassword)
      .toPromise()
      .then(
        () => {
          this.notiflixService.closeLoader();
          this.currentTransaction = 'resetPassword';
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.SUCCESS,
            SUCCESS_MESSAGES.TITLE,
            SUCCESS_MESSAGES.PASSWORD_CHANGED,
            null,
            this
          );
        },
        (error) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.ERROR,
            ERROR_MESSAGES.ERROR_ENCOUNTERED,
            error.error.message ?? error.error.clientError
          );
        }
      );
  }

  forgotPassword(credentials): void {
    this.currentTransaction = 'forgotPassword';
    this.httpService
      .post(`${environment.api}${API.forgotPW}`, credentials)
      .toPromise()
      .then(
        () => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.SUCCESS,
            SUCCESS_MESSAGES.TITLE,
            SUCCESS_MESSAGES.RESET_PASSWORD_REQUEST,
            null,
            this
          );
        },
        (error) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.ERROR,
            ERROR_MESSAGES.ERROR_ENCOUNTERED,
            error.error.message ?? error.error.clientError
          );
        }
      );
  }

  resetPassword(newPassword): void {
    this.currentTransaction = 'resetPassword';
    this.httpService
      .post(`${environment.api}${API.resetPW}`, newPassword)
      .toPromise()
      .then(
        () => {
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.SUCCESS,
            SUCCESS_MESSAGES.TITLE,
            SUCCESS_MESSAGES.PASSWORD_CHANGED,
            null,
            this
          );
        },
        (error) => {
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.ERROR,
            ERROR_MESSAGES.ERROR_ENCOUNTERED,
            error.error.message ?? error.error.clientError
          );
        }
      );
  }

  verifyResetLink(resetToken): Observable<any> {
    return this.httpService.post(
      `${environment.api}${API.verifyResetLink}`,
      resetToken
    );
  }

  activateUser(newPassword): void {
    this.currentTransaction = 'activateUser';
    this.httpService
      .post(`${environment.api}${API.activateUser}`, newPassword)
      .toPromise()
      .then(
        () => {
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.SUCCESS,
            SUCCESS_MESSAGES.TITLE,
            SUCCESS_MESSAGES.ACTIVATED_ACCOUNT,
            null,
            this
          );
        },
        (error) => {
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.ERROR,
            ERROR_MESSAGES.ERROR_ENCOUNTERED,
            error.error.message ?? error.error.clientError
          );
        }
      );
  }

  verifyActivationLink(resetToken): Observable<any> {
    return this.httpService.post(
      `${environment.api}${API.verifyActivationLink}`,
      resetToken
    );
  }

  activateAccount(token): Observable<any> {
    return this.httpService.post(
      `${environment.api}${API.activateUser}`,
      token
    );
  }

  validateSSOSession(): Observable<UserInfo> {
    return this.http.post<UserInfo>(
      `${environment.api}${API.validateSSO}`,
      null,
      { withCredentials: true }
    );
  }

  generateNewAccessToken(): Promise<any> {
    return this.httpService
      .post(`${environment.api}${API.generateNewAccessToken}`, null, {
        headers: new HttpHeaders().set(
          'X-HRP-REFRESH-TOKEN',
          this.cryptoService.getStorageItem('refreshToken')
        ),
      })
      .toPromise()
      .then(
        (res) => {
          const newAccessToken = res.accessToken;
          this.cryptoService.setStorageItem('currentUser', newAccessToken);
        },
        (err) => {
          this.currentTransaction = 'generateNewAccessToken';
          this.notiflixService.launchReport(
            NOTIFLIX_STATUS.ERROR,
            ERROR_MESSAGES.SESSION_EXPIRED,
            ERROR_MESSAGES.IDLE_MESSAGE,
            null,
            this
          );
        }
      );
  }

  onOk(): void {
    if (this.currentTransaction === 'generateNewAccessToken') {
      this.removeCredentials();
      this.router.navigateByUrl('/login');
    }

    if (this.currentTransaction === 'resetPassword') {
      this.router.navigate(['/login'], {
        queryParams: { token: null },
        queryParamsHandling: 'merge',
      });
    }

    if (this.currentTransaction === 'activateUser') {
      this.router.navigate(['/login'], {
        queryParams: { token: null },
        queryParamsHandling: 'merge',
      });
    }

    if (this.currentTransaction === 'forgotPassword') {
      this.router.navigate(['/login'], {
        queryParams: { token: null },
        queryParamsHandling: 'merge',
      });
    }

    if (this.currentTransaction === 'logout') {
      this.removeCredentials();
    }
  }
}
