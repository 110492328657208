import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { PaymentService } from '@app/core/services/payment.service';
import { environment } from 'environments/environment';

// tslint:disable-next-line: class-name
export interface paymentResponse {
  amount: number;
  originalResponseCode: string;
  responseCode: number;
  reasonCode: number;
  reasonCodeDescription: string;
  totalPayment: string;
}

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss'],
})
export class PaymentResponseComponent implements OnInit {
  id: string;
  responseCode: number;
  reasonCode: number;
  currentTransaction: string;
  paymentResponse: paymentResponse;
  // tslint:disable-next-line: no-inferrable-types
  isLoading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private notiflixService: NotiflixService,
    public paymentService: PaymentService
  ) {
    this.id = this.route.snapshot.queryParams.ID;
    this.responseCode = this.route.snapshot.queryParams.RespCode;
    this.reasonCode = this.route.snapshot.queryParams.ReasonCode;
  }

  ngOnInit(): void {
    this.paymentService.getPaymentResponseByToken(this.id).subscribe((res) => {
      this.paymentResponse = res;
      if (res.responseCode === 1 && res.reasonCode === 1) {
        this.isLoading = false;
        window.parent.postMessage(
          { data: 'SuccessPayment', refNo: res.refNo },
          window.location.origin
        );
      } else {
        this.isLoading = false;
        this.onProcess();
      }
    });
  }

  onOk(): void {
    switch (this.currentTransaction) {
      case 'CancelTransaction':
        window.parent.postMessage(
          { data: 'CancelTransaction' },
          window.location.origin
        );
        break;
    }
  }

  onProcess(): void {
    this.currentTransaction = 'PROCESS';
    this.notiflixService.launchConfirm(
      'An Error Occurred',
      // tslint:disable-next-line: no-string-literal
      this.paymentResponse['reasonCodeDescription'],
      this,
      'Try Again',
      'Cancel'
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'PROCESS':
        window.parent.postMessage(
          { data: 'RetryPayment' },
          window.location.origin
        );
        break;
    }
  }

  onNegative(): void {
    this.currentTransaction = 'CancelTransaction';
    this.notiflixService.launchReport(
      'info',
      'Notice',
      'The Flight plan is not saved. You will be redirected to the flight list.',
      '',
      this
    );
  }
}
