<ng-container *ngIf="isLoading; else content">
  <app-loader></app-loader>
</ng-container>
<ng-template #content>
  <div
    class="reset-pass-container"
    *ngIf="!isLoading && !resetToken; else formPanel"
  >
    <div class="container center">
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="logo"
        src="../../../../assets/images/Bahamas Logo.png"
        alt=""
      />
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="logo"
        src="../../../../assets/images/BANSA logo.png"
        alt=""
      />
      <div class="message-card">
        <h2 class="text-amazon-blue noe-display-regular">Oops!</h2>
        <p class="text-amazon-blue">
          The password reset link has expired or invalid. Please request a new
          one. <br /><br />
          Redirecting you to Forgot Password page in {{ counter }} seconds.
          Otherwise, please
          <a [routerLink]="['/forgot-password']" (click)="counterStop()"
            >click here</a
          >
        </p>
      </div>
    </div>
  </div>
  <ng-template #formPanel>
    <div class="reset-password-container">
      <div class="align-center">
        <img
          animateOnScroll
          animationName="animate__animated animate__fadeInRight"
          class="bahamas-logo"
          src="../../../../assets/images/Bahamas Logo.png"
          alt=""
        />
        <img
          animateOnScroll
          animationName="animate__animated animate__fadeInRight"
          class="bansa-logo"
          src="../../../../assets/images/BANSA logo.png"
          alt=""
        />
      </div>
      <div
        class="form-card"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        <h2 class="page-title">Reset your password!</h2>
        <p class="neo-sans-medium text-amazon-blue">
          {{ const.PASSWORD_REQUIREMENTS }}
        </p>
        <p class="req-1 text-amazon-blue">
          - {{ const.PASSWORD_CONTAIN_ATLEAST }}
          <span class="text-haze-blue neo-sans-medium">{{
            const.PASSWORD_MIN_LENGTH
          }}</span>
          {{ const.CHARACTERS }}
          <span class="text-haze-blue neo-sans-medium">{{
            const.PASSWORD_MAX_LENGTH
          }}</span>
          <br />

          - {{ const.CHARACTERS_CONTAIN_ATLEAST_THREE }} <br />
          <span class="req-2">
            • {{ const.CAPITAL_LETTERS }}
            <span class="text-haze-blue">
              {{ const.CAPITAL_LETTERS_EX }}
            </span>
          </span>
          <br />
          <span class="req-2">
            • {{ const.LOWERCASE_LETTERS }}
            <span class="text-haze-blue">
              {{ const.LOWERCASE_LETTERS_EX }}</span
            >
            <br />
          </span>
          <span class="req-2">
            • {{ const.NUMBERS }}
            <span class="text-haze-blue"> {{ const.NUMBERS_EX }}</span>
            <br />
          </span>
          <span class="req-2">
            • {{ const.SPECIAL_CHARACTERS }}
            <span class="text-haze-blue">
              {{ const.SPECIAL_CHARACTERS_EX }}</span
            >
            <br />
          </span>
          <span class="req-1">- {{ const.SYSTEM_REMEMBERS }}</span>
        </p>
        <app-new-password-form
          [isReset]="true"
          (newPassword)="onSubmitEvent($event)"
        ></app-new-password-form>
      </div>
    </div>
  </ng-template>
</ng-template>
