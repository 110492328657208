import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  userInformation;
  isFormChanged = false;

  constructor() {}

  setUserInfo(userInfo): void {
    this.userInformation = userInfo;
  }

  getUserInfo(): any {
    return this.userInformation;
  }

  getAppList(): Array<any> {
    return this.userInformation ? this.userInformation.userApplication : [];
  }

  generateFullName(...names: Array<string>): string {
    let fullName = '';
    names.forEach((name) => {
      if (name && name !== null && name !== undefined) {
        // fullName += ' ' + name;
        fullName = `${fullName} ${name}`;
      }
    });
    return fullName;
  }

  public getFullName(
    name: string,
    firstName?: string,
    middleName?: string,
    lastName?: string
  ): string {
    return name
      ? name
      : `${firstName}${this.setMiddleName(middleName)}${lastName}`;
  }

  setMiddleName(middleName: string): string {
    return middleName !== null && middleName !== '' ? ` ${middleName} ` : ' ';
  }

  getFormChange(): boolean {
    return this.isFormChanged;
  }

  setFormChange(formChange): void {
    this.isFormChanged = formChange;
  }

  public numberWithCommas(x: any): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // setEmployeeToUpdate(employee: EmployeeDetails): void {
  //   this.employeeToUpdate = employee;
  // }

  // getEmployeeToUpdate(): EmployeeDetails {
  //   return this.employeeToUpdate;
  // }
}
