<div class="flight-plant-container">
  <div class="row">
    <div class="col-md-12">
      <nb-card class="center">
        <nb-card-header>
          <div class="text-center">
            <h5 class="text-haze-blue">Registered Users</h5>
          </div>
          <div class="button-container">
            <div class="row justify-content-between">
              <div class="col-4">
                <button
                  nbButton
                  status="info"
                  size="medium"
                  (click)="fileDownload()"
                  id="downloadBtn"
                >
                  Download table as CSV
                </button>
              </div>
              <div class="col-4">
                <button
                  type="button"
                  matSuffix
                  [attr.aria-label]="'Hide password'"
                  color="accent"
                  size="medium"
                  mat-flat-button
                  class="cancel-button h-60 form-buttons"
                  matTooltip="Go to Fees payment record"
                  matTooltipClass="tooltipClass"
                  matTooltipPosition="below"
                  id="backBtn"
                  [routerLink]="['/flight-list']"
                >
                  <mat-icon>keyboard_backspace</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </nb-card-header>
        <nb-card-body class="center">
          <app-mat-data-table
            [tableContents]="tableContents"
            [dataSource]="registeredUsers"
            [loadingIndicator]="loadingIndicator$"
            [component]="this"
            [advancedSearchKeys]="advancedSearchKeys"
            [copyFlight]="false"
            [viewFlightDetails]="false"
            [viewUserDetails]="true"
            (isViewUserDetails)="onViewUserDetails($event)"
          ></app-mat-data-table>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
