<div fxLayout="row" fxLayoutAlign="space-between" class="header-title">
  <h5 class="aircraft">Aircraft Identification Number</h5>
  <button mat-icon-button color="primary" (click)="close()">
    <i class="material-icons"> close </i>
  </button>
</div>
<form [formGroup]="aircraftIdForm">
  <div class="center">
    <mat-form-field appearance="outline" class="width-1 center">
      <mat-label>Aircraft identification number</mat-label>
      <input
        maxlength="6"
        minlength="3"
        matInput
        formControlName="aircraftIdNumber"
        name="aircraftIdentification"
        id="aircraftIdentification"
        placeholder="Aircraft Identification"
        required
      />
      <mat-error>{{ constants.AIRCRAFT_ID_NO_INVALID }}</mat-error>
    </mat-form-field>
  </div>
  <div class="center">
    <button
      class="h-60 form-buttons"
      type="submit"
      nbButton
      size="large"
      status="info"
      (click)="onSave()"
      [disabled]="aircraftIdForm.invalid"
      id="saveBtn"
    >
      SAVE
    </button>
  </div>
</form>
