<div class="new-password-card remove-border center">
    <form [formGroup]="newPasswordForm" (submit)="onSubmit()">
        <mat-form-field appearance="outline" class="full-width" *ngIf="!isReset">
            <mat-label>{{constants.CURRENT_PASSWORD}}</mat-label>
            <input formControlName="oldPassword" name="oldPassword" id="oldPassword" placeholder="Current Password"
                autocomplete="off" minLength="8" maxLength="20" [type]="currentPasswordHide ? 'password' : 'text'"
                matInput />
            <button type="button" mat-icon-button matSuffix (click)="hidePassword('currentPasswordHide')"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="currentPasswordHide">
                <mat-icon>{{currentPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{constants.NEW_PASSWORD}}</mat-label>
            <input formControlName="newPassword" name="newPassword" id="newPassword" placeholder="New Password"
                autocomplete="off" minLength="8" maxLength="20" [type]="newPasswordHide ? 'password' : 'text'"
                matInput />
            <button type="button" mat-icon-button matSuffix (click)="hidePassword('newPasswordHide')"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="newPasswordHide">
                <mat-icon>{{newPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{constants.CONFIRM_PASSWORD}}</mat-label>
            <input formControlName="confirmPassword" name="confirmPassword" id="confirmPassword"
                placeholder="Confirm Password" autocomplete="off" minLength="8" maxLength="20"
                [type]="confirmPasswordHide ? 'password' : 'text'" matInput />
            <button type="button" mat-icon-button matSuffix (click)="hidePassword('confirmPasswordHide')"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmPasswordHide">
                <mat-icon>{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <div class="error-message">
            <ng-container *ngIf="hasError">
                {{constants.COMPLETE_FIELDS}}
            </ng-container>
            <br>
        </div>
        <button type="submit" class="h-60 form-buttons" nbButton status="info"
            [disabled]="!newPasswordForm.valid">{{buttons.SUBMIT}}</button>
        <button id="login-clear" nbButton status="primary" type="reset"
            class="h-60 form-buttons" (click)="clear()">{{buttons.CLEAR}}</button>
    </form>
</div>