import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NbDialogService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
} from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { MenuItem } from '@core/models/menu-item';
import { AuthService } from '@core/services/auth/auth.service';
import { userConfig } from '@app/core/constants/nb-dialog-configs';
import { ModalComponent } from '../modal/modal.component';
import { CONFIRM_MESSAGES } from '@app/core/constants/confirm-messages';
import { SUCCESS_MESSAGES } from '@app/core/constants/success-messages';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { Subscription } from 'rxjs';
import { CurrentUserInfoState } from '@app/core/store/current-user-info/current-user-info.reducer';
import { Store, select } from '@ngrx/store';
import { DataService } from '../services/data.service';
import {
  ADMIN_ACCESS,
  MENU_ITEMS_NB,
  USER_MENU,
} from '@app/core/constants/constants.const';
import { NotiflixService } from '../services/notiflix.service';
import { environment } from 'environments/environment';
import * as Notiflix from 'notiflix';
import { TestModeService } from '@app/core/services/test-mode.service';
// import { url } from 'inspector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  mode: any;
  @Input() menuItems: Array<MenuItem>;
  menuItemsNb: Array<NbMenuItem> = MENU_ITEMS_NB;

  /**
   * Boolean checker to check if the user is system admin
   */
  isSystemAdmin = false;

  /**
   * Boolean checker to check if the user avatar to be displayed is a picture with user's name or just the picture
   */
  onlyPicture: boolean;

  /**
   * Boolean checker for checking if the current screen size is small
   */
  isScreenSmall: boolean;

  /**
   * The current width of the screen
   */
  innerWidth: any;

  /**
   * The current user's name
   */
  userName: string;

  /**
   * The user role
   */
  userTitle: string;

  /**
   * The company name
   */
  carrierName: string;

  /**
   * NbItems for the dropdown menu
   */
  userMenu = USER_MENU;

  subscriptions = new Subscription();
  @Input() component: any;
  currentTransaction: string;
  /**
   * The HeaderComponent's constructor
   *
   * @param sidebarService An NbSidebarService used to expand/collapse the sidebar
   * @param nbMenuService An NbMenuService used for the NbMenuItems
   * @param authService The AuthService object used for the logout function
   * @param router A Router object for page navigation
   */
  constructor(
    private sidebarService: NbSidebarService,
    private nbMenuService: NbMenuService,
    private authService: AuthService,
    private router: Router,
    // private secureLocalStorage: SecureLocalStorageService
    private dataService: DataService,
    private cryptoService: CryptoService,
    private notiflixService: NotiflixService,
    private testMode: TestModeService
  ) {
    this.clickMenuItem();
  }

  /**
   * From the implemented OnInit Life Cycle Interface
   */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.setOnlyPicture(this.innerWidth);
    this.setUserName();
    this.testModeEnable();
  }

  ngOnChanges(): void {
    //
  }

  /**
   * Host listener for window resize events
   *
   * @param event The event on window resize
   */
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
    this.setOnlyPicture(this.innerWidth);
  }

  /**
   * Sets the user's name for display
   */
  setUserName(): void {
    // const currentUser = JSON.parse(this.secureLocalStorage.getLocalStorageItem('currentUser'));
    const loggedInUser = JSON.parse(
      this.cryptoService.getStorageItem('userInfo')
    );

    this.userName =
      loggedInUser && loggedInUser.userDetail
        ? this.dataService.getFullName(loggedInUser.userDetail.name)
        : 'User';

    this.userTitle =
      loggedInUser && loggedInUser.userProfile
        ? loggedInUser.userProfile.name
        : '';

    this.carrierName =
      loggedInUser && loggedInUser.userDetail
        ? loggedInUser.userDetail.carrierName
        : '';

    this.isSystemAdmin = ADMIN_ACCESS.includes(this.userTitle.toLowerCase());
  }

  /**
   * Fired upon clicking the NbMenuItem
   */
  clickMenuItem(): void {
    this.subscriptions.add(
      this.nbMenuService.onItemClick().subscribe((data) => {
        if (data.item.title === 'Logout') {
          if (this.authService.getUser()) {
            this.authService.onLogout(this.component);
          } else {
            this.currentTransaction = 'LOGOUT';
            this.notiflixService.launchConfirm(
              'Logout?',
              'Do you want to proceed?',
              this,
              'LOGOUT',
              'CANCEL'
            );
          }
        }
      })
    );
  }

  openUserMenu(): void {
    this.setUserName();
    const links = USER_MENU.map((e) => e.link);
    if (!links.includes(this.router.url)) {
      setTimeout(() => {
        const nbMenu = document.getElementsByClassName('context-menu');
        nbMenu[0]
          ?.querySelectorAll(':scope > .menu-items > .menu-item > a')
          .forEach((q) => {
            q.classList.remove('active');
          });
      }, 1);
    }
  }

  /**
   * Collapses/Expands sidebar
   */
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'left');
    return false;
  }

  /**
   * Sets the user's avatar to display picture only if the screen size is small,
   * otherwise the user's name shall be displayed
   *
   * @param screenWidth The current screen's size
   */
  setOnlyPicture(screenWidth): void {
    if (screenWidth <= 768) {
      this.onlyPicture = true;
      this.isScreenSmall = true;
    } else {
      this.onlyPicture = false;
      this.isScreenSmall = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  testModeEnable(): void {
    // this.testMode.testMode().subscribe(
    //   (res) => {
    //     this.mode = res;
    //   },
    //   (error) => {}
    // );
  }

  onCAABClick(): void {
    // window.location.href = 'https://caabahamas.com/';
    const url = this.router.url;
    if (url === '/add-flight' || url.includes('copy-flight')) {
      this.currentTransaction = 'CAABHome';
      this.notiflixService.launchConfirm(
        'Back to CAAB Homepage?',
        'Do you want to proceed?',
        this,
        'CONTINUE',
        'CANCEL'
      );
    } else {
      this.redirectCAAB();
    }
  }

  onBrandLogo(): void {
    const url = this.router.url;
    if (this.router.url !== '/home') {
      this.currentTransaction = 'HOMEPAGE';
      this.notiflixService.launchConfirm(
        'Back to Homepage?',
        'Do you want to proceed?',
        this,
        'CONTINUE',
        'CANCEL'
      );
    }
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'LOGOUT':
        this.authService.removeCredentials();
        break;
      case 'HOMEPAGE':
        this.redirect();
        break;
      case 'CAABHome':
        this.redirectCAAB();
        break;
    }
  }

  onNegative(): void {}

  redirectCAAB(): void {
    this.dataService.setFormChange(false);
    if (!this.dataService.getFormChange()) {
      window.location.href = `${environment.CAAB_url}`;
    } else {
      this.notiflixService.launchConfirm(
        CONFIRM_MESSAGES.DISCARD_CHANGES.title,
        CONFIRM_MESSAGES.DISCARD_CHANGES.body,
        this
      );
    }
  }

  redirect(): void {
    this.dataService.setFormChange(false);
    if (!this.dataService.getFormChange()) {
      this.router.navigate(['/home']);
    } else {
      this.notiflixService.launchConfirm(
        CONFIRM_MESSAGES.DISCARD_CHANGES.title,
        CONFIRM_MESSAGES.DISCARD_CHANGES.body,
        this
      );
    }
  }
}
