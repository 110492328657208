import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileElement } from '@app/core/models/file-element';
import { FileService } from '@app/core/services/file.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent implements OnInit {
  folderName: string;
  element: FileElement;
  hasSpecialCharacters: boolean;
  folderNameFormControl: any;
  isExist: boolean;
  parentElement: FileElement;
  maxLength: string;
  isValid: boolean;

  constructor(
    public fileService: FileService,
    private dialogRef: MatDialogRef<EditDialogComponent>
  ) {}

  ngOnInit(): void {
    this.folderName = this.element.optionals.aerodrome.trim();
    this.hasSpecialCharacters = false;
    this.isExist = false;
    this.isValid = false;
    this.folderNameFormControl = new FormControl(this.folderName);
  }

  checkFolderName(): boolean {
    this.hasSpecialCharacters = this.fileService.checkNameFormat(
      this.folderName
    );

    return this.fileService.checkName(
      this.folderName,
      this.hasSpecialCharacters,
      this.isExist
    );
  }

  checkForSpaces(): boolean {
    return (
      this.folderName.replace(/ {2,}/g, ' ').trim() === this.element.optionals.aerodrome.trim()
    );
  }

  checkName(): void {
    // this.hasSpecialCharacters = this.fileService.checkNameFormat(
    //   this.folderName
    // );

    // if ( this.folderName.toLowerCase() !== this.element?.name.toLowerCase().trim() ) {
    //   this.isExist = this.fileService.checkExisting(
    //     this.folderName,
    //     this.parentElement?.id
    //   );
    // }

    // if (this.hasSpecialCharacters || this.isExist) {
    //   this.folderNameFormControl.setErrors({ error: true });
    // } else {
    // }

    this.element.optionals.aerodrome = this.folderName;
    this.folderNameFormControl.setErrors(null);
    this.isValid = true;
    this.dialogRef.close();
  }
}
