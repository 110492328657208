import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { BUTTONS, CONSTANTS } from '@core/constants/constants.const';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatorComponent } from 'app/shared/paginator/paginator.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  @ViewChild('paginator') paginator: PaginatorComponent;

  @Input() elementData;
  @Input() headers: any[];
  @Input() title: string;

  @Output() pageEvent = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() goToFileReceipt = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @Output() fileClick = new EventEmitter();

  dataSource: MatTableDataSource<object> = new MatTableDataSource<object>();

  // Column headers of table
  displayedColumns;

  buttons = BUTTONS;
  constants = CONSTANTS;

  editRows = [];
  isEditing = false;

  maintenanceTableFormGroup: FormGroup;
  filterForm: FormGroup;
  initialValues: any;

  maintenanceTableForm: FormArray;
  dataSource$: Observable<any>;
  private data$: BehaviorSubject<any[]>;

  enabledCreateRow = false;
  enabledEditRowIndex: number;

  // Checks if the table will have an action button (for crud)
  withActionsColumn = false;
  // Used for pagination
  totalRecords = 0;

  constructor(
    private formBuilder: FormBuilder // private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    // this.data$ = new BehaviorSubject(this.elementData);
    this.setTableHeaders();
    this.maintenanceTableForm = this.formBuilder.array([this.setDefaultForm]);
    // For API filter upon enhancement
    this.filterForm = this.formBuilder.group({
      email: [''],
      lastName: [''],
      firstName: [''],
      middleName: [''],
      employeeCode: [''],
      status: [''],
    });

    if (this.withActionsColumn) {
      this.displayedColumns.push('actions');
    }
    if (this.elementData) {
      this.elementData.subscribe((res) => {
        if (res.employee) {
          this.dataSource.data = res.employee;
          this.totalRecords = res.employee.length;
          this.loadValuesIntoFormArray(res.employee);
        }
      });
    }
  }

  setDefaultForm(): FormGroup {
    switch (this.title) {
      case 'USERS': {
        return this.formBuilder.group({
          position: [''],
          name: [''],
          weight: [''],
          isActive: [''],
          symbol: [''],
        });
      }
      case 'EMPLOYEE': {
        return this.formBuilder.group({
          employeeCode: [''],
          firstName: [''],
          lastName: [''],
          middleName: [''],
          status: [''],
        });
      }
    }
  }

  loadValuesIntoFormArray(data): void {
    this.maintenanceTableForm = new FormArray([]);
    const fb = this.setDefaultForm();

    data.forEach((value, index) => {
      this.maintenanceTableForm.insert(index, this.loadValues(value));
    });
  }

  loadValues(value): FormGroup {
    switch (this.title) {
      case 'USERS': {
        return this.formBuilder.group({
          position: value.position,
          name: value.name,
          weight: value.weight,
          isActive: value.isActive,
          symbol: value.symbol,
        });
      }
      case 'EMPLOYEE': {
        return this.formBuilder.group({
          employeeCode: value.employeeCode,
          firstName: value.firstName,
          lastName: value.lastName,
          middleName: value.middleName,
          status: value.status,
        });
      }
    }
  }

  onDelete(rowIndex): void {
    this.maintenanceTableForm.removeAt(rowIndex);
    this.data$.next(this.maintenanceTableForm.value);
  }

  onEdit(rowIndex): void {
    this.initialValues = this.maintenanceTableForm.value[rowIndex];
    this.isEditing = true;
    this.enabledEditRowIndex = rowIndex;
  }

  onCancel(): void {
    this.isEditing = false;
    if (this.enabledCreateRow) {
      this.maintenanceTableForm.removeAt(0);
      this.enabledCreateRow = !this.enabledCreateRow;
    }
    this.maintenanceTableForm.clear();
    // this.loadValuesIntoFormArray();
    this.data$.next(this.maintenanceTableForm.value);
    this.enabledEditRowIndex = null;
  }

  onSave(): void {
    this.isEditing = false;
    if (this.enabledCreateRow) {
      this.enabledCreateRow = !this.enabledCreateRow;
    }
    this.data$.next(this.maintenanceTableForm.value);
    this.enabledEditRowIndex = null;
  }

  onAddRow(): void {
    this.enabledCreateRow = !this.enabledCreateRow;
    if (this.enabledCreateRow) {
      this.maintenanceTableForm.insert(0, this.setDefaultForm());
    } else {
      this.maintenanceTableForm.removeAt(0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  setTableHeaders(): void {
    this.displayedColumns = this.headers.map((header) => header.dataSourceKey);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator.paginator;
    this.dataSource.sort = this.sort;
  }

  onRowClick(row): void {
    this.rowClick.emit(row);
  }

  onCancelClick(row): void {
    this.cancel.emit(row);
  }

  onPageEvent(): void {
    this.pageEvent.emit();
  }

  applyFilter(filterValue): void {
    let newFilterValue = '';
    if (filterValue.target && filterValue.target.value) {
      newFilterValue = filterValue.target.value.trim(); // Remove whitespace
      newFilterValue = newFilterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    }
    this.dataSource.filter = newFilterValue;
  }

  setupFilter(column: string): any {
    this.dataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = (d[column] && d[column].toLowerCase()) || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }
}
