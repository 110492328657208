<div fxLayout="row" fxLayoutAlign="space-between" class="header-title">
  <h5 class="aircraft">Add System Parameters</h5>
  <button mat-icon-button color="primary" (click)="close()">
    <i class="material-icons"> close </i>
  </button>
</div>
<form [formGroup]="systemParametersForm">
  <div class="row">
    <mat-form-field appearance="outline" class="width-1 col-4">
      <mat-label>Type</mat-label>
      <mat-select
        formControlName="typeId"
        (selectionChange)="onChange($event.value)"
      >
        <mat-option *ngFor="let type of typeList" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="width-1 col-4"
      *ngIf="systemParametersForm.controls['typeId'].value == 3"
    >
      <mat-label>Type of Motor</mat-label>
      <input
        matInput
        placeholder="Type of Motor"
        formControlName="typeMotor"
        name="typeMotor"
        id="typeMotor"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="width-1 col-4"
      *ngIf="systemParametersForm.controls['typeId'].value == 3"
    >
      <mat-label>Number of Motors</mat-label>
      <input
        matInput
        type="number"
        min="0"
        placeholder="Number of motors"
        formControlName="numberMotors"
        name="numberMotors"
        id="numberMotors"
      />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field
      appearance="outline"
      class="width-1 col-4"
      *ngIf="systemParametersForm.controls['typeId'].value == 3"
    >
      <mat-label>MTOW</mat-label>
      <input
        matInput
        type="number"
        min="0"
        placeholder="MTOW"
        formControlName="mtow"
        name="mtow"
        id="mtow"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="width-1 col-4"
      *ngIf="systemParametersForm.controls['typeId'].value == 3"
    >
      <mat-label>MTOW Class</mat-label>
      <input
        matInput
        type="number"
        min="0"
        placeholder="MTOW Class"
        formControlName="mtowClass"
        name="mtowClass"
        id="mtowClass"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="width-1 col-4"
      *ngIf="systemParametersForm.controls['typeId'].value == 3"
    >
      <mat-label>Display Warning</mat-label>
      <mat-select formControlName="displayWarning" [(value)]="selected">
        <mat-option [value]="true">True</mat-option>
        <mat-option [value]="false">False</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" class="width-1 col-4">
      <mat-label>Code</mat-label>
      <input
        matInput
        maxlength="50"
        placeholder="Code"
        formControlName="code"
        name="code"
        id="code"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="width-1 col-4">
      <mat-label>Value</mat-label>
      <input
        matInput
        maxlength="50"
        placeholder="Value"
        formControlName="value"
        name="value"
        id="value"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="width-1 col-4">
      <mat-label>Description</mat-label>
      <input
        matInput
        maxlength="200"
        placeholder="Description"
        formControlName="description"
        name="description"
        id="description"
      />
    </mat-form-field>
  </div>
  <div class="center">
    <button
      class="h-60 form-buttons"
      type="submit"
      nbButton
      size="large"
      status="info"
      (click)="onSave()"
      [disabled]="systemParametersForm.invalid"
      id="saveBtn"
    >
      SAVE
    </button>
  </div>
</form>
