export const NOTIFLIX_CONFIG = {
  FONT_FAMILY: 'Neo Sans Regular',
  ANIMATION_DURATION: 200,
  ANIMATION_STYLE: 'zoom',
  NOTIFY_POSITION: 'right-bottom',
  NOTIFY_POSITION_CENTER_TOP: 'center-top',
  TITLE_FONT_SIZE: '25px',
  MESSAGE_FONT_SIZE: '14px',
  MESSAGE_MAX_LENGTH: 30,
  COLORS: {
    SUCCESS: '#004254',
    SUCCESS_OVERLAY: 'rgba(0, 66, 84, 0.3)',
    SUCCESS_NOTIFY: '#40717F',
    INFO: '#03657C',
    INFO_OVERLAY: 'rgba(3, 101, 124, 0.3)',
    INFO_NOTIFY: '#428B9C',
    WARNING: '#FBBB21',
    WARNING_OVERLAY: 'rgba(251, 187, 33, 0.1)',
    ERROR: '#79314F',
    ERROR_OVERLAY: 'rgba(121, 49, 79, 0.3)',
    PRIMARY: '#646e78',
  },
};
