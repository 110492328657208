<div class="flight-plant-container">
  <div class="row">
    <div class="col-md-12">
      <nb-card class="card">
        <nb-card-body class="py-3 py-md-5">
          <h2 class="mb-0">Flight planning</h2>
        </nb-card-body>
      </nb-card>

      <nb-card>
        <nb-card-header>
          <!-- <div class="text-center">
            <h5 class="text-haze-blue">My Flight Plans</h5>
          </div> -->
          <div class="button-container">
            <button
              nbButton
              status="info"
              size="medium"
              routerLink="/add-flight"
            >
              Register a new flight plan
            </button>
          </div>
        </nb-card-header>
        <nb-card-body>
          <app-mat-data-table
            [tableContents]="tableContents"
            [dataSource]="flightPlanList"
            [loadingIndicator]="loadingIndicator$"
            [component]="this"
            [advancedSearchKeys]="advancedSearchKeys"
            [copyFlight]="true"
            [viewFlightDetails]="true"
            [sendVfrMail]="true"
            (isViewDetails)="onViewDetails($event)"
            (isCopyFlight)="onCopyFlight($event)"
            (isSendVfrMail)="onSendVfrMail($event)"
          ></app-mat-data-table>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
