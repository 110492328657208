import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  Output,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { OperationsService } from '@app/core/services/operations.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { AddFlightService } from '@app/core/services/add-flight.service.ts.service';
import { FeesBreakdownService } from '@app/core/services/fees-breakdown.service';
import { AddFlightPlan } from '@app/core/models/add-flight-plan.model';
import { FlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.model';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatorComponent } from 'app/shared/paginator/paginator.component';
import { AuthService } from '@app/core/services/auth/auth.service';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import { getFlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.action';
import { selectFlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.selector';
import { select, Store } from '@ngrx/store';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { FlightPlanService } from '@app/core/services/flight-plan.service';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { REGEX } from '@app/core/constants/regex.const';
@Component({
  selector: 'app-copy-flight-plan',
  templateUrl: './copy-flight-plan.component.html',
  styleUrls: ['./copy-flight-plan.component.scss'],
})
export class CopyFlightPlanComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild('paginator') paginator: PaginatorComponent;
  @Output() pageEvent = new EventEmitter();
  public data: any;
  flightDetailsInitialState: any;
  flightPlanDetails: FlightPlanDetails;
  atcCharge: number;
  operationSubscription$: Subscription;
  operationTypes: Array<any>;
  flightRulesSubscription$: Subscription;
  flightRules: Array<any>;
  flightTypesSubscription$: Subscription;
  flightTypes: Array<any>;
  aircraftTypesSubscription$: Subscription;
  aircraftTypes: Array<any>;
  motorTypesSubscription$: Subscription;
  motorTypes: Array<any>;
  isLinear = true;
  // isDisabled = true;
  canDeacForm: FormGroup;
  flightDetailsNewForm: FormGroup;
  departureDetailsForm: FormGroup;
  emergencyDetailsForm: FormGroup;
  pilotDetailsForm: FormGroup;
  passengerDetailsForm: FormGroup;
  feesBreakdownForm: FormGroup;
  filingDate: Date;
  filteredOperations: Observable<string[]>;
  filteredRules: Observable<string[]>;
  filteredFlightTypes: Observable<string[]>;
  filteredAircraftTypes: Observable<string[]>;
  filteredMotorTypes: Observable<string[]>;
  currentTransaction: string;
  items: FormArray;
  mindateToday = new Date(1960, 0, 1);
  propertiesSubscription$: Subscription;
  propertiesList: Array<any>;
  minDate = new Date(1960, 0, 1);
  checker: any;
  icaoErrorMsg: string;
  // tslint:disable-next-line: no-inferrable-types
  airportCodeList: Array<any>;
  icaoWarning = false;
  isDoneLoading = false;
  public icaoWarningMsg = 'This airport is not in Bahamas';
  public offBlockTime: any;
  public departureTime: any;
  public splitStr: any;
  public requiredFieldMsg = 'This is a required field.';
  public cruisingSpeedMsg = '';
  deptAeroWarning = false;
  destAeroWarning = false;
  alterAeroWarning = false;
  secAlterAeroWarning = false;
  mtowDisplayWarning = false;
  id: number;
  constants = CONSTANTS;
  totalAmount: string;
  formdata = new AddFlightPlan();
  // tslint:disable-next-line: no-inferrable-types
  forValidation: boolean = true;
  paymentBaseUrl: string = null;
  paymentHostedPage: string = null;
  // tslint:disable-next-line: no-inferrable-types
  enablePayment = false;
  enableAddRoute = true;
  enableAddPassenger = false;
  isFreeTax = false;
  cruisingSpeedRegEx = new RegExp(/^[0-9]+(\.[0-9]{1,3})?$/);
  timeZone: any;

  constructor(
    private notiflixService: NotiflixService,
    private formBuilder: FormBuilder,
    private operationsService: OperationsService,
    private feesBreakdownService: FeesBreakdownService,
    private addFlightService: AddFlightService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private store: Store<FlightPlanState>,
    private changeDetector: ChangeDetectorRef,
    private flightPlanService: FlightPlanService,
    private cryptoService: CryptoService
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.id = data.id;
    });
  }

  ngOnInit(): void {
    if (this.cryptoService.isAdmin()) {
      this.router.navigateByUrl('/forbidden');
    }

    this.getAllOperations();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getTimeZone(): void {
    this.flightPlanService.getTimeZone().subscribe((timeZone) => {
      this.timeZone = this.flightPlanService.formatTimeZone(timeZone.value);

      this.flightDetailsNewForm
        .get('filingTime')
        .setValue(this.timeZone.filingTime);

      this.flightDetailsNewForm
        .get('filingDate')
        .setValue(this.timeZone.filingDate);

      this.flightDetailsNewForm
        .get('flightDate')
        .setValue(this.timeZone.currentDate);
    });
  }

  initFlightPlanDetails(): void {
    this.notiflixService.loadPulse('Retrieving flight plan details...');
    this.store.dispatch(getFlightPlanDetails({ id: this.id }));
    this.store
      .pipe(select(selectFlightPlanDetails))
      .subscribe((res: FlightPlanDetails) => {
        if (res) {
          this.flightPlanDetails = res;
          this.initFlightPlanDetailsForm();
          this.getTimeZone();
          this.initChecker();
          this.validateAerodromes();
        }
      });
  }

  private filterAirportCode(value: string): string[] {
    const filterValue = value;
    return this.airportCodeList.find((arr) => arr.value === filterValue);
    // return this.airportCodeList.filter((key) => key.value.includes(filterValue));
  }

  private filterAircraftType(value: string): string[] {
    const filterValue = value;
    return this.aircraftTypes.filter((option) =>
      option.value.toLowerCase().includes(filterValue)
    );
  }

  getFilteredAircraftType(value: string): Observable<string[]> {
    return of(value).pipe(
      map((filterString) => this.filterAircraftType(filterString))
    );
  }

  displayAircraftTypeFn(aircraftType): string {
    return aircraftType && aircraftType.value ? aircraftType.value : '';
  }

  onAircraftTypeChange(event): void {
    this.filteredAircraftTypes = this.getFilteredAircraftType(
      this.flightDetailsNewForm.get('aircraftType').value
    );
    this.filteredAircraftTypes.subscribe((res) => {
      if (res.length === 0) {
        this.icaoErrorMsg = `Warning! Aircraft ICAO model code not recognized, please be
        sure it is correct`;
        this.icaoWarning = true;
      } else {
        this.icaoWarning = false;
      }
    });
  }

  onSelectAircraftType(option): void {
    this.mtowDisplayWarning = option.displayWarning;
    this.flightDetailsNewForm.get('aircraftType').setValue(option);
    this.flightDetailsNewForm.get('numberMotors').setValue(option.numberMotors);
    this.flightDetailsNewForm.get('typeMotors').setValue(option.typeMotor);
    this.flightDetailsNewForm.get('mtow').setValue(option.mtow);
  }

  getAllOperations(): void {
    this.operationSubscription$ = this.operationsService
      .getAllOperations()
      .subscribe((response: any) => {
        if (response) {
          this.operationTypes = response.operationTypes;
          this.flightRules = response.flightRules;
          this.flightTypes = response.flightTypes;
          this.motorTypes = response.motorTypes;
          this.airportCodeList = response.airports;
          this.aircraftTypes = response.aircraftTypes;
          this.initFlightPlanDetails();
          this.filteredAircraftTypes = this.getFilteredAircraftType(
            this.flightDetailsNewForm.get('aircraftType').value?.label
          );
          // this.paymentBaseUrl = response.payment[0]?.value ? response.payment[0].value : null;
          // this.paymentHostedPage = response.payment[1]?.value ? response.payment[1].value : null;

          response.payment.find((res) => {
            if (res.code === 'PAYMENT_BASE_URL') {
              this.paymentBaseUrl = res.value;
            }
            if (res.code === 'PAYMENT_HOSTED_PAGE') {
              this.paymentHostedPage = res.value;
            }
          });
        }
      });
  }

  initChecker(): void {
    this.checker = [
      JSON.stringify(this.flightDetailsNewForm.value).length,
      JSON.stringify(this.departureDetailsForm.value).length,
      JSON.stringify(this.emergencyDetailsForm.value).length,
      JSON.stringify(this.pilotDetailsForm.value).length,
      JSON.stringify(this.passengerDetailsForm.value).length,
      JSON.stringify(this.feesBreakdownForm.value).length,
    ];
  }

  // tslint:disable-next-line:typedef
  isExist(key, value) {
    switch (key) {
      case 'flightRules':
        return this.flightRules.find((arr) => arr.value === value);
      case 'typeFlight':
        return this.flightTypes.find((arr) => arr.value === value);
      case 'typeOperation':
        return this.operationTypes.find((arr) => arr.value === value);
      case 'motorTypes':
        return this.motorTypes.find((arr) => arr.value === value);
      case 'default':
        break;
    }
  }

  initFlightPlanDetailsForm(): void {
    const currentAircraftType = this.aircraftTypes.find(
      (rec) =>
        rec?.value?.toLowerCase() ===
        this.flightPlanDetails?.aircraftType?.toLowerCase()
    );
    this.flightDetailsNewForm = this.formBuilder.group({
      filingTime: ['', Validators.required],
      filingDate: ['', Validators.required],
      typeOperation: [
        this.isExist('typeOperation', this.flightPlanDetails.typeOperation)
          ? this.flightPlanDetails.typeOperation
          : '',
        Validators.required,
      ],
      typeFlight: [
        this.isExist('typeFlight', this.flightPlanDetails.typeFlight)
          ? this.flightPlanDetails.typeFlight
          : '',
        Validators.required,
      ],
      aircraftIdentification: [
        this.flightPlanDetails.aircraftIdentification,
        Validators.required,
      ],
      aircraftType: [
        currentAircraftType ? currentAircraftType : '',
        Validators.required,
      ],
      flightRules: [
        this.isExist('flightRules', this.flightPlanDetails.flightRules)
          ? this.flightPlanDetails.flightRules
          : '',
        Validators.required,
      ],
      numberMotors: [this.flightPlanDetails.numberMotors, Validators.required],
      typeMotors: [
        this.isExist('motorTypes', this.flightPlanDetails.typeMotor)
          ? this.flightPlanDetails.typeMotor
          : '',
        Validators.required,
      ],
      mtow: [this.flightPlanDetails.mtow, Validators.required],
      flightDate: ['', Validators.required],
    });
    this.departureDetailsForm = this.formBuilder.group({
      departureTime: ['', Validators.required],
      departureHour: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      departureMinute: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      flightRoutes: new FormArray([
        new FormGroup({
          flightRoute: new FormControl(null, [Validators.required]),
        }),
      ]),
      offBlockHour: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      offBlockMinute: [
        '',
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      departureAerodrome: [
        this.flightPlanDetails.departureAerodrome,
        [Validators.required, Validators.minLength(4)],
      ],
      destinationAerodrome: [
        this.flightPlanDetails.destinationAerodrome,
        [Validators.required, Validators.minLength(4)],
      ],
      estimatedTimeEnrouteHours: [
        this.flightPlanDetails.estimatedTimeEnrouteHours,
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      estimatedTimeEnrouteMinutes: [
        this.flightPlanDetails.estimatedTimeEnrouteMinutes,
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      fuelEnduranceHours: [
        this.flightPlanDetails.fuelEnduranceHours,
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      fuelEnduranceMinutes: [
        this.flightPlanDetails.fuelEnduranceMinutes,
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      cruisingSpeed: [
        this.flightPlanDetails.cruisingSpeed?.toString(),
        [Validators.required, Validators.pattern(this.cruisingSpeedRegEx)],
      ],
      cruisingAltitude: [
        this.flightPlanDetails.cruisingAltitude,
        Validators.required,
      ],
      otherInformation: [
        this.flightPlanDetails.otherInformation,
        Validators.required,
      ],
      alternateAerodrome: [
        this.flightPlanDetails.alternateAerodrome,
        [Validators.required, Validators.minLength(4)],
      ],
      secondAlternateAerodrome: [
        this.flightPlanDetails.secondAlternateAerodrome,
        [Validators.minLength(4)],
      ],
    });
    this.emergencyDetailsForm = this.formBuilder.group({
      emergencyRadio: [
        this.flightPlanDetails.emergencyRadio,
        Validators.required,
      ],
      numberLifeJacketsColor: [
        this.flightPlanDetails.numberLifeJacketsColor,
        Validators.required,
      ],
      numberDinghies: [
        this.flightPlanDetails.dinghiesNumber?.toString(),
        Validators.required,
      ],
      capacityDinghies: [
        this.flightPlanDetails.dinghiesCapacity?.toString(),
        Validators.required,
      ],
      colorDinghies: [
        this.flightPlanDetails.dinghiesColor,
        Validators.required,
      ],
      aircraftColor: [
        this.flightPlanDetails.aircraftColor,
        Validators.required,
      ],
    });
    this.pilotDetailsForm = this.formBuilder.group({
      name: [
        this.flightPlanDetails.pilotInformation?.name,
        Validators.required,
      ],
      phone: [
        this.flightPlanDetails.pilotInformation?.phone.replace(/-/gi, ''),
        [Validators.required, Validators.pattern(REGEX.numberOnly)],
      ],
      email: [
        this.flightPlanDetails.pilotInformation?.email,
        [
          Validators.required,
          Validators.pattern(
            /[a-zA-Z0-9.-_]{1,}@[a-zA-Z-]{1,}[.]{1}[a-zA-Z]{2,}/
          ),
        ],
      ],
      address: [
        this.flightPlanDetails.pilotInformation?.address,
        Validators.required,
      ],
      aircraftHomeBase: [
        this.flightPlanDetails.pilotInformation?.aircraftHomeBase,
        Validators.required,
      ],
    });
    this.passengerDetailsForm = this.formBuilder.group({
      passenger: new FormArray([
        new FormGroup({
          name: new FormControl(''),
          address: new FormControl(''),
          phone: new FormControl('', [Validators.pattern(REGEX.numberOnly)]),
          emergencyContact: new FormControl(''),
        }),
      ]),
      totalNumberPersonsOnBoard: [
        this.flightPlanDetails.totalNumberPersonsOnBoard ?? 0,
        [Validators.maxLength(50), Validators.pattern(REGEX.numberOnly)],
      ],
      numberOfUSPassenger: [
        this.flightPlanDetails.numberOfUSPassenger ?? 0,
        [
          Validators.maxLength(50),
          Validators.pattern(REGEX.numberOnly),
          Validators.required,
        ],
      ],
      numberOfNonUSPassenger: [
        this.flightPlanDetails.numberOfNonUSPassenger ?? 0,
        [
          Validators.maxLength(50),
          Validators.pattern(REGEX.numberOnly),
          Validators.required,
        ],
      ],
      methodClosure: [
        this.flightPlanDetails.methodClosure,
        Validators.required,
      ],
      otherEquipmentRemarks: [
        this.flightPlanDetails.otherEquipmentRemarks,
        Validators.required,
      ],
    });
    this.feesBreakdownForm = this.formBuilder.group({
      atcCharge: [''],
      taxAtc: [''],
      subtotalAtc: [''],
      passengerCharge: [''],
      taxPassenger: [''],
      subtotalPassenger: [''],
      totalPayment: [''],
    });
    this.filteredAircraftTypes = this.getFilteredAircraftType(
      this.flightDetailsNewForm.get('aircraftType').value
    );
    this.offBlockTime = this.formatTime(
      this.flightPlanDetails.offBlockTime
    )?.split(':');
    this.departureTime = this.formatTime(
      this.flightPlanDetails.departureTime
    )?.split(':');
    this.departureDetailsForm.patchValue({
      offBlockTime: this.offBlockTime,
      offBlockHour: this.offBlockTime ? this.offBlockTime[0] : '',
      offBlockMinute: this.offBlockTime ? this.offBlockTime[1] : '',
      departureTime: this.departureTime,
      departureHour: this.departureTime ? this.departureTime[0] : '',
      departureMinute: this.departureTime ? this.departureTime[1] : '',
    });
    // remove initial empty fields
    this.removePassenger(0);
    // loop through passenger details
    this.generateExistingPassengerDetails();
    // generate route flights
    this.flightRoutes.removeAt(0);
    this.generateExistingRouteFlights();
    // enable adding of passengers
    this.inputTotal(
      this.passengerDetailsForm.get('totalNumberPersonsOnBoard').value
    );

    this.isDoneLoading = true;
    this.notiflixService.closeLoader();
  }

  inputTotal(length: number): void {
    if (length < 0) {
      return;
    }
    length = Math.min(length, 100);
    if (length > this.passenger.length) {
      this.enableAddPassenger = true;
    } else if (length < this.passenger.length) {
      const size = this.passenger.length - length;
      for (let i = 0; i < size; i++) {
        this.enableAddPassenger = false;
        this.removePassenger();
      }
    }
    if (length === 0) {
      this.passenger.clear();
      this.addPassenger();
      this.passengerDetailsForm.get('passenger').disable();
    } else {
      this.passengerDetailsForm.get('passenger').enable();
    }
  }

  addPassenger(): void {
    if (
      this.passenger.length <
        this.passengerDetailsForm.get('totalNumberPersonsOnBoard').value ||
      this.passenger.length === 0
    ) {
      this.passenger.push(
        new FormGroup({
          name: new FormControl(),
          address: new FormControl(),
          phone: new FormControl('', [Validators.pattern('^[0-9]*$')]),
          emergencyContact: new FormControl(''),
        })
      );
      if (
        this.passenger.length ===
        this.passengerDetailsForm.get('totalNumberPersonsOnBoard').value
      ) {
        this.enableAddPassenger = false;
      }
    } else {
      this.enableAddPassenger = false;
    }
  }

  removePassenger(i?: number): void {
    const index = this.passenger.length - 1;
    this.passenger.removeAt(i ? i : index);
  }

  populatePassenger(index: number): void {
    this.passenger.push(
      new FormGroup({
        name: new FormControl(this.flightPlanDetails.passengers[index]?.name),
        address: new FormControl(
          this.flightPlanDetails.passengers[index]?.address
        ),
        phone: new FormControl(
          this.flightPlanDetails.passengers[index]?.phone.replace(/-/gi, ''),
          [Validators.pattern('^[0-9]*$')]
        ),
        emergencyContact: new FormControl(
          this.flightPlanDetails.passengers[index]?.emergencyContact
        ),
      })
    );
  }

  filterPassenger(passengerList): Array<any> {
    return passengerList.filter((value) => {
      if (
        value.name !== null ||
        value.address !== null ||
        value.phone !== '' ||
        value.emergencyContact !== ''
      ) {
        return value;
      }
    });
  }

  get passenger(): FormArray {
    return this.passengerDetailsForm.get('passenger') as FormArray;
  }

  get flightRoutes(): FormArray {
    return this.departureDetailsForm.get('flightRoutes') as FormArray;
  }

  onPageEvent(): void {
    this.pageEvent.emit();
  }

  calculateFeesBreakdown(): void {
    const request = {
      departureAerodrome:
        this.departureDetailsForm.get('departureAerodrome').value,
      destinationAerodrome: this.departureDetailsForm.get(
        'destinationAerodrome'
      ).value,
      aircraftIdentification: this.flightDetailsNewForm.get(
        'aircraftIdentification'
      ).value,
      typeFlight: this.flightDetailsNewForm.get('typeFlight').value,
      typeMotor: this.flightDetailsNewForm.get('typeMotors').value,
      typeOperation: this.flightDetailsNewForm.get('typeOperation').value.label,
      numberMotors: this.flightDetailsNewForm.get('numberMotors').value,
      // typeMotor: this.flightDetailsNewForm.get('typeMotors').value.label,
      mtow: this.flightDetailsNewForm.get('mtow').value,
      totalNumberPersonsOnBoard: this.passengerDetailsForm.get(
        'totalNumberPersonsOnBoard'
      ).value,
    };
    this.feesBreakdownService.calculateFeesBreakdown(request).subscribe(
      (data) => {
        if (data) {
          this.feesBreakdownForm.setValue({
            atcCharge: data.atcCharge.toFixed(2),
            taxAtc: data.taxAtc.toFixed(2),
            subtotalAtc: data.subtotalAtc.toFixed(2),
            passengerCharge: data.passengerCharge.toFixed(2),
            taxPassenger: data.taxPassenger.toFixed(2),
            subtotalPassenger: data.subtotalPassenger.toFixed(2),
            totalPayment: data.totalPayment.toFixed(2),
          });
          this.isFreeTax =
            this.feesBreakdownForm.get('totalPayment').value === '0.00'
              ? true
              : false;
          this.notiflixService.closeLoader();
        }
        this.totalAmount = data.totalPayment;
      },
      (error) => {
        this.notiflixService.closeLoader();
        this.notiflixService.launchReport(
          'error',
          'Error',
          `${error.error.clientError}`,
          '',
          this
        );
      }
    );
  }

  onSubmit(): void {
    this.currentTransaction = 'SUBMIT';
    this.notiflixService.launchConfirm(
      'Submit Flight Plan?',
      'The information introduced will be submitted to the Authority and will not be modifiable. Are you sure that you want to continue?',
      this,
      'SUBMIT FORM',
      'CANCEL'
    );
  }

  onProcess(): void {
    this.currentTransaction = 'PROCESS';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'You will be redirected to the payment page.',
      this,
      'YES',
      'NO'
    );
  }
  onBack(): void {
    const formLength = [
      JSON.stringify(this.flightDetailsNewForm.value).length,
      JSON.stringify(this.departureDetailsForm.value).length,
      JSON.stringify(this.emergencyDetailsForm.value).length,
      JSON.stringify(this.pilotDetailsForm.value).length,
      JSON.stringify(this.passengerDetailsForm.value).length,
      JSON.stringify(this.feesBreakdownForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );
    if (currentFormLength !== formLength) {
      this.currentTransaction = 'BACK';
      this.notiflixService.launchConfirm(
        'Are you sure you want to exit?',
        'Changes you made will not be saved.',
        this,
        'EXIT',
        'NO'
      );
    } else {
      this.currentTransaction = 'BACK';
      this.onPositive();
    }
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'SUBMIT':
        this.onProcessPayment(true);
        break;
      case 'PROCESS':
        this.onProcessPayment(false);
        const processPaymentBtn = document.getElementById(
          'processPaymentBtn'
        ) as HTMLElement;
        processPaymentBtn.click();
        this.enablePayment = true;
        break;
      case 'BACK':
        this.router.navigate(['/flight-list'], {
          queryParams: { page: null },
          queryParamsHandling: 'merge',
        });
        break;
      default:
        throw new Error(`${this.currentTransaction} is invalid`);
    }
  }

  onNegative(): void {}

  reFormatTime(time: string): string {
    if (time.length > 1 && String(time).charAt(0) === '0') {
      return time;
    }

    return (+time < 10 ? '0' : '') + time;
  }

  // tslint:disable-next-line: typedef
  processPayload(isValidation: boolean, payLater: boolean) {
    let flightRoutes =
      this.departureDetailsForm.controls.flightRoutes.value.map(
        (x) => x.flightRoute
      );
    flightRoutes = flightRoutes.map((x) => x).join('-');
    this.passengerDetailsForm.get('passenger').enable();
    const flightPlanDetails = {
      filingTime: this.flightDetailsNewForm.controls.filingTime.value,
      filingDate: moment(
        this.flightDetailsNewForm.controls.filingDate.value,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD'),
      flightDate: moment(
        this.flightDetailsNewForm.controls.flightDate.value
      ).format('YYYY-MM-DD'),
      departureTime: `${this.reFormatTime(
        this.departureDetailsForm.controls.departureHour.value
      )}:${this.reFormatTime(
        this.departureDetailsForm.controls.departureMinute.value
      )}`,
      offBlockTime: `${this.reFormatTime(
        this.departureDetailsForm.controls.offBlockHour.value
      )}:${this.reFormatTime(
        this.departureDetailsForm.controls.offBlockMinute.value
      )}`,
      typeOperation: this.flightDetailsNewForm.controls.typeOperation.value,
      typeFlight: this.flightDetailsNewForm.controls.typeFlight.value,
      flightRules: this.flightDetailsNewForm.controls.flightRules.value,
      aircraftIdentification:
        this.flightDetailsNewForm.controls.aircraftIdentification.value,
      aircraftType:
        this.flightDetailsNewForm.controls.aircraftType.value.value ??
        this.flightDetailsNewForm.controls.aircraftType.value,
      numberMotors: this.flightDetailsNewForm.controls.numberMotors.value,
      typeMotor: this.flightDetailsNewForm.controls.typeMotors.value,
      mtow: this.flightDetailsNewForm.controls.mtow.value,
      departureAerodrome:
        this.departureDetailsForm.controls.departureAerodrome.value.toUpperCase(),
      destinationAerodrome:
        this.departureDetailsForm.controls.destinationAerodrome.value.toUpperCase(),
      estimatedTimeEnrouteHours:
        this.departureDetailsForm.controls.estimatedTimeEnrouteHours.value,
      estimatedTimeEnrouteMinutes:
        this.departureDetailsForm.controls.estimatedTimeEnrouteMinutes.value,
      fuelEnduranceHours:
        this.departureDetailsForm.controls.fuelEnduranceHours.value,
      fuelEnduranceMinutes:
        this.departureDetailsForm.controls.fuelEnduranceMinutes.value,
      otherInformation:
        this.departureDetailsForm.controls.otherInformation.value.toUpperCase(),
      alternateAerodrome:
        this.departureDetailsForm.controls.alternateAerodrome.value,
      secondAlternateAerodrome: this.departureDetailsForm.controls
        .secondAlternateAerodrome.value
        ? this.departureDetailsForm.controls.secondAlternateAerodrome.value.toUpperCase()
        : null,
      flightRoute: flightRoutes,
      // flightRoutes.length === 95 ? flightRoutes : flightRoutes.slice(0, -1),
      cruisingSpeed: this.departureDetailsForm.controls.cruisingSpeed.value,
      cruisingAltitude:
        this.departureDetailsForm.controls.cruisingAltitude.value,
      emergencyRadio: this.emergencyDetailsForm.controls.emergencyRadio.value,
      numberLifeJacketsColor:
        this.emergencyDetailsForm.controls.numberLifeJacketsColor.value,
      dinghiesNumber: this.emergencyDetailsForm.controls.numberDinghies.value,
      dinghiesCapacity:
        this.emergencyDetailsForm.controls.capacityDinghies.value,
      dinghiesColor: this.emergencyDetailsForm.controls.colorDinghies.value,
      aircraftColor: this.emergencyDetailsForm.controls.aircraftColor.value,
      totalNumberPersonsOnBoard:
        this.passengerDetailsForm.controls.totalNumberPersonsOnBoard.value ?? 0,
      numberOfUSPassenger:
        this.passengerDetailsForm.controls.numberOfUSPassenger.value ?? 0,
      numberOfNonUSPassenger:
        this.passengerDetailsForm.controls.numberOfNonUSPassenger.value ?? 0,
      methodClosure: this.passengerDetailsForm.controls.methodClosure.value,
      otherEquipmentRemarks:
        this.passengerDetailsForm.controls.otherEquipmentRemarks.value,
      pilotInformation: this.pilotDetailsForm.value,
      passengers:
        this.filterPassenger(this.passengerDetailsForm.value.passenger) ?? null,
      forValidation: isValidation,
      payLater,
    };

    return flightPlanDetails;
  }

  submitFlightPlan(payLater = false): void {
    this.formdata = this.processPayload(false, payLater);
    this.notiflixService.loadPulse('Processing...');
    this.addFlightService
      .createAddFlight(JSON.stringify(this.formdata))
      .subscribe(
        (res) => {
          if (res) {
            this.notiflixService.closeLoader();
            this.notiflixService.launchReport(
              'success',
              'Success',
              `${res.body.message}`,
              '',
              this
            );
          } else {
            this.notiflixService.closeLoader();
            this.notiflixService.launchReport(
              'error',
              'Error',
              'An Error Occurred.',
              '',
              this
            );
          }
        },
        (error) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchReport(
            'error',
            'Error',
            `${error?.error?.message}`,
            '',
            this
          );
        }
      );
  }

  onOk(): void {
    switch (this.currentTransaction) {
      case 'VERIFICATION_ERROR':
        break;
      default:
        this.router.navigate(['/flight-list'], {
          queryParams: { page: null },
          queryParamsHandling: 'merge',
        });
        break;
    }
  }

  onProcessPayment(forValidation: boolean): void {
    this.notiflixService.loadPulse('Processing...');
    this.formdata = this.processPayload(forValidation, false);
    if (forValidation === true) {
      this.addFlightService
        .createAddFlight(JSON.stringify(this.formdata))
        .subscribe(
          (res) => {
            this.calculateFeesBreakdown();
            const feesBreakdownBtn = document.getElementById(
              'feesBreakdownBtn'
            ) as HTMLElement;
            feesBreakdownBtn.click();
          },
          (error) => {
            this.currentTransaction = 'VERIFICATION_ERROR';
            this.notiflixService.closeLoader();
            this.notiflixService.launchReport(
              'error',
              'Error',
              `${error?.error?.message}`,
              '',
              this
            );
          }
        );
    }
  }

  buildFormData(formData: any, data: any, parentKey?: any): void {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else if (data === null || data === undefined || data === '') {
      const value = null;
      formData.append(parentKey, value);
      if (!parentKey.includes('indraHistory')) {
        formData.delete(parentKey, value);
      }
    } else {
      const value = data === '' ? null : data;
      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data: any): FormData {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  inputNumber(value: any): void {
    if (value) {
      this.flightDetailsNewForm
        .get('numberMotors')
        .setValidators([Validators.pattern('^[0-9]*$')]);
    }
  }

  inputMtow(value: any): void {
    if (value) {
      this.flightDetailsNewForm.get('mtow').updateValueAndValidity();
      this.mtowDisplayWarning =
        this.flightPlanDetails.mtow === 10000 ? true : false;
    }
  }

  inputCruisingSpeed(value: any): void {
    if (value) {
      this.cruisingSpeedMsg = 'Error! only numbers are allowed';
    } else {
      this.cruisingSpeedMsg = 'This is a required field';
    }
  }

  inputHours(value: any, formControl: string): void {
    if (value > 23) {
      this.departureDetailsForm.get(formControl).setErrors({ invalid: true });
    }
  }

  inputMinutes(value: any, formControl: string): void {
    if (value > 59) {
      this.departureDetailsForm.get(formControl).setErrors({ invalid: true });
    }
  }

  inputDeptAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value.toUpperCase());
    if (!filteredAirportCode && value.length === 4) {
      this.deptAeroWarning = true;
    } else {
      this.deptAeroWarning = false;
    }
  }

  inputDestAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value.toUpperCase());
    if (!filteredAirportCode && value.length === 4) {
      this.destAeroWarning = true;
    } else {
      this.destAeroWarning = false;
    }
  }

  inputAlterAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value.toUpperCase());
    if (!filteredAirportCode && value.length === 4) {
      this.alterAeroWarning = true;
    } else {
      this.alterAeroWarning = false;
    }
  }
  inputSecAltAero(value: any): void {
    const filteredAirportCode = this.filterAirportCode(value.toUpperCase());
    if (!filteredAirportCode && value.length === 4) {
      this.secAlterAeroWarning = true;
    } else {
      this.secAlterAeroWarning = false;
    }
  }
  formatTime(time: string): string {
    if (!time) {
      return;
    }
    return moment(time, 'HH:mm').format('HH:mm');
  }

  generateExistingPassengerDetails(): void {
    const passengersObj = { ...this.flightPlanDetails.passengers };
    if (Object.keys(passengersObj).length === 0) {
      this.addPassenger();
      this.passengerDetailsForm.get('passenger').disable();
    }
    for (let i = 0; i < Object.keys(passengersObj).length; i++) {
      this.populatePassenger(i);
    }
  }

  generateExistingRouteFlights(index?: number): void {
    const routeFlightString = this.flightPlanDetails.flightRoute;
    this.splitStr = routeFlightString ? routeFlightString.split('-') : '';
    if (this.splitStr.length <= 15) {
      for (let i = 0; i < this.splitStr.length; i++) {
        this.addFlightRoute(i);
      }
    } else {
      for (let i = 0; i < this.splitStr.length; i++) {
        this.addFlightRoute(i);
      }
    }
  }

  addRoute(): void {
    this.addBlankField();
    this.enableAddRoute = false;
  }

  InputFlightRoute(value, index): void {
    // if length of input is equal to 5 and the number of fields is less than 16, add blank field
    // if (value.length === 5) {
    //   this.addBlankField();
    // }
    const flightRoutesArr = this.departureDetailsForm.controls
      .flightRoutes as FormArray;
    if (value.length > 0 && flightRoutesArr.length < 16) {
      this.enableAddRoute = true;
    }
    // if length of input is equal to 0, delete field
    else if (value.length === 0) {
      this.removeFlightRoute(index);
    }
  }

  addBlankField(): void {
    const flightRoutesArr = this.departureDetailsForm.controls
      .flightRoutes as FormArray; // get array of flight route fields
    const fRFieldLength = flightRoutesArr.length; // get number of flight route fields
    // get concatenated flight route values
    let itemJoin = flightRoutesArr.value.map((x) => x.flightRoute);
    itemJoin = itemJoin.map((x) => x).join('');
    const fRStringLength = fRFieldLength * 5;
    if (fRFieldLength < 16) {
      this.addFlightRoute();
    } else {
      this.enableAddRoute = false;
    }
    // this is for automatic adding of field
    // if (itemJoin.length === fRStringLength && fRFieldLength < 16) {
    //   this.addFlightRoute();
    // }
  }

  addFlightRoute(index?: number): void {
    if (index === 0) {
      this.flightRoutes.push(
        new FormGroup({
          flightRoute: new FormControl(this.splitStr[index], [
            Validators.required,
            Validators.pattern(/[a-zA-Z0-9.-_]/),
          ]),
        })
      );
    } else {
      this.flightRoutes.push(
        new FormGroup({
          flightRoute: new FormControl(
            this.splitStr[index],
            Validators.pattern(/[a-zA-Z0-9.-_]/)
          ),
        })
      );
    }
  }

  removeFlightRoute(index): void {
    const flightRoutesArr = this.departureDetailsForm.controls
      .flightRoutes as FormArray; // get array of flight route fields
    const fRFieldLength = flightRoutesArr.length; // get number of flight route fields
    // get concatenated flight route values
    if (index < fRFieldLength - 1 || fRFieldLength > 1) {
      this.enableAddRoute = true;
      // delete the field only if the deleted text is not from the last field
      this.flightRoutes.removeAt(index);
      // this.addBlankField();
      // add validators on first element
      flightRoutesArr
        .at(0)
        .get('flightRoute')
        .setValidators([
          Validators.required,
          Validators.pattern(/[a-zA-Z0-9.-_]/),
        ]);
      // refresh validators
      flightRoutesArr.at(0).get('flightRoute').updateValueAndValidity();
    } else {
      this.enableAddRoute = false;
    }
  }

  validateAerodromes(): void {
    if (!this.flightPlanDetails) {
      return;
    }
    const deptAero = this.filterAirportCode(
      this.flightPlanDetails?.departureAerodrome
    );
    const destAero = this.filterAirportCode(
      this.flightPlanDetails?.destinationAerodrome
    );
    const alterAero1 = this.filterAirportCode(
      this.flightPlanDetails?.alternateAerodrome
    );
    const alterAero2 = this.flightPlanDetails?.secondAlternateAerodrome
      ? this.filterAirportCode(this.flightPlanDetails?.secondAlternateAerodrome)
      : true;
    this.mtowDisplayWarning =
      this.flightPlanDetails.mtow === 10000 ? true : false;
    this.deptAeroWarning = deptAero ? false : true;
    this.destAeroWarning = destAero ? false : true;
    this.alterAeroWarning = alterAero1 ? false : true;
    this.secAlterAeroWarning = alterAero2 ? false : true;
  }

  ngOnDestroy(): void {}
}
