import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CONSTANTS,
  NOTIFLIX_STATUS,
} from '@app/core/constants/constants.const';
import { UserInfo } from '@app/core/models/user-info.model';
import { AuthService } from '@app/core/services/auth/auth.service';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { SsoService } from '@app/core/services/sso/sso.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import * as SecureLS from 'secure-ls';

@Component({
  selector: 'app-checkpoint',
  templateUrl: './checkpoint.component.html',
  styleUrls: ['./checkpoint.component.scss'],
})
export class CheckpointComponent implements OnInit {
  CONSTANTS = CONSTANTS;
  appVersion = environment.version;
  copyrightYear = environment.copyright_year;
  returnUrl: string;
  subscriptions$ = new Subscription();

  private secureLS = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: AuthService.SECRET_KEY,
  });

  constructor(
    private route: ActivatedRoute,
    private ssoService: SsoService,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private router: Router,
    private notiflixService: NotiflixService
  ) {
    if (this.route.snapshot.queryParams.returnUrl) {
      this.returnUrl = `${this.route.snapshot.queryParams.returnUrl}`;
    } else {
      this.returnUrl = `/home`;
      // this.returnUrl = `/flight-list`;
    }
  }

  ngOnInit(): void {
    if (this.secureLS.get(AuthService.CREDENTIAL_KEY)) {
      this.subscriptions$.add(
        this.ssoService.validateSSOSession().subscribe(
          (data: UserInfo) => {
            if (data) {
              this.authService.currentUserSubject.next(data);
              this.cryptoService.setStorageItem(
                'refreshToken',
                data.refreshToken
              );

              // console.log(data.refreshToken);

              this.subscriptions$.add(
                this.ssoService.refreshToken(data.refreshToken).subscribe(
                  (response) => {
                    if (![null, undefined].includes(response.accessToken)) {
                      this.cryptoService.setStorageItem(
                        'currentUser',
                        response.accessToken
                      );

                      this.router.navigateByUrl(this.returnUrl);
                    }
                  },
                  (error) => {
                    this.notiflixService.launchReport(
                      NOTIFLIX_STATUS.ERROR,
                      'Oops!',
                      `Session is invalid. We're taking you to the login page now.`,
                      null,
                      this
                    );
                  }
                )
              );
            }
          },
          (error) => {
            this.onOk();
          }
        )
      );
    } else {
      this.router.navigate(['/login']);
    }
  }

  onOk(): void {
    this.authService.removeCredentials();
  }
}
