import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  isLoading = false;
  env: any;

  constructor(private router: Router, private authService: AuthService) {
    this.env = environment;
  }

  ngOnInit(): void {}

  caabClick(): void {
    window.location.href = `${environment.CAAB_url}`;
  }

  logoClick(): void {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/home'], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['/login']);
    }
  }
}
