import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { FileElement } from '@app/core/models/file-element';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { MoveDialogComponent } from './modals/move-dialog/move-dialog.component';
import { NewFolderDialogComponent } from './modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from './modals/rename-dialog/rename-dialog.component';
import { UploadFileDialogComponent } from './modals/upload-file-dialog/upload-file-dialog.component';
// import { log } from 'console';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss'],
})
export class FileExplorerComponent implements OnInit, OnChanges {
  @Input() fileElements: FileElement[];
  @Input() canNavigateUp: boolean;
  @Input() path: string;
  @Input() root: FileElement;
  @Input() isAdmin: boolean;
  @Input() maxLength: string;

  @Output() folderAdded = new EventEmitter<{
    name: string;
    path: string;
  }>();
  @Output() elementRemoved = new EventEmitter<{
    name: string;
    path: string;
    id: string;
  }>();
  @Output() elementRenamed = new EventEmitter<{
    name: string;
    newName: string;
    path: string;
    id: string;
  }>();
  @Output() fileRemoved = new EventEmitter<{
    name: string;
    path: string;
    id: string;
  }>();
  @Output() fileDownload = new EventEmitter<{
    name: string;
    path: string;
    id: string;
  }>();
  @Output() fileUpload = new EventEmitter<{
    reload: boolean;
    path: string;
  }>();
  @Output() fileView = new EventEmitter<{
    name: string;
    path: string;
    id: string;
  }>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();
  @Output() elementMoved = new EventEmitter<{
    id: string;
    directory: string;
  }>();
  displayedColumns: string[] = ['name', 'menu', 'dateModified', 'type'];
  currentTransaction: string;
  selectedElement: FileElement;
  displayPath: string;

  constructor(public dialog: MatDialog, private notiflix: NotiflixService) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  deleteElement(element: FileElement): void {
    this.currentTransaction = element.isFolder
      ? 'DELETE_FOLDER'
      : 'DELETE_FILE';
    this.selectedElement = element;
    this.notiflix.launchConfirm(
      'Delete Folder',
      `Are you sure you want to delete this ${
        element.isFolder ? 'folder' : 'file'
      } ?`,
      this
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'DELETE_FOLDER':
        this.elementRemoved.emit({
          name: this.selectedElement.name,
          path: this.path ? `/${this.path}` : '',
          id: this.selectedElement.id,
        });
        break;
      case 'DELETE_FILE':
        this.fileRemoved.emit({
          name: this.selectedElement.name,
          path: this.path ? `/${this.path}` : '',
          id: this.selectedElement.id,
        });
        break;
      default:
        break;
    }
  }

  downloadFile(element: FileElement): void {
    this.fileDownload.emit({
      name: element.name,
      path: this.path ? `/${this.path}` : '',
      id: element.id,
    });
  }

  viewFile(element: FileElement): void {
    if (!element.isFolder) {
      this.fileView.emit({
        name: element.name,
        path: this.path ? `/${this.path}` : '',
        id: element.id,
      });
    }
  }

  onNegative(): void {}

  navigate(element: FileElement): void {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    }
  }

  navigateUp(): void {
    this.navigatedUp.emit();
  }

  openNewFolderDialog(): void {
    const dialogRef = this.dialog.open(NewFolderDialogComponent, {
      width: '400px',
      height: '200px',
      disableClose: true,
    });

    dialogRef.componentInstance.parentElement = this.root;
    dialogRef.componentInstance.maxLength = this.maxLength;

    dialogRef.afterClosed().subscribe((res) => {
      if (dialogRef.componentInstance.isValid) {
        this.folderAdded.emit({
          name: dialogRef.componentInstance.folderName
            .replace(/ {2,}/g, ' ')
            .trim(),
          path: this.path ? `/${this.path}` : '',
        });
      }
    });
  }

  openRenameDialog(element: FileElement): void {
    const dialogRef = this.dialog.open(RenameDialogComponent, {
      width: '400px',
      height: '200px',
      disableClose: true,
    });

    dialogRef.componentInstance.element = element;
    dialogRef.componentInstance.parentElement = this.root;
    dialogRef.componentInstance.maxLength = this.maxLength;

    const prevName = element.name;

    dialogRef.afterClosed().subscribe((res) => {
      if (dialogRef.componentInstance.isValid) {
        this.elementRenamed.emit({
          name: prevName.trim(),
          newName: dialogRef.componentInstance.folderName
            .replace(/ {2,}/g, ' ')
            .trim(),
          path: this.path ? `/${this.path}` : '',
          id: element.id,
        });
      }
    });
  }

  openUploadDialog(): void {
    const dialogRef = this.dialog.open(UploadFileDialogComponent, {
      width: '600px',
      disableClose: true,
    });

    dialogRef.componentInstance.path = this.path ? `/${this.path}` : '/';

    dialogRef.afterClosed().subscribe((res: any) => {
      if (dialogRef.componentInstance.triggerReload) {
        this.fileUpload.emit({
          reload: dialogRef.componentInstance.triggerReload,
          path: this.path ? `/${this.path}` : '',
        });
      }
    });
  }

  openMoveDialog(element: FileElement): void {
    const dialogRef = this.dialog.open(MoveDialogComponent, {
      width: '600px',
      disableClose: true,
    });

    dialogRef.componentInstance.element = element;
    dialogRef.componentInstance.currentPath = this.path;

    dialogRef.afterClosed().subscribe((res: any) => {
      if (dialogRef.componentInstance.isMoved) {
        this.elementMoved.emit({
          id: element.id,
          directory: dialogRef.componentInstance.path.substring(1),
        });
      }
    });
  }

  openMenu(
    event: MouseEvent,
    viewChild: MatMenuTrigger,
    isFolder: boolean
  ): void {
    event.preventDefault();
    if (!this.isAdmin && !isFolder) {
      viewChild.openMenu();
    }
    if (this.isAdmin) {
      viewChild.openMenu();
    }
  }

  getColor(fileType: string): string {
    switch (fileType) {
      case '.pdf':
        return '#79314f';
      case '.docx':
      case '.txt':
        return '#03657c';
      case '.xls':
      case '.xlsx':
        return '#11523d';
      case '.bmp':
        return '#40717f';
      default:
        return '#646e78';
    }
  }

  getIcon(fileType: string): string {
    switch (fileType) {
      case '.pdf':
        return 'picture_as_pdf';
      case '.docx':
      case '.txt':
        return 'description';
      case '.xls':
      case '.xlsx':
        return 'table_chart';
      case '.bmp':
      case '.jpeg':
      case '.jpg':
      case '.png':
      case '.svg':
      case '.webp':
        return 'image';
      default:
        return 'insert_drive_file';
    }
  }

  getFileType(fileType: string): string {
    switch (fileType) {
      case '.pdf':
        return 'PDF File';
      case '.docx':
      case '.doc':
        return 'Word File';
      case '.txt':
        return 'Text File';
      case '.xls':
      case '.xlsx':
        return 'Spreadsheet File';
      case '.bmp':
      case '.jpeg':
      case '.jpg':
      case '.png':
      case '.svg':
      case '.webp':
        return 'Image File';
      default:
        return 'File';
    }
  }

  backTo(path: string): string {
    if (path) {
      const temp = path.split('/');
      temp.pop();
      temp.pop();
      return temp.length === 0 ? 'root' : temp.join('/');
    }
    return path;
  }

  formatPath(path: string): string {
    if (path) {
      const temp = path.split('/');
      temp.pop();
      return temp.join(' > ');
    }

    return path;
  }
}
