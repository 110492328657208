export const REGEX = {
  password:
    /^((?=.*[a-z])(?=.*[A-Z])(?=.*[\!\@\#\$\%\&\*\_\+\-\=\{\}\|\\\;\<\>\?\,\.\/])|(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\!\@\#\$\%\&\*\_\+\-\=\{\}\|\\\;\<\>\?\,\.\/])|(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\&\*\_\+\-\=\{\}\|\\\;\<\>\?\,\.\/])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\!\@\#\$\%\&\*\_\+\-\=\{\}\|\\\;\<\>\?\,\.\/])).*$/,
  uppercase: /(?=.*[A-Z])/,
  numeric: /(?=.*[0-9])/,
  punctuation: /(?=.*[!@#\$%\^&\*])/,
  contactNumber: /^(09|\+639)\d{9}$|^(0|\+63)\d{8,9}$/,
  date: /^\d{2}\/\d{2}\/\d{4}$/,
  filename: /\.[^.$]+$/,
  whitespace: /\s/g,
  code: /^\S*$/,
  specialCharacters: /^[a-zA-Z0-9]*$/,
  alphanumeric: /^([^\W\0-9\uD800-\uDBFF\uDC00-\uDFFF]|[\s.-])+$/,
  numericWithDash: /(^[\d-]+$)/,
  strictNumeric: /(^[\d]+$)/,
  amountWithTwoDecimal: /(?!^0*$)(?!^0*\.0*$)^\d{0,8}(\.\d{1,2})?$/,
  amountWithThreeDecimal: /(?!^0*$)(?!^0*\.0*$)^\d{0,8}(\.\d{1,3})?$/,
  contactNumberWithFiftyChar: /^(09|\+639)\d{9}$|^(0|\+63)\d{8,49}$/,
  commaDelimitedList: /^[a-z0-9A-Z]*[-\w\s]+(?:,[-\w\s]*[a-z0-9A-Z])*$/,
  alpha: /^[a-zA-Z]+$/,
  numbers: /[0-9]/g,
  numberOnly: /^[0-9]*$/,
  specialChars: /[^a-zA-Z ]/g,
  nonNumeric: /\D/g,
  name: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  mobileNumber: /^[\+\(\) \-0-9]+$/,
};
