import { createAction, props } from '@ngrx/store';

export const getFlightPlan = createAction(
  '[Flight Plan Component] Get Flight Plan'
);

export const getFlightPlanSuccess = createAction(
  '[Flight Plan Component] Get Flight Plan Success',
  props<{ response: any }>()
);

export const getFlightPlanFailure = createAction(
  '[Flight Plan Component] Get Flight Plan Failure',
  props<{ error: any }>()
);

export const createFlightPlan = createAction(
  '[Flight Plan Component] Create Flight Plan',
  props<{ payload: any }>()
);

export const createFlightPlanSuccess = createAction(
  '[Flight Plan Component] Create Flight Plan Success',
  props<{ response: any }>()
);

export const createFlightPlanFailure = createAction(
  '[Flight Plan Component] Create Flight Plan Failure',
  props<{ error: any }>()
);
export const getFlightPlanDetails = createAction(
  '[Flight Plan Component] Get Flight Plan Details',
  props<{ id: number }>()
);

export const getFlightPlanDetailsSuccess = createAction(
  '[Flight Plan Component] Get Flight Plan Details Success',
  props<{ response: any }>()
);

export const getFlightPlanDetailsFailure = createAction(
  '[Flight Plan Component] Get Flight Plan Details Failure',
  props<{ error: any }>()
);

export const getFeesPaymentBreakdown = createAction(
  '[Fees Payment Breakdown Component] Get Fees Payment Breakdown'
);

export const getFeesPaymentBreakdownSuccess = createAction(
  '[Fees Payment Breakdown Component] Get Fees Payment Breakdown Success',
  props<{ response: any }>()
);

export const getFeesPaymentBreakdownFailure = createAction(
  '[Fees Payment Breakdown Component] Get Fees Payment Breakdown Failure',
  props<{ error: any }>()
);

export const getDownloadFees = createAction(
  '[Fees Payment Breakdown Component] Get Download Fees Payment Breakdown'
);

export const getDownloadFeesSuccess = createAction(
  '[Fees Payment Breakdown Component] Get Download Fees Payment Breakdown Success',
  props<{ response: any }>()
);

export const getDownloadFeesFailure = createAction(
  '[Fees Payment Breakdown Component] Get Download Fees Payment Breakdown Failure',
  props<{ error: any }>()
);

export const getRegisteredUsers = createAction(
  '[Registered Users Component] Get Registered Users'
);

export const getRegisteredUsersSuccess = createAction(
  '[Registered Users Component] Get Registered Users Success',
  props<{ response: any }>()
);

export const getRegisteredUsersFailure = createAction(
  '[Registered Users Component] Get Registered Users Failure',
  props<{ error: any }>()
);

export const resetUserDetails = createAction(
  '[Registered Users Component] Reset User Details'
);

export const getRegisteredUserDetails = createAction(
  '[Registered User Component] Get Registered User Details',
  props<{ id: number }>()
);

export const getRegisteredUserDetailsSuccess = createAction(
  '[Registered User Component] Get Registered User Details Success',
  props<{ response: any }>()
);

export const getRegisteredUserDetailsFailure = createAction(
  '[Registered User Component] Get Registered User Details Failure',
  props<{ error: any }>()
);
