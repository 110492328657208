<div mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="space-between" class="header-title">
    <h5 class="text-haze-blue">Upload Files</h5>
    <button mat-icon-button color="primary" (click)="close()">
      <mat-icon> close </mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <form [formGroup]="uploadFileForm">
    <div class="form-field-wrapper">
      <div class="input-wrapper">
        <div
          class="col drag-file hover"
          [ngClass]="{ 'error-input': uploadFileForm.get('files').touched }"
        >
          <input
            type="file"
            nbInput
            required
            fullWidth
            #inputFile
            id="uploadFile"
            class="upload-guideline"
            multiple
            accept="{{ validTypes?.value }}"
            (change)="onChangeFile($event)"
          />
          <div class="align-center">
            <div class="pdf-icon-wrapper">
              <img
                class="image-responsive"
                src="../../../../../../assets/images/document.png"
              />
            </div>
            <h5>Select files or drop your files here</h5>
            <p class="upload-file-note">
              Allowed types: {{ formatValidTypes() | uppercase }} files
            </p>
          </div>
          <div class="align-center btn-select">
            <button
              mat-raised-button
              color="primary"
              (click)="inputFile.click()"
            >
              Select Files
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        mat-raised-button
        [disabled]="
          messages?.length === selectedFiles?.length &&
          isUploading &&
          retryFiles?.length <= 0
        "
        (click)="close()"
      >
        Cancel
      </button>
      &nbsp;
      <button
        mat-raised-button
        color="primary"
        [disabled]="selectedFiles?.length <= 0 || disableUpload"
        (click)="uploadFiles()"
      >
        Upload
      </button>
    </div>
    <div class="progress-loader">
      <ng-container *ngIf="isUploading; else uploadList">
        <ng-container *ngFor="let progress of progressDetails; let i = index">
          <div class="row">
            <div class="col-10">
              <span class="{{ messages[i]?.status }}">{{
                messages[i]?.message ? messages[i]?.message : progress.fileName
              }}</span>
              <nb-progress-bar
                [value]="progress.value"
                [displayValue]="true"
                status="info"
              ></nb-progress-bar>
            </div>
            <div class="col-2">
              <div class="re-upload">
                <button
                  *ngIf="
                    messages[i]?.status === 'error' && messages[i]?.duplicate
                  "
                  mat-mini-fab
                  color="primary"
                  class="pointer"
                  matTooltip="Retry Upload"
                  matTooltipClass="tooltipClass"
                  matTooltipPosition="above"
                  (click)="retryUpload(i)"
                >
                  <mat-icon> replay </mat-icon>
                </button>
                <span
                  *ngIf="messages[i]?.status === 'success'"
                  class="success-upload"
                >
                  <mat-icon class="success-icon"> done </mat-icon>
                </span>
              </div>
            </div>
          </div>
          <hr />
        </ng-container>
      </ng-container>
      <ng-template #uploadList>
        <ng-container *ngIf="selectedFiles?.length > 0">
          <p class="neo-sans-medium text-haze-blue">Files to be uploaded:</p>
          <ul *ngFor="let file of selectedFiles">
            <li>{{ file.name }}</li>
          </ul>
        </ng-container>
      </ng-template>
    </div>
  </form>
</div>
<div mat-dialog-actions align="center">
  <div
    class=""
    *ngIf="messages?.length === selectedFiles?.length && isUploading"
  >
    <button
      mat-raised-button
      color="primary"
      *ngIf="retryFiles?.length <= 0"
      (click)="close()"
    >
      OK
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="retryFiles?.length > 1"
      [disabled]="disableRetryAll"
      (click)="retryAllUpload()"
    >
      Replace All
    </button>
  </div>
</div>
