
    <nb-card>
      <nb-card-header></nb-card-header>
      <nb-card-body style="min-height: 517.1px">
        <form [formGroup]="maintenanceTableForm" autocomplete="off">
          <mat-table matSort [dataSource]="dataSource">
            <div
              *ngFor="let row; let column; of: headers; let colIndex = index"
              [matColumnDef]="column.dataSourceKey"
            >
              <!-- ACTIONS COLUMN -->
              <ng-container
                *ngIf="
                  withActionsColumn &&
                    colIndex === displayedColumns?.length - 1;
                  else generic
                "
              >
                <mat-header-cell
                  class="text-white"
                  mat-sort-header
                  *matHeaderCellDef
                >
                  {{ column.label }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let rowIndex = index">
                  <!-- CREATE/EDIT STATE ACTIONS -->
                  <button
                    (click)="onCancel()"
                    *ngIf="
                      (enabledCreateRow && rowIndex === 0) ||
                      rowIndex === enabledEditRowIndex
                    "
                    mat-icon-button
                    matTooltip="Click to Cancel"
                    class="iconbutton"
                    color="primary"
                  >
                    <mat-icon>{{ buttons.CANCEL | lowercase }}</mat-icon>
                  </button>
                  <button
                    (click)="onSave()"
                    *ngIf="
                      (enabledCreateRow && rowIndex === 0) ||
                      rowIndex === enabledEditRowIndex
                    "
                    mat-icon-button
                    matTooltip="Click to Save"
                    class="iconbutton"
                    color="primary"
                    [disabled]="
                      (initialValues | json) ===
                      (maintenanceTableForm.value[rowIndex] | json)
                    "
                  >
                    <mat-icon>{{ buttons.SAVE | lowercase }}</mat-icon>
                  </button>

                  <!-- GENERIC STATE ACTIONS -->
                  <button
                    (click)="onEdit(rowIndex)"
                    *ngIf="
                      !(
                        (enabledCreateRow && rowIndex === 0) ||
                        rowIndex === enabledEditRowIndex
                      )
                    "
                    mat-icon-button
                    matTooltip="Click to Edit"
                    class="iconbutton"
                    color="primary"
                    [disabled]="enabledCreateRow"
                  >
                    <mat-icon>{{ buttons.EDIT | lowercase }}</mat-icon>
                  </button>
                  <button
                    (click)="onDelete(rowIndex)"
                    *ngIf="
                      !(
                        (enabledCreateRow && rowIndex === 0) ||
                        rowIndex === enabledEditRowIndex
                      )
                    "
                    mat-icon-button
                    matTooltip="Click to Delete"
                    class="iconbutton"
                    color="warn"
                    [disabled]="isEditing || enabledCreateRow"
                  >
                    <mat-icon>{{ buttons.DELETE | lowercase }}</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <!-- DATA COLUMN -->
              <ng-template #generic>
                <mat-header-cell
                  class="text-white"
                  *matHeaderCellDef
                  mat-sort-header
                >
                  {{ column.label }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let rowIndex = index">
                  <ng-template
                    *ngIf="
                      enabledCreateRow && rowIndex === 0;
                      then createBlock;
                      else genericBlock
                    "
                  >
                  </ng-template>

                  <!-- CREATE ROW -->
                  <ng-template #createBlock>
                    <div>
                      <mat-form-field
                        [formGroup]="maintenanceTableForm.controls[rowIndex]"
                      >
                        <input
                          matInput
                          type="text"
                          [formControlName]="column.dataSourceKey"
                        />
                      </mat-form-field>
                    </div>
                  </ng-template>

                  <!-- GENERIC ROW -->
                  <ng-template #genericBlock>
                    <ng-template
                      *ngIf="
                        rowIndex === enabledEditRowIndex;
                        then editBlock;
                        else readOnlyBlock
                      "
                    >
                    </ng-template>

                    <!-- EDIT CELLS -->
                    <ng-template #editBlock>
                      <div>
                        <mat-form-field
                          [formGroup]="maintenanceTableForm.controls[rowIndex]"
                        >
                          <input
                            matInput
                            type="text"
                            [formControlName]="column.dataSourceKey"
                          />
                        </mat-form-field>
                      </div>
                    </ng-template>

                    <!-- READ-ONLY CELLS -->
                    <ng-template #readOnlyBlock>
                      <div
                        matTooltip="{{ element[column.dataSourceKey] }}"
                        matTooltipPosition="left"
                      >
                        {{ element[column.dataSourceKey] }}
                      </div>
                    </ng-template>
                  </ng-template>
                </mat-cell>
              </ng-template>
            </div>

            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
              class="header-amazon-blue"
            >
            </mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="clickable hover-color"
              (click)="onRowClick(row)"
            ></mat-row>
          </mat-table>

          <div class="no-item center" *ngIf="dataSource.data.length === 0">
            No record found!
          </div>

          <div class="paginator-container">
            <app-paginator-custom-navigate-page
              #paginator
              [length]="totalRecords"
              [pageSize]="10"
              [pageIndex]="0"
              [pageSizeOptions]="[5, 10, 20]"
              (page)="onPageEvent()"
            >
            </app-paginator-custom-navigate-page>
          </div>
        </form>
      </nb-card-body>
    </nb-card>