import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  registeredUserFeatureKey,
  RegisteredUserState,
} from './registered-user.reducer';
import { CurrentUser, RegisteredUsers } from './registered-user.model';

export const selectRegisteredUserFeature =
  createFeatureSelector<RegisteredUserState>(registeredUserFeatureKey);

export const selectRegisteredUserResponse = createSelector(
  selectRegisteredUserFeature,
  (state: RegisteredUserState) => {
    return state.currentUser;
  }
);

export const selectAircraftIdNumber = createSelector(
  selectRegisteredUserFeature,
  (state: RegisteredUserState) => {
    return state.editAircraft;
  }
);
