<div class="file-manager">
    <div class="row">
        <div class="col-md-12">
            <nb-card class="card-wrapper">
                <nb-card-body>
                    <div class="row">
                        <div class="col-md-12">
                            <app-file-explorer-notam [fileElements]="fileElements | async" [path]="currentPath"
                                [root]="currentRoot" [canNavigateUp]="canNavigateUp" [isAdmin]="isAdmin"
                                [maxLength]="maxLength" (folderAdded)="addFolder($event)"
                                (elementRemoved)="removeElement($event)" (navigatedDown)="navigateToFolder($event)"
                                (navigatedUp)="navigateUp()" (elementRenamed)="renameElement($event)"
                                (elementEdit)="editPdf($event)"
                                (searchForm)="handleSearchForm($event)"
                                (elementMoved)="moveElement($event)" (fileRemoved)="removeFile($event)"
                                (fileDownload)="downloadFile($event)" (fileUpload)="uploadFile($event)"
                                (fileView)="viewFile($event)">
                            </app-file-explorer-notam>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>