<div *ngIf="!tableContents.noGenericSearch || component" class="row pb-20">
  <div class="col-md-4">
    <input
      [hidden]="tableContents.noGenericSearch"
      nbInput
      fullWidth
      placeholder="Search"
      (keyup)="applyFilter($event.target.value)"
    />
  </div>
  <div class="col-md-8">
    <div *ngIf="component" class="float-right">
      <ng-container *ngIf="component.hasBatchUploadBtn">
        <button
          nbButton
          status="info"
          (click)="
            launchAddModal(
              component.bacthUploadDialogTitle,
              component.batchUploadModalName,
              component.isSubmit
            )
          "
        >
          {{ component.batchUploadBtnName }}
        </button>
        &nbsp;
      </ng-container>
      <button
        *ngIf="component.hasAddBtnNextPage"
        nbButton
        status="success"
        [routerLink]="[component.addNextPageLink]"
      >
        {{ component.addBtnNextPageName }}
      </button>
      <button
        *ngIf="component.hasAddBtn"
        nbButton
        status="success"
        (click)="launchAddModal(component.dialogTitle, component.modalName)"
      >
        {{ component.addBtnName }}
      </button>
      <button
        [disabled]="this.displayedData.filteredData.length <= 0"
        *ngIf="component.hasExportBtn"
        nbButton
        status="info"
        type="button"
        matTooltip="Export Report"
        matTooltipPosition="above"
        (click)="handleExportBtn()"
      >
        <nb-icon icon="download"></nb-icon>
      </button>
    </div>
  </div>
</div>
<div class="scrollable material-table-container">
  <table
    [hidden]="loadingIndicator"
    mat-table
    [dataSource]="displayedData"
    matSort
    multiTemplateDataRows
    #matTable
  >
    <ng-container
      matColumnDef="{{ column.prop }}"
      *ngFor="let column of displayedColumns"
    >
      <ng-container *ngIf="column.prop !== 'actions'">
        <th
          [ngStyle]="{
            'max-width': column.maxWidth ? column.maxWidth : null,
            'min-width': column.minWidth ? column.minWidth : null,
            'text-align': column.alignment ? column.alignment : null
          }"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          <span>{{ column.label }}</span>
        </th>
      </ng-container>
      <ng-container *ngIf="column.prop === 'actions'">
        <th
          [ngStyle]="{
            'max-width': column.maxWidth ? column.maxWidth : null,
            'min-width': column.minWidth ? column.minWidth : null,
            'text-align': column.alignment ? column.alignment : null
          }"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>{{ column.label }}</span>
        </th>
      </ng-container>
      <td
        [ngStyle]="{
          'max-width': column.maxWidth ? column.maxWidth : null,
          'min-width': column.minWidth ? column.minWidth : null,
          'text-align': column.alignment ? column.alignment : null,
          width: column.defWidth ? column.defWidth : null
        }"
        mat-cell
        *matCellDef="let data"
      >
        <span *ngIf="column.prop === 'actions'; else dataCell">
          <!--
            ///////////////////////////////////////////////////////////////
           //                      CUSTOM ACTIONS                       //
          ///////////////////////////////////////////////////////////////
          -->
          <ng-container>
            <span
              *ngIf="viewFlightDetails"
              class="action text-haze-blue"
              (click)="onViewDetails(data)"
              matTooltip="View Details"
              matTooltipPosition="left"
            >
              <i class="material-icons"> remove_red_eye </i>
            </span>
          </ng-container>
          &nbsp;
          <ng-container>
            <span
              *ngIf="copyFlight"
              class="action text-haze-blue"
              (click)="onCopyFlight(data)"
              matTooltip="Register new flight plan based on this one"
              matTooltipPosition="left"
            >
              <i class="material-icons"> app_registration </i>
            </span>
            &nbsp;
            <span
              *ngIf="data['canSendVfrMail'] && sendVfrMail"
              class="action text-haze-blue"
              (click)="onSendVfrMail(data)"
              matTooltip="Close Flight Plan"
              matTooltipPosition="left"
            >
              <i class="material-icons"> send </i>
            </span>
            <span *ngIf="!data['canSendVfrMail'] && sendVfrMail">
              &nbsp; &nbsp; &nbsp;
            </span>
          </ng-container>
          <ng-container>
            <span
              *ngIf="editButton"
              class="action text-haze-blue"
              (click)="onEdit(data)"
              matTooltip="Edit Details"
              matTooltipPosition="left"
            >
              <i class="material-icons"> edit </i>
            </span>
          </ng-container>
          &nbsp;
          <ng-container>
            <span
              *ngIf="deleteButton"
              class="action text-haze-blue"
              (click)="onDelete(data)"
              matTooltip="Delete Data"
              matTooltipPosition="left"
            >
              <i class="material-icons"> delete </i>
            </span>
          </ng-container>
          <ng-container>
            <span
              *ngIf="viewUserDetails"
              class="action text-haze-blue"
              (click)="onViewUserDetails(data)"
              matTooltip="View User Details"
              matTooltipPosition="left"
            >
              <i class="material-icons"> remove_red_eye </i>
            </span>
          </ng-container>
        </span>
        <ng-template #dataCell>
          <ng-container *ngIf="column.isLink; else notLink">
            <a
              class="table-link"
              [routerLink]="[
                column.alternateLinkCondition &&
                column.alternateLinkCondition.toUpperCase() ===
                  data[column.checkConditionProp].toUpperCase()
                  ? tableContents.alternateLink
                  : tableContents.tableLink,
                data.variableBonusId,
                data.gmcYear
              ]"
              >{{ data[column.prop] }}</a
            >
          </ng-container>
          <ng-template #notLink>
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="column.type === 'date'">
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] | date: 'dd/MM/yyyy' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'time'">
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'string'">
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || 'None' | titlecase }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'code'">
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || 'None' | uppercase }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'float'">
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || '0.00' | number: '1.2-2' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'number'">
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || '0' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'passenger'">
                <div class="passenger-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || 'N/A' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'id'">
                <div class="user-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || '0' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'stringLeftAlign'">
                <div class="user-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || 'None' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="column.type === 'type'">
                <div class="user-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data.systemParameterType['name'] || 'None' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="flight-data">
                  <span
                    matTooltip="{{ data[column.prop] }}"
                    matTooltipPosition="left"
                    matTooltipClass="break"
                  >
                    {{ data[column.prop] || 'None' }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
            <!-- <span
              matTooltip="{{ data[column.prop] }}"
              matTooltipPosition="left"
              matTooltipClass="break"
              >{{ data[column.prop] }}</span
            > -->
          </ng-template>
        </ng-template>
      </td>
    </ng-container>
    <ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="getColumnHeaders(); sticky: true"
      ></tr>
      <ng-container *ngIf="tableContents.isRowClickable; else rowNotClickable">
        <tr
          mat-row
          *matRowDef="let row; columns: getColumnHeaders()"
          class="detail-row clickable hover-color"
          (click)="onRowClick(row)"
        ></tr>
      </ng-container>
      <ng-template #rowNotClickable>
        <tr
          mat-row
          *matRowDef="let row; columns: getColumnHeaders()"
          class="detail-row"
        ></tr>
      </ng-template>
    </ng-container>
    <ng-template #noRecords>
      <div class="align-center ptb-10">No records found</div>
    </ng-template>
  </table>
  <div *ngIf="displayNoRecords" class="align-center ptb-10">
    No records found
  </div>
</div>
<mat-paginator
  color="primary"
  #paginator="matPaginator"
  [pageSizeOptions]="[10, 20, 30]"
  showFirstLastButtons
></mat-paginator>
