import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIRM_MESSAGES } from '@app/core/constants/confirm-messages';
import { MenuItem } from '@core/models/menu-item';
import { DataService } from '../services/data.service';
import { NotiflixService } from '../services/notiflix.service';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemsComponent implements OnInit {
  @Input() menuItems: Array<MenuItem>;
  link: string;

  constructor(
    private router: Router,
    private dataService: DataService,
    private notiflixService: NotiflixService
  ) {}

  ngOnInit(): void {}

  routeTo(link: string): void {
    this.link = link;
    if (!this.dataService.getFormChange()) {
      this.redirect();
    } else {
      this.notiflixService.launchConfirm(
        CONFIRM_MESSAGES.DISCARD_CHANGES.title,
        CONFIRM_MESSAGES.DISCARD_CHANGES.body,
        this
      );
    }
  }

  onPositive(): void {
    this.redirect();
  }

  onNegative(): void {}

  redirect(): void {
    this.dataService.setFormChange(false);
    this.router.navigate([this.link]);
  }
}
