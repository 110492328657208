import { createReducer, on } from '@ngrx/store';
import { RegisteredUser } from '../registered-user-by-id/registered-user.model';
import {
  getFlightPlan,
  getFlightPlanDetails,
  getFlightPlanDetailsFailure,
  getFlightPlanDetailsSuccess,
  getFlightPlanFailure,
  getFlightPlanSuccess,
  getFeesPaymentBreakdown,
  getFeesPaymentBreakdownFailure,
  getFeesPaymentBreakdownSuccess,
  getDownloadFees,
  getDownloadFeesFailure,
  getDownloadFeesSuccess,
  getRegisteredUsers,
  getRegisteredUsersSuccess,
  getRegisteredUsersFailure,
  resetUserDetails,
  getRegisteredUserDetails,
  getRegisteredUserDetailsSuccess,
  getRegisteredUserDetailsFailure,
} from './flight-plan.action';
import {
  FlightPlan,
  FlightPlanDetails,
  FeesPaymentBreakdown,
  DownloadFees,
  RegisteredUsers,
  RegisteredUserDetails,
} from './flight-plan.model';

export const flightPlanFeatureKey = 'flightPlan';

export interface FlightPlanState {
  flightPlan: FlightPlan[];
  flightPlanDetails: FlightPlanDetails;
  feesPaymentBreakdown: FeesPaymentBreakdown[];
  downloadFees: DownloadFees[];
  registeredUsers: RegisteredUsers[];
  userDetails: RegisteredUserDetails;
  registeredUserDetails: RegisteredUserDetails;

  error?: any;
}

export const initialState: FlightPlanState = {
  flightPlan: undefined,
  flightPlanDetails: undefined,
  feesPaymentBreakdown: undefined,
  downloadFees: undefined,
  registeredUsers: undefined,
  userDetails: undefined,
  registeredUserDetails: undefined,
};

export const reducer = createReducer(
  initialState,

  on(getFlightPlan, (state) => ({ ...state })),
  on(getFlightPlanSuccess, (state, action) => ({
    ...state,
    flightPlan: action.response,
  })),
  on(getFlightPlanFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(getFlightPlanDetails, (state) => ({ ...state })),
  on(getFlightPlanDetailsSuccess, (state, action) => ({
    ...state,
    flightPlanDetails: action.response,
  })),
  on(getFlightPlanDetailsFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(getFeesPaymentBreakdown, (state) => ({ ...state })),
  on(getFeesPaymentBreakdownSuccess, (state, action) => ({
    ...state,
    feesPaymentBreakdown: action.response,
  })),
  on(getFeesPaymentBreakdownFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(getDownloadFees, (state) => ({ ...state })),
  on(getDownloadFeesSuccess, (state, action) => ({
    ...state,
    downloadFees: action.response,
  })),
  on(getDownloadFeesFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(getRegisteredUsers, (state) => ({ ...state })),
  on(getRegisteredUsersSuccess, (state, action) => ({
    ...state,
    registeredUsers: action.response,
  })),
  on(getRegisteredUsersFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(resetUserDetails, (state) => ({
    ...state,
    userDetails: initialState.userDetails,
  })),
  on(getRegisteredUserDetails, (state) => ({ ...state })),
  on(getRegisteredUserDetailsSuccess, (state, action) => ({
    ...state,
    registeredUserDetails: action.response,
  })),
  on(getRegisteredUserDetailsFailure, (state, action) => ({
    ...state,
    error: action.error,
  }))
);
