<nb-card status="info" accent="warning">
    <nb-card-header>
        <h4 class="text-white">{{ dialogTitle }}</h4>
    </nb-card-header>
    <nb-card-body>
        <div class="scrollable modals-container">
            <!-- 
                  ///////////////////////////////////////////////////////////////
                 //                      MODAL DETAILS                        //
                ///////////////////////////////////////////////////////////////
                Create a component and put it in the partials folder for 
                your modal content then import your component enclosed
                in an ng-container with *ngIf for checking your modal name
            -->
            <!-- Sample modal definition -->
            <ng-container *ngIf="modalName === 'sample-modal'">
                <app-sample-modal></app-sample-modal>
            </ng-container>
            <!-- END OF Sample modal definition -->
    
            <!-- Unit Modal definition -->
            <ng-container *ngIf="modalName === 'unit-modal'" #newUnit>
                <app-unit-modal></app-unit-modal>
            </ng-container>
            <!-- END OF Unit Modal definition -->
            
             <!-- Markets Modal definition -->
             <ng-container *ngIf="modalName === 'markets-modal'">
                <app-markets-modal></app-markets-modal>
            </ng-container>
            <!-- END OF Markets Modal definition -->
    
            <!-- User Modal definition -->
            <ng-container *ngIf="modalName === 'user-modal'">
                <app-user-modal [modalComponent]="modalComponent" #newUser></app-user-modal>
            </ng-container>
            <!-- END OF User Modal definition -->
    
            <!-- Form Part Modal definition -->
            <ng-container *ngIf="modalName === 'form-part-modal'">
                <app-form-part-modal></app-form-part-modal>
            </ng-container>
            <!-- END OF Form Part Modal definition -->
    
            <!-- Document Evaluatee Modal definition -->
            <ng-container *ngIf="modalName === 'evaluatee-modal'">
                <app-evaluatee-modal [modalComponent]="modalComponent"></app-evaluatee-modal>
            </ng-container>
            <!-- END OF Document Evaluatee Modal definition -->
            
            <!-- Rating Scales Modal definition -->
            <ng-container *ngIf="modalName === 'rating-scales-modal'">
                <app-rating-scales-modal></app-rating-scales-modal>
            </ng-container>
            <!-- END OF Rating Scales definition -->

            <!-- Batch Upload Evaluatee Modal definition -->
            <ng-container *ngIf="modalName === 'batch-upload-evaluatee-modal'">
                <app-batch-upload-evaluatee-modal></app-batch-upload-evaluatee-modal>
            </ng-container>
            <!-- END OF Batch Upload Evaluatee definition -->

            <!--Send Evaluation Modal definition -->
            <ng-container *ngIf="modalName === 'send-evaluation-modal'">
                <app-send-evaluation-modal></app-send-evaluation-modal>
            </ng-container>
            <!-- END OFSend Evaluation definition -->
            
            <!-- This handles if no modal name was passed -->
            <ng-container *ngIf="modalName === '' || modalName === null">
                <div class="align-center">
                    <h3 class="text-garnet-transports">Error: no modal content was defined</h3>
                </div>
            </ng-container>
            <!-- End of Handler -->
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="warning" class="float-left" (click)="dialogRef.close()">Close</button>
        <!-- <button nbButton status="success" class="float-right" (click)="handleSubmit(modalName)">{{ isSubmit ? 'Submit' : submitBtnName }}</button> -->
    </nb-card-footer>
</nb-card>
