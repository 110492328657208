import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { HttpService } from '../services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class FeesBreakdownService {
  BACKEND_ENDPOINT = environment;
  API_ROUTES = API_ENPOINTS;

  constructor(private httpService: HttpService) {}

  calculateFeesBreakdown(payload): Observable<any> {
    return this.httpService.post(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.calculateFeesBreakdown}`,
      payload
    );
  }
}
