export const API = {
  getAllSystemParams: '/api/system-params/all',
  updateSystemParam: '/api/system-params/update',
  auth: '/api/auth/login',
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  forgotPW: '/api/auth/forgot-password',
  changePass: '/api/auth/change-password',
  resetPW: '/api/auth/reset-password',
  forgotPWContinue: '/api/users/forgot-pass-continue/',
  currentUserInfo: '/api/auth/current-user',
  verifyResetLink: '/api/auth/verify-reset-link',
  getEmployeeList: '/api/employees',
  generateNewAccessToken: '/api/auth/refresh-token',
  validateSSO: '/api/auth/validate',
  companyList: '/api/companies',
  deliveryUnitList: '/api/delivery-units',
  employmentProfileList: '/api/employment-profiles',
  marketList: '/api/markets',
  roleList: '/api/roles',
  applicationList: '/api/applications',
  addEmployee: '/api/employees',
  batchUpload: '/api/employees/batch-upload',
  verifyActivationLink: '/api/auth/verify-activation-link',
  activateUser: '/api/auth/activate-account',
  assignments: '/api/assignments',
  applications: '/api/applications',
  users: 'users',
  batchActivateUsers: '/api/employees/activate-users',
  editEmployee: '/api/employees',
  resendEmailActivation: '/api/employees/activate-users',
  employees: '/api/employees',
  movementsYears: '/api/movements/years',
  activeYear: '/active-year',
  movements: '/api/movements',
  oncycle: '/oncycle',
  years: '/years',
  offcycle: '/offcycle',
  post: '/post',
  employmentInfo: '/employment-info',
  separation: '/separation',
  rehireEmployees: '/api/employees',
  deactivateUsers: '/api/employees/deactivate-users',
  profile: '/api/auth/profile',
  updatePersonalInfo: '/api/auth/personal-info',
  // BAHAMAS API
  flightPlans: '/api/flight-plans',
  registeredUserById: '/api/users/:id',
  registeredUser: '/api/auth/current-user',
  registerUser: '/api/users',
};

export const INDRAURL = 'https://www.indracompany.com';
