export const ERROR_MESSAGES = {
  SWAL_SAMPLE: {
    title: 'Error',
    body: 'This is a sample error Sweetalert.',
  },
  ERROR_ENCOUNTERED: 'An error was encountered:',
  IDLE_TIMEOUT: 'You exceed set idle timeout',
  RELOGIN: 'Please relogin to continue',
  SESSION_EXPIRED: 'Session Expired',
  IDLE_MESSAGE: 'You were idle longer than expected. Please login again.',
  INVALID_FILE_TYPE: 'Invalid File Type',
  EXCEL_ONLY: 'Sorry, only excel file is allowed',
  NO_UPDATE_AVAILABLE:
    'Sorry, no available update for the current employment type.',
  GENERAL_ERROR: 'An unexpected error occurred. Please try again later.'
};
