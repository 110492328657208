import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileElement } from '@app/core/models/file-element';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree'; 
import { FileService } from '@app/core/services/file.service';

interface FolderNode {
  id: string;
  name: string;
  children?: FolderNode[];
  path: string;
}

@Component({
  selector: 'app-file-tree',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss']
})
export class FileTreeComponent implements OnInit, OnChanges {
  treeControl = new NestedTreeControl<FolderNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FolderNode>();

  @Input() currentActive: string;
  @Input() reloadDir: boolean;
  @Output() directory = new EventEmitter<{ directory: string, id: string}>();
  directories: FolderNode[];
  selected: string;

  constructor(public fileService: FileService) { }

  ngOnInit(): void {
    this.getDirectories();
  }

  getDirectories(): void {
    this.fileService.getTreeDirectory().subscribe((data: FolderNode) => {
      this.dataSource.data = data.children;
    });
  }

  goToDirectory(path, id): void {
    this.selected = id;
    const payload = {directory: path.substring(1), id};
    this.directory.emit(payload);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selected = this.currentActive;
    if (this.reloadDir === true) {
      this.getDirectories();
    }
  }

  hasChild = (_: number, node: FolderNode) => !!node.children && node.children.length > 0;

}
