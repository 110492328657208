<div class="flight-plant-container">
  <div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-header>
          <div class="text-center">
            <h5 class="text-haze-blue">Fees Payment Record</h5>
          </div>

          <div class="button-container">
            <button
              nbButton
              status="info"
              size="medium"
              (click)="fileDownload()"
              id="fileDownload"
              name="fileDownload"
            >
              Download table as CSV</button
            >&nbsp;
            <button
              nbButton
              status="info"
              size="medium"
              (click)="onNavigate('/reg-users')"
            >
              See registered users
            </button>
            <div class="settings-button">
              <button
                nbButton
                status="info"
                size="small"
                matTooltip="System Parameters"
                matTooltipClass="tooltipClass"
                matTooltipPosition="below"
                (click)="onNavigate('/system-parameters')"
              >
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>
        </nb-card-header>
        <nb-card-body>
          <app-mat-data-table
            [tableContents]="tableContents"
            [dataSource]="flightPlanList"
            [loadingIndicator]="loadingIndicator$"
            [component]="this"
            [advancedSearchKeys]="advancedSearchKeys"
            [copyFlight]="false"
            [viewUserDetails]="false"
            [editButton]="false"
            [deleteButton]="false"
            [viewFlightDetails]="true"
            (isViewDetails)="onViewDetails($event)"
          ></app-mat-data-table>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
