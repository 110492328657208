import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { environment } from '@environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { HttpService } from './http/http.service';

@Injectable({
  providedIn: 'root',
})
export class SystemParameterService {
  BACKEND_ENDPOINT = environment;
  API_ROUTES = API_ENPOINTS;
  deleteSuccess$ = new Subject();
  addUpdateSuccess$ = new Subject();

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private notiflix: NotiflixService,
    private router: Router
  ) {}

  getTypeList(): Observable<any> {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.typeList}`
    );
  }

  getSystemParameters(): Observable<any> {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}`
    );
  }

  getSystemParameterById(id): Observable<any> {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}/${id}`
    );
  }

  addSystemParameters(formData): Subscription {
    return this.http
      .post(`${environment.api}${API_ENPOINTS.systemParameters}`, formData, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: true,
      })
      .subscribe(
        (response: any) => {
          this.addUpdateSuccess$.next(response);
          this.notiflix.launchReport(
            'success',
            'Success',
            'System Parameter has been successfully added.',
            '',
            this
          );
        },
        (error) => {}
      );
  }

  editSystemParameters(id, formdata): Subscription {
    return this.http
      .patch(
        `${environment.api}${API_ENPOINTS.systemParameters}/${id}`,
        formdata,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          observe: 'response',
          withCredentials: true,
        }
      )
      .subscribe(
        (response: any) => {
          this.addUpdateSuccess$.next(response);
          this.notiflix.launchReport(
            'success',
            'Success',
            'System Parameters has been successfully updated.',
            '',
            this
          );
        },
        (error) => {
          this.notiflix.launchReport(
            'error',
            'Error',
            error.error.clientError,
            '',
            this
          );
        }
      );
  }

  deleteSystemParameter(id: number): Subscription {
    return this.http
      .delete(
        `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}/` + id
      )
      .subscribe(
        (response: any) => {
          this.deleteSuccess$.next(response);
          this.notiflix.launchReport(
            'success',
            'Success',
            'System Parameter has been successfully deleted.',
            '',
            this
          );
        },
        (error) => {}
      );
  }

  onOk(): void {
    window.location.reload();
  }
}
