import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SIZE_MULTIPLIER } from '@app/core/constants/constants.const';
import { FileService } from '@app/core/services/file.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { exit } from 'process';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent implements OnInit {
  triggerReload = false;
  uploadFileForm: FormGroup;
  validTypes: any;
  path: string;
  selectedFiles: File[] = [];
  progressDetails: any[] = [];
  messages: any[] = [];
  isUploading = false;
  disableUpload = true;
  disableRetryAll = true;
  maxFileSize: any;
  retryFiles: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<UploadFileDialogComponent>,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private notiflixService: NotiflixService
  ) {}

  ngOnInit(): void {
    this.fileService.getValidTypes().subscribe((el) => (this.validTypes = el));
    this.fileService
      .getMaxFileSize()
      .subscribe((el: any) => (this.maxFileSize = el.value));
    this.uploadFileForm = this.formBuilder.group({
      files: [null, Validators.required],
    });
  }

  onChangeFile(event): void {
    this.selectedFiles = event.target.files;
    this.progressDetails = [];
    this.messages = [];
    this.retryFiles = [];
    this.isUploading = false;
    this.disableUpload = false;
    this.disableRetryAll = false;
  }

  uploadFiles(): void {
    this.isUploading = true;
    this.disableUpload = true;

    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.progressDetails[i] = {
        value: 0,
        fileName: this.selectedFiles[i].name,
      };

      const payload = {
        path: this.path,
        file: this.selectedFiles[i],
      };

      if (
        payload.file.size <=
        this.maxFileSize * SIZE_MULTIPLIER.MB_SIZE ** 2
      ) {
        this.fileService.uploadFile(payload).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressDetails[i].value = Math.round(
                (100 * event.loaded) / event.total
              );
            } else if (event instanceof HttpResponse) {
              this.messages[i] = {
                message: `${this.selectedFiles[i].name} was uploaded successfully.`,
                status: 'success',
              };
            }

            this.triggerReload = true;
          },
          (err) => {
            this.progressDetails[i].value = 0;
            const duplicate =
              err?.error?.clientError.includes('already exists');
            const errMsg = `${
              err?.error?.clientError ??
              'Could not upload' + this.selectedFiles[i].name
            }`;

            // Add to retryFiles array
            const body = {
              ...payload,
              canOverwrite: true,
            };
            if (duplicate) {
              this.retryFiles.push({ payload: body, index: i });
            }

            this.messages[i] = { message: errMsg, status: 'error', duplicate };
          }
        );
      } else {
        this.progressDetails[i].value = 0;
        this.messages[i] = {
          message: `Invalid file size "${this.selectedFiles[i].name}", max file size: ${this.maxFileSize} MB`,
          status: 'error',
        };
      }
    }
  }

  retryAllUpload(): void {
    this.disableRetryAll = true;
    this.retryFiles.forEach((el, index) => {
      this.fileService.uploadFile(el.payload).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressDetails[el.index].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            this.messages[el.index] = {
              message: `${
                this.selectedFiles[el.index].name
              } was uploaded successfully.`,
              status: 'success',
            };

            if (index + 1 === this.retryFiles.length) {
              this.retryFiles = [];
            }
          }

          this.triggerReload = true;
        },
        (err) => {
          this.progressDetails[el.index].value = 0;
          this.messages[el.index] = {
            message: `Could not upload ${this.selectedFiles[el.index].name}.`,
            status: 'error',
          };
        }
      );
    });
  }

  removeToRetryFiles(index): void {
    for (let i = 0; i < this.retryFiles.length; i++) {
      if (this.retryFiles[i].index === index) {
        this.retryFiles.splice(i, 1);
      }
    }
  }

  retryUpload(index): void {
    const payload = {
      path: this.path,
      file: this.selectedFiles[index],
      canOverwrite: true,
    };

    this.fileService.uploadFile(payload).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressDetails[index].value = Math.round(
            (100 * event.loaded) / event.total
          );
        } else if (event instanceof HttpResponse) {
          this.messages[index] = {
            message: `${this.selectedFiles[index].name} was uploaded successfully.`,
            status: 'success',
          };
        }

        this.removeToRetryFiles(index);
        this.triggerReload = true;
      },
      (err) => {
        this.progressDetails[index].value = 0;
        this.messages[index] = {
          message: `Could not upload ${this.selectedFiles[index].name}.`,
          status: 'error',
        };
      }
    );
  }

  formatValidTypes(): string {
    return this.validTypes?.value?.replace(/[\.|]/g, ' ');
  }

  close(): void {
    if (
      this.messages.length !== this.selectedFiles.length ||
      this.retryFiles.length > 0
    ) {
      this.notiflixService.launchConfirm(
        'Discard Changes',
        'Are you sure you want to discard files?',
        this
      );
    } else {
      this.dialogRef.close();
    }
  }

  onPositive(): void {
    this.dialogRef.close();
  }

  onNegative(): void {}
}
