import { createAction, props } from '@ngrx/store';

export const getRegisteredUser = createAction(
  '[Pages Component] Get Registered User'
);

export const getRegisteredUserSuccess = createAction(
  '[API Registered User] Get Registered User Success',
  props<{ response: any }>()
);

export const getRegisteredUserFailure = createAction(
  '[API Registered User] Get Registered User Failure',
  props<{ error: any }>()
);

export const getAircraftIdNumber = createAction(
  '[Edit Aircraft Component] Get Aircraft Id Number',
  props<{ id: number }>()
);

export const getAircraftIdNumberSuccess = createAction(
  '[Edit Aircraft Component] Get Aircraft Id Number Success',
  props<{ response: any }>()
);

export const getAircraftIdNumberFailure = createAction(
  '[Edit Aircraft Component] Get Aircraft Id Number Failure',
  props<{ error: any }>()
);
