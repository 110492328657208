<div class="page-header">
  <div class="center">
    <div class="row justify-content-end">
      <div class="col">
        <h5 class="text-haze-blue">User Profile</h5>
      </div>
      <div class="col">
        <div class="flight-list">
          <button
            type="button"
            matSuffix
            [attr.aria-label]="'Hide password'"
            color="accent"
            mat-flat-button
            [routerLink]="['/flight-list']"
            class="cancel-button h-60 form-buttons"
            matTooltip="Go to List of Flight Plans"
            matTooltipClass="tooltipClass"
            matTooltipPosition="below"
            id="backBtn"
          >
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<div class="row page-container">
  <div class="col-lg-3" *ngIf="isAdmin"></div>
  <nb-card class="col-lg-6 col-md-6 col-sm-12">
    <nb-card-header>
      <div class="text-center">
        <h5 class="text-haze-blue">User details</h5>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="user-details">
        <div class="row">
          <div class="col-5">
            <div class="item-label">Operator/Owner Name:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.name || '' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">Carrier Name:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.carrierName || '' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">Email:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.email || '' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">Street:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.street || '' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">City:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.city || '' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">State:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.state || '' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">Zip Code:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.zipCode || 'N/A' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="item-label">Country:</div>
          </div>
          <div class="col-7">
            <div class="item-value">
              {{ aircraftIdentificationList.userDetail?.country || '' }}
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    &nbsp;
  </nb-card>
  <nb-card *ngIf="!isAdmin" class="col-lg-6 col-md-6 col-sm-12">
    <nb-card-header>
      <div class="text-center">
        <h5 class="text-haze-blue">Aircraft Identification Number</h5>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="button-container">
        <button
          nbButton
          status="info"
          size="medium"
          matTooltip="Add Aircraft Identification Number"
          matTooltipClass="tooltipClass"
          matTooltipPosition="below"
          (click)="addAircraft()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <app-mat-data-table
        [tableContents]="tableContents"
        [dataSource]="aircraftIdentificationList.aircraftIdentifications"
        [loadingIndicator]="loadingIndicator$"
        [component]="this"
        [advancedSearchKeys]="advancedSearchKeys"
        [copyFlight]="false"
        [viewFlightDetails]="false"
        [editButton]="true"
        [deleteButton]="true"
        (isDelete)="onDeleteAircraftId($event)"
        (isEdit)="onEditAircraft($event)"
      ></app-mat-data-table>
    </nb-card-body>
  </nb-card>
</div>
