export class FileElement {
    id?: string;
    isFolder: boolean;
    name: string;
    parent: string;
    type: string;
    hasChild: boolean;
    dateCreated: string;
    dateModified: string;
    optionals?: any;
    
    constructor(res) {
        this.id = res.id;
        this.isFolder = res.isFolder;
        this.name = res.name;
        this.parent = res.parent;
        this.type = res.type;
        this.hasChild = res.hasChild;
        this.dateCreated = res.dateCreated;
        this.dateModified = res.dateModified;
        this.optionals = res.optionals;
    }
}
