import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/core/services/auth/auth.service';
import { CryptoService } from '../../core/services/crypto/crypto.service';
import { NotiflixService } from '../services/notiflix.service';
import {
  SIDEBAR_ITEMS,
  SIDEBAR_ITEMS_BUTTON,
  SIDEBAR_ITEMS_USERS,
} from '@app/core/constants/constants.const';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  // items =  this.authService.currentUserValue ? USER_MENU:SIDEBAR_ITEMS;
  // items =  SIDEBAR_ITEMS;
  items: NbMenuItem[];
  itemsButton = SIDEBAR_ITEMS_BUTTON;

  subscriptions = new Subscription();
  @Input() component: any;
  currentTransaction: string;

  constructor(
    private sidebarService: NbSidebarService,
    private authService: AuthService,
    private nbMenuService: NbMenuService,
    private notiflixService: NotiflixService,
    private router: Router,
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
  ) {
    const thisUserRole = this.cryptoService.isAdmin();

    if (thisUserRole) {
      this.items = SIDEBAR_ITEMS;
    } else {
      this.items = SIDEBAR_ITEMS_USERS;
    }
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initSubscriptions(): void {
    this.subscriptions.add(
      this.nbMenuService.onItemClick().subscribe((data) => {
        if (data.item.title === 'Log out') {
          if (this.authService.getUser()) {
            this.authService.onLogout(this.component);
          } else {
            this.currentTransaction = 'LOGOUT';
            this.notiflixService.launchConfirm(
              'Logout?',
              'Do you want to proceed?',
              this,
              'LOGOUT',
              'CANCEL'
            );
          }
        }
      })
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'LOGOUT':
        this.authService.removeCredentials();
        break;
    }
  }

  onNegative(): void {}

  toggle(): boolean {
    this.sidebarService.toggle(true, 'left');
    return false;
  }

  isItemActive(item: NbMenuItem): boolean {
    const currentUrl = this.router.url.split('?')[0];
    const targetUrl = item.link.split('?')[0];
    return currentUrl === targetUrl;
  }
}
