<div class="align-right">
  <div fxLayout="row wrap" style="justify-content: flex-end">
    <div class="custom-paginator-container">
      <div class="custom-paginator-go-to-label">Navigate to page:</div>
      <mat-form-field>
        <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
          <mat-option
            *ngFor="let pageNumber of pageNumbers"
            [value]="pageNumber"
            >{{ pageNumber }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <mat-paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
      (page)="paginationChange($event)"
    >
    </mat-paginator>
  </div>
</div>
