import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { API_ENPOINTS } from '../constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class TestModeService {
  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: typedef
  testMode() {
    return this.http.get(
      `${environment.api}${API_ENPOINTS.systemParameters}/public/TEST_MODE_FLAG`
    );
  }
}
