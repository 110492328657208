import { NbMenuItem } from '@nebular/theme';

export const CONSTANTS = {
  REGISTER: 'Register a new flight plan',
  FEES_BREAKDOWN: 'Fees Breakdown',
  EMPLOYEE_MAINTENANCE: 'Employee Maintenance',
  EMPLOYEE_MANAGEMENT: 'Employee Management',
  USER_MAINTENANCE: 'User Maintenance',
  USER_MANAGEMENT: 'User Management',
  APPLICATION_MANAGEMENT: 'Application Management',
  EMPLOYEES: 'Employees',
  EMPLOYEE_PROFILE: 'Employee Profile',
  ACTIONS: 'actions',
  HI: 'Hi',

  COMPLETE_FIELDS: 'Please complete all fields.',
  PASSWORD: 'Password',
  CANT_ACCESS_ACCOUNT: 'Forgot password?',
  EMPLOYEE_ID: 'Employee ID',
  EMPLOYEE_NUMBER: 'Employee Number',
  SIGN_IN: 'Login to your account',
  SIGN_UP: 'Register an account',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD_REQUIREMENTS: 'Requirements for creating password:',
  PASSWORD_CONTAIN_ATLEAST: 'Password must contain at least',
  CHARACTERS: 'characters; Max characters:',
  CHARACTERS_CONTAIN_ATLEAST_THREE:
    'Must contain characters from the following:',
  CAPITAL_LETTERS: 'Capital letters',
  CAPITAL_LETTERS_EX: '(A, B, C, D ...)',
  LOWERCASE_LETTERS: 'Lowercase letters',
  LOWERCASE_LETTERS_EX: '(a, b, c, d ...)',
  NUMBERS: 'Numbers',
  NUMBERS_EX: '(0, 1, 2, 3 ...)',
  SPECIAL_CHARACTERS: 'Special characters',
  SPECIAL_CHARACTERS_EX: '( ! @ # $ % & * _ + - = | ; > ? , . / )',
  SYSTEM_REMEMBERS:
    'New password should not be the same as your current password',
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 20,
  CHANGE_PASSWORD: 'Change Password',
  PASSWORD_HIDE_TIMER: 5000,
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  INDRA_EMAIL_ADDRESS: 'Email Address',
  EMAIL_ADDRESS: 'Email Address',
  LET_US_HELP_YOU: 'Let us help you!',
  RESET_PASSWORD: 'Reset Password',
  INDRA_EMPLOYEE_NUMBER: 'Indra Employee Number',
  EMPLOYEE_INFORMATION: 'Employee Information',
  LIST_OF_EMPLOYEES: 'List of Employees',
  ADD_EMPLOYEE: 'Add Employee',
  APPLICATION_ACCESS: 'Application Access',
  USER_ACCESS: 'User Access',
  APPLICATIONS: 'Applications',
  APPLICATION_USERS: 'Application Users',
  HR_TOOLS_AT_YOUR_FINGERTIPS: 'HR Tools at your fingertips',
  REGULARIZE_EMPLOYEE: 'Regularize Employee',
  MY_PROFILE: 'My Profile',
  BAHAMAS_WELCOME:
    'Welcome to the Flight Plan submission platform of The Bahamas',
  BAHAMAS_EMAIL: 'Email',
  BAHAMAS_LOADING: 'Bahamas Air Navigation Services Authority',
  // tslint:disable-next-line: quotemark
  REGISTER_USER: "Don't have an account?",
  EMAIL_INVALID: 'Invalid email address',
  AIRCRAFT_ID_NO_INVALID: 'Error! Value should be 3-6 characters',
  FIVE_CHARACTERS: 'Must consist of 5 characters',
  REMEMBER_ME: 'Remember me',
  ASTERISK: ' * ',
};

export const BUTTONS = {
  SAVE: 'Save',
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  LOGIN: 'Login',
  CLEAR: 'Clear',
  SUBMIT: 'Submit',
};

export const MENU_ITEMS_NB: Array<NbMenuItem> = [
  {
    title: 'HR Apps',
    link: '/flight-list',
    icon: 'widgets',
  },
  {
    title: 'User Management',
    link: '/users',
    icon: 'admin_panel_settings',
  },
  {
    title: 'Application Management',
    link: '/applications',
    icon: 'handyman',
  },
  {
    title: 'Employee Management',
    link: '/employees',
    icon: 'recent_actors',
  },
];

export const MENU_ITEMS = [
  {
    itemName: 'HR Apps',
    link: '/flight-list',
    icon: 'widgets',
  },
  {
    itemName: 'User Management',
    link: '/users',
    icon: 'admin_panel_settings',
  },
  {
    itemName: 'Application Management',
    link: '/applications',
    icon: 'handyman',
  },
  {
    itemName: 'Employee Management',
    link: '/employees',
    icon: 'recent_actors',
  },
];

export const USER_MENU: Array<NbMenuItem> = [
  {
    title: 'My Profile',
    icon: 'account_circle',
    link: '/my-profile',
  },
  {
    title: 'Change Password',
    icon: 'vpn_key',
    link: '/change-password',
  },
  // {
  //   title: 'Aeronautical Information',
  //   icon: 'folder',
  //   link: '/aeronautical-information?folder=notam',
  // },
  {
    title: 'Logout',
    icon: 'call_made',
    link: '',
  },
];

export const ADMIN_ACCESS = ['system administrator', 'administrator'];

export const CIVIL_STATUS = [
  'SINGLE',
  'MARRIED',
  'DIVORCED',
  'SEPARATED',
  'WIDOWED',
];

export const EMPLOYMENT_TYPE = [
  { label: 'PROJECT HIRE', value: 'PROJECT_HIRE' },
  { label: 'PROBATIONARY', value: 'PROBATIONARY' },
  { label: 'EXTENDED PROBATIONARY', value: 'EXT_PROBATIONARY' },
  { label: 'PERMANENT', value: 'PERMANENT' },
];

export const EMPLOYMENT_TYPE_FOR_UPDATING_PROJECT_HIRE = [
  { label: 'PROBATIONARY', value: 'PROBATIONARY' },
  { label: 'PERMANENT', value: 'PERMANENT' },
];

export const EMPLOYMENT_TYPE_FOR_UPDATING_PROBATIONARY = [
  { label: 'EXTENDED PROBATIONARY', value: 'EXT_PROBATIONARY' },
  { label: 'PERMANENT', value: 'PERMANENT' },
];

export const EMPLOYMENT_TYPE_FOR_UPDATING_EXT_PROBATIONARY = [
  { label: 'PERMANENT', value: 'PERMANENT' },
];

export const VALID_EXCEL_FILES = ['xlsx', 'xls', 'csv'];

export const NOTIFLIX_STATUS = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const MODES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  VIEW: 'VIEW',
  SAVE: 'SAVE',
  EXIT: 'EXIT',
};

export const SIZE_MULTIPLIER = {
  MB_SIZE: 1024,
};

export const SIDEBAR_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    link: '/home',
    icon: { icon: 'home-outline', pack: 'eva' },
  },
  {
    title: 'Flight planning',
    link: '/flight-list',
    icon: { icon: 'calendar-outline', pack: 'eva' },
    // children: [
    //   {
    //     title: 'Registrer new',
    //     link: '/add-flight',
    //     icon: { icon: 'plus-outline', pack: 'eva' },
    //   },
    // ],
  },
  // {
  //   title: 'Publications',
  //   link: '/publications',
  //   icon: { icon: 'book-outline', pack: 'eva' },
  // },
  {
    title: 'Aeronautical Information',
    link: '/aeronautical-information',
    // queryParams: { folder: 'aeronautical' },
    icon: { icon: 'book-outline', pack: 'eva' },
  },
  {
    title: 'NOTAM',
    link: '/notam',
    // queryParams: { folder: 'notam' },
    icon: { icon: 'file-text-outline', pack: 'eva' },
  },
  {
    title: 'System Parameters',
    link: '/system-parameters',
    icon: { icon: 'settings-outline', pack: 'eva' },
  },
  {
    title: 'Registered users',
    link: '/reg-users',
    icon: { icon: 'plus-outline', pack: 'eva' },
  },
];

export const SIDEBAR_ITEMS_USERS: NbMenuItem[] = [
  {
    title: 'Home',
    link: '/home',
    icon: { icon: 'home-outline', pack: 'eva' },
  },
  {
    title: 'Flight planning',
    link: '/flight-list',
    icon: { icon: 'calendar-outline', pack: 'eva' },
  },
  {
    title: 'Aeronautical Information',
    link: '/aeronautical-information',
    queryParams: { folder: 'aeronautical' },
    icon: { icon: 'book-outline', pack: 'eva' },
  },
  // {
  //   title: 'NOTAM',
  //   link: '/aeronautical-information',
  //   queryParams: { folder: 'notam' },
  //   icon: { icon: 'file-text-outline', pack: 'eva' },
  // },
  {
    title: 'NOTAM',
    link: '/notam',
    queryParams: { folder: 'notam' },
    icon: { icon: 'file-text-outline', pack: 'eva' },
  },
];

export const SIDEBAR_ITEMS_BUTTON: NbMenuItem[] = [
  {
    title: 'Log out',
    link: '',
    icon: { icon: 'log-out-outline', pack: 'eva' },
  },
];
