import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  const = CONSTANTS;

  ngOnInit(): void {
  }

  onSubmitEvent(newPassword): void {
    this.authService.changePassword(newPassword);
  }

}
