import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
  constructor(public http: HttpClient) {}

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  post(
    url: string,
    postBody: any,
    options?: any,
    currentModule?: string,
    currentMode?: string,
    fileName?: string,
    snackbar?: boolean
  ): Observable<any> {
    return this.http.post(url, postBody, options);
  }

  put(
    url: string,
    putBody: any,
    currentModule?: string,
    currentMode?: string,
    name?: string,
    snackbar?: boolean
  ): any {
    return this.http.put(url, putBody).subscribe(
      (response: any) => {},
      (error) => {}
    );
  }

  delete(
    url: string,
    deleteBody?: any,
    currentModule?: string,
    snackbar?: boolean
  ): any {
    return this.http.delete(`${url}${deleteBody}`).subscribe(
      (response: any) => {},
      (error) => {}
    );
  }

  deleteBody(
    url: string,
    deleteBody?: any,
    currentModule?: string,
    snackbar?: boolean
  ): any {
    const options = {
      body: deleteBody,
    };
    return this.http.request('DELETE', `${url}`, options).subscribe(
      (response: any) => {},
      (error) => {}
    );
  }

  patch(
    url: string,
    patchBody: any,
    currentModule?: string,
    currentMode?: string,
    name?: string,
    snackbar?: boolean
  ): Observable<any> {
    return this.http.patch(url, patchBody);
  }
}
