import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from '@app/core/constants/api.const';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err.status === 401 &&
          !request.url.includes(API.validateSSO) &&
          !request.url.includes(API.generateNewAccessToken)
        ) {
          this.authService.validateSSOSession().subscribe(
            (response) => {},
            (er) => {
              this.authService.generateNewAccessToken();
            }
          );
        }

        if (err.status === 403) {
          // this.notiflixService.closeLoader();
          this.router.navigateByUrl('/forbidden');
        }

        if (err.status === 404) {
          // this.notiflixService.closeLoader();

          if (err.statusText === 'OK') {
            // this.notiflixService.launchReport(NOTIFLIX_STATUS.error, DEFAULT_TEXTS.RECORD_NOT_FOUND_GENERAL, DEFAULT_TEXTS.THINGS_HAPPEN);
          }
        }

        if (err.status === 500) {
          // this.notiflixService.closeLoader();
          this.router.navigate([`/internal-server-error`], {
            queryParams: { errorTraceId: err.error.errorTraceId ?? '' },
          });
        }

        if (err.status === 502) {
          this.router.navigateByUrl('/internal-server-error');
        }

        if (err.status === 504) {
          this.router.navigate([`/internal-server-error`], {
            queryParams: { status: err?.status },
          });
        }
        const error = err;
        return throwError(error);
      })
    );
  }
}
