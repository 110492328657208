<ng-container *ngIf="isLoading; else contents">
  <app-loader></app-loader>
</ng-container>
<ng-template #contents>
  <nb-layout>
    <nb-layout-column>
      <div class="page-container">
        <div class="content-wrapper">
          <div class="logo-wrapper">
            <img
              animateOnScroll
              animationName="animate__animated animate__fadeInRight"
              class="bahamas-logo"
              src="../../../../assets/images/Bahamas Logo.png"
              alt=""
            />
            <img
              animateOnScroll
              animationName="animate__animated animate__fadeInRight"
              class="bansa-logo"
              src="../../../../assets/images/BANSA logo.png"
              alt=""
            />
            <h5
              animateOnScroll
              animationName="animate__animated animate__fadeInDown"
              class="subtitle text-haze-blue"
            >
              {{ constants.BAHAMAS_WELCOME }}
            </h5>
          </div>
          <div
            animateOnScroll
            animationName="animate__animated animate__fadeInUp"
            class="forgot-password-card"
          >
            <h5 class="align-center text-amazon-blue">Forgot your password?</h5>
            <p class="text-haze-blue">
              Don't you worry, we can help you with that!
            </p>
            <p>
              <!-- Just submit your email address and employee number below, then you are
              good to go! -->
            </p>
            <form [formGroup]="forgotPasswordForm" (submit)="onSubmit()">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ constants.EMAIL_ADDRESS }}</mat-label>
                <input
                  maxLength="50"
                  formControlName="email"
                  name="email"
                  id="email"
                  placeholder="{{ constants.EMAIL_ADDRESS }}"
                  autocomplete="off"
                  type="email"
                  matInput
                />
                <mat-error>{{ constants.EMAIL_INVALID }}</mat-error>
              </mat-form-field>
              <div class="error-message">
                <ng-container *ngIf="hasError">
                  {{ constants.COMPLETE_FIELDS }}
                </ng-container>
                <br />
              </div>
              <div class="center">
                <div class="row">
                  <div class="col-6">
                    <button
                      type="submit"
                      class="h-60 form-buttons"
                      nbButton
                      status="info"
                      [disabled]="!forgotPasswordForm.valid"
                    >
                      {{ buttons.SUBMIT }}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      id="login-clear"
                      nbButton
                      type="reset"
                      status="primary"
                      class="h-60 form-buttons"
                    >
                      {{ buttons.CLEAR }}
                    </button>
                  </div>
                </div>
                <br />
                <br />
                Remembered your password? <br />
                <a [routerLink]="['/login']" class="link">Try logging in</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </nb-layout-column>
    <nb-layout-footer>
      <app-footer></app-footer>
    </nb-layout-footer>
  </nb-layout>
</ng-template>
