import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.model';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { PublicUrlService } from '@app/core/services/public-url.service';
import * as moment from 'moment';
import { AuthService } from '@app/core/services/auth/auth.service';
import { PAYMENT_STATUS } from '@app/core/constants/payment-status';

@Component({
  selector: 'app-flight-plan-details',
  templateUrl: './flight-plan-details.component.html',
  styleUrls: ['./flight-plan-details.component.scss'],
})
export class FlightPlanDetailsComponent implements OnInit {
  isLoading = false;
  flightPlanDetails: FlightPlanDetails;
  appVersion = environment.version;
  qrIdentifier: string;
  paymentStatus = PAYMENT_STATUS;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private publicUrl: PublicUrlService,
    private authService: AuthService
  ) {
    this.qrIdentifier = this.route.snapshot.queryParams.qrIdentifier;
  }

  caabClick(): void {
    window.location.href = `${environment.CAAB_url}`;
  }

  logoClick(): void {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/home'], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.publicUrl.getFlightPlanByQRIdentifier(this.qrIdentifier).subscribe(
      (res) => {
        this.flightPlanDetails = res;
      },
      (err) => this.router.navigate(['/page-not-found'])
    );
  }

  formatTime(time: string): string {
    return moment(time, 'HH:mm').format('HH:mm');
  }
}
