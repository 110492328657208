import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
  CONSTANTS = CONSTANTS;
  appVersion = environment.version;
  copyrightYear = environment.copyright_year;
  constructor() {}

  ngOnInit(): void {}
}
