import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TableContents } from '@app/core/models/table-contents.model';
import { SystemParameterService } from '@app/core/services/system-parameter.service';
import { DataService } from '@app/shared/services/data.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { Store } from '@ngrx/store';
import { AddSystemParametersComponent } from './add-system-parameters/add-system-parameters/add-system-parameters.component';
import { EditSystemParametersComponent } from './edit-system-parameters/edit-system-parameters.component';

@Component({
  selector: 'app-system-parameters',
  templateUrl: './system-parameters.component.html',
  styleUrls: ['./system-parameters.component.scss'],
})
export class SystemParametersComponent implements OnInit {
  tableContents: TableContents;
  loadingIndicator$ = false;
  advancedSearchKeys: any;
  typeList: any;
  systemParameters: any;
  filteredSystemParams: any;
  tempFiltered: any;
  type = '';
  value = '';
  currentTransaction = '';
  systemParameterId = 0;
  constructor(
    private dataService: DataService,
    private store: Store<any>,
    private systemParametersService: SystemParameterService,
    public dialog: MatDialog,
    private notiflixService: NotiflixService
  ) {}

  ngOnInit(): void {
    this.getTypes();
    this.getSystemParameters();
    this.initTableContents();
    this.systemParametersService.deleteSuccess$.subscribe((res: any) => {
      this.getSystemParameters();
      this.type = '';
      this.value = '';
    });
    this.systemParametersService.addUpdateSuccess$.subscribe((res: any) => {
      this.getSystemParameters();
    });
  }

  initTableContents(): void {
    this.tableContents = {
      tableName: 'system-parameters',
      noGenericSearch: true,
      isRowClickable: false,
      columns: [
        {
          prop: 'id',
          label: 'Ref',
          type: 'stringLeftAlign',
        },
        {
          prop: 'code',
          label: 'Code',
          type: 'stringLeftAlign',
        },
        {
          prop: 'value',
          label: 'Value',
          type: 'stringLeftAlign',
        },
        {
          prop: 'description',
          label: 'Description',
          type: 'stringLeftAlign',
        },
        {
          prop: 'type',
          label: 'Type',
          type: 'type',
        },
        {
          prop: 'actions',
          label: '',
          type: 'action',
        },
      ],
    };
  }

  getTypes(): void {
    this.systemParametersService.getTypeList().subscribe((data) => {
      this.typeList = data;
    });
  }

  getSystemParameters(): void {
    this.systemParametersService.getSystemParameters().subscribe((data) => {
      this.systemParameters = data;
      this.filteredSystemParams = data;
    });
  }

  onAddSystemParameters(): void {
    const modalConfig = {
      disableClose: true,
      autoFocus: false,
      width: '800px',
      maxHeight: '800px',
    };
    const dialogRef: MatDialogRef<AddSystemParametersComponent> =
      this.dialog.open(AddSystemParametersComponent, modalConfig);
  }

  onSelect(): void {
    if (this.type === '' && this.value === '') {
      this.filteredSystemParams = this.systemParameters;
    } else {
      this.filteredSystemParams = [];
      this.systemParameters.map((data) => {
        if (data.systemParameterType.id === this.type) {
          this.filteredSystemParams.push(data);
        }
      });
      this.tempFiltered = this.filteredSystemParams;
    }
  }

  onPress(): void {
    if (this.value === '' && this.type === '') {
      this.filteredSystemParams = this.systemParameters;
    } else {
      if (this.type === '') {
        this.filteredSystemParams = [];
        this.systemParameters.map((data) => {
          if (data.value.includes(this.value)) {
            this.filteredSystemParams.push(data);
          }
        });
      }
      if (this.type !== '') {
        this.filteredSystemParams = [];
        this.tempFiltered.map((data) => {
          if (data.value.includes(this.value)) {
            this.filteredSystemParams.push(data);
          }
        });
      }
      if (this.type !== '' && this.value === '') {
        this.onSelect();
      }
    }
  }

  onDelete(event): void {
    this.currentTransaction = 'DELETE SYSTEM PARAMETER';
    this.systemParameterId = event.id;
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'System Parameter will be deleted.',
      this,
      'DELETE',
      'CANCEL'
    );
  }

  onEditSystemParameters(systemParametersDetails): void {
    // tslint:disable-next-line: prefer-const
    let systemParams;
    this.systemParametersService
      .getSystemParameterById(systemParametersDetails.id)
      .subscribe((data) => {
        const modalConfig = {
          disableClose: true,
          autoFocus: false,
          width: '800px',
          maxHeight: '800px',
        };
        const dialogRef: MatDialogRef<EditSystemParametersComponent> =
          this.dialog.open(EditSystemParametersComponent, modalConfig);
        dialogRef.componentInstance.systemParams = data;
      });
  }
  onPositive(): void {
    switch (this.currentTransaction) {
      case 'DELETE SYSTEM PARAMETER':
        this.systemParametersService.deleteSystemParameter(
          this.systemParameterId
        );
        break;
    }
  }

  onNegative(): void {}

  onViewDetails(event): void {}
}
