export class AddSystemParameters {
  code: string;
  value: string;
  description: string;
  typeId: string;
  typeMotor: string;
  numberMotors: number;
  mtow: number;
  mtowClass: number;
  displayWarning: boolean;
}

export class SystemParameters {
  id: number;
  code: string;
  description: string;
  value: string;
  typeId: number;
  type: string;

  constructor(res) {
    this.id = res.id;
    this.code = res.code;
    this.description = res.description;
    this.value = res.value;
    this.typeId = res.systemParameterType?.id;
    this.type = res.systemParameterType?.name;
  }
}
