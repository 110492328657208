<ng-container *ngIf="isLoading; else contents">
  <app-loader></app-loader>
</ng-container>
<ng-template #contents>
  <nb-layout>
    <nb-layout-column>
      <div class="page-container">
        <div class="logo-wrapper">
          <!-- <img
            animateOnScroll
            animationName="animate__animated animate__fadeInRight"
            src="../../../../assets/images/Bahamas Logo.png"
            alt=""
          /> -->
          <img
            animateOnScroll
            animationName="animate__animated animate__fadeInRight"
            src="../../../../assets/images/BANSA logo.png"
            alt=""
          />
        </div>
        <div class="content-wrapper">
          <div
            animateOnScroll
            animationName="animate__animated animate__fadeInUp"
            class="signup-card"
          >
            <h5 class="align-center text-amazon-blue">
              {{ constants.SIGN_UP }}
            </h5>
            <br />
            <form [formGroup]="signUpForm" (submit)="onSubmit()">
              <!-- <div class="field-wrapper"> -->
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Carrier name</mat-label>
                    <input
                      type="text"
                      formControlName="carrierName"
                      name="carrierName"
                      placeholder="Carrier name"
                      matInput
                      required
                    />
                    <button
                      type="button"
                      mat-icon-button
                      matSuffix
                      matTooltip="{{ carrier }}"
                      matTooltipClass="tooltipClass"
                      matTooltipPosition="below"
                    >
                      <mat-icon>help</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>ICAO 3 letters code</mat-label>
                    <input
                      type="text"
                      formControlName="name"
                      name="name"
                      placeholder="ICAO 3 letters code"
                      matInput
                    />
                    <button
                      type="button"
                      mat-icon-button
                      matSuffix
                      matTooltip="{{ operatorName }}"
                      matTooltipClass="tooltipClass"
                      matTooltipPosition="below"
                    >
                      <mat-icon>help</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="field-wrapper"> -->
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Email</mat-label>
                    <input
                      type="text"
                      formControlName="email"
                      name="email"
                      placeholder="Email"
                      matInput
                      required
                    />
                    <mat-error>{{ constants.EMAIL_INVALID }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Aircraft identification number</mat-label>
                    <input
                      maxlength="6"
                      minlength="3"
                      type="text"
                      formControlName="aircraftIdNo"
                      name="aircraftIdNo"
                      placeholder="Aircraft identification number"
                      matInput
                      required
                    />
                    <mat-error>{{
                      constants.AIRCRAFT_ID_NO_INVALID
                    }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Password</mat-label>
                    <input
                      [type]="passwordHide ? 'password' : 'text'"
                      formControlName="password"
                      name="password"
                      placeholder="Password"
                      matInput
                      required
                    />
                    <button
                      type="button"
                      mat-icon-button
                      matSuffix
                      (click)="hidePassword('passwordHide')"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="passwordHide"
                    >
                      <mat-icon>{{
                        passwordHide ? 'visibility_off' : 'visibility'
                      }}</mat-icon>
                    </button>
                    <mat-error>
                      Invalid password format
                      <span
                        class="action"
                        matTooltip="{{ passwordFormat }}"
                        matTooltipPosition="left"
                      >
                        <i class="material-icons"> help_outline </i>
                      </span></mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Confirm password</mat-label>
                    <input
                      [type]="confirmPasswordHide ? 'password' : 'text'"
                      formControlName="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      matInput
                      (input)="checkPassword($event)"
                      required
                    />
                    <button
                      type="button"
                      mat-icon-button
                      matSuffix
                      (click)="hidePassword('confirmPasswordHide')"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="confirmPasswordHide"
                    >
                      <mat-icon>{{
                        confirmPasswordHide ? 'visibility_off' : 'visibility'
                      }}</mat-icon>
                    </button>
                    <mat-error>Password must be same</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="field-wrapper"> -->
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Street</mat-label>
                    <input
                      type="text"
                      formControlName="street"
                      name="street"
                      placeholder="Street"
                      matInput
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>City</mat-label>
                    <input
                      type="text"
                      formControlName="city"
                      name="city"
                      placeholder="City"
                      matInput
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>State</mat-label>
                    <input
                      type="text"
                      formControlName="state"
                      name="state"
                      placeholder="State"
                      matInput
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Zip code</mat-label>
                    <input
                      type="text"
                      formControlName="zipCode"
                      name="zipCode"
                      placeholder="Zip code"
                      matInput
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Country</mat-label>
                    <input
                      type="text"
                      formControlName="country"
                      name="country"
                      placeholder="Country"
                      matInput
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
              <!-- </div> -->
              <div class="center">
                <br />
                <button
                  class="h-60 form-buttons"
                  type="submit"
                  nbButton
                  size="large"
                  status="info"
                  [disabled]="signUpForm.invalid"
                >
                  Create your account
                </button>
                <!-- <br /><br /><br /> -->
                <!-- <mat-divider></mat-divider>
                <br /> -->
                <div class="login">
                  <span
                    >Already have an account?
                    <a class="link" [routerLink]="['/login']">Login here</a>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </nb-layout-column>
    <nb-layout-footer>
      <app-footer></app-footer>
    </nb-layout-footer>
  </nb-layout>
</ng-template>
