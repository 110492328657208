<div class="page-wrapper">
  <br class="place-holder-break" />
  <br /><br />
  <br class="screen-sm" />
  <br class="screen-sm" />
  <br class="screen-sm" />
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="img-wrapper">
          <img
            id="page-img"
            src="../../../../assets/images/svgs/Control_acceso.svg"
            alt="mano-lupa"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-8">
        <div class="body-wrapper">
          <br /><br /><br /><br class="place-holder-break" />
          <p class="text-haze-blue page-msg">
            <span class="page-title noe-display-regular text-amazon-blue"
              >Stop right there!</span
            >
            <br />
            You are not allowed to access this page/link.
          </p>
          <a class="link" [href]="['/']">Back to home</a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="align-center">
      <div class="bahamas-logo">
        <!-- <img src="../../../../assets/images/bahamas.png" alt="" /> -->
        <img src="../../../../assets/images/BANSA logo.png" alt="" />
      </div>
      <p class="text-haze-blue subtitle">
        {{ CONSTANTS.BAHAMAS_LOADING }}
      </p>
    </div>
    <div class="footer-texts">
      <p>
        Copyright &copy; {{ copyrightYear }} Bahamas.
        <br />
        All rights reserved. Version {{ appVersion }}
      </p>
    </div>
  </div>
</div>
