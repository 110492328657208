import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbDatepickerModule,
  NbDialogModule,
  NbLayoutModule,
  NbMenuModule,
  NbProgressBarModule,
  NbSidebarModule,
  NbThemeModule,
  NbTreeGridModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt/jwt.interceptor';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { HttpService } from '@core/services/http/http.service';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './core/root-store';
import { AppEffects } from './core/root-store/app-effect.effects';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { AddFlightService } from './core/services/add-flight.service.ts.service';
import { SafePipe } from './core/pipes/safe.pipe';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CookieService } from 'ngx-cookie-service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePasswordComponent,
    RegisterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    PagesModule,
    AnimateOnScrollModule.forRoot(),
    RxReactiveFormsModule,
    NbThemeModule.forRoot({ name: 'indra' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbTreeGridModule,
    NbProgressBarModule,
    NbActionsModule,
    UserIdleModule.forRoot({
      idle: environment.idle,
      timeout: environment.timeout,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    NgxMaskModule.forRoot(options),
    NgxSkeletonLoaderModule,
    NbButtonModule,
    NbAutocompleteModule,
    NbEvaIconsModule,
  ],
  providers: [
    AuthGuard,
    HttpService,
    AddFlightService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
