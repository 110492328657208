<div class="flex-container">
  <img
    animateOnScroll
    animationName="animate__animated animate__fadeInRight"
    class="bansa-logo d-md-none"
    src="../../../../assets/images/BANSA logo.png"
    alt=""
    style="width: 70px"
  />
  <!-- <ng-container *ngIf="isSystemAdmin">
    <div *ngIf="onlyPicture">
      <div class="screen-sm">
        <div class="menu">
          <div class="menu-item" [nbContextMenu]="menuItemsNb">
            <i class="material-icons md-36"> menu </i>
          </div>
        </div>
      </div>
    </div>
  </ng-container> -->
  <!-- <div class="brand-logo-wrapper">
    <div class="brand-logo">
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="indra-logo"
        src="../../../../assets/images/Bahamas Logo.png"
        (click)="onCAABClick()"
      />
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="indra-logo"
        src="../../../../assets/images/BANSA logo.png"
        (click)="onBrandLogo()"
      />
      <h4>
        <span class="text-white neo-sans-medium">hr</span
        ><span class="text-solar-yellow neo-sans-medium">Portál</span>
      </h4>
    </div>
  </div> -->
  <div *ngIf="!isSystemAdmin" style="width: 40%"></div>
  <div *ngIf="isSystemAdmin">
    <!-- <app-menu-items [menuItems]="menuItems"></app-menu-items> -->
  </div>
  <div>
    <div class="user-avatar-container d-none d-md-block">
      <nb-user
        size="medium"
        [nbContextMenu]="userMenu"
        name="{{ userName | titlecase }}"
        title="{{ carrierName }}"
        color="white"
        nbContextMenuTag="userMenuContext"
        onlyPicture="false"
        (click)="openUserMenu()"
      >
      </nb-user>
    </div>

    <button
      nbButton
      ghost
      class="header-btn-menu d-md-none"
      (click)="toggleSidebar()"
    >
      <nb-icon icon="menu-outline" pack="eva"></nb-icon>
    </button>
  </div>
</div>
<div class="temporary-message" *ngIf="mode">
  <p class="subtitle test-mode">
    {{ mode.value }}
  </p>
</div>
