<ng-container *ngIf="isLoading; else contents">
  <app-loader></app-loader>
</ng-container>
<ng-template #contents>
  <nb-layout>
    <nb-layout-header fixed>
      <div class="logo-wrapper">
        <img
          animateOnScroll
          animationName="animate__animated animate__fadeInRight"
          class="bahamas-logo"
          src="../../../../assets/images/Bahamas Logo.png"
          alt=""
          (click)="caabClick()"
        />
        <img
          animateOnScroll
          animationName="animate__animated animate__fadeInRight"
          class="bansa-logo"
          src="../../../../assets/images/BANSA logo.png"
          alt=""
          (click)="logoClick()"
        />
      </div>
    </nb-layout-header>
    <nb-layout-column>
      <div class="flight-plan-container">
        <div fxLayout="row" fxLayoutAlign="space-between" class="header-title">
          <h3>Flight Plan</h3>
        </div>
        <br />
        <mat-dialog-content class="flight-plan-details">
          <!-- add ngIf later -->
          <ng-container>
            <div class="header-wrapper">
              <div class="row">
                <div class="col-5">
                  <div class="filing-container">
                    <div class="filing-wrapper">
                      <div class="label-wrapper">
                        <span class="label">Filing Date</span>
                      </div>
                      <div class="value-wrapper">
                        <p class="value">
                          {{
                            flightPlanDetails?.filingDate | date: 'dd/MM/yyyy'
                          }}
                        </p>
                      </div>
                      <div class="label-wrapper">
                        <span class="label">Filing Time (EST)</span>
                      </div>
                      <div class="value-wrapper">
                        <p class="value">
                          {{ formatTime(flightPlanDetails?.filingTime) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <div class="pilot-profile-wrapper left-border">
                    <table>
                      <tr>
                        <td rowspan="2">
                          <div class="pilot-icon">
                            <img
                              src="../../../../../assets/images/pilot-logo.png"
                              alt="Pilot icon"
                            />
                          </div>
                        </td>
                        <td class="pilot-info">
                          <h5 class="pilot-name">
                            {{ flightPlanDetails?.pilotInformation?.name }}
                          </h5>
                          <span class="pilot-base">
                            {{
                              flightPlanDetails?.pilotInformation
                                ?.aircraftHomeBase
                            }}</span
                          >
                          <span class="pilot-number">
                            {{
                              flightPlanDetails?.pilotInformation?.phone
                            }}</span
                          >
                          <p>
                            {{ flightPlanDetails?.pilotInformation?.email }}
                          </p>
                          <p>
                            {{ flightPlanDetails?.pilotInformation?.address }}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <mat-tab-group
              mat-stretch-tabs
              class="example-stretched-tabs mat-elevation-z4"
              disableRipple
              class="default"
            >
              <mat-tab>
                <ng-template mat-tab-label>Flight Details</ng-template>
                <div class="tab-body">
                  <div class="body-container">
                    <div class="row">
                      <div class="col-md-12 no-gutters">
                        <div class="details-card-body">
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Flight Date</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.flightDate
                                      | date: 'dd/MM/yyyy'
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Departure Time (EST)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    formatTime(flightPlanDetails?.departureTime)
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Off block Time (EST)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    formatTime(flightPlanDetails?.offBlockTime)
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Type of Operation</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.typeOperation }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Type of Flight</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.typeFlight }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Flight Rules</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.flightRules }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Aircraft Identification
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.aircraftIdentification
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Type of aircraft (ICAO)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.aircraftType }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Number of Motors</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.numberMotors }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Type of Motor</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.typeMotor }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">MTOW (kg)</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.mtow }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>Departure Details</ng-template>
                <div class="tab-body">
                  <div class="body-container">
                    <div class="row">
                      <div class="col-md-12 no-gutters">
                        <div class="details-card-body">
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Departure Aerodrome (ICAO)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.departureAerodrome
                                      | uppercase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Destination Aerodrome (ICAO)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.destinationAerodrome
                                      | uppercase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Other Information</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.otherInformation }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Estimated Time Enroute Hours
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.estimatedTimeEnrouteHours
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Estimated Time Enroute Minutes
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.estimatedTimeEnrouteMinutes
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Fuel Endurance Hours
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.fuelEnduranceHours }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Fuel Endurance Minutes
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.fuelEnduranceMinutes }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Route</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.flightRoute | uppercase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Cruising speed (TAS-Knots)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.cruisingSpeed }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Cruising level</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.cruisingAltitude
                                      | uppercase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Alternate Aerodrome (ICAO)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.alternateAerodrome
                                      | uppercase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  2nd Alternate Aerodrome (ICAO)
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.secondAlternateAerodrome ||
                                      '-' | uppercase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>Emergency Details</ng-template>
                <div class="tab-body">
                  <div class="body-container">
                    <div class="row">
                      <div class="col-md-12 no-gutters">
                        <div class="details-card-body">
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Emergency Radio</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.emergencyRadio }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Number of life jackets and color
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.numberLifeJacketsColor
                                      | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Number of dinghies</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.dinghiesNumber || 'N/a'
                                      | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Capacity of dinghies
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.dinghiesCapacity || 'N/a'
                                      | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Color of dinghies</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.dinghiesColor || 'N/a'
                                      | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  Aircraft color and markings
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.aircraftColor | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>Passenger Details</ng-template>
                <div class="tab-body">
                  <div class="body-container">
                    <div class="row">
                      <div class="col-md-12 no-gutters">
                        <div class="details-card-body">
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">
                                  SOB/No. of U.S./Non-U.S.
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails.totalNumberPersonsOnBoard
                                  }}
                                  /
                                  {{ flightPlanDetails.numberOfUSPassenger }} /
                                  {{ flightPlanDetails.numberOfNonUSPassenger }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Method of closure</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.methodClosure }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="item-label">Equipment</div>
                              </div>
                              <div class="col-md-9">
                                <div class="item-value">
                                  {{ flightPlanDetails?.otherEquipmentRemarks }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>Payment Details</ng-template>
                <div class="tab-body">
                  <div class="body-container">
                    <div class="row">
                      <div class="col-md-12 no-gutters">
                        <div class="details-card-body">
                          <!-- start -->
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-2">
                                <div class="item-label">Reference Number</div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.paymentResponse?.refNo ||
                                      '-'
                                  }}
                                </div>
                              </div>
                              <div class="col-md-2"></div>
                              <div class="col-md-2">
                                <div class="item-label">Payment Status</div>
                              </div>
                              <div class="col-md-4">
                                <div class="item-value">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.paymentStatus !== null &&
                                    !flightPlanDetails?.paymentInformation
                                      ?.paymentStatus
                                      ? paymentStatus.PAYMENT_WITH_IATA
                                      : paymentStatus.PAYMENT_COMPLETED
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <!-- end -->
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-2">
                                <div class="item-label">ATC charge</div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.atcCharge || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-label">
                                  Tax amount (VAT, BSD)
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.taxAtc || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-label">
                                  Subtotal amount (BSD)
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.subtotalAtc || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-2">
                                <div class="item-label">Passenger Charge</div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.passengerCharge || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-label">
                                  Tax amount (VAT, BSD)
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.taxPassenger || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-label">
                                  Subtotal amount (BSD)
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.subtotalPassenger || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div class="col-md-2">
                                <div class="item-label">Total Payment</div>
                              </div>
                              <div class="col-md-2">
                                <div class="item-value decimal">
                                  {{
                                    flightPlanDetails?.paymentInformation
                                      ?.totalPayment || 0 | number: '1.2-2'
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
        </mat-dialog-content>
      </div>
    </nb-layout-column>
    <nb-layout-footer>
      <app-footer></app-footer>
    </nb-layout-footer>
  </nb-layout>
</ng-template>
