import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RegisteredUserDetails } from '@app/core/store/flight-plan/flight-plan.model';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-view-user-details',
  templateUrl: './view-user-details.component.html',
  styleUrls: ['./view-user-details.component.scss'],
})
export class ViewUserDetailsComponent implements OnInit {
  public data: any;
  registeredUserDetails: RegisteredUserDetails;
  id: number;

  constructor(
    private store: Store<FlightPlanState>,
    private dialogRef: MatDialogRef<ViewUserDetailsComponent>
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
