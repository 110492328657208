import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/constants/api.const';
import { HttpService } from '@app/core/services/http/http.service';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  createFlightPlan,
  createFlightPlanFailure,
  createFlightPlanSuccess,
  getFlightPlan,
  getFlightPlanDetails,
  getFlightPlanDetailsFailure,
  getFlightPlanDetailsSuccess,
  getFlightPlanFailure,
  getFlightPlanSuccess,
  getFeesPaymentBreakdown,
  getFeesPaymentBreakdownFailure,
  getFeesPaymentBreakdownSuccess,
  getDownloadFees,
  getDownloadFeesFailure,
  getDownloadFeesSuccess,
  getRegisteredUsers,
  getRegisteredUsersFailure,
  getRegisteredUsersSuccess,
  getRegisteredUserDetails,
  getRegisteredUserDetailsSuccess,
  getRegisteredUserDetailsFailure,
} from './flight-plan.action';
import {
  FlightPlan,
  FeesPaymentBreakdown,
  DownloadFees,
  RegisteredUsers,
} from './flight-plan.model';

@Injectable()
export class FlightPlanEffects {
  constructor(
    private actions$: Actions,
    private http: HttpService,
    private httpClient: HttpClient
  ) {}

  getFlightPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFlightPlan),
      mergeMap(() => {
        return this.http.get(`${environment.api}${API.flightPlans}`).pipe(
          map((res: FlightPlan) => getFlightPlanSuccess({ response: res })),
          catchError((err) => of(getFlightPlanFailure({ error: err.message })))
        );
      })
    )
  );

  createFlightPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFlightPlan),
      mergeMap((action) =>
        this.http
          .post(`${environment.api}${API.flightPlans}`, action.payload)
          .pipe(
            map((res) => {
              return createFlightPlanSuccess({ response: res });
            }),
            catchError((err) => {
              return of(createFlightPlanFailure({ error: err }));
            })
          )
      )
    )
  );
  getFlightPlanDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFlightPlanDetails),
      mergeMap((action) => {
        return this.http
          .get(`${environment.api}${API.flightPlans}/${action.id}`)
          .pipe(
            switchMap((res) => {
              return [getFlightPlanDetailsSuccess({ response: res })];
            }),
            catchError((err) =>
              of(getFlightPlanDetailsFailure({ error: err.message }))
            )
          );
      })
    )
  );

  getRegisteredUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegisteredUserDetails),
      mergeMap((action) => {
        return this.http
          .get(`${environment.api}${API.registerUser}/${action.id}`)
          .pipe(
            switchMap((res) => {
              return [getRegisteredUserDetailsSuccess({ response: res })];
            }),
            catchError((err) =>
              of(getRegisteredUserDetailsFailure({ error: err.message }))
            )
          );
      })
    )
  );

  getFeesPaymentBreakdown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFeesPaymentBreakdown),
      mergeMap(() => {
        return this.http.get(`${environment.api}${API.flightPlans}`).pipe(
          map((res: FeesPaymentBreakdown) =>
            getFeesPaymentBreakdownSuccess({ response: res })
          ),
          catchError((err) =>
            of(getFeesPaymentBreakdownFailure({ error: err.message }))
          )
        );
      })
    )
  );

  getDownloadFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDownloadFees),
      mergeMap(() => {
        return this.http
          .get(`${environment.api}${API.flightPlans}?download=true`)
          .pipe(
            map((res: DownloadFees) =>
              getDownloadFeesSuccess({ response: res })
            ),
            catchError((err) =>
              of(getDownloadFeesFailure({ error: err.message }))
            )
          );
      })
    )
  );

  getRegisteredUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegisteredUsers),
      mergeMap(() => {
        return this.http.get(`${environment.api}${API.registerUser}`).pipe(
          map((res: RegisteredUsers) =>
            getRegisteredUsersSuccess({ response: res })
          ),
          catchError((err) =>
            of(getRegisteredUsersFailure({ error: err.message }))
          )
        );
      })
    )
  );
}
