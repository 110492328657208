<div class="wrapper">
  <h5 class="text-haze-blue">Edit</h5>
  <form>
    <mat-form-field appearance="outline" class="width-1 center">
      <mat-label>Aerodrome</mat-label>
      <input
        matInput
        fullWidth
        placeholder="Aerodrome"
        [formControl]="folderNameFormControl"
        [(ngModel)]="folderName"
        maxlength="{{ maxLength }}"
        required
      />
      <!-- <mat-error *ngIf="hasSpecialCharacters">
        Folder name cannot contain any of the following characters: / \
      </mat-error>
      <mat-error *ngIf="isExist"> Folder name already exists. </mat-error> -->
    </mat-form-field>
  </form>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="checkForSpaces() || folderName === ''"
      (click)="checkName()"
    >
      OK
    </button>
  </mat-dialog-actions>
</div>
