import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { environment } from '@environments/environment';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { getRegisteredUser } from '../store/registered-user-by-id/registered-user.action';
import { RegisteredUserState } from '../store/registered-user-by-id/registered-user.reducer';
import { selectRegisteredUserResponse } from '../store/registered-user-by-id/registered-user.selectors';
import { HttpService } from './http/http.service';

@Injectable({
  providedIn: 'root',
})
export class AddAircraftIdService {
  API_ROUTES = API_ENPOINTS;
  BACKEND_ENDPOINT = environment;
  aircraftIdentificationList: any;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private notiflix: NotiflixService,
    private router: Router,
    private store: Store<RegisteredUserState>
  ) {}

  createAddAircraftId(formData): Subscription {
    return this.http
      .post(`${environment.api}${API_ENPOINTS.aircraftId}`, formData, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: true,
      })
      .subscribe(
        (response: any) => {
          this.notiflix.launchReport(
            'success',
            'Success',
            'Aircraft Identification Number has been successfully added.',
            '',
            this
          );
        },
        (error) => {}
      );
  }

  editAircraftId(id, formData): Subscription {
    return this.http
      .patch(`${environment.api}${API_ENPOINTS.aircraftId}/${id}`, formData, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: true,
      })
      .subscribe(
        (response: any) => {
          this.notiflix.launchReport(
            'success',
            'Success',
            'Aircraft Identification number has been successfully updated.',
            '',
            this
          );
        },
        (error) => {
          this.notiflix.launchReport(
            'error',
            'Error',
            error.error.clientError,
            '',
            this
          );
        }
      );
  }

  deleteAircraftId(id): Subscription {
    return this.http
      .delete(`${environment.api}${API_ENPOINTS.aircraftId}/${id}`, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        withCredentials: true,
      })
      .subscribe(
        (response: any) => {
          this.notiflix.launchReport(
            'success',
            'Success',
            'Aircraft Identification number has been successfully deleted.',
            '',
            this
          );
        },
        (error) => {
          this.notiflix.launchReport(
            'error',
            'Error',
            error.error.clientError,
            '',
            this
          );
        }
      );
  }

  onOk(): void {
    this.router.navigate(['/my-profile'], {
      queryParams: { page: null },
      queryParamsHandling: 'merge',
    });
    this.getAircraftIdNumber();
  }

  getAircraftIdNumber(): void {
    this.store.dispatch(getRegisteredUser());
    this.store.pipe(select(selectRegisteredUserResponse)).subscribe((data) => {
      if (data) {
        this.aircraftIdentificationList = data ? data : [];
      }
    });
  }
}
