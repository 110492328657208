<div class="view-flight-details-container">
  <div fxLayout="row" fxLayoutAlign="space-between" class="header-title">
    <h3>Flight Plan</h3>
    <button mat-icon-button color="primary" (click)="close()">
      <i class="material-icons"> close </i>
    </button>
  </div>
  <mat-dialog-content class="view-flight-plan-modal">
    <ng-container *ngIf="flightPlanDetails; else stillLoading">
      <div class="header-wrapper">
        <div class="row">
          <div
            [ngClass]="{
              'col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2':
                flightPlanDetails?.user,
              'col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3':
                !flightPlanDetails?.user
            }"
          >
            <div
              [ngClass]="{
                'filling-wrapper': !flightPlanDetails?.user,
                'admin-filing-wrapper': flightPlanDetails?.user
              }"
            >
              <div class="label-wrapper">
                <span class="label">Filing Date</span>
              </div>
              <div class="value-wrapper">
                <p class="value">
                  {{ flightPlanDetails.filingDate | date: 'dd/MM/yyyy' }}
                </p>
              </div>
              <div class="label-wrapper">
                <span class="label">Filing Time (EST)</span>
              </div>
              <div class="value-wrapper">
                <p class="value">
                  {{ formatTime(flightPlanDetails.filingTime) }}
                </p>
              </div>
            </div>
          </div>
          <div
            *ngIf="flightPlanDetails?.user"
            class="
              col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5
              user-wrapper
            "
          >
            <div
              [ngClass]="{
                'pilot-profile-wrapper': !flightPlanDetails?.user,
                'admin-pilot-profile-wrapper': flightPlanDetails?.user
              }"
              class=""
            >
              <table class="left-border">
                <tr>
                  <td rowspan="2">
                    <div class="pilot-icon">
                      <img
                        src="../../../../../assets/images/user-logo.png"
                        alt="Pilot icon"
                      />
                    </div>
                  </td>
                  <td>
                    <h5 class="pilot-name">
                      {{ flightPlanDetails.user?.userDetail?.name }}
                    </h5>
                    <span class="role">{{
                      flightPlanDetails.user?.userDetail?.carrierName
                    }}</span>
                    <p>
                      {{ flightPlanDetails.user?.userDetail?.street || '' }}
                      {{ flightPlanDetails.user?.userDetail?.city || '' }}
                      {{ flightPlanDetails.user?.userDetail?.state || '' }}
                      {{ flightPlanDetails.user?.userDetail?.zipCode || '' }}
                      {{ flightPlanDetails.user?.userDetail?.country || '' }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="
              col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5
              user-wrapper
            "
          >
            <div
              [ngClass]="{
                'pilot-profile-wrapper': !flightPlanDetails?.user,
                'admin-pilot-profile-wrapper': flightPlanDetails?.user
              }"
              class=""
            >
              <table class="left-border">
                <tr>
                  <td rowspan="2">
                    <div class="pilot-icon">
                      <img
                        src="../../../../../assets/images/pilot-logo.png"
                        alt="Pilot icon"
                      />
                    </div>
                  </td>
                  <td>
                    <h5 class="pilot-name">
                      {{ flightPlanDetails.pilotInformation?.name }}
                    </h5>
                    <span class="role">{{
                      flightPlanDetails.pilotInformation?.aircraftHomeBase
                    }}</span>

                    <span class="number">{{
                      flightPlanDetails.pilotInformation?.phone
                    }}</span>
                    <p>{{ flightPlanDetails.pilotInformation?.email }}</p>
                    <p>{{ flightPlanDetails.pilotInformation?.address }}</p>
                  </td>
                </tr>
              </table>
              <div *ngIf="!flightPlanDetails?.user"></div>
            </div>
          </div>
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            *ngIf="!flightPlanDetails?.user"
          >
            <div class="left-border">
              <app-flight-plan-pdf [flightDetails]="data"></app-flight-plan-pdf>
              <!-- <qrcode [qrdata]="qrCode" [width]="100" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode> -->
            </div>
          </div>
        </div>
      </div>
      <mat-tab-group
        mat-stretch-tabs
        class="example-stretched-tabs mat-elevation-z4"
        disableRipple
        class="default"
      >
        <mat-tab>
          <ng-template mat-tab-label>Flight Details</ng-template>
          <div class="tab-body">
            <div class="body-container">
              <div class="row">
                <div class="col-md-12 no-gutters">
                  <div class="details-card-body">
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Flight Date</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.flightDate | date: 'dd/MM/yyyy'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Departure Time (EST)</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ formatTime(flightPlanDetails.departureTime) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Off block Time (EST)</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ formatTime(flightPlanDetails.offBlockTime) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Type of Operation</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.typeOperation }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Type of Flight</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.typeFlight }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Flight Rules</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.flightRules }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Aircraft Identification</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.aircraftIdentification }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Type of aircraft (ICAO)</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.aircraftType }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Number of Motors</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.numberMotors }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Type of Motor</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.typeMotor }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">MTOW (kg)</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.mtow }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper" *ngIf="flightPlanDetails?.user">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">O/D</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">1</div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper" *ngIf="flightPlanDetails?.user">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Overflight</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">0</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Departure Details</ng-template>
          <div class="tab-body">
            <div class="body-container">
              <div class="row">
                <div class="col-md-12 no-gutters">
                  <div class="details-card-body">
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Departure Aerodrome (ICAO)
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.departureAerodrome | uppercase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Destination Aerodrome (ICAO)
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.destinationAerodrome | uppercase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Other Information</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.otherInformation }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Estimated Time Enroute Hours
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.estimatedTimeEnrouteHours }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Estimated Time Enroute Minutes
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.estimatedTimeEnrouteMinutes }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Fuel Endurance Hours</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.fuelEnduranceHours }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Fuel Endurance Minutes</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.fuelEnduranceMinutes }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Route</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.flightRoute | uppercase }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Cruising speed (TAS-Knots)
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.cruisingSpeed }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Cruising level</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.cruisingAltitude | uppercase }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Alternate Aerodrome (ICAO)
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.alternateAerodrome | uppercase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            2nd Alternate Aerodrome (ICAO)
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.secondAlternateAerodrome || '-'
                                | uppercase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Emergency Details</ng-template>
          <div class="tab-body">
            <div class="body-container">
              <div class="row">
                <div class="col-md-12 no-gutters">
                  <div class="details-card-body">
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Emergency Radio</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.emergencyRadio }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Number of life jackets and color
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.numberLifeJacketsColor
                                | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Number of dinghies</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.dinghiesNumber || 'N/a'
                                | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Capacity of dinghies</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.dinghiesCapacity || 'N/a'
                                | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Color of dinghies</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{
                              flightPlanDetails.dinghiesColor || 'N/a'
                                | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">
                            Aircraft color and markings
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.aircraftColor | titlecase }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Passenger Details</ng-template>
          <div class="tab-body">
            <div class="body-container">
              <div class="row">
                <div class="col-md-12 no-gutters">
                  <div class="details-card-body">
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">SOB/No. of U.S./Non-U.S.</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.totalNumberPersonsOnBoard }} /
                            {{ flightPlanDetails.numberOfUSPassenger }} /
                            {{ flightPlanDetails.numberOfNonUSPassenger }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Method of closure</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.methodClosure }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="item-label">Equipment</div>
                        </div>
                        <div class="col-md-9">
                          <div class="item-value">
                            {{ flightPlanDetails.otherEquipmentRemarks }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-12">
                          <app-mat-data-table
                            [tableContents]="tableContents"
                            [dataSource]="flightPlanDetails.passengers"
                            [loadingIndicator]="loadingIndicator$"
                            [component]="this"
                            [advancedSearchKeys]="advancedSearchKeys"
                          ></app-mat-data-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Payment Details</ng-template>
          <div class="tab-body">
            <div class="body-container">
              <div class="row">
                <div class="col-md-12 no-gutters">
                  <div class="details-card-body">
                    <!-- start -->
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="item-label">Reference Number</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value">
                            {{
                              flightPlanDetails?.paymentResponse?.refNo || '-'
                            }}
                          </div>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-2">
                          <div class="item-label">Payment Status</div>
                        </div>
                        <div class="col-md-4">
                          <div class="item-value">
                            {{
                              flightPlanDetails?.paymentInformation
                                ?.paymentStatus !== null &&
                              !flightPlanDetails?.paymentInformation
                                ?.paymentStatus
                                ? paymentStatus.PAYMENT_WITH_IATA
                                : paymentStatus.PAYMENT_COMPLETED
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <!-- end -->
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="item-label">ATC charge</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails?.paymentInformation
                                ?.atcCharge || 0 | number: '1.2-2'
                            }}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-label">Tax amount (VAT, BSD)</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails?.paymentInformation?.taxAtc || 0
                                | number: '1.2-2'
                            }}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-label">Subtotal amount (BSD)</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails?.paymentInformation
                                ?.subtotalAtc || 0 | number: '1.2-2'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="item-label">Passenger Charge</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails?.paymentInformation
                                ?.passengerCharge || 0 | number: '1.2-2'
                            }}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-label">Tax amount (VAT, BSD)</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails?.paymentInformation
                                ?.taxPassenger || 0 | number: '1.2-2'
                            }}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-label">Subtotal amount (BSD)</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails.paymentInformation
                                ?.subtotalPassenger || 0 | number: '1.2-2'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-wrapper">
                      <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-2">
                          <div class="item-label">Total Payment</div>
                        </div>
                        <div class="col-md-2">
                          <div class="item-value decimal">
                            {{
                              flightPlanDetails?.paymentInformation
                                ?.totalPayment || 0 | number: '1.2-2'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #stillLoading>
      <div class="spinner-wrapper">
        <mat-spinner [diameter]="70"></mat-spinner>
      </div>
    </ng-template>
  </mat-dialog-content>
</div>
