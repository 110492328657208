import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS, BUTTONS } from '@app/core/constants/constants.const';
import { TestModeService } from '@app/core/services/test-mode.service';
import { AuthService } from '@core/services/auth/auth.service';
import { CryptoService } from '@core/services/crypto/crypto.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { RegisterUserService } from '@app/core/services/register-user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  appVersion = environment.version;
  returnUrl: string;
  hasError: boolean;
  constants = CONSTANTS;
  buttons = BUTTONS;
  isLoading = false;
  subscriptions$ = new Subscription();
  localUser: string;
  localPass: string;
  testMode: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private router: Router,
    private route: ActivatedRoute,
    private testModeService: TestModeService,
    private userService: RegisterUserService
  ) {
    this.userRoleLogin();
  }

  ngOnInit(): void {
    this.initFormControls();
    this.populateUserPass();
    this.testModeToggle();
    const currentUser = this.cryptoService.getStorageItem('currentUser');
    if (currentUser) {
      if (!this.authService.isTokenExpired()) {
        this.router.navigate([`/home`]);
        // this.router.navigate([`/flight-list`]);
      }
    }
  }

  initFormControls(): void {
    this.localUser = this.cryptoService.getStorageItem('username');
    this.localPass = this.cryptoService.getStorageItem('password');
    this.loginForm = this.formBuilder.group({
      email: [
        this.localUser,
        [Validators.required, this.userService.validateEmail],
      ],
      password: [this.localPass, [Validators.required]],
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    if (this.loginForm.valid) {
      this.subscriptions$.add(
        this.authService.login(this.loginForm.value, this)
      );
      // this.userRoleLogin();
      this.hasError = false;
    } else {
      this.isLoading = false;
      this.hasError = true;
    }
  }
  inputEmail(value: any): void {
    if (value) {
      this.hasError = false;
    } else {
      this.hasError = true;
    }
  }
  inputPassword(value: any): void {
    if (value) {
      this.hasError = false;
    } else {
      this.hasError = true;
    }
  }

  onRememberMeCheck(event: any): void {
    if (event.checked === true) {
      this.cryptoService.setEncryptedLocalStorageItem(
        'username',
        this.loginForm.controls.email.value
      );
      this.cryptoService.setEncryptedLocalStorageItem(
        'password',
        this.loginForm.controls.password.value
      );
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
  }

  clear(): void {
    this.hasError = false;
  }

  /**
   * Redirect to homepage if already logged in
   */
  userRoleLogin(): void {
    if (this.authService.getUser()) {
      // const currentUser = JSON.parse(this.cryptoService.getStorageItem('currentUser'));
      const currentUser = this.cryptoService.getStorageItem('currentUser');
      this.router.navigate(['/home'], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
      });
    }
    this.returnUrl =
      (this.route.snapshot.queryParams.returnUrl === '/'
        ? '/home'
        : this.route.snapshot.queryParams.returnUrl) || '/home';
  }

  populateUserPass(): void {
    if (this.localUser && this.localPass) {
      this.loginForm.get('email').setValue(this.localUser);
      this.loginForm.get('password').setValue(this.localPass);
    } else {
      this.loginForm.get('email').setValue('');
      this.loginForm.get('password').setValue('');
    }
  }

  testModeToggle(): void {
    // this.testModeService.testMode().subscribe((res) => {
    //   this.testMode = res;
    // });
  }
}
