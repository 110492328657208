import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { HttpService } from './http/http.service';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class FlightPlanService {
  BACKEND_ENDPOINT = environment;
  API_ROUTES = API_ENPOINTS;

  constructor(private httpService: HttpService) {}

  // tslint:disable: typedef
  sendVfrEmail(id) {
    return this.httpService.post(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.sendVfrEmail}/${id}`,
      null
    );
  }

  getTimeZone() {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}/code/BAHAMAS_TIMEZONE`
    );
  }

  formatTimeZone(timeZone) {
    const timeTz: momentTz.Moment = momentTz().tz(timeZone);
    const filingDate = timeTz.format('DD/MM/YYYY');

    return {
      filingDate,
      filingTime: timeTz.format('HH:mm'),
      currentDate: moment(filingDate, 'DD/MM/YYYY').toDate(),
    };
  }
}
