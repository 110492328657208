import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth/auth.guard';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { BahamasAppComponent } from './bahamas-app/bahamas-app.component';
import { RegisteredUsersComponent } from './bahamas-app/registered-users/registered-users.component';
import { SystemParametersComponent } from './bahamas-app/system-parameters/system-parameters.component';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { NotamComponent } from './notam/notam.component';
// import { FileManagerComponentNotam } from './file-manager-notam/file-manager-notam.component';
import { PagesComponent } from './pages.component';
import { AddFlightPlanComponent } from './user/add-flight-plan/add-flight-plan.component';
import { CopyFlightPlanComponent } from './user/copy-flight-plan/copy-flight-plan.component';
import { EditAircraftIdComponent } from './user/my-profile/edit-aircraft-id/edit-aircraft-id.component';
import { MyProfileComponent } from './user/my-profile/my-profile.component';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'flight-list',
        component: BahamasAppComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'add-flight',
        component: AddFlightPlanComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reg-users',
        component: RegisteredUsersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'copy-flight/:id',
        component: CopyFlightPlanComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'aeronautical-information',
        component: FileManagerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'notam',
        component: NotamComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit-aircraft/:id',
        component: EditAircraftIdComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'system-parameters',
        component: SystemParametersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'others-parameters',
        component: SystemParametersComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
