import { Component, OnInit } from '@angular/core';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { CurrentUser } from '@app/core/store/registered-user-by-id/registered-user.model';
import { DataService } from '@app/shared/services/data.service';
import { CONSTANTS } from '@core/constants/constants.const';

@Component({
  selector: 'app-bahamas-app',
  templateUrl: './bahamas-app.component.html',
  styleUrls: ['./bahamas-app.component.scss'],
})
export class BahamasAppComponent implements OnInit {
  constants = CONSTANTS;
  user;
  nickname: string;
  isAdmin: boolean;
  currentUser: CurrentUser;

  constructor(
    private cryptoService: CryptoService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    const loggedInUser = JSON.parse(
      this.cryptoService.getStorageItem('userInfo')
    );
    this.isAdmin = loggedInUser.profile.name === 'System Administrator' ? true : false;
    this.user = loggedInUser.userDetail?.name;
  }
}
