import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERROR_MESSAGES } from '@app/core/constants/error-messages';
import { FileElement } from '@app/core/models/file-element';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { FileService } from '@app/core/services/file.service';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-notam',
  templateUrl: './notam.component.html',
  styleUrls: ['./notam.component.scss'],
})
export class NotamComponent implements OnInit, OnDestroy {
  fileElements: Observable<FileElement[]>;
  currentRoot: FileElement;
  currentPath: string;
  canNavigateUp = false;
  currentActive: string;
  reloadDir = false;
  isAdmin: boolean;
  maxLength: any;

  constructor(
    public fileService: FileService,
    private notiflix: NotiflixService,
    private cryptoService: CryptoService,
    private router: Router,
    private activateRouter: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.cryptoService.isAdmin();
    this.fileService
      .getMaxLength()
      .subscribe((data: any) => (this.maxLength = data.value));
      
    // this.activateRouter.queryParams.subscribe((params) => {
    //   // const field = 'folder';
    
    // });
      
    this.getContent();
    
    setTimeout(() => {
      this.goToDirectory({
        directory: 'NOTAM',
        id: '22a19eb6-58f6-5085-b623-7a78dfe313fe',
      });
    }, 200);
  }

  getContent(): void {
    this.fileService.clearMap();
    this.notiflix.loadPulse();
    this.fileService.getAllDirectory().subscribe(
      (data: FileElement[]) => {
        data.forEach((element) => {
          this.fileService.add(new FileElement(element));
        });

        this.updateFileElementQuery();
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );
  }

  getCurrentDir(dir, treeView = false, id = '', searchValue = ''): void {
    this.notiflix.loadPulse();
    this.fileService.getAllDirectory(dir, searchValue).subscribe(
      (data: FileElement[]) => {
        data.forEach((element) => {
          this.fileService.add(new FileElement(element));
        });

        if (treeView) {
          this.currentRoot = this.fileService.get(id);
          this.updateFileElementQuery();
        }

        this.currentActive = this.currentRoot?.id;
        this.updateFileElementQuery();
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );
  }

  addFolder(folder: { name: string; path: string }): void {
    this.notiflix.loadPulse();
    this.fileService.addFolder(folder).subscribe(
      (el) => {
        if (folder.path !== '') {
          this.getCurrentDir(folder.path);
        } else {
          this.getContent();
        }
        this.reloadDir = true;
        this.updateFileElementQuery();
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err?.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );

    this.reloadDir = false;
  }

  removeElement(folder: { name: string; path: string; id: string }): void {
    const payload = { name: folder.name, path: folder.path };
    this.notiflix.loadPulse();
    this.fileService.deleteFolder(payload).subscribe(
      (el) => {
        if (folder.path !== '') {
          this.getCurrentDir(folder.path);
        } else {
          this.getContent();
        }

        this.fileService.delete(folder.id);
        this.reloadDir = true;
        this.updateFileElementQuery();
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err?.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );

    this.reloadDir = false;
  }

  removeFile(file: { name: string; path: string; id: string }): void {
    const payload = { name: file.name, path: file.path };
    this.notiflix.loadPulse();
    this.fileService.deleteFile(payload).subscribe(
      (el) => {
        if (file.path !== '') {
          this.getCurrentDir(file.path);
        } else {
          this.getContent();
        }

        this.fileService.delete(file.id);
        this.reloadDir = true;
        this.updateFileElementQuery();
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err?.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );
  }

  moveElement(element: { id: string; directory: string }): void {
    this.notiflix.loadPulse();
    if (this.currentPath !== '' && this.currentPath) {
      this.getCurrentDir(`/${this.currentPath}`);
    } else {
      this.getContent();
    }

    this.fileService.delete(element.id);
    this.reloadDir = true;
    this.updateFileElementQuery();
    this.notiflix.closeLoader();
  }

  renameElement(folder: {
    name: string;
    newName: string;
    path: string;
    id: string;
  }): void {
    const payload = {
      name: folder.name,
      newName: folder.newName,
      path: folder.path,
    };
    this.notiflix.loadPulse();
    this.fileService.renameFolder(payload).subscribe(
      (el) => {
        this.fileService.clearMap();

        if (folder.path !== '') {
          this.getCurrentDir(folder.path);
        } else {
          this.getContent();
        }

        this.updateFileElementQuery();
        this.reloadDir = true;
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err?.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );

    this.reloadDir = false;
  }

  editPdf(response: any): void {
    const payload = {
      id: response.id,
      optionals: response.optionals,
    };
    this.notiflix.loadPulse();
    this.fileService.editPdf(payload).subscribe(
      (el) => {
        // this.fileService.clearMap();

        // this.currentPath = this.popFromPath(response.path);

        // if (response.path && response.path !== '') {
        //   this.getCurrentDir(response.path, true);
        // } else {
        //   this.getContent();
        // }

        // this.updateFileElementQuery();
        // this.reloadDir = true;
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err?.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );

    this.reloadDir = false;
  }

  downloadFile(file: { name: string; path: string; id: string }): void {
    const payload = { name: file.name, path: file.path };
    this.notiflix.loadPulse();
    this.fileService.downloadFile(payload);
  }

  viewFile(file: { name: string; path: string; id: string }): void {
    const payload = { name: file.name, path: file.path };
    this.fileService.viewFile(payload);
  }

  uploadFile(file: { reload: boolean; path: string }): void {
    if (file.reload) {
      this.notiflix.loadPulse();
      if (file.path !== '') {
        this.getCurrentDir(file.path);
        this.notiflix.closeLoader();
      } else {
        this.getContent();
        this.notiflix.closeLoader();
      }
    }
  }

  navigateUp(): void {
    // this.fileService.clearMap();

    this.getContent();

    const tempArr = this.currentPath.split('/');
    // removes current, previous and blank paths
    tempArr.splice(tempArr.length - 2, 2);
    const parentPath = tempArr.join('/');
    
    setTimeout(() => {
      this.goToDirectory({
        directory: `${parentPath}`,
        id: this.currentRoot.parent,
      });
    }, 200);

    // if (this.currentRoot && this.currentRoot.parent === 'root') {
    //   this.getContent();
    //   this.currentRoot = null;
    //   this.canNavigateUp = false;
    //   this.updateFileElementQuery();
    //   this.currentActive = 'root';
    // } else {
    //   const tempArr = this.currentPath.split('/');
    //   // removes current, previous and blank paths
    //   tempArr.splice(tempArr.length - 2, 2);
    //   const parentPath = tempArr.join('/');
    //   this.getCurrentDir(`/${parentPath}/`, true, this.currentRoot.parent);
    //   this.updateFileElementQuery();
    // }

    // this.reloadDir = false;
    // this.currentPath = this.popFromPath(this.currentPath);
  }

  navigateToFolder(element: FileElement): void {
    this.currentPath = this.pushToPath(this.currentPath, element.name);
    this.getCurrentDir(`/${this.currentPath}`);
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.canNavigateUp = true;
    this.currentActive = element.id;
    this.reloadDir = false;
  }

  pushToPath(path: string, folderName: string): any {
    let updatedPath = path ?? '';
    updatedPath += `${folderName}/`;
    return updatedPath;
  }

  popFromPath(path: string): any {
    let updatedPath = path ?? '';
    const split = updatedPath.split('/');
    split.splice(split.length - 2, 1);
    updatedPath = split.join('/');
    return updatedPath;
  }

  updateFileElementQuery(): void {
    this.fileElements =
      this.fileService
        .queryInFolder(this.currentRoot ? this.currentRoot.id : 'root')
        .pipe(
          tap((res) =>
            res.sort((fileA, fileB) => {
              if (fileA.isFolder && !fileB.isFolder) {
                return -1;
              }

              if (!fileA.isFolder && fileB.isFolder) {
                return 1;
              }

              return 0;
            })
          )
        ) ?? null;
  }

  goToDirectory(event: { directory: string; id: string }): void {
    this.currentPath = `${event.directory}/`;
    const tempArr = event.directory.split('/');
    tempArr.pop();
    const parentPath = tempArr.join('/');
    this.getCurrentDir(`/${this.currentPath}`);
    this.getCurrentDir(`/${parentPath}/`, true, event.id);
    this.updateFileElementQuery();
    this.canNavigateUp = true;
  }

  onBack(): void {
    this.router.navigate(['/flight-list']);
  }

  ngOnDestroy(): void {
    this.fileElements = null;
  }

  handleSearchForm(searchValue: string) {
    this.notiflix.loadPulse();
    this.fileService.getAllDirectory(`/${this.currentPath}`, searchValue).subscribe(
      (data: FileElement[]) => {
        this.fileService.clearMap();

        data.forEach((element) => {
          this.fileService.add(new FileElement(element));
        });

        this.getCurrentDir(`/${this.currentPath}`, false, '', searchValue);

        // const tempArr = this.currentPath.split('/');
        // // removes current, previous and blank paths
        // tempArr.splice(tempArr.length - 2, 2);
        // const parentPath = tempArr.join('/');
        // this.getCurrentDir(`/${parentPath}/`, true, this.currentRoot.parent);

        this.currentActive = this.currentRoot?.id;
        this.updateFileElementQuery();
        // this.reloadDir = true;
        this.notiflix.closeLoader();
      },
      (err) => {
        this.notiflix.closeLoader();
        this.notiflix.launchNotify(
          'error',
          err.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
        );
      }
    );
  }

}
