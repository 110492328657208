export const API_ENPOINTS = {
  flightPlan: '/api/flight-plans',
  flightProperties: '/api/flight-plans/properties',
  calculateFeesBreakdown: '/api/flight-plans/calculate-fees-breakdown',
  registerUser: '/api/auth/register',
  flightPlanByQRIdentifier: '/api/flight-plans/public',
  hppCode: '/api/flight-plans/preprocess-payment',
  paymentResponse: '/api/flight-plans/payment-response/',
  aircraftId: '/api/users/aircraft-identifications',
  typeList: '/api/system-parameters/types',
  systemParameters: '/api/system-parameters',
  activateEmail: '/api/auth/activate-account',
  files: '/api/file-manager',
  directories: '/api/file-manager/tree-view',
  directory: '/api/file-manager/directory',
  file: '/api/file-manager/file',
  downloadFile: '/api/file-manager/download',
  uploadFile: '/api/file-manager/upload',
  editPdf: '/api/file-manager/update',
  flightPlanPDF: '/api/flight-plans/download-pdf',
  moveFolder: '/api/file-manager/directory/move',
  moveFile: '/api/file-manager/file/move',
  sendVfrEmail: '/api/flight-plans/send-vfr-mail',
};
