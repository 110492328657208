import { Component, OnInit } from '@angular/core';
import { FlightPlanDetails } from '@app/core/store/flight-plan/flight-plan.model';
import { UserDetails } from '@app/core/models/user-info.model';
import { select, Store } from '@ngrx/store';
import { TableContents } from '@app/core/models/table-contents.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AircraftIdentificationComponent } from './aircraft-identification/aircraft-identification.component';
import { getRegisteredUser } from '@app/core/store/registered-user-by-id/registered-user.action';
import { selectRegisteredUserResponse } from '@app/core/store/registered-user-by-id/registered-user.selectors';
import { RegisteredUserState } from '@app/core/store/registered-user-by-id/registered-user.reducer';
import { DeleteAircraftIdService } from '@app/core/services/delete-aircraft-id.service';
import { AddAircraftIdNumber } from '@app/core/models/add-aircraft-id-number.model';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { Router } from '@angular/router';
import { EditAircraftIdComponent } from './edit-aircraft-id/edit-aircraft-id.component';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { AddAircraftIdService } from '@app/core/services/add-aircraft-id.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  public data: any;
  flightPlanDetails: FlightPlanDetails;
  aircraftIdentificationList: any;
  userDetail: UserDetails;
  tableContents: TableContents;
  advancedSearchKeys: any;
  loadingIndicator$ = false;
  currentTransaction: string;
  isAdmin: boolean;
  aircraftIdentificationId: number;

  constructor(
    private store: Store<RegisteredUserState>,
    public dialog: MatDialog,
    private deleteAircraftIdNumber: DeleteAircraftIdService,
    private notiflixService: NotiflixService,
    private router: Router,
    private cryptoService: CryptoService,
    private addAircraftIdNumber: AddAircraftIdService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.cryptoService.isAdmin();
    this.aircraftIdentificationList = [];
    this.getAircraftIdNumber();
    this.initTableContents();
  }

  initTableContents(): void {
    this.tableContents = {
      tableName: 'aircraft-identification-number-table',
      noGenericSearch: true,
      isRowClickable: false,
      hasUpdateBtn: true,
      hasDeleteBtn: true,
      columns: [
        {
          prop: 'aircraftIdNumber',
          label: 'Aircraft Identification Number',
          type: 'stringLeftAlign',
          minWidth: '60px',
          maxWidth: '60px',
        },
        {
          prop: 'actions',
          label: '',
          type: 'action',
          minWidth: '1px',
          maxWidth: '1px',
        },
      ],
    };
  }

  getAircraftIdNumber(): void {
    this.store.dispatch(getRegisteredUser());
    this.store.pipe(select(selectRegisteredUserResponse)).subscribe((data) => {
      if (data) {
        this.aircraftIdentificationList = data ? data : [];
      }
    });
  }

  addAircraft(): void {
    const modalConfig = {
      disableClose: true,
      autoFocus: false,
      width: '500px',
      maxHeight: '500px',
    };
    const dialogRef: MatDialogRef<AircraftIdentificationComponent> =
      this.dialog.open(AircraftIdentificationComponent, modalConfig);
  }

  onDeleteAircraftId(aircraftDetails): void {
    this.aircraftIdentificationId = aircraftDetails.id;
    this.currentTransaction = 'DELETE';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'Aircraft Identification Number will be deleted.',
      this,
      'DELETE',
      'CANCEL'
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'DELETE':
        this.addAircraftIdNumber.deleteAircraftId(
          this.aircraftIdentificationId
        );
        break;
    }
  }

  onNegative(): void {}

  onEditAircraft(aircraftDetails): void {
    const modalConfig = {
      disableClose: true,
      autoFocus: false,
      width: '500px',
      maxHeight: '500px',
    };
    const dialogRef: MatDialogRef<EditAircraftIdComponent> = this.dialog.open(
      EditAircraftIdComponent,
      modalConfig
    );
    dialogRef.componentInstance.data = aircraftDetails;
  }
}
