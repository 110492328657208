import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileElement } from '../models/file-element';
import { HttpService } from './http/http.service';
import { environment } from '@environments/environment';
import { API_ENPOINTS } from '../constants/api-endpoints';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { FileSaverService } from 'ngx-filesaver';

export interface IFileService {
  // tslint:disable: typedef
  add(fileElement: FileElement);
  delete(id: string);
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
}

@Injectable({
  providedIn: 'root',
})
export class FileService implements IFileService {
  BACKEND_ENDPOINT = environment;
  API_ROUTES = API_ENPOINTS;
  private map = new Map<string, FileElement>();
  private querySubject: BehaviorSubject<FileElement[]>;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private notiflix: NotiflixService,
    private fileSaverService: FileSaverService
  ) {}

  getTreeDirectory() {
    return this.httpService.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.directories}`
    );
  }

  getAllDirectory(path = '/', search = '') {
    const payload = { path, search };
    return this.httpService.post(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.files}`,
      payload
    );
  }

  addFolder(payload) {
    return this.httpService.post(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.directory}`,
      payload
    );
  }

  deleteFolder(payload) {
    return this.http.request(
      'delete',
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.directory}`,
      { body: payload }
    );
  }

  renameFolder(payload) {
    return this.httpService.patch(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.directory}`,
      payload
    );
  }

  editPdf(payload) {
    return this.httpService.post(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.editPdf}`,
      payload
    );
  }

  deleteFile(payload) {
    return this.http.request(
      'delete',
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.file}`,
      { body: payload }
    );
  }

  downloadFile(payload) {
    return this.http
      .post(
        `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.downloadFile}`,
        payload,
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .subscribe((res) => {
        this.fileSaverService.save(res.body, payload.name);
        this.notiflix.closeLoader();
      });
  }

  viewFile(payload) {
    return this.http
      .post(
        `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.downloadFile}`,
        payload,
        {
          responseType: 'blob',
        }
      )
      .toPromise()
      .then((downloaded) => {
        const url = window.URL.createObjectURL(downloaded);
        window.open(url, '_blank');
      });
  }

  uploadFile(payload): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('path', payload.path);
    formData.append('file', payload.file);
    if (payload.canOverwrite) {
      formData.append('canOverwrite', payload.canOverwrite);
    }

    const req = new HttpRequest(
      'POST',
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.uploadFile}`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }

  getValidTypes() {
    return this.http.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}/code/FILE_EXTENSIONS`
    );
  }

  getMaxLength() {
    return this.http.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}/code/FOLDER_NAME_MAX_LENGTH`
    );
  }

  getMaxFileSize() {
    return this.http.get(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.systemParameters}/code/FILE_MAX_SIZE`
    );
  }

  moveFolder(payload) {
    return this.httpService.patch(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.moveFolder}`,
      payload
    );
  }

  moveFile(payload) {
    return this.httpService.patch(
      `${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.moveFile}`,
      payload
    );
  }

  add(fileElement: FileElement) {
    this.map.set(fileElement.id, this.clone(fileElement));
    return fileElement;
  }

  delete(id: string) {
    this.map.delete(id);
  }

  update(id: string, update: Partial<FileElement>) {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
  }

  queryInFolder(folderId: string): Observable<FileElement[]> {
    const result: FileElement[] = [];
    this.map.forEach((element: FileElement) => {
      if (element.parent === folderId) {
        result.push(this.clone(new FileElement(element)));
      }
    });

    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      const folders = result.filter((e) => e.isFolder) ?? [];
      const files = result.filter((e) => !e.isFolder) ?? [];
      this.querySubject.next([...folders, ...files]);
    }

    return this.querySubject.asObservable();
  }

  clearMap(): void {
    this.map = new Map<string, FileElement>();
  }

  get(id: string): FileElement {
    return this.map.get(id);
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }

  checkExisting(name: string, root: string): boolean {
    const temp = name?.trim();
    const parent = root ? root : 'root';
    for (const el of this.map) {
      if (el[1].parent === parent) {
        if (el[1].name.toLowerCase() === temp.toLowerCase()) {
          return true;
        }
      }
    }

    return false;
  }

  checkNameFormat(name: string): boolean {
    const temp = name?.trim();
    const specialCharaters = /[\\/]/;
    return specialCharaters.test(temp);
  }

  checkName(
    name: string,
    hasSpecialCharacters: boolean,
    isExist: boolean
  ): boolean {
    return name?.length > 0 &&
      name?.trim() !== '' &&
      !hasSpecialCharacters &&
      !isExist
      ? false
      : true;
  }
}
