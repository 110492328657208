import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { AddAircraftIdNumber } from '@app/core/models/add-aircraft-id-number.model';
import { AddAircraftIdService } from '@app/core/services/add-aircraft-id.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { CONSTANTS } from '@app/core/constants/constants.const';

@Component({
  selector: 'app-aircraft-identification',
  templateUrl: './aircraft-identification.component.html',
  styleUrls: ['./aircraft-identification.component.scss'],
})
export class AircraftIdentificationComponent implements OnInit {
  aircraftIdForm: FormGroup;
  currentTransaction: string;
  checker: any;
  constants = CONSTANTS;

  constructor(
    private dialogRef: MatDialogRef<AircraftIdentificationComponent>,
    private formBuilder: FormBuilder,
    private notiflixService: NotiflixService,
    private addAircraftIdNumber: AddAircraftIdService,
    private authService: AuthService
  ) {}

  @HostListener('window:beforeunload', ['$event']) unloadHandler(
    event: Event
  ): void {
    const formLength = [
      JSON.stringify(this.aircraftIdForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );

    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentFormLength !== formLength) {
        // tslint:disable-next-line: deprecation
        event.returnValue = false;
      }
      // tslint:disable-next-line: deprecation
      // event.returnValue = false; // stay on same page
    }
  }

  ngOnInit(): void {
    this.initFormControls();
  }

  initFormControls(): void {
    this.aircraftIdForm = this.formBuilder.group({
      aircraftIdNumber: ['', Validators.required],
    });

    this.checker = [JSON.stringify(this.aircraftIdForm.value).length];
  }

  close(): void {
    // this.dialogRef.close();
    const formLength = [
      JSON.stringify(this.aircraftIdForm.value).length,
    ].reduce((acc, cur) => acc + Number(cur), 0);
    const currentFormLength = this.checker.reduce(
      (acc, cur) => acc + Number(cur),
      0
    );
    if (currentFormLength !== formLength) {
      this.currentTransaction = 'BACK';
      this.notiflixService.launchConfirm(
        'Are you sure you want to exit?',
        'Changes you made will not be saved.',
        this,
        'EXIT',
        'NO'
      );
    } else {
      this.currentTransaction = 'BACK';
      this.onPositive();
    }
  }

  onSave(): void {
    this.currentTransaction = 'SAVE';
    this.notiflixService.launchConfirm(
      'Are you sure?',
      'Aircraft Identification Number will be saved.',
      this,
      'SAVE',
      'CANCEL'
    );
  }

  onPositive(): void {
    switch (this.currentTransaction) {
      case 'SAVE':
        this.onSaveAircraftId();
        const saveBtn = document.getElementById('saveBtn') as HTMLElement;
        saveBtn.click();
        this.dialogRef.close();
        break;
      case 'BACK':
        this.dialogRef.close();
        break;
      default:
        throw new Error(`${this.currentTransaction} is invalid`);
    }
  }

  onNegative(): void {}

  onSaveAircraftId(): void {
    const addAircraftId = new AddAircraftIdNumber();

    addAircraftId.aircraftIdNumber =
      this.aircraftIdForm.controls.aircraftIdNumber.value;

    this.addAircraftIdNumber.createAddAircraftId(JSON.stringify(addAircraftId));
  }
}
