import { formatNumber } from '@angular/common';
import { PAYMENT_STATUS, STATUS } from '@app/core/constants/payment-status';
import * as moment from 'moment';

export interface PilotInformation {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
  address?: string;
  aircraftHomeBase?: string;
}

export interface PassengerInformation {
  id: number;
  name: string;
  emergencyContact: string;
  address: string;
  phone: string;
}

export interface PaymentInformation {
  id: number;
  atcCharge: string;
  taxAtc: string;
  subtotalAtc: string;
  passengerCharge: string;
  taxPassenger: string;
  subtotalPassenger: string;
  totalPayment: string;
  // isPaymentSuccess: boolean;
}

export class FeesPaymentBreakdown {
  id: number;
  filingDate: string;
  aircraftIdentification: string;
  aircraftType: string;
  typeMotor: string;
  mtow: number;
  departureAerodrome: string;
  destinationAerodrome: string;
  paymentInformation: PaymentInformation;
  paymentId: number;
  paymentatcCharge: number;
  paymenttaxAtc: number;
  paymentsubtotalAtc: number;
  paymentpassengerCharge: number;
  paymenttaxPassenger: number;
  paymentsubtotalPassenger: number;
  paymenttotalPayment: number;
  paymentisPaymentSuccess: boolean;
  user: User;
  userDetail: UserDetail;
  userName: string;
  userCarrier: string;
  userStreet: string;
  userCity: string;
  userState: string;
  userZipcode: string;
  userCountry: string;
  overflight: string;
  od: string;
  paymentStatus: string;
  status: string;

  constructor(response) {
    this.id = response.id;
    this.aircraftIdentification = response.aircraftIdentification;
    this.departureAerodrome = response.departureAerodrome;
    this.destinationAerodrome = response.destinationAerodrome;
    this.userCarrier = response.user?.userDetail.carrierName;
    this.filingDate = response.filingDate;
    this.aircraftType = response.aircraftType;
    this.mtow = response.mtow;
    this.overflight = '0';
    this.od = '1';
    this.paymentatcCharge = response.paymentInformation?.atcCharge;
    this.paymenttaxAtc = response.paymentInformation?.taxAtc;
    this.paymentsubtotalAtc = response.paymentInformation?.subtotalAtc;
    this.paymentpassengerCharge = response.paymentInformation?.passengerCharge;
    this.paymenttaxPassenger = response.paymentInformation?.taxPassenger;
    this.paymentsubtotalPassenger =
      response.paymentInformation?.subtotalPassenger;
    this.paymenttotalPayment = response.paymentInformation?.totalPayment;
    this.userName = response.user?.userDetail.name;
    this.userStreet = response.user?.userDetail.street;
    this.userCity = response.user?.userDetail.city;
    this.userState = response.user?.userDetail.state;
    this.userZipcode = response.user?.userDetail.zipcode;
    this.userCountry = response.user?.userDetail.country;
    this.paymentStatus =
      response.paymentInformation?.paymentStatus !== null
        ? response.paymentInformation?.paymentStatus
          ? PAYMENT_STATUS.PAYMENT_COMPLETED
          : PAYMENT_STATUS.PAYMENT_WITH_IATA
        : PAYMENT_STATUS.PAYMENT_COMPLETED;

    this.status =
      response.isClosed !== null
        ? response.isClosed
          ? STATUS.CLOSED_VIA_PORTAL
          : STATUS.OPEN
        : '-';
  }
}

export class DownloadFees {
  id: number;
  filingDate: string;
  aircraftIdentification: string;
  aircraftType: string;
  typeMotor: string;
  mtow: number;
  departureAerodrome: string;
  destinationAerodrome: string;
  passengers: string;
  paymentInformation: PaymentInformation;
  paymentId: number;
  paymentatcCharge: string;
  paymenttaxAtc: string;
  paymentsubtotalAtc: string;
  paymentpassengerCharge: string;
  paymenttaxPassenger: string;
  paymentsubtotalPassenger: string;
  paymenttotalPayment: string;
  paymentisPaymentSuccess: boolean;
  user: User;
  userDetail: UserDetail;
  userName: string;
  userEmail: string;
  userCarrier: string;
  userStreet: string;
  userCity: string;
  userState: string;
  userZipcode: string;
  userCountry: string;
  overflight: string;
  od: string;
  paymentStatus: string;
  status: string;

  constructor(response) {
    this.id = response.id;
    this.filingDate = this.formatDateTime(
      response.flightDate,
      response.departureTime
    );

    this.aircraftIdentification = response.aircraftIdentification ?? '';
    this.departureAerodrome = response.departureAerodrome ?? '';
    this.destinationAerodrome = response.destinationAerodrome ?? '';
    this.userCarrier = response.user?.userDetail.carrierName ?? '';
    this.aircraftType = response.aircraftType ?? '';
    this.mtow = response.mtow ?? '';
    this.overflight = '0';
    this.od = '1';
    const atcCharge = response.paymentInformation?.atcCharge ?? '';
    const taxAtc = response.paymentInformation?.taxAtc ?? '';
    const subtotalAtc = response.paymentInformation?.subtotalAtc ?? '';
    const passengerCharge = response.paymentInformation?.passengerCharge ?? '';
    const subtotalPassenger =
      response.paymentInformation?.subtotalPassenger ?? '';

    const taxPassenger = response.paymentInformation?.taxPassenger ?? '';
    const totalPayment = response.paymentInformation?.totalPayment ?? '';
    this.paymentatcCharge = formatNumber(Number(atcCharge), 'en-US', '1.2-2');
    this.paymenttaxAtc = formatNumber(Number(taxAtc), 'en-US', '1.2-2');
    this.paymentsubtotalAtc = formatNumber(
      Number(subtotalAtc),
      'en-US',
      '1.2-2'
    );

    this.paymentpassengerCharge = formatNumber(
      Number(passengerCharge),
      'en-US',
      '1.2-2'
    );

    this.paymenttaxPassenger = formatNumber(
      Number(taxPassenger),
      'en-US',
      '1.2-2'
    );

    this.paymentsubtotalPassenger = formatNumber(
      Number(subtotalPassenger),
      'en-US',
      '1.2-2'
    );

    this.paymenttotalPayment = formatNumber(
      Number(totalPayment),
      'en-US',
      '1.2-2'
    );

    this.paymentStatus =
      response.paymentInformation?.paymentStatus !== null
        ? response.paymentInformation?.paymentStatus
          ? PAYMENT_STATUS.PAYMENT_COMPLETED
          : PAYMENT_STATUS.PAYMENT_WITH_IATA
        : PAYMENT_STATUS.PAYMENT_COMPLETED;

    this.status =
      response.isClosed !== null
        ? response.isClosed
          ? STATUS.CLOSED_VIA_PORTAL
          : STATUS.OPEN
        : '-';

    this.userName = response.user?.userDetail.name ?? '';
    this.userEmail = response.user?.userDetail?.email ?? '';
    this.userStreet = response.user?.userDetail.street ?? '';
    this.userCity = response.user?.userDetail.city ?? '';
    this.userState = response.user?.userDetail.state ?? '';
    this.userZipcode = response.user?.userDetail.zipcode ?? '';
    this.userCountry = response.user?.userDetail.country ?? '';
    this.passengers = '';

    if (response.passengers) {
      response?.passengers?.forEach((passenger) => {
        if (passenger) {
          this.passengers += `${this.checkNullOrEmpty(
            passenger.name
          )}| ${this.checkNullOrEmpty(
            passenger.address
          )}| ${this.checkNullOrEmpty(
            passenger.phone
          )}| ${this.checkNullOrEmpty(passenger.emergencyContact)}; `;
        }
      });
    }
  }

  checkNullOrEmpty(str): string {
    return str !== null && str !== '' ? str : 'N/A';
  }

  formatDateTime(date, time): string {
    return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY HH:mm'
    );
  }
}

export class FlightPlan {
  id: number;
  filingTime: string;
  filingDate: string;
  flightDate: string;
  departureTime: string;
  typeOperation: string;
  typeFlight: string;
  aircraftIdentification: string;
  aircraftType: string;
  numberMotors: number;
  typeMotor: string;
  mtow: number;
  departureAerodrome: string;
  destinationAerodrome: string;
  pilotInformation: PilotInformation;
  pilotName: string;
  canSendVfrMail: string;
  paymentInformation: PaymentInformation;
  paymentId: number;
  paymentatcCharge: number;
  paymenttaxAtc: number;
  paymentsubtotalAtc: number;
  paymentpassengerCharge: number;
  paymenttaxPassenger: number;
  paymentsubtotalPassenger: number;
  paymenttotalPayment: number;
  paymentisPaymentSuccess: boolean;
  user: User;
  userDetail: UserDetail;
  userName: string;
  paymentStatus: string;
  // paymentResponse?: PaymentResponse;
  // referenceNumber: string;

  constructor(response) {
    this.id = response.id;
    this.filingTime = moment(response.filingTime, 'HH:mm').format('HH:mm');
    this.filingDate = response.filingDate;
    this.flightDate = response.flightDate;
    this.departureTime = response.departureTime;
    this.typeOperation = response.typeOperation;
    this.typeFlight = response.typeFlight;
    this.aircraftIdentification = response.aircraftIdentification;
    this.aircraftType = response.aircraftType;
    this.numberMotors = response.numberMotors;
    this.typeMotor = response.typeMotor;
    this.mtow = response.mtow;
    this.departureAerodrome = response.departureAerodrome;
    this.destinationAerodrome = response.destinationAerodrome;

    this.pilotInformation = response.pilotInformation;
    this.pilotName = response.pilotInformation?.name;

    this.canSendVfrMail = response?.canSendVfrMail;

    this.paymentInformation = response.paymentInformation;
    this.paymentId = response.paymentInformation?.id;
    this.paymentatcCharge = response.paymentInformation?.atcCharge;
    this.paymenttaxAtc = response.paymentInformation?.taxAtc;
    this.paymentsubtotalAtc = response.paymentInformation?.subtotalAtc;
    this.paymentpassengerCharge = response.paymentInformation?.passengerCharge;
    this.paymenttaxPassenger = response.paymentInformation?.passengerCharge;
    this.paymentsubtotalPassenger =
      response.paymentInformation?.subtotalPassenger;
    this.paymenttotalPayment = response.paymentInformation?.totalPayment;
    this.paymentisPaymentSuccess =
      response.paymentInformation?.isPaymentSuccess;

    this.user = response.user;
    this.userDetail = response.user?.userDetail;
    this.userName = response.user?.userDetail.name;
    this.paymentStatus =
      response.paymentInformation?.paymentStatus !== null
        ? response.paymentInformation?.paymentStatus
          ? PAYMENT_STATUS.PAYMENT_COMPLETED
          : PAYMENT_STATUS.PAYMENT_WITH_IATA
        : PAYMENT_STATUS.PAYMENT_COMPLETED;
    // this.paymentResponse = response.paymentResponse;
    // this.referenceNumber = response.paymentResponse?.referenceNo ?? null;
  }
}

export interface User {
  id: number;
  userDetail: UserDetail;
}

export interface UserDetail {
  name: string;
  email: string;
  carrierName: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

export class RegisteredUsers {
  id: number;
  name: string;
  carrierName: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  aircraftIdentifications: AircraftIdNumber;

  constructor(response) {
    this.id = response.userDetail.id;
    this.carrierName = response.userDetail.carrierName;
    this.name = response.userDetail.name;
    this.email = response.userDetail.email;
    this.street = response.userDetail.street;
    this.city = response.userDetail.city;
    this.state = response.userDetail.state;
    this.zipCode = response.userDetail.zipCode;
    this.country = response.userDetail.country;
    this.aircraftIdentifications = response.aircraftIdentifications
      .map((x) => x.aircraftIdNumber)
      .join('; ');
  }
}

export interface PaymentResponse {
  responseCode: string;
  reasonCode: string;
  reasonDescription: string;
  refNo: string;
}

export interface AircraftIdNumber {
  id: number;
  aircraftIdNumber: string;
}

export interface RegisteredUserDetails {
  id: number;
  name: string;
  carrierName: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  aircraftIdentifications?: AircraftIdNumber;
}

export interface FlightPlanDetails {
  qrIdentifier: string;
  id: number;
  filingTime: string;
  filingDate: string;
  flightDate: string;
  ofBlockTime: string;
  offBlockTime: string;
  departureTime: string;
  typeOperation: string;
  typeFlight: string;
  flightRules: string;
  aircraftIdentification: string;
  aircraftType: string;
  numberMotors: number;
  typeMotor: string;
  mtow: number;
  departureAerodrome: string;
  destinationAerodrome: string;
  estimatedTimeEnrouteHours: number;
  estimatedTimeEnrouteMinutes: number;
  fuelEnduranceHours: number;
  fuelEnduranceMinutes: number;
  otherInformation: string;
  alternateAerodrome: string;
  secondAlternateAerodrome: string;
  flightRoute: string;
  cruisingSpeed: number;
  cruisingAltitude: string;
  emergencyRadio: string;
  numberLifeJacketsColor: string;
  dinghiesNumber: string;
  dinghiesCapacity: string;
  dinghiesColor: string;
  aircraftColor: string;
  totalNumberPersonsOnBoard: number;
  numberOfUSPassenger: number;
  numberOfNonUSPassenger: number;
  methodClosure: string;
  otherEquipmentRemarks: string;
  pilotInformation: PilotInformation;
  passengers?: PassengerInformation;
  paymentInformation: PaymentInformation;
  user?: User;
  paymentResponse: PaymentResponse;
  paymentStatus: boolean;
}
