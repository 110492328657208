import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  CONSTANTS = CONSTANTS;
  appVersion = environment.version;
  copyrightYear = environment.copyright_year;
  constructor() {}

  ngOnInit(): void {}
}
