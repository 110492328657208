<ng-container *ngIf="isLoading; else content">
  <app-loader></app-loader>
</ng-container>
<ng-template #content>
  <div class="activate-user-container" *ngIf="activationToken">
    <div class="container center">
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="logo"
        src="../../../../assets/images/Bahamas Logo.png"
        alt=""
      />
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="logo"
        src="../../../../assets/images/BANSA logo.png"
        alt=""
      />
      <div class="message-card">
        <h2 class="text-amazon-blue noe-display-regular">Success!</h2>
        <p class="text-amazon-blue">
          {{ responseMessage }} <br /><br />
          Redirecting you to Login page in {{ counter }} seconds. Otherwise,
          please
          <a [routerLink]="['/login']" (click)="counterStop()">click here</a>
        </p>
      </div>
    </div>
  </div>
  <div class="activate-user-container" *ngIf="!isLoading && hasError">
    <div class="container center">
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="logo"
        src="../../../../assets/images/Bahamas Logo.png"
        alt=""
      />
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInRight"
        class="logo"
        src="../../../../assets/images/BANSA logo.png"
        alt=""
      />
      <div class="message-card">
        <h2 class="text-amazon-blue noe-display-regular">Oops!</h2>
        <p class="text-amazon-blue">
          {{ responseMessage }} <br /><br />
          Redirecting you to Login page in {{ counter }} seconds. Otherwise,
          please
          <a [routerLink]="['/login']" (click)="counterStop()">click here</a>
        </p>
      </div>
    </div>
  </div>
</ng-template>
