import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http/http.service';
import { environment } from 'environments/environment';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { FormControl } from '@angular/forms';
import { isEmail } from 'validator';

@Injectable({
  providedIn: 'root',
})
export class RegisterUserService {
  constructor(
    private httpService: HttpService,
  ) {}

  registerUser(payload): Observable<any> {
    return this.httpService.post(
      `${environment.api}${API_ENPOINTS.registerUser}`,
      payload
    );
  }

  validateEmail(control: FormControl): { [s: string]: boolean } {
    // tslint:disable-next-line:object-literal-key-quotes
    return !isEmail(control.value ?? '') ? { emailIsInvalid: true } : null;
  }
}
