import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbProgressBarModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTableModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.component';
import { SharedModule } from '../shared/shared.module';
import { ForbiddenComponent } from './error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { InternalServerErrorComponent } from './error-pages/internal-server-error/internal-server-error.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from '../core/guards/auth/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../core/interceptors/jwt/jwt.interceptor';
import { LogoutComponent } from './auth/logout/logout/logout.component';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCurrentInfoUser from '../core/store/current-user-info/current-user-info.reducer';
import { CurrentUserInfoEffects } from '../core/store/current-user-info/current-user-info.effects';
import * as flightPlan from '../core/store/flight-plan/flight-plan.reducer';
import { FlightPlanEffects } from '../core/store/flight-plan/flight-plan.effects';
import { ForgotPasswordComponent } from './other-pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './other-pages/reset-password/reset-password.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { ImagePreloadDirective } from '@app/core/directives/image-preload.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorInterceptor } from '@app/core/interceptors/error/error.interceptor';
import { CheckpointComponent } from './auth/checkpoint/checkpoint.component';
import { AddFlightPlanComponent } from './user/add-flight-plan/add-flight-plan.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlightPlanComponent } from './view-pages/flight-plan/flight-plan.component';
import { ViewFlightPlanComponent } from './view-pages/flight-plan/view-flight-plan/view-flight-plan.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FeesPaymentRecordComponent } from './bahamas-app/fees-payment-record/fees-payment-record.component';
import { RegisterComponent } from './auth/register/register.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivateUserComponent } from './other-pages/activate-user/activate-user.component';
import { CopyFlightPlanComponent } from './user/copy-flight-plan/copy-flight-plan.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FlightPlanDetailsComponent } from './other-pages/flight-plan-details/flight-plan-details.component';
import { FlightPlanPdfComponent } from './view-pages/flight-plan/view-flight-plan/flight-plan-pdf/flight-plan-pdf.component';
import { PaymentResponseComponent } from './payment/payment-response/payment-response.component';
import { PaymentPortalComponent } from './payment/payment-portal/payment-portal.component';
import { SafePipe } from './../core/pipes/safe.pipe';
import { BahamasAppComponent } from './bahamas-app/bahamas-app.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MyProfileComponent } from './user/my-profile/my-profile.component';
import { AircraftIdentificationComponent } from './user/my-profile/aircraft-identification/aircraft-identification.component';
import * as registeredUser from '../core/store/registered-user-by-id/registered-user.reducer';
import { RegisteredUserEffects } from '../core/store/registered-user-by-id/registered-user.effects';
import { EditAircraftIdComponent } from './user/my-profile/edit-aircraft-id/edit-aircraft-id.component';
import { RegisteredUsersComponent } from './bahamas-app/registered-users/registered-users.component';
import { ViewUserDetailsComponent } from './bahamas-app/registered-users/view-user-details/view-user-details/view-user-details.component';
import { SystemParametersComponent } from './bahamas-app/system-parameters/system-parameters.component';
import { AddSystemParametersComponent } from './bahamas-app/system-parameters/add-system-parameters/add-system-parameters/add-system-parameters.component';
import { EditSystemParametersComponent } from './bahamas-app/system-parameters/edit-system-parameters/edit-system-parameters.component';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { AppFileExplorerNotamComponent } from './app-file-explorer-notam/app-file-explorer-notam.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { NotamComponent } from './notam/notam.component';
import { NewFolderDialogComponent } from './file-explorer/modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from './file-explorer/modals/rename-dialog/rename-dialog.component';
import { EditDialogComponent } from './file-explorer/modals/edit-dialog/edit-dialog.component';
import { FileTreeComponent } from './file-tree/file-tree.component';
import { UploadFileDialogComponent } from './file-explorer/modals/upload-file-dialog/upload-file-dialog.component';
import { MoveDialogComponent } from './file-explorer/modals/move-dialog/move-dialog.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [
    PagesComponent,
    ForbiddenComponent,
    NotFoundComponent,
    InternalServerErrorComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ImagePreloadDirective,
    CheckpointComponent,
    AddFlightPlanComponent,
    FlightPlanComponent,
    ViewFlightPlanComponent,
    FeesPaymentRecordComponent,
    ActivateUserComponent,
    CopyFlightPlanComponent,
    FlightPlanDetailsComponent,
    FlightPlanPdfComponent,
    PaymentResponseComponent,
    PaymentPortalComponent,
    SafePipe,
    BahamasAppComponent,
    TermsAndConditionsComponent,
    MyProfileComponent,
    AircraftIdentificationComponent,
    EditAircraftIdComponent,
    RegisteredUsersComponent,
    ViewUserDetailsComponent,
    SystemParametersComponent,
    AddSystemParametersComponent,
    EditSystemParametersComponent,
    FileManagerComponent,
    NotamComponent,
    FileExplorerComponent,
    AppFileExplorerNotamComponent,
    NewFolderDialogComponent,
    RenameDialogComponent,
    EditDialogComponent,
    FileTreeComponent,
    UploadFileDialogComponent,
    MoveDialogComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AnimateOnScrollModule,
    NgxSkeletonLoaderModule,

    // Nebular Components
    NbThemeModule,
    NbSidebarModule,
    NbMenuModule,
    NbLayoutModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbButtonModule,
    NbDatepickerModule,
    NbCheckboxModule,
    NbSelectModule,
    NbDialogModule,
    NbIconModule,
    NbSpinnerModule,
    NbProgressBarModule,
    NbActionsModule,
    NbTabsetModule,
    NbTableModule,
    NbUserModule,
    NbAutocompleteModule,
    NbAlertModule,

    // Material Design
    MatStepperModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatAutocompleteModule,

    StoreModule.forFeature(
      fromCurrentInfoUser.currentUserInfoFeatureKey,
      fromCurrentInfoUser.reducer
    ),
    EffectsModule.forFeature([CurrentUserInfoEffects]),
    NgxMaskModule.forChild(),
    StoreModule.forFeature(flightPlan.flightPlanFeatureKey, flightPlan.reducer),
    EffectsModule.forFeature([FlightPlanEffects]),
    NgxMaskModule.forChild(),
    StoreModule.forFeature(
      registeredUser.registeredUserFeatureKey,
      registeredUser.reducer
    ),
    EffectsModule.forFeature([RegisteredUserEffects]),
    QRCodeModule,
  ],
  providers: [
    MatDatepickerModule,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-BS' },
  ],
  exports: [SafePipe],
})
export class PagesModule {}
