import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BUTTONS, CONSTANTS } from '@app/core/constants/constants.const';

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.scss']
})
export class NewPasswordFormComponent implements OnInit {

  constants = CONSTANTS;
  buttons = BUTTONS;
  newPasswordForm: FormGroup;

  /** Checks if the new password form has errors */
  hasError = false;

  /** Used to show / mask the text in the password fields */
  currentPasswordHide = true;
  newPasswordHide = true;
  confirmPasswordHide = true;

  /** Used to check if the form is for changing password or resetting password */
  @Input() isReset: boolean;
  @Output() newPassword = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initFormControls();
  }

  initFormControls(): void {
    if (this.isReset){
      this.newPasswordForm = this.formBuilder.group({
        newPassword: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(8)]],
      });
    }
    else {
      this.newPasswordForm = this.formBuilder.group({
        oldPassword: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(8)]],
        newPassword: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(8)]],
      });
    }
  }

  onSubmit(): void {
    if (this.newPasswordForm.valid) {
      this.newPassword.emit(this.newPasswordForm.value);
      this.hasError = false;
    } else {
      this.hasError = true;
    }
  }

  clear(): void {
    this.hasError = false;
  }

  /** Shows / Masks the password field
   * If the password is being shown, it will be
   * automatically be hidden after N seconds
   */
  hidePassword(passwordField: string): void {
    switch (passwordField) {
      case 'currentPasswordHide':
        this.currentPasswordHide = !this.currentPasswordHide;
        if (!this.currentPasswordHide) {
          setTimeout(() => {
            if (this.currentPasswordHide === false) {
              this.currentPasswordHide = true;
            }
          }, this.constants.PASSWORD_HIDE_TIMER);
        }
        break;
      case 'newPasswordHide':
        this.newPasswordHide = !this.newPasswordHide;
        if (!this.newPasswordHide) {
          setTimeout(() => {
            if (this.newPasswordHide === false) {
              this.newPasswordHide = true;
            }
          }, this.constants.PASSWORD_HIDE_TIMER);
        }
        break;
      case 'confirmPasswordHide':
        this.confirmPasswordHide = !this.confirmPasswordHide;
        if (!this.confirmPasswordHide) {
          setTimeout(() => {
            if (this.confirmPasswordHide === false) {
              this.confirmPasswordHide = true;
            }
          }, this.constants.PASSWORD_HIDE_TIMER);
        }
        break;
      default:
        break;
    }
  }
}
