<div class="page-header">
  <div class="center">
    <div class="row justify-content-between">
      <div class="col">
        <h2 class="text-haze-blue">Register a new flight plan</h2>
      </div>
      <div class="col">
        <div class="flight-list">
          <button
            type="button"
            matSuffix
            [attr.aria-label]="'Hide password'"
            color="accent"
            mat-flat-button
            class="cancel-button h-60 form-buttons"
            matTooltip="Go to List of Flight Plans"
            matTooltipClass="tooltipClass"
            matTooltipPosition="below"
            (click)="onBack()"
            id="backBtn"
          >
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="stepper-container">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <!-- FLIGHT DETAILS FORM -->
    <mat-step [stepControl]="flightDetailsForm">
      <form [formGroup]="flightDetailsForm">
        <!-- <ng-template matStepLabel>Flight Details</ng-template> -->
        <h4 class="center text-haze-blue">Flight Details</h4>
        <div class="center filing-date-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-label>Filing time (EST)</mat-label><br />
                <!-- <div class="specific-border"> -->
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input matInput readonly formControlName="filingTime" />
                </mat-form-field>
                <!-- </div> -->
              </div>
              <div class="col-md">
                <mat-label>Filing date</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input matInput readonly formControlName="filingDate" />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-label>Flight date</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  (click)="flightDatePicker.open()"
                >
                  <input
                    matInput
                    [matDatepicker]="flightDatePicker"
                    readonly
                    formControlName="flightDate"
                    [min]="minDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="flightDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #flightDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="center operation-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <!-- <mat-label></mat-label> <br> -->
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Type of operation</mat-label>
                  <mat-select
                    (selectionChange)="onSelect($event)"
                    formControlName="typeOperation"
                  >
                    <mat-option
                      required
                      *ngFor="let operation of operationTypes"
                      [value]="operation.value"
                    >
                      {{ operation.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Flight rules</mat-label>
                  <!-- test autocomplete flight rules -->
                  <mat-select
                    formControlName="flightRules"
                    (selectionChange)="onSelect($event)"
                  >
                    <mat-option
                      *ngFor="let rule of flightRules"
                      [value]="rule.value"
                    >
                      {{ rule.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Type of flight</mat-label>
                  <!-- test autocomplete flight types -->
                  <mat-select
                    formControlName="typeFlight"
                    (selectionChange)="onSelect($event)"
                  >
                    <mat-option
                      *ngFor="let flight of flightTypes"
                      [value]="flight.value"
                    >
                      {{ flight.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Aircraft identification</mat-label>
                  <input
                    maxlength="50"
                    matInput
                    name="aircraftIdentification"
                    id="aircraftIdentification"
                    formControlName="aircraftIdentification"
                    placeholder="Aircraft Identification"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  [class.mat-form-field-invalid-warning]="icaoWarning"
                  [class.warning]="icaoWarning"
                >
                  <mat-label>Type of aircraft (ICAO)</mat-label>
                  <!-- test autocomplete aircraft type -->
                  <input
                    type="text"
                    maxlength="10"
                    placeholder="Type of aircraft (ICAO)"
                    aria-label="Type of aircraft (ICAO)"
                    matInput
                    formControlName="aircraftType"
                    [matAutocomplete]="type"
                    (input)="onAircraftTypeChange($event)"
                  />
                  <span matSuffix class="down">
                    <mat-icon>arrow_drop_down</mat-icon>
                  </span>
                  <mat-autocomplete
                    #type="matAutocomplete"
                    (optionSelected)="onSelectAircraftType($event.option.value)"
                    [displayWith]="displayAircraftTypeFn"
                  >
                    <mat-option
                      *ngFor="let type of filteredAircraftTypes | async"
                      [value]="type"
                    >
                      {{ type.value }}
                    </mat-option>
                  </mat-autocomplete>
                  <!-- end test autocomplete -->
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                  <mat-hint class="warning" *ngIf="icaoWarning">{{
                    icaoErrorMsg
                  }}</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Number of motors</mat-label>
                  <input
                    name="numberMotors"
                    id="numberMotors"
                    type="number"
                    min="0"
                    maxlength="50"
                    matInput
                    formControlName="numberMotors"
                    placeholder="Number of Motors"
                    required
                  />
                  <mat-error>Error! only numbers are allowed.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Type of motor</mat-label>
                  <mat-select formControlName="typeMotors">
                    <mat-option
                      required
                      *ngFor="let motor of motorTypes"
                      [value]="motor.value"
                    >
                      {{ motor.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  [class.mat-form-field-invalid-warning]="mtowDisplayWarning"
                  [class.warning]="mtowDisplayWarning"
                >
                  <mat-label>MTOW (kg)</mat-label>
                  <input
                    name="mtow"
                    id="mtow"
                    type="number"
                    min="0"
                    maxlength="50"
                    matInput
                    formControlName="mtow"
                    placeholder="MTOW"
                    (input)="inputMtow(mtow.value)"
                    #mtow
                    required
                  />
                  <mat-error>Error! only numbers are allowed.</mat-error>
                  <mat-hint *ngIf="mtowDisplayWarning" class="warning"
                    >Estimated MTOW of this aircraft is below 10,000 kg. Charges
                    will be calculated accordingly.</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <button
            mat-button
            matStepperNext
            [disabled]="flightDetailsForm.invalid"
            class="next-button"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- DEPARTURE DETAILS FORM -->
    <mat-step [stepControl]="departureDetailsForm">
      <form [formGroup]="departureDetailsForm">
        <!-- <ng-template matStepLabel>Departure Details</ng-template> -->
        <h4 class="center text-haze-blue">Departure Details</h4>
        <div class="center aerodrome-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  [class.mat-form-field-invalid-warning]="deptAeroWarning"
                  [class.warning]="deptAeroWarning"
                >
                  <mat-label>Departure Aerodrome (ICAO)</mat-label>
                  <input
                    matInput
                    maxlength="4"
                    name="departureAerodrome"
                    id="departureAerodrome"
                    formControlName="departureAerodrome"
                    placeholder="Departure Aerodrome (ICAO)"
                    (input)="inputDeptAero(departure.value)"
                    #departure
                    required
                  />
                  <mat-error *ngIf="!deptAeroWarning"
                    >Error! The ICAO code is not correct. The length should be 4
                    characters.</mat-error
                  >
                  <mat-hint class="warning" *ngIf="deptAeroWarning">{{
                    icaoWarningMsg
                  }}</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  [class.mat-form-field-invalid-warning]="destAeroWarning"
                  [class.warning]="destAeroWarning"
                >
                  <mat-label>Destination Aerodrome (ICAO)</mat-label>
                  <input
                    matInput
                    maxlength="4"
                    name="destinationAerodrome"
                    id="destinationAerodrome"
                    formControlName="destinationAerodrome"
                    placeholder="Destination Aerodrome (ICAO)"
                    (input)="inputDestAero(destination.value)"
                    #destination
                    required
                  />
                  <mat-error *ngIf="!destAeroWarning"
                    >Error! The ICAO code is not correct. The length should be 4
                    characters.</mat-error
                  >
                  <mat-hint class="warning" *ngIf="destAeroWarning">{{
                    icaoWarningMsg
                  }}</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="center estimated-time-group">
                &nbsp;
                <mat-label class="label text">Estimated time enroute</mat-label>
                <div class="row">
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>hours</mat-label>
                      <input
                        type="number"
                        min="0"
                        name="estHours"
                        id="estHours"
                        maxlength="50"
                        matInput
                        formControlName="estimatedTimeEnrouteHours"
                        placeholder="hours"
                        required
                      />
                      <mat-error>Error! Only numbers are allowed.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>minutes</mat-label>
                      <input
                        type="number"
                        min="0"
                        max="59"
                        name="estMinutes"
                        id="estMinutes"
                        maxlength="50"
                        matInput
                        formControlName="estimatedTimeEnrouteMinutes"
                        placeholder="minutes"
                        oninput="this.value=this.value.slice(0, this.maxLength)"
                        (input)="
                          inputMinutes(
                            estminutes.value,
                            'estimatedTimeEnrouteMinutes'
                          )
                        "
                        #estminutes
                        required
                      />
                      <mat-error>
                        Error! Input should be less than 60 minutes.</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="center fuel-endurance-group">
                &nbsp; <mat-label class="label text">Fuel endurance</mat-label>
                <div class="row">
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>hours</mat-label>
                      <input
                        type="number"
                        min="0"
                        maxlength="50"
                        name="enduranceHours"
                        id="enduranceHours"
                        matInput
                        formControlName="fuelEnduranceHours"
                        placeholder="hours"
                        required
                      />
                      <mat-error>Error! Only numbers are allowed.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>minutes</mat-label>
                      <input
                        type="number"
                        min="0"
                        max="59"
                        maxlength="50"
                        name="enduranceMinutes"
                        id="enduranceMinutes"
                        matInput
                        formControlName="fuelEnduranceMinutes"
                        placeholder="minutes"
                        oninput="this.value=this.value.slice(0, this.maxLength)"
                        (input)="
                          inputMinutes(endminutes.value, 'fuelEnduranceMinutes')
                        "
                        #endminutes
                        required
                      />
                      <mat-error
                        >Error! Input should be less than 60 minutes.</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Other Information (ADIZ, ADCUS, ETC.)</mat-label>
                  <input
                    name="otherInfo"
                    id="otherInfo"
                    maxlength="50"
                    matInput
                    formControlName="otherInformation"
                    placeholder="Other Information"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="center off-block-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="center estimated-time-group">
                &nbsp;
                <mat-label class="label text">Off block time (EST)</mat-label>
                <div class="row">
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>hour</mat-label>
                      <input
                        type="number"
                        min="0"
                        max="23"
                        name="offBlockHour"
                        id="offBlockHour"
                        maxlength="2"
                        matInput
                        formControlName="offBlockHour"
                        placeholder="hour"
                        oninput="this.value=this.value.slice(0, this.maxLength)"
                        (input)="inputHours(offBlockHour.value, 'offBlockHour')"
                        #offBlockHour
                        required
                      />
                      <mat-error
                        >Error! Input should be less than 24 hours.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <p class="time-seperator">:</p>
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>minute</mat-label>
                      <input
                        type="number"
                        min="0"
                        max="59"
                        name="offBlockMinute"
                        id="offBlockMinute"
                        maxlength="2"
                        matInput
                        formControlName="offBlockMinute"
                        placeholder="minute"
                        oninput="this.value=this.value.slice(0, this.maxLength)"
                        (input)="
                          inputMinutes(offBlockMinute.value, 'offBlockMinute')
                        "
                        #offBlockMinute
                        required
                      />
                      <mat-error>
                        Error! Input should be less than 60 minutes.</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="center fuel-endurance-group">
                &nbsp;
                <mat-label class="label text">Departure time (EST)</mat-label>
                <div class="row">
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>hour</mat-label>
                      <input
                        type="number"
                        min="0"
                        max="23"
                        maxlength="2"
                        name="departureHour"
                        id="departureHour"
                        matInput
                        formControlName="departureHour"
                        placeholder="hour"
                        oninput="this.value=this.value.slice(0, this.maxLength)"
                        (input)="
                          inputHours(departureHour.value, 'departureHour')
                        "
                        #departureHour
                        required
                      />
                      <mat-error
                        >Error! Input should be less than 24 hours.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <p class="time-seperator">:</p>
                  <div class="col-md">
                    <mat-form-field appearance="outline" class="width-1">
                      <mat-label>minute</mat-label>
                      <input
                        type="number"
                        min="0"
                        max="59"
                        maxlength="2"
                        name="departureMinute"
                        id="departureMinute"
                        matInput
                        formControlName="departureMinute"
                        placeholder="minutes"
                        oninput="this.value=this.value.slice(0, this.maxLength)"
                        (input)="
                          inputMinutes(departureMinute.value, 'departureMinute')
                        "
                        #departureMinute
                        required
                      />
                      <mat-error
                        >Error! Input should be less than 60 minutes.</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Cruising speed (TAS-knots)</mat-label>
                  <input
                    name="cruisingSpeed"
                    id="cruisingSpeed"
                    maxlength="14"
                    matInput
                    formControlName="cruisingSpeed"
                    placeholder="Cruising Speed"
                    (input)="inputCruisingSpeed(speed.value)"
                    #speed
                    required
                  />
                  <mat-error>{{ cruisingSpeedMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Cruising level</mat-label>
                  <input
                    name="cruisingLevel"
                    id="cruisingLevel"
                    maxlength="50"
                    matInput
                    formControlName="cruisingAltitude"
                    placeholder="FLXXX"
                    required
                    class="cancel-button h-60 form-buttons"
                    matTooltip='Please enter your 
                    expected cruising altitude, 
                    you can introduce "FLXXX" or either cruising altitude in [ft]'
                    matTooltipClass="tooltipClass"
                    matTooltipPosition="above"
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="center flight-route-group">
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-md-12">
                  <div formArrayName="flightRoutes">
                    <div class="row">
                      <div
                        class="col-md-3"
                        *ngFor="
                          let flightRoute of flightRoutes?.controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <mat-form-field appearance="outline" class="width-1">
                          <mat-label
                            >Route
                            <span *ngIf="flightRoute.invalid" class="asterisk"
                              >{{ constants.ASTERISK }}
                            </span>
                          </mat-label>
                          <input
                            matInput
                            name="flightRoute"
                            id="flightRoute"
                            formControlName="flightRoute"
                            placeholder="Flight route"
                            (input)="InputFlightRoute(route.value, i)"
                            #route
                          />
                          <mat-error> {{ requiredFieldMsg }}</mat-error>
                          <!-- <mat-error> {{ constants.FIVE_CHARACTERS }}</mat-error> -->
                        </mat-form-field>
                      </div>
                      <div class="col-md-1 add-route">
                        <button
                          mat-mini-fab
                          color="accent"
                          mat-flat-button
                          class="cancel-button h-60 form-buttons"
                          [disabled]="!enableAddRoute"
                          (click)="addRoute()"
                        >
                          <mat-icon>add</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center alter-icao-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  [class.mat-form-field-invalid-warning]="alterAeroWarning"
                  [class.warning]="alterAeroWarning"
                >
                  <mat-label>Alternate aerodrome(ICAO)</mat-label>
                  <input
                    matInput
                    maxlength="4"
                    name="altAeroIcao"
                    id="altAeroIcao"
                    formControlName="alternateAerodrome"
                    placeholder="Alternate Aerodrome"
                    (input)="inputAlterAero(alternate.value)"
                    #alternate
                    required
                  />
                  <mat-error *ngIf="!alterAeroWarning"
                    >Error! The ICAO code is not correct. The length should be 4
                    characters.</mat-error
                  >
                  <mat-hint class="warning" *ngIf="alterAeroWarning">{{
                    icaoWarningMsg
                  }}</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field
                  appearance="outline"
                  class="width-1"
                  [class.mat-form-field-invalid-warning]="secAlterAeroWarning"
                  [class.warning]="secAlterAeroWarning"
                >
                  <mat-label>2nd Alternate aerodrome(ICAO)</mat-label>
                  <input
                    matInput
                    maxlength="4"
                    name="altAeroIcao2"
                    id="altAeroIcao2"
                    formControlName="secondAlternateAerodrome"
                    placeholder="2nd Alternate Aerodrome"
                    (input)="inputSecAltAero(secondAlt.value)"
                    #secondAlt
                  />
                  <mat-error *ngIf="!secAlterAeroWarning"
                    >Error! The ICAO code is not correct. The length should be 4
                    characters.</mat-error
                  >
                  <mat-hint class="warning" *ngIf="secAlterAeroWarning">{{
                    icaoWarningMsg
                  }}</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="center">
          <button
            mat-button
            color="accent"
            mat-flat-button
            class="cancel-button h-60 form-buttons"
            matStepperPrevious
          >
            Back
          </button>
          <button
            mat-button
            matStepperNext
            [disabled]="departureDetailsForm.invalid"
            class="next-button"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- EMERGENCY DETAILS FORM -->
    <mat-step [stepControl]="emergencyDetailsForm">
      <form [formGroup]="emergencyDetailsForm">
        <!-- <ng-template matStepLabel>Emergency Details</ng-template> -->
        <h4 class="center text-haze-blue">Emergency Details</h4>
        <div class="center emergency-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Emergency radio</mat-label>
                  <input
                    name="emergencyRadio"
                    id="emergencyRadio"
                    maxlength="50"
                    matInput
                    formControlName="emergencyRadio"
                    placeholder="Emergency Radio"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Number of life jackets and color</mat-label>
                  <input
                    name="numLifeJacketsColor"
                    id="numLifeJacketsColor"
                    maxlength="50"
                    matInput
                    formControlName="numberLifeJacketsColor"
                    placeholder="Number of life jackets and color"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Aircraft color and markings</mat-label>
                  <input
                    name="aircraftColor"
                    id="aircraftColor"
                    maxlength="50"
                    matInput
                    formControlName="aircraftColor"
                    placeholder="Aircraft color and markings"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Number of dinghies</mat-label>
                  <input
                    name="dinghies"
                    id="dinghies"
                    maxlength="50"
                    matInput
                    formControlName="dinghiesNumber"
                    placeholder="Number of dinghies"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Capacity of dinghies</mat-label>
                  <input
                    name="capacity"
                    id="capacity"
                    maxlength="50"
                    matInput
                    formControlName="dinghiesCapacity"
                    placeholder="Capacity of dinghies"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Color of dinghies</mat-label>
                  <input
                    name="dinghiesColor"
                    id="dinghiesColor"
                    maxlength="50"
                    matInput
                    formControlName="dinghiesColor"
                    placeholder="Color of dinghies"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="center">
          <button
            mat-button
            color="accent"
            mat-flat-button
            class="cancel-button h-60 form-buttons"
            matStepperPrevious
          >
            Back
          </button>
          <button
            mat-button
            matStepperNext
            [disabled]="emergencyDetailsForm.invalid"
            class="next-button"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- PILOT DETAILS FORM -->
    <mat-step [stepControl]="pilotDetailsForm">
      <form [formGroup]="pilotDetailsForm">
        <!-- <ng-template matStepLabel>Pilot Details</ng-template> -->
        <h4 class="center text-haze-blue">Pilot Details</h4>
        <div class="center pilot-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Pilot-in-command</mat-label>
                  <input
                    name="pilotName"
                    id="pilotName"
                    maxlength="50"
                    matInput
                    formControlName="name"
                    placeholder="Pilot-in-command"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Pilot's phone</mat-label>
                  <input
                    formControlName="phone"
                    name="pilotPhone"
                    id="pilotPhone"
                    placeholder="Pilot's phone number"
                    autocomplete="off"
                    matInput
                    required
                  />
                  <mat-error>Error! only numbers are allowed.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Pilot's email</mat-label>
                  <input
                    formControlName="email"
                    name="email"
                    id="email"
                    placeholder="email@address.com"
                    autocomplete="off"
                    type="text"
                    matInput
                    required
                  /><mat-error>Error! email is incorrect</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Pilot's address</mat-label>
                  <input
                    name="pilotAddress"
                    id="pilotAddress"
                    maxlength="250"
                    matInput
                    formControlName="address"
                    placeholder="Pilot's address"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Pilot's Aircraft Home Base</mat-label>
                  <input
                    name="aircraftHomeBase"
                    id="aircraftHomeBase"
                    maxlength="50"
                    matInput
                    formControlName="aircraftHomeBase"
                    placeholder="Pilot's Aircraft Home Base"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="center">
          <button
            mat-button
            color="accent"
            mat-flat-button
            class="cancel-button h-60 form-buttons"
            matStepperPrevious
          >
            Back
          </button>
          <button
            mat-button
            matStepperNext
            [disabled]="pilotDetailsForm.invalid"
            class="next-button"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- PASSENGER DETAILS -->
    <mat-step [stepControl]="passengerDetailsForm">
      <form [formGroup]="passengerDetailsForm">
        <!-- <ng-template matStepLabel>Passenger Details</ng-template> -->
        <h4 class="center text-haze-blue">Passenger Details</h4>
        <div class="passenger-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Method of closure</mat-label>
                  <input
                    name="methodClosure"
                    id="methodClosure"
                    maxlength="250"
                    matInput
                    formControlName="methodClosure"
                    placeholder="Method of closure"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="width-1">
                  <mat-label>Equipment</mat-label>
                  <input
                    name="otherRemarks"
                    id="otherRemarks"
                    maxlength="250"
                    matInput
                    formControlName="otherEquipmentRemarks"
                    placeholder="Equipment"
                    required
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-form-field appearance="outline" class="total width-1">
                  <mat-label>SOB</mat-label>
                  <input
                    name="totalNumberPersonsOnBoard"
                    id="totalNumberPersonsOnBoard"
                    type="number"
                    min="0"
                    maxlength="50"
                    matInput
                    formControlName="totalNumberPersonsOnBoard"
                    placeholder="Total number of persons on board"
                    (input)="inputTotal(total.value)"
                    #total
                    appNumbersSpecial
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="total width-1">
                  <mat-label>No. of U.S.</mat-label>
                  <input
                    name="numberOfUSPassenger"
                    id="numberOfUSPassenger"
                    type="number"
                    min="0"
                    maxlength="50"
                    matInput
                    formControlName="numberOfUSPassenger"
                    placeholder="No. of U.S."
                    appNumbersSpecial
                    required
                  />
                  <mat-error>
                    {{ requiredFieldMsg }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field appearance="outline" class="total width-1">
                  <mat-label>No. of non-U.S.</mat-label>
                  <input
                    name="numberOfNonUSPassenger"
                    id="numberOfNonUSPassenger"
                    type="number"
                    min="0"
                    maxlength="50"
                    matInput
                    formControlName="numberOfNonUSPassenger"
                    placeholder="No. of non-U.S."
                    required
                    appNumbersSpecial
                  />
                  <mat-error>{{ requiredFieldMsg }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div formArrayName="passenger">
            <div
              *ngFor="let passengerGroup of passenger?.controls; let i = index"
              [formGroupName]="i"
            >
              <div class="passenger-details-group">
                <div class="container">
                  <div class="row justify-content-start">
                    <br />
                    <p>{{ i + 1 }}</p>
                    <div class="col-md">
                      <mat-form-field appearance="outline" class="width-1">
                        <mat-label>Passenger's name</mat-label>
                        <input
                          maxlength="50"
                          name="passengersName"
                          id="passengersName"
                          matInput
                          formControlName="name"
                          placeholder="Passenger's name"
                        />
                        <mat-error>{{ requiredFieldMsg }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md">
                      <mat-form-field appearance="outline" class="width-1">
                        <mat-label>Passenger's address</mat-label>
                        <input
                          maxlength="250"
                          name="passengersAddress"
                          id="passengersAddress"
                          matInput
                          formControlName="address"
                          placeholder="Passenger's address"
                        />
                        <mat-error>{{ requiredFieldMsg }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md">
                      <mat-form-field appearance="outline" class="width-1">
                        <mat-label>Passenger's emergency phone</mat-label>
                        <input
                          maxlength="50"
                          name="passengersPhone"
                          id="passengersPhone"
                          matInput
                          formControlName="phone"
                          placeholder="Passenger's emergency phone"
                          #pass
                        />
                        <mat-error
                          >Error! Please enter a valid phone number.</mat-error
                        >
                      </mat-form-field>
                    </div>
                    <div class="col-md">
                      <mat-form-field appearance="outline" class="width-1">
                        <mat-label>Passenger emergency contact</mat-label>
                        <input
                          formControlName="emergencyContact"
                          maxlength="50"
                          name="emergencyContact"
                          id="emergencyContact"
                          placeholder="Passenger emergency contact"
                          autocomplete="off"
                          type="text"
                          matInput
                          #email
                        />
                        <mat-error>{{ requiredFieldMsg }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 add-passenger">
              <button
                mat-button
                color="accent"
                mat-flat-button
                class="cancel-button h-60 form-buttons"
                [disabled]="!enableAddPassenger"
                (click)="addPassenger()"
              >
                Add Passenger
              </button>
            </div>
          </div>
        </div>

        <br />
        <div class="center">
          <button
            mat-button
            color="accent"
            mat-flat-button
            class="cancel-button h-60 form-buttons"
            matStepperPrevious
          >
            Back
          </button>
          <button
            mat-button
            class="next-button"
            [disabled]="passengerDetailsForm.invalid"
            (click)="onSubmit()"
          >
            Submit flight plan
          </button>
          <button
            mat-button
            [hidden]="true"
            matStepperNext
            id="feesBreakdownBtn"
          >
            To Fees Breakdown
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step class="last-page">
      <form [formGroup]="feesBreakdownForm">
        <!-- <ng-template matStepLabel>Fees Breakdown</ng-template> -->
        <h4 class="center text-haze-blue">Fees Breakdown</h4>
        <div class="center fees-breakdown-group">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-label>ATC charge (BSD)</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input
                    formControlName="atcCharge"
                    maxlength="50"
                    matInput
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-label>Tax amount (VAT, BSD)</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input
                    formControlName="taxAtc"
                    maxlength="50"
                    matInput
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-label>Subtotal amount (BSD)</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input
                    formControlName="subtotalAtc"
                    maxlength="50"
                    matInput
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col-md">
                <mat-label>Passenger charge (BSD)</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input
                    formControlName="passengerCharge"
                    maxlength="50"
                    matInput
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-label>Tax amount (VAT, BSD)</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input
                    formControlName="taxPassenger"
                    maxlength="50"
                    matInput
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-label>Subtotal amount (BSD)</mat-label><br />
                <mat-form-field
                  appearance="outline"
                  class="width-1 specific-border"
                >
                  <input
                    formControlName="subtotalPassenger"
                    maxlength="50"
                    matInput
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="center">
            <mat-label>TOTAL PAYMENT (BSD)</mat-label>
          </div>
          <mat-form-field
            appearance="outline"
            class="center width-2 specific-border"
          >
            <input
              formControlName="totalPayment"
              maxlength="50"
              matInput
              readonly
              required
            />
          </mat-form-field>
        </div>
        <div class="center" *ngIf="!paymentBaseUrl || isFreeTax">
          <button
            mat-button
            class="next-button"
            (click)="submitFlightPlan()"
            id="paymentbtn"
          >
            Submit Flight Plan
          </button>
        </div>
        <div class="center" *ngIf="paymentBaseUrl && !isFreeTax">
          <button
            mat-button
            class="next-button"
            (click)="onProcess()"
            id="paymentbtn"
          >
            Proceed to payment
          </button>
          <button
            mat-button
            [hidden]="true"
            matStepperNext
            id="processPaymentBtn"
          >
            To Fees Breakdown
          </button>
          <button
            mat-button
            class="next-button"
            (click)="submitFlightPlan(true)"
            id="paymentbtn"
          >
            Settle Payment with IATA
          </button>
        </div>
      </form>
    </mat-step>

    <!-- PAYMENT DETAILS -->
    <mat-step class="last-page" *ngIf="paymentBaseUrl !== null">
      <form [formGroup]="feesBreakdownForm">
        <h4 class="center text-haze-blue">Payment</h4>
        <div class="container">
          <div class="row">
            <div class="col-2"></div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <app-payment-portal
                [totalAmount]="totalAmount"
                [flightPlanDetails]="formdata"
                [paymentBaseUrl]="paymentBaseUrl"
                [paymentHostedPage]="paymentHostedPage"
                *ngIf="totalAmount !== undefined && enablePayment"
              >
              </app-payment-portal>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
