// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '0.6.24.2',
  api: 'https://bansa-dev.unow.es/bahamas-api',
  base_url: 'https://bansa-dev.unow.es/',
  // api: 'https://bahamas-api.indracompany.com.ph',
  // base_url: 'http://localhost:4200/',
  CAAB_url: 'https://caabahamas.com/',
  year_published: 2021,
  owner: 'Indra',
  watch_idle: false,
  idle: 10000,
  timeout: 10000,
  support_email: 'Bahamas.support@alg-global.com',
  copyright_year: 2022,
  cookie_name: 'caab_sess_id',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
