import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileElement } from '@app/core/models/file-element';
import { FileService } from '@app/core/services/file.service';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss'],
})
export class RenameDialogComponent implements OnInit {
  folderName: string;
  element: FileElement;
  hasSpecialCharacters: boolean;
  folderNameFormControl: any;
  isExist: boolean;
  parentElement: FileElement;
  maxLength: string;
  isValid: boolean;

  constructor(
    public fileService: FileService,
    private dialogRef: MatDialogRef<RenameDialogComponent>
  ) {}

  ngOnInit(): void {
    this.folderName = this.element.name.trim();
    this.hasSpecialCharacters = false;
    this.isExist = false;
    this.isValid = false;
    this.folderNameFormControl = new FormControl(this.folderName);
  }

  checkFolderName(): boolean {
    this.hasSpecialCharacters = this.fileService.checkNameFormat(
      this.folderName
    );

    return this.fileService.checkName(
      this.folderName,
      this.hasSpecialCharacters,
      this.isExist
    );
  }

  checkForSpaces(): boolean {
    return (
      this.folderName.replace(/ {2,}/g, ' ').trim() === this.element.name.trim()
    );
  }

  checkName(): void {
    this.hasSpecialCharacters = this.fileService.checkNameFormat(
      this.folderName
    );

    if (
      this.folderName.toLowerCase() !== this.element?.name.toLowerCase().trim()
    ) {
      this.isExist = this.fileService.checkExisting(
        this.folderName,
        this.parentElement?.id
      );
    }

    if (this.hasSpecialCharacters || this.isExist) {
      this.folderNameFormControl.setErrors({ error: true });
    } else {
      this.folderNameFormControl.setErrors(null);
      this.isValid = true;
      this.dialogRef.close();
    }
  }
}
