import { createReducer, on } from '@ngrx/store';
import { CurrentUserInfo } from './current-user-info.model';
import { getCurrentUserInfo, getCurrentUserInfoSuccess, getCurrentUserInfoFailure } from './current-user-info.actions';

export const currentUserInfoFeatureKey = 'currentUserInfo';

export interface CurrentUserInfoState {
    currentUserInfo: CurrentUserInfo[];
    error?: any;
}

export const initialState: CurrentUserInfoState = {
    currentUserInfo: undefined,
};


export const reducer = createReducer(
    initialState,

    on(getCurrentUserInfo, (state) => ({ ...state })),

    on(getCurrentUserInfoSuccess, (state, action) => ({ ...state, currentUserInfo: action.response })),

    on(getCurrentUserInfoFailure, (state, action) => ({ ...state, error: action.error })),

);

