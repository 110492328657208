<div class="page-container">
  <div class="content-wrapper">
    <div class="align-center">
      <div class="bahamas-logo">
        <!-- <img src="../../../../assets/images/bahamas.png" alt="" /> -->
        <img src="../../../../assets/images/BANSA logo.png" alt="" />
      </div>
      <p class="text-white subtitle">
        {{ CONSTANTS.BAHAMAS_LOADING }}
      </p>
      <br />
      <p class="getting-ready">We are getting things ready for you</p>
      <!-- SPINNER START -->
      <div class="loadingio-spinner-ripple-g6un6fvr9xb">
        <div class="ldio-3yn8zx7svlr">
          <div></div>
          <div></div>
        </div>
      </div>
      <!-- SPINNER END -->
    </div>
  </div>
  <div class="footer-texts">
    <p>
      Copyright &copy; {{ copyrightYear }} Bahamas.
      <br />
      All rights reserved. Version {{ appVersion }}
    </p>
  </div>
</div>
