import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/constants.const';
import { AuthService } from '@app/core/services/auth/auth.service';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss'],
})
export class ActivateUserComponent implements OnInit {
  const = CONSTANTS;

  activationToken = '';
  isLoading = true;
  responseMessage: string;
  hasError = false;

  countDown: Subscription;
  counter = 15;
  tick = 1000;
  stopCounter = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.userRoleLogin();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        const requestBody = {
          activationToken: params.token,
        };

        this.isLoading = false;

        this.authService
          .activateAccount(requestBody)
          .toPromise()
          .then(
            (res: any) => {
              this.activationToken = params.token;
              this.responseMessage = res.message;
              this.isLoading = false;
              this.redirectToLogin();
            },
            (error) => {
              this.hasError = true;
              this.responseMessage = error.error.clientError;
              this.isLoading = false;
              this.redirectToLogin();
            }
          );
      } else {
        this.isLoading = false;
        this.redirectToLogin();
      }
    });
  }

  redirectToLogin(): void {
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (!this.stopCounter) {
        this.counter--;
        if (!this.counter) {
          window.location.href = 'login';
        }
      }
    });
  }

  counterStop(): void {
    this.stopCounter = true;
  }

  userRoleLogin(): void {
    if (this.authService.getUser()) {
      this.router.navigate(['/home'], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
      });
    }
  }
}
