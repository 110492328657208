import { AfterViewChecked, HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth/auth.service';
import { MenuItem } from '../core/models/menu-item';
import { CurrentUserInfoState } from '@app/core/store/current-user-info/current-user-info.reducer';
import { Store } from '@ngrx/store';
import { DataService } from '@app/shared/services/data.service';
import { Subscription } from 'rxjs';
import { MENU_ITEMS } from '@app/core/constants/constants.const';
import { Router } from '@angular/router';
import { CryptoService } from '@app/core/services/crypto/crypto.service';
import { NbSidebarService } from '@nebular/theme';
import * as moment from 'moment';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, AfterViewChecked {
  // class="scrollable" style="max-height: 80vh"
  innerWidth: any;
  isCompacted: string;
  menuItems: MenuItem[] = MENU_ITEMS;
  userInfo;
  loading = true;
  subscriptions = new Subscription();
  logoutMsg = `We'll see you around!`;
  isLoggingout = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private cryptoService: CryptoService,
    private sidebarService: NbSidebarService
  ) {
    // adcasorla - Sets the storage host for outside access
    this.authService.setStorageHost();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.setIsCompacted(this.innerWidth);
    // loader
    this.loading = true;
    const currentUser = this.cryptoService.getStorageItem('currentUser');
    if (currentUser) {
      if (this.router.url === '/' && !currentUser) {
        this.router.navigate([`/login`]);
      } else {
        const url = this.router.url === '/' ? '/home' : this.router.url;
        if (!this.authService.isTokenExpired()) {
          this.router.navigate([url]);
        } else {
          this.router.navigate([`/login`]);
        }
      }
    } else {
      this.router.navigate([`/login`]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
    this.setIsCompacted(this.innerWidth);
  }

  setIsCompacted(screenWidth): void {
    if (screenWidth <= 768) {
      this.isCompacted = 'compacted';
      // this.sidebarService.compact('left');
    } else {
      this.isCompacted = null;
      // this.sidebarService.toggle(false, 'left');
    }
  }

  ngAfterViewChecked(): void {
    this.loading = false;
  }
}
