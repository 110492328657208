import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotiflixService } from '@app/shared/services/notiflix.service';
import { environment } from '@environments/environment';
import { FileSaverService } from 'ngx-filesaver';
import { API_ENPOINTS } from '../constants/api-endpoints';
import { ERROR_MESSAGES } from '../constants/error-messages';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  BACKEND_ENDPOINT = environment;
  API_ROUTES = API_ENPOINTS;

  constructor(
    private http: HttpClient,
    private fileSaverService: FileSaverService,
    private notiflixService: NotiflixService
  ) {}

  generateFlightPlanPdf(id: number): any {
    this.notiflixService.loadPulse();
    return this.http
      .get(`${this.BACKEND_ENDPOINT.api}${this.API_ROUTES.flightPlanPDF}/${id}`)
      .subscribe(
        (res) => {
          this.downloadPdf(res);
        },
        (err) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchNotify(
            'error',
            err.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
          );
        }
      );
  }

  downloadPdf(pdf): any {
    const filename = pdf.path.split('/').pop();
    this.http
      .get(pdf.path, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(
        (res) => {
          this.fileSaverService.save(res.body, filename);
          this.notiflixService.closeLoader();
        },
        (err) => {
          this.notiflixService.closeLoader();
          this.notiflixService.launchNotify(
            'error',
            err.error?.clientError ?? ERROR_MESSAGES.GENERAL_ERROR
          );
        }
      );
  }
}
