import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TableContents } from '@app/core/models/table-contents.model';
import {
  getDownloadFees,
  getFeesPaymentBreakdown,
} from '@app/core/store/flight-plan/flight-plan.action';
import {
  DownloadFees,
  FeesPaymentBreakdown,
} from '@app/core/store/flight-plan/flight-plan.model';
import { FlightPlanState } from '@app/core/store/flight-plan/flight-plan.reducer';
import {
  selectDownloadFees,
  selectFeesBreakdownResponse,
} from '@app/core/store/flight-plan/flight-plan.selector';
import { ViewFlightPlanComponent } from '@app/pages/view-pages/flight-plan/view-flight-plan/view-flight-plan.component';
import { DataService } from '@app/shared/services/data.service';
import { select, Store } from '@ngrx/store';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Router } from '@angular/router';
import { CryptoService } from '@app/core/services/crypto/crypto.service';

@Component({
  selector: 'app-fees-payment-record',
  templateUrl: './fees-payment-record.component.html',
  styleUrls: ['./fees-payment-record.component.scss'],
})
export class FeesPaymentRecordComponent implements OnInit {
  tableContents: TableContents;
  flightPlanList: FeesPaymentBreakdown[];
  loadingIndicator$ = false;
  advancedSearchKeys: any;
  csvData: DownloadFees[];
  value = '';
  type = '';
  filteredPaymentFees: any;
  systemPaymentFees: any;
  tempFiltered: any;

  constructor(
    private dataService: DataService,
    private store: Store<FlightPlanState>,
    public dialog: MatDialog,
    private cryptoService: CryptoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.flightPlanList = [];
    this.getFeesPaymentBreak();
    this.initTableContents();
    this.getDownloadFees();
  }

  initTableContents(): void {
    this.tableContents = {
      tableName: 'fees-payment-record-table',
      noGenericSearch: true,
      isRowClickable: false,
      columns: [
        {
          prop: 'id',
          label: 'Ref',
          type: 'number',
          alignment: 'left',
          minWidth: '50px',
          maxWidth: '50px',
        },
        {
          prop: 'filingDate',
          label: 'Date (EST)',
          type: 'date',
          alignment: 'left',
          minWidth: '90px',
          maxWidth: '90px',
        },
        {
          prop: 'aircraftIdentification',
          label: 'Aircraft Identification',
          type: 'code',
          alignment: 'center',
          minWidth: '85px',
          maxWidth: '85px',
        },
        {
          prop: 'departureAerodrome',
          label: 'Departure Aerodrome',
          type: 'code',
          alignment: 'center',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'destinationAerodrome',
          label: 'Destination Aerodrome',
          type: 'code',
          alignment: 'center',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'userCarrier',
          label: 'Carrier Name',
          type: 'string',
          alignment: 'left',
          minWidth: '100px',
          maxWidth: '100px',
        },
        {
          prop: 'aircraftType',
          label: 'Type of Aircraft (ICAO)',
          type: 'code',
          alignment: 'center',
          minWidth: '100px',
          maxWidth: '100px',
        },
        {
          prop: 'mtow',
          label: 'MTOW(kg)',
          type: 'number',
          alignment: 'right',
          minWidth: '60px',
          maxWidth: '60px',
        },
        {
          prop: 'paymentatcCharge',
          label: 'ATC Charge (BSD)',
          type: 'float',
          alignment: 'right',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'paymenttotalPayment',
          label: 'Total Payment (BSD)',
          type: 'float',
          alignment: 'right',
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          prop: 'paymentStatus',
          label: 'Payment Status',
          type: 'stringLeftAlign',
          alignment: 'left',
          minWidth: '120px',
          maxWidth: '120px',
        },
        {
          prop: 'status',
          label: 'Status',
          type: 'stringLeftAlign',
          alignment: 'left',
          minWidth: '120px',
          maxWidth: '120px',
        },
        {
          prop: 'userName',
          label: 'Operator/ Owner Name',
          type: 'string',
          alignment: 'left',
          minWidth: '120px',
          maxWidth: '120px',
        },
        {
          prop: 'actions',
          label: '',
          type: 'action',
          defWidth: '5px',
        },
      ],
    };
  }

  getDownloadFees(): void {
    this.store.dispatch(getDownloadFees());
    this.store.pipe(select(selectDownloadFees)).subscribe((data) => {
      if (data) {
        this.csvData = data;
      }
    });
  }

  getFeesPaymentBreak(): void {
    this.store.dispatch(getFeesPaymentBreakdown());
    this.store.pipe(select(selectFeesBreakdownResponse)).subscribe((data) => {
      if (data) {
        this.flightPlanList = data ? data : [];
      }
    });
  }

  onViewDetails(event): void {
    const modalConfig = {
      disableClose: true,
      autoFocus: false,
      width: '1000px',
      maxHeight: '750px',
    };

    const dialogRef: MatDialogRef<ViewFlightPlanComponent> = this.dialog.open(
      ViewFlightPlanComponent,
      modalConfig
    );
    dialogRef.componentInstance.data = event.id;
  }

  onNavigate(url): void {
    this.router.navigateByUrl(
      this.cryptoService.isAdmin() ? url : '/forbidden'
    );
  }

  fileDownload(): void {
    if (this.cryptoService.isAdmin()) {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Fees Payment Record',
        useBom: true,
        noDownload: false,
        headers: [
          'Ref',
          'Date (UTC)',
          'Aircraft identification',
          'Departure Aerodrome',
          'Destination Aerodrome',
          'Carrier name',
          'Type of aircraft (ICAO)',
          'MTOW(kg)',
          'Overflight',
          'O/D',
          'ATC charge (BSD)',
          'Tax ATC amount VAT',
          'Subtotal ATC amount (BSD)',
          'Passenger charge',
          'Tax passenger amount (VAT BSD)',
          'Subtotal passenger amount (BSD)',
          'Total payment(BSD)',
          'Payment Status',
          'Status',
          'Operator/Owner name',
          'Email',
          'Street',
          'City',
          'State',
          'Zipcode',
          'Country',
          'Passengers',
        ],
      };

      if (this.csvData.length > 0) {
        // tslint:disable-next-line:no-unused-expression
        new ngxCsv(this.csvData, 'Fees payment record', options);
      }
    } else {
      this.router.navigateByUrl('/forbidden');
    }
  }

  onPress(): void {
    if (this.value === '' && this.type === '') {
      this.filteredPaymentFees = this.systemPaymentFees;
    } else {
      if (this.type === '') {
        this.filteredPaymentFees = [];
        this.systemPaymentFees.map((data) => {
          if (data.value.includes(this.value)) {
            this.filteredPaymentFees.push(data);
          }
        });
      }
      if (this.type !== '') {
        this.filteredPaymentFees = [];
        this.tempFiltered.map((data) => {
          if (data.value.includes(this.value)) {
            this.filteredPaymentFees.push(data);
          }
        });
      }
      // if (this.type !== '' && this.value === '') {
      //   this.onSelect();
      // }
    }
  }
}
